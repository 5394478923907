import React from 'react'
import Container from './Container'
import ContainerChild from './ContainerChild'

export default class Tabs extends Container {
    static cn = 'tabs'
    constructor(props) {
        super(props)
        this.state = { active: this.props.initial }
    }

    render() {
        const children = [].concat(this.props.children)
        const { optionsCenter, optionsLeft, extraTabContent } = this.props
        
        return this.wrapper(<>
            <div className={['tabs-header line', this.props.headerCN].join(' ')}>
                {Tabs.Tab.filter(children).map(tab => (
                    <div key={tab.props.name} className={['tab-title', tab.props.name === this.getActive() && 'active', 'line-item'].join(' ')} onClick={this.toggleTabs(tab.props.name)}>{tab.props.title || tab.props.name}</div>
                ))}
                {!optionsCenter && !optionsLeft && <div className='line-stretch'/>}
                {Tabs.Options.filter(children).map((option, index) => {
                    if (!option.props.forTabs || option.props.forTabs.includes(this.getActive())) return <div key={'tab-option-' + index} className={'line-item ' + (option.props.className || '')}>{option}</div>
                    return ''
                })}
                {optionsCenter && <div className='line-stretch'/>}
                {extraTabContent}
            </div>
            
            <div className={['tabs-body', this.props.bodyClassName].join(' ')}>
                {children.map(child => {
                    if (Tabs.Tab.isChild(child)) {
                        if (child.props.name === this.getActive()) return <div key={child.props.name} className={['tab', this.props.tabCN, child.props.className].join(' ')}>{child}</div>
                    } else if (!Tabs.Options.isChild(child)) {
                        return child
                    }
                    return ''
                })}
            </div>
        </>)
    }
    
    toggleTabs = tab => () => {
        if (this.state.active !== tab) {
            this.setState({ active: tab })
            if (this.props.onChange) this.props.onChange(tab)
        }
    }

    getActive() {
        if (this.state.active) return this.state.active
        const tabs = Tabs.Tab.filter(this.props.children)
        return tabs.length > 1 && (tabs.find(tab => tab.props.default) || tabs[0]).props.name
    }

}

Tabs.Tab = class Tab extends ContainerChild {
    static cn = 'tab'
    render() { return this.props.children || '' }
}

Tabs.Options = class Options extends ContainerChild {
    static cn = 'tabs-options'
    render() { return this.props.children || '' }
}