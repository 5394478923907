import { Definition, Ref}  from '../../../framework/infra'
import RefFilter from '../../../framework/infra/model/RefFilter';
import { EmploymentEvent } from '../../employment';
import { ParticipationEvent } from '../../membership';
import CommonFilters from '../CommonFilters';

export default class EmploymentView extends Ref {

    get participationEventDescription() {
        let event = new ParticipationEvent({code: this.participationStatusKey});
        return event.statusDesc;
    }

    get employmentEventDescription() {
        let event = new EmploymentEvent({code: this.employmentStatusKey});
        return event.desc;
    }

    static get filters() {
        return EmploymentViewFilters;
    }

    static get exportConfigs() {
        return {
            fileName: 'EmploymentExport-' + new Date().toISOString().slice(0, 10),
            sheetName: 'Employments',
            columns: ['sin', 'nameOriginal', 'employmentEventDescription', 'statusEffectiveDate', 'employeeNumber', 'joinDateString', 'participationEventDescription', 'baseMonthlyEarning']
        };
    }

    static definitions = {
        id: { type: Definition.types.STRING, text: 'ID' },
        nameOriginal: { type: Definition.types.STRING, text: 'Name' },
        sin: { type: Definition.types.SIN, text: 'SIN' },
        joinDate: { type: Definition.types.TIMESTAMP, text: 'Join Date' },
        joinDateString: { type: Definition.types.STRING, text: 'Join Date' },
        employmentStatusKey: { type: Definition.types.STRING, text: 'Employment Status Key' },
        statusEffectiveDate: { type: Definition.types.DATE, text: 'Status Effective Date' },
        participationStatusKey: { type: Definition.types.STRING, text: 'Participation Status Key' },
        baseMonthlyEarning: { type: Definition.types.AMOUNT, text: 'Base Monthly Earnings' },
        employeeNumber: { type: Definition.types.STRING, text: 'Employee #' },

        //abstracts
        participationEventDescription: { abstract: true, type: Definition.types.STRING, text: 'Participation Status' },
        employmentEventDescription: { abstract: true, type: Definition.types.STRING, text: 'Employment Status' },
        filters: { abstract: true, type: EmploymentViewFilters, text: 'Filters' }
    }
}


class EmploymentViewFilters extends RefFilter {

    constructor(data) {
        super(data, EmploymentViewFilters.definitions);
    }

    static definitions = {
        sin: CommonFilters.sin,
        name: CommonFilters.nameWithFuzziness,
        joinDateString: CommonFilters.joinDateString,
        employmentStatusKey: CommonFilters.employmentStatusKey,
        participationStatusKey: CommonFilters.participationStatusKey,
        isLastParticipation: { 
            isFilter: true,
            type: Definition.types.BOOLEAN, 
            text: 'Last Participation', 
            icon: 'user',
            getLabel: (instance) => { return 'Last Participation' },
        },
        fuzziness: CommonFilters.nameFuzziness
    }
}