import Ref from "./Ref";
import Definition from './Definition';
export default class RefPointer extends Ref {

    get value() {
        if(!this.isLoaded) throw new Error('Accessing an unloaded RefPointer');
        return this.instance;
    }

    get isLoaded() {
        return !!this.instance;
    }

    async load() {
        if(!this.isLoaded) {
            const registry = require('../../../services/ServiceRegistry').default;
            this.instance = await registry.loadPointer(this);
        }
        return this;
    }

    static definitions = {
        // persistent
        registryKey: { type: Definition.types.STRING, text: 'Registry Key' },
        name: { type: Definition.types.STRING, text: 'Name' },
        instanceKey: { type: Definition.types.STRING, text: 'Key Value' },
        // transient
        instance: { transient: true, type: Definition.types.ENTITY, text: 'Instance' },
        // computed
        isLoaded: { abstract: true, type: Definition.types.BOOLEAN, text: 'Is Loaded' },
        value: { abstract: true, text: 'Entity' },
    }
}