
export const SIN_VARIATIONS = ['sin', 'nas', 'ssn'];
export const FIRST_NAME_VARIATIONS = ['firstname', 'prenom', 'prénom', 'prnom','fname'];
export const LAST_NAME_VARIATIONS = ['lastname', 'lname'];
export const PPNO_VARIATIONS = ['pp'];
export const EMPLOYEE_ID_VARIATIONS = ['id', 'employee'];

//Decomposers
export const DCfirstLastCommaSpace = (val) => { 
    let delim = ' ';
    if (val?.includes(',')) delim = ', ';
    var namePair = val?.trim().split(delim) ?? ['',''];
    return {first: namePair[1], last: namePair[0]};
}

export const FIRST_LAST_VARIATIONS = [{
    variation: ['lastnamefirstname', 'nom', 'name'], 
    decompose: DCfirstLastCommaSpace,
}];
export const NAMES = [
    ...FIRST_LAST_VARIATIONS.reduce((array, x)=> [...x.variation, ...array], []), 
    ...LAST_NAME_VARIATIONS, 
    ...FIRST_NAME_VARIATIONS
];

export const errors = {
    noFileSelected: {text:'No File Selected'},
    fileNotSupported: {text:'File Type Not Supported'},
    noHeaderFound: {text:"No valid header row found - The Header row must start with the column 'SIN or NAS'"},
    unexpectedError: {text:'Error while loading file, please try again'},
}

export const findHeaderPosition = (rows, headerNames) => {
    return rows.findIndex(row => {
        const headers = cleanHeaderRow(row);
        return headers && headers.findIndex(header => (headerNames.includes(header))) >= 0;
    })
}

export const findNameHeaderPosition = (rows) => {
    return rows.findIndex(row => {
        const headers = cleanHeaderRow(row);
        if (headers) {
            let index = headers.findIndex(header => (NAMES.find(n=> {
                return header === n || header?.includes(n)
            })));
            if (index >= 0) return true;
        }
    })
}

export const cleanHeaderRow = (headerRow) => {
    return headerRow && Array.isArray(headerRow) && headerRow.map(text => {
        return text ? String(text).replace(/[^a-zA-Z0-9]+/g, '').toLowerCase() : '';
    });
}

export const getEarningMappings = (earningTypes, headers, subHeaders) => {
    return earningTypes.reduce((mapped, earningType) => {
        const searchStr = earningType.label.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase();
        const searchAlias = earningType.alias.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase();
        const index = headers.findIndex(header => (header === searchStr || (searchAlias && header === searchAlias)));
        if (index > 0) {
            mapped.push({
                earningType: earningType,
                index: index,
                hoursOrRate: earningType.category.isHourly() ? (subHeaders && String(subHeaders[index + 1]).startsWith('rate') ? 'rate' : 'hours') : ''
            });
        }
        return mapped;
    }, []);
}