import { useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";
import { Beneficiary, Participation } from "../../entities";
import { Modal } from "../../framework/containers";
import { Table } from "../../framework/controls";
import { Definition, Ref } from "../../framework/infra";
import { BeneficiaryService } from "../../services";
import BeneficiaryForm from "./BeneficiaryForm";
import { TYPES as beneficiaryType } from "../../entities/pension/member/Beneficiary";
import { renderPercentage } from '../../framework/utils/renders'
import moment from "moment";
import { DATE_FORMAT, ETS_FORMAT } from "../../framework/utils/helper";
import uuid from 'uuid/v4'

const BeneficiaryDisplay = ({membership, onClose}) => {
    
    const [selectedBeneficiary, setSelectedBeneficiary] = useState();
    const [isLoading, setLoading] = useState(false);

    const participationsData = Report.getData(membership.participations.all);

    const columns = new Table.Headers(Report, ['designationReceivedDate', 'type', 'details', 'cmt']);
    columns['designationReceivedDate'].width = 200;
    columns['type'].width = 150;
    columns["cmt"].className = "comment-overflow";


    const renderHighlight = (item) => {
        let data = item.getData();
        if(data.participation.beneficiaries.latestGroup.find(x=>x.id === data.beneficiary.id)) {
            item.getElement().className += ' highlight'
        }
    }

    const handleDelete = async (beneficiary) => {
        setLoading(true);
        let participation = membership.participations.find(x=>x.no === beneficiary.participationNo);
        participation.beneficiaries.removeById(beneficiary.id);
        setSelectedBeneficiary();
        BeneficiaryService.delete(beneficiary).then(finalizeSave);
    }

    const handleEdit = async (beneficiary) => {
        setLoading(true);
        let sourceParticipation = membership.participations.find(x=> x.beneficiaries.find(y=> y.id === beneficiary.id));
        let destParticipation = membership.participations.find(x=>x.no === beneficiary.participationNo);

        sourceParticipation.beneficiaries.removeById(beneficiary.id);
        destParticipation.beneficiaries.push(beneficiary);
        await BeneficiaryService.update(beneficiary)
        
        finalizeSave()
    }

    const handleAdd = async (beneficiary, target = []) => {
        setLoading(true);

        let targets = [];
        if (beneficiary.participationNo === 'vested') {
            targets = membership.vestedParticipations;
        } else {
            targets = [membership.participations.find(x=>x.no === beneficiary.participationNo)];
        }

        for (const participation of targets) {
            let clone = beneficiary.clone();
            clone.participationNo = participation.no;
            clone.id = uuid();
            participation.beneficiaries.push(clone);
            await BeneficiaryService.add(clone);
        }
        finalizeSave()
    }

    const onEdit = (row) => {
        if (row.beneficiary.id) {
            setSelectedBeneficiary(row.beneficiary);
        }
    }
    const finalizeSave = async () => {
        setLoading(false);
    }

    const onAdd = () => {
        const beneficiary = new Beneficiary();
        beneficiary.parent = membership.person.id;
        beneficiary.noSin = true;
        setSelectedBeneficiary(beneficiary) 
    }

    return isLoading ? <Loader/> : <>

        <Table 
            rowFormatter={renderHighlight}
            className='h-100 mih-400'
            data={participationsData} 
            columns={columns}
            id="historical-table" 
            onSelect={onEdit}  
            dataTree={true}
            dataTreeStartExpanded={true}
            fitHeightToContent 
        />            

        {selectedBeneficiary && <Modal className='w-80 modal-bg-color'>
            <BeneficiaryForm beneficiary={selectedBeneficiary} membership={membership} onClose={() => setSelectedBeneficiary()} onSave={handleEdit} onAdd={handleAdd} onDelete={handleDelete}/>    
        </Modal>}
        <div className='line-end mt-2'> 
            <Button key='add' className='btn-secondary' onClick={onAdd}>Add Beneficiary</Button>
        </div>
    </>
}

class Report extends Ref {
    static getData(participations) {
        return participations.reduce((rows, participation) => {
            if (participation.beneficiaries.all.length === 0) return rows;

            const total = participation.beneficiaries.totalShare;
            const share = 'Share - '+ (total < 0 ? '??%': renderPercentage(total));

            const row = new Report({ 
                type: 'Total: '+ participation.beneficiaries.length, 
                designationReceivedDate: 'PP#' + participation.ppNo, 
                details: participation.statusDesc + ' (' + share + ')', 
                cmt: '', 
                participation: participation,
                beneficiary: null, 
            });
            rows.push(row)
            row._children = []

            participation.beneficiaries.forEach(beneficiary => {
                row._children.push(new Report({ 
                    designationReceivedDate: beneficiary.effDt,
                    type: beneficiary.type, 
                    details: beneficiary.details, 
                    cmt: beneficiary.cmt, 
                    beneficiary: beneficiary, 
                    participation: participation,
                }))
            })

            row._children = row._children.sort((a, b) => a.rts - b.rts);
            rows.sort((a,b) => a.participation.no > b.participation.no ? -1 : 1);

            return rows
        }, [])
    }
    
    static definitions = {

		details: { type: Definition.types.STRING, text: 'Details'},
        participation: { ref: Participation, text: 'Participation' },

        designationReceivedDate: { type: Definition.types.STRING, text: 'Designation Date'},
		beneficiary: { ref: Beneficiary, text: 'Beneficiary'},
		type: { type: Definition.types.STRING, text: 'Type'},
		cmt: { type: Definition.types.STRING, text: 'Comment'},
    }
}


export default BeneficiaryDisplay;