import { Service } from '../../framework'
import { getSafe, isEmptyObj, isValidSIN } from '../../framework/utils/helper'
import { Person} from "../../entities"
import { setAPIError } from '../../hooks/useAPIError';
import SpouseHistory from '../../entities/person/SpouseHistory';
import { SpouseService } from '..';
import PeopleMapper from '../../mappers/PeopleMapper';
import uuid from 'uuid/v4'

class PersonService extends Service {
    constructor() {
        super(Person, 'Person', 'person')
    }

    GET_ACTION = 'GetPersons';

	getPersonsForEmployer(employerId, options = {}) {
        return this.getBy('GetPersonsForEmployer', {id: employerId}, options);
    }

    getPersonBySin(personSin) {
        return this.getBy('GetPersonBySin', {sin: personSin}).then(persons => {
            if (persons.length > 1) {
                this.logDataIntegrity(`Sin ${personSin} is duplicated`)
            }
            return persons.first
        })
    }

    getPersons() {
        return this.getBy('GetPersons');
    }

    async load(person, options) {
        if (person.spouseHistory?.length > 0) { 
            person.spouseHistory = await SpouseService.get(person.id);
        }
    }

    async getAll() {
        return await this.callApi(this.GET_ACTION, this.GET_ACTION, null, PeopleMapper, {});
    }

    searchPerson(searchPerson) {
        return this.getAll().then(people => {
            let searchResult = []
            if(searchPerson['sin']) searchResult = people.filter(per => per['sin'] === searchPerson['sin'])
            if(searchResult.length === 0){
                var firstLow = searchPerson.firstName?.toLowerCase() ?? '';
                var lastLow = searchPerson.lastName?.toLowerCase() ?? '';
                searchResult = (firstLow !== '' || lastLow !== '') ? people : [];
                if (firstLow !== '') searchResult = searchResult.filter(x=> (x.firstName.toLowerCase().startsWith(firstLow)));
                if (lastLow !== '') searchResult = searchResult.filter(x=> (x.lastName.toLowerCase().startsWith(lastLow)));
            }
            return searchResult
        })
    }

    deletePerson(person) {
        this.invalidateCache();
        return this.persistence
            .callApi("person_DeletePerson", {}, { person: person.keyValue })
            .then((returnData) => returnData)
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });
    }

    validate(person) {
        var errorMessages = [];
        if (!person.sin) errorMessages.push('Missing SIN number');
        if (person.sin && !isValidSIN(person.sin)) errorMessages.push('Invalid SIN number');
        if (person.lastName === '' || person.firstName === '') errorMessages.push('Invalid name, first and last name are required');

        return errorMessages;
    }

    create(person) {
        var validationResults = this.validate(person);
        if (!person.id) person.id = uuid();
        if (validationResults.length > 0) return { errors: validationResults }

        return this.update(person);
    }

}

const instance = new PersonService()
export default instance

