import React from 'react'
import  { Modal, Card } from '../containers'

export default class Wait extends React.Component {
    render() {
        return <Modal id='wait'>
            <Card framed>
                <span className="centered">Loading...</span>
            </Card>
        </Modal>
    }
}
