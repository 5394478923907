import { Definition, Ref}  from '../../framework/infra'

export default class SearchResult extends Ref {
    static definitions = {
        total: { type: Definition.types.NUMBER, text: 'Total' },
        page: { type: Definition.types.NUMBER, text: 'Page' },
        pageSize: { type: Definition.types.NUMBER, text: 'Page Size' },
        totalPages: { type: Definition.types.NUMBER, text: 'Total Pages' },
        scrollId: { type: Definition.types.STRING, text: 'Scroll ID' },
    }
}