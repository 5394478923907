import { RefMap } from '../../../framework/infra'

import EarningType from './EarningType'

export default class EarningTypes extends RefMap {
	static ref = EarningType
	
	get sorted() {
		const categoryOrder = {r:0, o:1, t:2, n:3}
		return [...this._list].sort((t1,t2) => {
			const s = categoryOrder[t1.category.key] === categoryOrder[t2.category.key] ? 0 : (categoryOrder[t1.category.key] < categoryOrder[t2.category.key] ? -1 : 1 )
			return s !== 0 ? s : (t1.getIndex() < t2.getIndex() ? -1 : 1)  
		})
	}

	getNextCode(category) {
		let earnings = EarningType.splitEarningsTypesByCategory(this)[category.key];
		let number = earnings.earningTypes.reduce((highest, earning) => {
			let num = Number(earning.code.replace(category.codePrefix ,""));
			return num > highest ? num : highest;
		}, 0) + 1;
		return category.codePrefix + number;
	}
}

