import React from 'react'
import { StyleSheet } from '@react-pdf/renderer'

import { Page, Document, Container, Image, Text, Table, Row, Col, Cell, Separator, AmountDetail } from '../../../framework/utils/pdf' 

import moment from 'moment'
import PDFHeader from './PDFHeader'
import Account from '../../../entities/financial/trustee/Account'

export default class BankInstructions extends React.Component {
    render() {
        const { config, employer, payment } = this.props
		console.log(config)
		const plan = employer.plan
		const treasury = config.trustee.treasury
		const emailTreasury = treasury.emails.last.email
		
		const accountInfoList = Account.bankOrderedAccounts.map(account => { 
			const list = {
				name: account.desc,
				detail: payment.distribution.longDescByAccount(account.key),
				amount: payment.distribution.sumByAccount(account.key)
			}
		   return list
		})
		function hideEmptyCell(account) {return account.detail === '' ? '' : employer.name } 
		const styles = StyleSheet.create({
			tableTitle: {fontFamily: 'Roboto-Bold', textAlign: 'center'},
			lineHeight: { lineHeight: '1.6', fontSize: '8' },
			signature: { width: '50', height: '20' },
			align: { display: 'flex', alignItems: 'baseline', justifyContent:'space-between' },
			center: {textAlign: 'center'},
			left: {textAlign: 'left'},
			letterTitle: { fontFamily: 'Roboto-Bold', fontSize:'12' },
			bold: {fontFamily: 'Roboto-Bold'}
		})
		
        return <Document><Page>
				<PDFHeader config={config} />
				<Container style={styles.letterTitle} className='ml-4 mt-5'>
					<Text>RBCIT{'&'}S REMITTANCE INSTRUCTIONS:</Text>
					<Text>PENSION CONTRIBUTION ALLOCATION</Text>
				</Container>
				<Container className="ml-4 mt-4">
					<Row className='mb-3'>
						<Text className='w-20'>Mail To:   </Text>
						<Col><Text>Attention: {treasury.attn}</Text>
							<Text>{treasury.departmentName}</Text>
							<Text>{treasury.address.civic}</Text>
							<Text>{treasury.address.city} {treasury.address.prov} {treasury.address.pc}</Text>
						</Col>
					</Row>
					<Row><Text className='w-20 mb-3'>Email To: </Text><Text>{emailTreasury}</Text></Row>
					<Row><Text className='w-20 mb-3'>Plan Name: </Text><Text>{plan.name}</Text></Row>  
					<Row><Text className='w-20 mb-3'>RBC Account Number:</Text><Text>{plan.accntNumber}</Text></Row>	
					<Row><Text className='w-20 mb-3'>Date:  </Text><Text>{moment().format('LL')}</Text></Row>	
					<Row><Text className='w-20 mb-3'>Associated Employer: </Text><Text>{employer.name} ({employer.code})</Text></Row>  
				</Container>
				<Table style={styles.center} className='ml-4 w-90'>
					<Row style={styles.tableTitle}>
						<Cell className='w-30'>Contribution Type [RBC Codes]</Cell>
						<Cell className='w-50'>Contribution Period [Month/Year]</Cell>
						<Cell className='w-20'>Amount (CAD)</Cell>
					</Row>
					{accountInfoList.map(account => 
						<Row>
							<Cell style={styles.left} className='w-30'>{account.name}</Cell>
							<Cell className='w-50'><Text style={styles.bold}>{hideEmptyCell(account)}{'\n'}</Text><Text>{account.detail}</Text></Cell> 
							<Cell className='w-20'><AmountDetail amount={account.amount} blankForZero /></Cell> 
						 </Row> )} 
						 <Row>
						<Cell style={styles.left} className='w-80'>Total Remittance</Cell>
						<Cell className='w-20'><AmountDetail amount={payment.distribution.total} /></Cell>
					</Row>
				</Table>				
				<Container className='ml-4 mt-4 w-90' style={styles.lineHeight}>
					<Text>I confirm that I am an individual authorized to sign for the Plan and accordingly, with respect to the information provided herein, I certify that</Text>
					<Container className='pl-3'>
						<Text>(a) all contributions owing to the Plan for the period(s) indicated above are being remitted with this Contribution Allocation summary; and</Text>
						<Text>(b) the contribution amounts shown above have been determined in accordance with </Text>
						<Container className='pl-3'>
							<Text>(i) the terms and conditions of the Plan,</Text>
							<Text>(ii) the Plan's current actuarial valuation and the cost certificate which has been or will be submitted to the applicable regulatory authority,</Text>	             
							<Text>(iii) applicable regulatory requirements.</Text>
						</Container>
					</Container>	
				</Container>
				<Container className='ml-4 mt-4 w-90' >
					<Row style={styles.align}>
						<Col>
							<Text>Shelley Hayes</Text>
							<Separator />
							<Text>Name of individual (s) authorized to sign for the plan</Text>
						</Col>
						<Col >
							<Image style={styles.signature} src="/img/signature.jpg" />
							<Separator />
							<Text>Signature (s) authorized individual (s)</Text>
						</Col>
					</Row>
				</Container>
        </Page>
      </Document>
    }
}