import React, { useState } from 'react'
import { EInput } from '../../framework/components'
import { Row, Title } from '../../framework/containers'
import { Form } from '../../framework/controls'

const HistoricalForm = ({ selected, name, instance, disableZeroValue, onCancel, onOK }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [error, setError] = useState();

    const handleConfirm = () => { if (onOK) onOK(selected) }
    const handleCancel = () => { if (onCancel) onCancel() }
    const handleChange = (value) => { 
        setError(disableZeroValue && value === 0 ? 'Value can not be zero for ' + instance.constructor.definitions[name].desc : '');
        setIsDirty(true);
    }

    return (<>
        <Title title={`Edit ${instance.constructor.definitions[name].desc}`} onHide={handleCancel}/>
        <Form 
            className='h-100' 
            id="historicalForm" 
            data={selected} 
            onSave={handleConfirm} 
            onCancel={handleCancel} 
            labelBtnSave='Save'
            errorMessage={error}
            disableSave={error}
            bypassDirty={isDirty && !error}
        >
            <Row className='input-spacing-2'>
                <EInput name="effDt" instance={selected}/>
                <EInput name="cmt" instance={selected}/>
                <EInput 
                    name="value" 
                    definition={instance.constructor.definitions[name]} 
                    instance={selected} 
                    noHist={true} 
                    onChange={handleChange} 
                />
            </Row> 
        </Form>
    </>);
}

export default HistoricalForm;
