
import { RefMap }  from '../../framework/infra'

export default class Employers extends RefMap {
    get activeEmployers() { return this.getFiltered(employer => employer.act === true)}
    getEmployersForJurisdiction(jurisdiction) { return this.getFiltered(employer => employer.jurisdictionCode === jurisdiction) }

    static definitions = {
        activeEmployers: { abstract: true, ref: this, text: 'Active Employers' },
    }
}

