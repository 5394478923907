import { Entity, Definition } from '../../framework/infra'

export default class Company extends Entity {

    get admin() {
        return this.contacts && this.contacts.find(contact => {
            return (contact.adm === 'yes')
        })
    }

    get committee() {
        return this.contacts && this.contacts.find(contact => {
            return (contact.cme === 'yes')
        })
    }

    get treasurer() {
        return this.contacts && this.contacts.find(contact => {
            return (contact.trr === 'yes')
        })
    }
    get formatedName() {
        return this.name.replace(/[a-zA-Z0-9_^`]+/g, n => n.charAt(0).toUpperCase() + n.substr(1).toLowerCase());
    }

}

const { STRING, BOOLEAN, CHOICE } = Definition.types
Company.definitions = {
    code: { type: STRING, text: 'Code', text_fr: 'Code' },
    name: { type: STRING, text: 'Name', text_fr: 'Nom' },
    comment: { type: STRING, text: 'Comments', text_fr: 'Comments' },
    address: { ref: require('../address/Address'), text: 'Address', text_fr: 'Adress' },
    phone: { ref: require('../address/Phone'), text: 'Phone', text_fr: '' },
    email: { ref: require('../address/Email'), text: 'Email', text_fr: '' },
    fax: { ref: require('../address/Phone'), text: 'Fax Number', text_fr: '' },
    lng: {
        type: CHOICE, text: 'Language', text_fr: 'Lang', options: [
        { key: 'en', text: 'English', text_fr:'Anglais'},
        { key: 'fr', text: 'French', text_fr:'Français'},
    ]},
    contacts: { list: true, ref: require('./CompanyContact'), text: 'Contacts', text_fr: 'Contactes' },
    admin: { abstract: true, ref: require('./CompanyContact'), text: 'Administrator', text_fr: 'Administrateur' },
    committee: { abstract: true, ref: require('./CompanyContact'), text: 'Committee Member', text_fr: 'Membre du Comité' },
    tresurer: { abstract: true, ref: require('./CompanyContact'), text: 'Tresurer', text_fr: 'Tresorier' },
}

