import { Ref, Definition } from '../../../framework/infra'
import { moment, round } from '../../../framework/utils/helper'
import { RefundAccount } from '../../pension/refund'
import ParticipationPensionType from './ParticipationPensionType'
import PayeeType from './PayeeType'

export default class ParticipationPension extends Ref { 
    // get elapsedMonths() { return moment(this.paymentStart0Date).format('M') - moment(this.retirementDate).format('M') }
    get elapsedMonths() {
        const yearDiff = moment(this.paymentStartDate).format('Y') - moment(this.retirementDate).format('Y')
        const monthDiff = moment(this.paymentStartDate).format('M') - moment(this.retirementDate).format('M')
        return monthDiff + (yearDiff * 12)
    }
    get retroactiveAmount() { return this.retirementDate && this.paymentStartDate ? round(this.elapsedMonths * this.pensionAmount) : 0 }
   
    // see schema in src/model/membership/Participation.js in Services
    static definitions = {
        retirementDate: { type: Definition.types.DATE, text: 'Retirement Date', text_fr: ''},
        paymentStartDate: { type: Definition.types.DATE, text: 'Payment Start Date', text_fr: '' },
        
        indexationDate: { type: Definition.types.DATE, text: 'Indexation Date', text_fr: '' },
        pensionAmount: { type: Definition.types.AMOUNT, text: 'Pension Amount', text_fr: '' },

        guaranteeEndDate: { type: Definition.types.DATE, text: 'Guarantee End Date', text_fr: '' },
        pensionType: {ref: ParticipationPensionType, text: 'Pension Type', text_fr: 'Pension Type', },
        authType: {type: Definition.types.CHOICE, text: 'Authorization Type', text_fr: 'Authorization Type', options: [
            { key: 'new', text: 'New Authorization', text_fr: ''},
            { key: 'change', text: 'Change Authorization', text_fr: ''}
        ]},
        payeeType: { ref: PayeeType, text: 'Payee Type', text_fr: 'Payee Type'},
        bankAccount: {ref: RefundAccount, text: 'Bank Account'},
        retroactiveAmount: { abstract: true, type: Definition.types.AMOUNT, text: 'Retroactive Amount', text_fr: '' },
        elapsedMonths: { abstract: true, type: Definition.types.NUMBER, text: 'Elapsed Days' }
    }
}