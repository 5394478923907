// Definitions:
//  - secondCheckboxCode: if there is a second checkbox in the same section, this is the YE Certification Event code associated to it

export const yeCertificationConfigs = {
  default: {},
  bme: {
    tooltip:
      "BME provided for any member on long-term disability, maternity, or self-contributing leave.",
  },
  creditedService: {
    tooltip:
      "If work schedule and hours have been validated, the service will be correctly calculated.",
  },
  pensionableEarnings: {
    secondCheckboxCode: "bme",
  },
};
