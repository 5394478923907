import { useEffect, useState, useRef } from "react"

import { Col, FieldSet, Row } from '../../../framework/containers';
import { Button, Table } from '../../../framework/controls';
import { Excel, Period } from '../../../framework/utils';
import { YearEndService, EmployerService, RemittanceDetailService } from '../../../services';
import { EarningType} from '../../../entities';
import UploadTemplateReport from "./UploadTemplateReport";
import { array2Object } from "../../../framework/utils/helper";

const YEReport = (props) => {

    const { employer, year } = props.params;
    const [tableData, setTableData] = useState();
    const [earningTypes, setEarningTypes] = useState();
    const [loaderText, setLoaderText] = useState('Processing...');
    const [isLoading, setIsLoading] = useState(false);
    const [finalTime, setFinalTime] = useState();
    
    useEffect(() => {
        let isMounted = true;

        const getTableData = async () => {
            var begin= Date.now();
            //report for one or all employers
            var employers = employer ? [employer] : (await EmployerService.getEmployers())?._list;
            let report = { details: [], processed: 0 };

            //pull all earning types for the header
            let earnTypes = {}
            employers.forEach((emp => {
                earnTypes = {...earningTypes, ...EarningType.splitEarningsTypesByCategory(emp.getActiveEarningTypes())};
            }))
            setEarningTypes(earnTypes);

            //direct export sheets, will export directly to sheet to save memory
            let processed = 0;
            for ( let emp of employers ) {
                setLoaderText((employers.length > 1 ? ' ('+processed+'/'+employers.length+') ' : '') + 'Processing ' + emp.code +'...')
                let response = await YearEndService.getData({employer: emp, year: year, setLoaderText});
                processed++;
                report = { 
                    details: [...report.details, ...response.details],
                    processed: (report.processed ?? 0) + response.processed
                }
            }
            
            if (isMounted) {
                report.earningTypes = earnTypes;
                setTableData(report);
                var end = Date.now();
                setFinalTime((end-begin)/1000/60);
            }
        }
        
        getTableData().catch((error)=>console.error(error));

        return () => { isMounted = false };
    }, []);

    const handleExportData = (data) => {
        console.log(data)
        const workbook = initExcelSheets(earningTypes);
        writeToSheets(workbook, data);
        return workbook.excel.download()
    }

    const handleSavingComment = async (details) => {
        setIsLoading(true);
        const detailBySin = array2Object(details, 'employment.person.sin');
        tableData.details.forEach(detail => {
            detail.details = detailBySin[detail.sin];
            detail.validation = {};
        });
        const newReport = await YearEndService.getData({ employer, year });
        setTableData(newReport);
        setIsLoading(false);
    }


    const initExcelSheets = (earningTypes) => {
        const excel = new Excel(`YearEnd-${year}-${employer ? employer.code : 'ALL'}-report`);
        const groupHeaders = YearEndService.initHeader(YearEndService.displayTypes.FULL, Excel, earningTypes, employer);
        const wsDetails = excel.addSheet(groupHeaders,[], 'Report');
        return { excel, wsDetails, groupHeaders };
    }

    const writeToSheets = (workbook, data) => {
        workbook.excel.addDataRows(workbook.wsDetails, workbook.groupHeaders, data.details)
    }

    if (!tableData || isLoading) return <div className="report-loader">{loaderText}</div>;
    return (<>
            <FieldSet title="Employer Report" >
                <Row className="justify-content-end">
                    <Button key="export" type="link" onClick={() => handleExportData(tableData)}>Export Data</Button>
                    <UploadTemplateReport fileName={`Year-End-Report-${employer?.code ?? ''}-${year}`} isMercer={false} report={tableData} onCommentSave={handleSavingComment}/>
                </Row>
            </FieldSet>
            <FieldSet className='mt-4' title="Mercer Report">
                <Row className="justify-content-end">
                    <UploadTemplateReport fileName={`Mercer-Report-${employer?.code ?? ''}-${year}`} isMercer={true} report={tableData}/>
                </Row>
            </FieldSet>
            <Row className="justify-content-end mt-4">
                <div>Report ran in: {finalTime?.toFixed(2)} minutes.</div>
            </Row>
            <Row className="justify-content-end">
                <div>Processed {tableData.processed} employments.</div>
            </Row>
    </>)
}
export default YEReport;

