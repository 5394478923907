import { RefEnum } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class Account extends RefEnum {
    constructor(key, desc, no) {
        super(key, desc)
        this.no = no
    }
    
    static types = deepFreeze({
        EE: new Account('EE', 'Employee [2022]','2022'),
        VOL: new Account('VOL', {en: 'Voluntary [2024]', fr: 'Volontaire [2024]'}, '2024'),
        SOL: new Account('SOL', 'Solvency [2017]', '2017'),
        ER: new Account('ER', 'Employer [2012]', '2012')
    })
    static default = Account.types.ER
    static definitions = {}

    static get bankOrderedAccounts() {
        return [Account.types.EE, Account.types.VOL, Account.types.ER, Account.types.SOL]
    }
}



