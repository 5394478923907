import React from 'react'
import { Ref, Definition } from '../../../framework/infra'

import { Component, EInput } from '../../../framework/components'
import { Card, Col, FieldSet, Row } from '../../../framework/containers'
import { Table, Button, Alert } from '../../../framework/controls'
import { getSafe, moment, removeDuplicates, sort } from '../../../framework/utils/helper'
import { Excel, Period } from '../../../framework/utils'

//
// PROPS:
//
// - refreshWithButton: flag to disable fetching the data on first render and on each param change. 
// When true, a Refresh button is shown and the data is only fetched when this button is clicked.
// See also the needsRefresh state.

//
// STATE:
//
// - needsRefresh: Flag to know when the data needs to be refreshed. Used with the prop refreshWithButton.
// Set to true after the first render or when a params changes.
// When it's true, a warning alert is shown to remind the user to refresh the data.

export default class ReportLayout extends Component {

    constructor(props) {
        super(props);
        const customStateInit = {needsRefresh: props?.refreshWithButton};
        this.state = this?.state ? {...(this.state), ...customStateInit} : {...customStateInit};
    }

    load() {
        const that = this
        
        class FilterRef extends Ref {
            static definitions = Object.getOwnPropertyNames(that.filters).reduce((defs, filterName) => {
                if(that.filters[filterName].definition) defs[filterName] = that.filters[filterName].definition
                else defs[filterName] = that.entity.getDefinition(filterName)
                return defs
            }, {})
        }
        class QueryParams extends Ref {
            static definitions = Object.getOwnPropertyNames(that.params).reduce((defs, pName) => {
                if(that.params[pName].definition) defs[pName] = that.params[pName].definition
                else defs[pName] = that.entity.getDefinition(pName) || new Definition()
                return defs
            }, {})
        }

        const state = {
            data: [],
            // headers: new Table.Headers(this.entity, this.headers),
            filtersInstance: new FilterRef(Object.getOwnPropertyNames(this.filters).reduce((initialValues, filterName) => {
                if (this.filters[filterName].initialValue) initialValues[filterName] = this.filters[filterName].initialValue
                return initialValues
            }, {})),
            queryParamsInstance: new QueryParams(Object.getOwnPropertyNames(this.params).reduce((initialValues, pName) => {
                if (this.params[pName].initialValue) initialValues[pName] = this.params[pName].initialValue
                return initialValues
            }, {})),
            FilterRef: FilterRef,
            QueryParams: QueryParams,
        }

        if(this.props?.refreshWithButton) {
            return Promise.resolve().then(() => {
                state.data = [];
                state.needsRefresh = true;
                return state;
            });
        }
        
        return this.execQuery(state.queryParamsInstance).then(data => {
            setFilterValues(this.filters, data, FilterRef)
            state.data = data
            return state
        });
    }

    view() {
        const { queryParamsInstance, filtersInstance, data, selectedPage, showDetails } = this.state
        const headers = new Table.Headers(this.entity, this.headers, this.headerProps)

        const filteredData = this.filterDataWithFiltersInstances(data)
        if (this.groupHeaders) {
            this.groupHeaders.forEach(path => {
                const header = headers[path] = new Table.Header(this.entity, path, this.headerProps[path])
                const columns = this.subHeaders.reduce((cols, sub) => {
                    cols.push(path + `.${sub}`)
                    return cols
                }, [])
                header.headers = new Table.Headers(this.subEntity, columns) 
            })
        }

        return <>
            <Card className='p-3' framed>
                <Row className='input-spacing-2'>
                    <Col span='3'>
                        <FieldSet className={this.fieldsetClass} title={this.reportDesc} variant='page'>
                            <Row className='input-spacing-2 '>
                                {Object.getOwnPropertyNames(this.params).map(pName => {
                                    return <EInput name={pName} className={this.params[pName].className} instance={queryParamsInstance} onChange={this.handleParamsChange.bind(this)}/>
                                })}
                                </Row>
                        </FieldSet>
                    </Col>
                    <Col className='ml-3 align-self-end '>
                        <Row className='input-spacing-2'>
                            {Object.getOwnPropertyNames(this.filters).map(pName => {
                                return <EInput name={pName} instance={filtersInstance} className={this.filters[pName].className} options={this.filters?.[pName].options?.sort((a,b) => {
                                    if(a.text < b.text) { return -1; }
                                    if(a.text > b.text) { return 1; }
                                    return 0;
                                })} nullable={this.filters[pName].nullable} onChange={this.handleFilterChange.bind(this)} readOnly={this.props?.refreshWithButton && this.state?.needsRefresh}/>
                            })}
                        </Row>
                    </Col>
                    <Col className='align-self-end' right>
                        {this.props?.refreshWithButton && this.state?.needsRefresh && <Button key='refresh' className='btn-secondary' onClick={this.handleRefresh.bind(this)}>Update Data</Button>}
                        <Button key='export' className='btn-secondary' onClick={this.handleExport.bind(this, filteredData)} disabled={this.props?.refreshWithButton && this.state?.needsRefresh}>Export</Button>
                    </Col>
                </Row>
            </Card>

            {this.props?.refreshWithButton && this.state?.needsRefresh &&
                <div style={{ marginBottom: '10px'}}>
                    <Alert variant="warning">
                        <div className="alert-warning">
                        The date filters have changed. Click on the Update Data button to update the data.
                        </div>
                    </Alert>
                </div>
            }
          
            {!this.props.params?.hideTable && <Table id='reports-table' 
                dataKey='id' 
                data={filteredData} 
                columns={headers} 
                onTableLoad={(table) => this.table = table} 
                sort={this.tableSort}
                onSelect={this.handleSelect.bind(this)}
                removeDuplicateRows={this.removeDuplicateRows}
            />}
            {showDetails && selectedPage}
        </>     
    }
    handleParamsChange() {
        if(this.props?.refreshWithButton) {
            // do not trigger fetch on param change, it will be triggered on Refresh button click.
            this.setState({needsRefresh: true});
            return Promise.resolve();
        } else {
            const { queryParamsInstance, FilterRef } = this.state;
            return this.busy(() => this.execQuery(queryParamsInstance).then(data => {
                setFilterValues(this.filters, data, FilterRef)
                this.setState({data: data})
            }));
        }
    }
    
    handleFilterChange(val) {
        this.setState({touched: true});
    }
    handleSelect(row) {
        const page = this.handleRowSelect ? this.handleRowSelect(row) : null
        this.setState({selectedPage: page, showDetails: true})
    }
    handleCancel() {
        this.setState({showDetails: false})
        return this.busy(() => this.execQuery().then(data => {
            setFilterValues(this.filters, data, this.state.FilterRef)
            this.setState({data: data})
        }));
    }
    handleRefresh() {
        this.busy(() => this.execQuery(this.state?.queryParamsInstance).then(data => {
            setFilterValues(this.filters, data, this.state?.FilterRef)
            this.setState({data: data, needsRefresh: false});
        }));
    }
    handleExport(data) {
        const { queryParamsInstance } = this.state
        const excel = new Excel(this.reportDesc)
        const headers = new Excel.Headers(this.entity, this.headers)
        if (this.groupHeaders) {
            this.groupHeaders.forEach(path => {
                const header = headers[path] =  new Excel.Header(this.subEntity, "")
                const columns = this.subHeaders.reduce((cols, sub) => {
                    cols.push(path + `.${sub}`)
                    return cols
                }, [])
                header.headers = new Excel.Headers(this.subEntity, columns) 
            })
        }
        if (this.tableSort) data = sort(data, this.tableSort)
        if (this.headerProps) this.applyHeaderProps(headers)
        excel.addSheet(headers, data, this.reportDesc)
        if (queryParamsInstance && (queryParamsInstance.from || queryParamsInstance.to)) this.addPeriods(excel, this.reportDesc, queryParamsInstance)
        return excel.download()
    }
    addPeriods(excel, sheetname, dateRange) {  //TODO - Loop over the params and filters and put all
        const ws = excel.findSheet(sheetname)
        const periodStartDate = dateRange.from instanceof Period ? dateRange.from.text : dateRange.from
        const periodEndDate = dateRange.to instanceof Period ? dateRange.to.text : dateRange.to
        ws.addRows([[], [`Start Date: ${periodStartDate}`], [`End Date: ${periodEndDate}`], [], [`Printed On: ${moment().format('LLLL')}`]])
        return ws
    }
    applyHeaderProps(headers) {
        Object.getOwnPropertyNames(this.headerProps).forEach(headerProp => {
            Object.getOwnPropertyNames(this.headerProps[headerProp]).forEach(prop => {
                if(prop !== 'overrideText') {
                    if(!headers[headerProp]) headers[headerProp] = {};
                    headers[headerProp][prop] = this.headerProps[headerProp][prop];
                }
            });
            if(this.headerProps[headerProp]['overrideText']) {
                if(!headers[headerProp]) headers[headerProp] = {};
                headers[headerProp]['title'] = this.headerProps[headerProp]['overrideText'];
            }
        })
        return headers
    }
    isEmpty(val) {
        if ((val instanceof Ref && !(val.desc || val.id || val.key)) || val.length === 0) return true
        else return false
    }

    handleCellSelect(e, cell) {
        e.stopPropagation()
        var page = this.handleCellClick ? this.handleCellClick(cell) : null
        this.setState({selectedPage: page, showDetails: true}) 
	}

    filterDataWithFiltersInstances(data) {
        if(!data?.length) return [];
        if(!this.filters || typeof this.filters !== 'object') return data;
        if(!this.state?.filtersInstance) return data;
        return Object.entries(this.filters).reduce((fData, [pName, obj]) => {
            const value = this.state.filtersInstance[pName]
            if (value && !this.isEmpty(value)) {
                fData = fData.filter(v => {
                    if (obj.filterBy) return getSafe(v, `${pName}.${obj.filterBy}`) === value[obj.filterBy];
                    else return getSafe(v, pName) === value;
                })
            }
            return fData
        }, data)
    }
}

function setFilterValues(filters, data, FilterRef) {
    Object.getOwnPropertyNames(filters).forEach(pName => {
        const filter = filters[pName]
        if (FilterRef.ownDefinitions[pName].isRef()) {
            const pList = data.map(v => getSafe(v, pName))
            var filterList = removeDuplicates(pList, 'keyValue')
            if (filter?.sortBy) filterList = sort(filterList, filter.sortBy)
            if(filter) filter.options = filterList.map(entity => ({key: (entity.keyValue), text: (entity[filter.display || entity.desc]), value: entity}))
        } else {
            if(filter) filter.options = FilterRef.ownDefinitions[pName].options
                ? FilterRef.ownDefinitions[pName].options.map((option) => ({
                      key: option.key,
                      text: option.text,
                      value: option.key,
                  }))
                : null;
        }
    })

}