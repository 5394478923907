import React, { useEffect, useState } from "react"
import { PageTitle } from '../../framework/components'
import { Table } from '../../framework/controls'

import { EmployerService } from '../../services'
import Employer from "../../entities/employment/Employer"
import { useHistory } from "react-router-dom"
import ExportEmployers from "./ExportEmployers"
import Loading from "../../components/containers/Loading"
import { Button } from "react-bootstrap"
import EmployerInfoForm from "./details/EmployerInfoForm"
import { Modal } from "../../framework/containers"
import SuperAdminGuard from "../../guards/SuperAdminGuard"

const EmployersPage = () => {
    const [employers, setEmployers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [newEmployer, setNewEmployer] = useState();

    let history = useHistory();
    const columns = new Table.Headers(
        Employer,
        "code, name, plan.jurisdiction, act"
    );
    columns.code.headerFilter = true;
    columns.name.headerFilter = true;
    columns.code.headerFilterPlaceholder = "Enter an employer code...";
    columns.name.headerFilterPlaceholder = "Enter an employer name...";
    
    useEffect(() => {
        let isMounted = true;

        EmployerService.getEmployers().then((newEmployers) => {
            if (isMounted) {
                setEmployers(newEmployers);
                setIsLoading(false);
            }
        });

        return () => { isMounted = false };
    }, []);

    const handleSelect = (row) => { 
        history.push(`/employer/${row.code}`);
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-between">
                <PageTitle className="mt-1">Employers</PageTitle>
                <div style={{display: 'flex'}}>
                    {!isLoading && <ExportEmployers employers={employers} />}
                    <SuperAdminGuard>
                        <Button
                            key="new-employer"
                            className="btn btn-secondary"
                            onClick={() => setNewEmployer(new Employer())}
                        >
                            New Employer
                        </Button>
                        {newEmployer && <Modal className='w-80 modal-bg-color'>
                                <EmployerInfoForm employer={newEmployer} onCancel={() => setNewEmployer()} />
                            </Modal>}
                    </SuperAdminGuard>
                </div>
            </div>
            {isLoading ? (
                <Loading />
            ) : (
                <Table
                    id="employers-list-table"
                    data={[...employers.all]}
                    columns={columns}
                    noPagination
                    sort="code"
                    onSelect={handleSelect}
                />
            )}
        </>
    );
    
}

export default EmployersPage;

