import { Service } from '../../framework'
import { sort, group, removeFalsy } from '../../framework/utils/helper'
import { Rates, HistoricRates } from "../../entities/pension"
import { PlanService } from '.'

class RatesService extends Service {
    constructor() {
        super(Rates, 'Rates', 'config')
    }

    getRates(options = {}) {
        const promise = () => this.persistence.getAll('config_GetRates').then(allRates => sort(allRates, 'id')).then(allRates => group(allRates, 'jurisdiction')).then(ratesByJur => {
            return Object.getOwnPropertyNames(ratesByJur).reduce((rates, jur) => {
                rates[jur] = (new HistoricRates(ratesByJur[jur])).clone()
                rates[jur].reduce((prev, rate) => {
                    let updatedRate;
                    if (prev) {
                        const erContribZero = rate.employerContribution === 0;
                        updatedRate = Object.assign(rate, prev, removeFalsy(rate));
                        if (erContribZero) {
                            updatedRate.employerContribution = 0;
                        }
                    } else {
                        updatedRate = rate;
                    }
                     return updatedRate;
                } , null)
                return rates;
            }, {})
        })
        return this.useCache('_rates', promise, options.refresh)
    }

    validate(rates) {
        var errorMessages = {};
        if (!rates.effPeriod) errorMessages['effPeriod']= 'Invalid period';
        return errorMessages;
    }

    async createRates(rates) {
        var validationResults = this.validate(rates);
        if (Object.keys(validationResults).length > 0) return { errors: validationResults }
        return this.updateRates(rates);
    }
    async updateRates(rates) { 
        await this.update(rates) 
        this.refreshCache();
    }
    
    async deleteRates(rates) { 
        await this.deleteBy('DeleteRates', {id: rates.id});
        this.refreshCache();
    }

    refreshCache() {
        PlanService.invalidateCache();
        this.invalidateCache();
    }


}
const instance = new RatesService()
export default instance
