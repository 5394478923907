import React, { useEffect, useState } from 'react'
import { Modal, Tabs } from '../../framework/containers'
import { Table } from '../../framework/controls'
import Loading from '../../components/containers/Loading'
import Button from '../../framework/controls/Button';
import Rates from '../../entities/pension/Rates'
import RatesService from '../../services/pension/RatesService'
import SuperAdminGuard from '../../guards/SuperAdminGuard'
import PeriodicRatesEdit from './PeriodicRatesEdit';
import useUser from '../../hooks/useUser';
import uuid from 'uuid/v4'
import { Period } from '../../framework/utils';
import { formatNumber } from '../../framework/utils/formating';

const RatesTable = ({rates, jurisdiction, onSelect}) => {
    const columns = new Table.Headers(Rates, 'effPeriod,deductionRate,increassedRate,employerContribution,ympe,maxContributions,maxVoluntaryCQPP,maxVoluntaryNonCQPP,moneyPurchaseLimit,definedBenefitLimit,fundRateOfReturn,reduceAccrual,reduceAccrualIncreased,monthlyInterestRate,valuationRate,maternityDuration,solRetroChangeInt,erContribRetroChangeInt, aiwRate')
    columns.maternityDuration.format = (val) => (Number(val) / 7) + ' Weeks';
    columns.maternityDuration.title = 'Materninty';
    columns.employerContribution.format = formatNumber;

    return !rates[jurisdiction] ? <Loading/> : <Table 
        id='rates-table' 
        data={rates[jurisdiction]} 
        entity={Rates} 
        columns={columns} 
        sort='effPeriod' 
        desc 
        dataKey='id' 
        className='header-no-gap' 
        onSelect={onSelect} 
        key={uuid()}
    />
}

const PeriodicRatesList = () => {

    const user = useUser();
    const [jurRates, setRates] = useState([]);
    const [jurisdiction, setJurisdiction] = useState('que');
    const [selectedRates, _setSelectedRates] = useState();

    const setSelectedRates = (rates) => {
        if (user.isSuperAdmin) _setSelectedRates(rates)
    }

    const loadRates = async () => {
        const rates = await RatesService.getRates({refresh: true});
        setRates(rates);
    }

    useEffect(() => {
        loadRates();
    }, []);

    const handleNewRate = () => {
        const newRates = jurRates[jurisdiction].last.clone();
        newRates.effPeriod = new Period();
        newRates.id = '';
        setSelectedRates(newRates);
    }

    return jurRates.length === 0 ? <Loading /> : (<>
            <div className='justify-content-between d-flex'>
                <Tabs bodyClassName='pt-2 bg-color' onChange={setJurisdiction}>
                    <Tabs.Tab name='que' title='Quebec'/>
                    <Tabs.Tab name='fed' title='Federal'/>
                    <Tabs.Tab name='pol' title='Police(EEPF)'/>
                </Tabs>
                <SuperAdminGuard>
                    <div className="button-row mt15 mb15">
                        <Button
                            key="new-employer"
                            className="btn btn-secondary"
                            onClick={handleNewRate}
                        >
                            New Rates
                        </Button>
                    </div>
                    {selectedRates && <Modal className='w-80 modal-bg-color'> 
                        <PeriodicRatesEdit selectedRates={selectedRates} handleClose={() => { setSelectedRates(); loadRates(); }}/> 
                    </Modal>}
                </SuperAdminGuard>
            </div>
            <RatesTable rates={jurRates} onSelect={setSelectedRates} jurisdiction={jurisdiction}/>
    </>)
}


export default PeriodicRatesList;

