import { RefMap } from '../../framework/infra'
import Solvency from './Solvency'

export default class Solvencies extends RefMap {
    getSolvencyAtPeriod(period) {
        return this.findLast(solv => solv.effPeriod.isSameOrBefore(period))
    }
    
    static ref = Solvency
}
