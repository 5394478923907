import React from 'react'

export default class Input extends React.Component {
    render() {
        return this.view()
    }
    
    view() {
        return <input {...this.inputProps} />
    }

    _handleChange(event) {
        if(this.props.onChange) this.handleChange(event, this.props.onChange)
    }
    handleChange(event, onChange) {
        onChange(event.target.value, this.props.name)
    }

    get inputProps() {
        //support label(i18n), inline, label-span, input-span, nullable
        const { name, value, required, readOnly, className, placeholder } = this.props
        return { 
            id: name,  
            name: name, 
            value: value,
            required: required, 
            readOnly: readOnly, 
            className: ['s-input', className].join(' '), 
            placeholder: placeholder, 
            onChange: this._handleChange.bind(this),
        }
    }
}

