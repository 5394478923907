import React from 'react'
import InputControl from './InputControl'
import { Form } from 'react-bootstrap'

export default class Choice extends InputControl {
    handleChange(event){
        const option = this.props.options.find(o => String(o.key) === event.target.value) //added in case the key is not a string (the option convert the value to string)
        this.props.instance[this.props.realName] = option && option.key
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { instance, realName, name, required, className, options, readOnly, disabled} = this.props
        return (
            <Form.Control 
                className={className}    
                as="select" 
                name = {name}
                key = {name}
                value = {instance[realName]}
                onChange={this.onChange}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
            >
                <option disabled selected value=""></option>
                {options.map(option => (
                    <option key = {option.key} value = {option.key}>{option.text}</option>
                ))}  
            </Form.Control>
        )
    }
}

Choice.format = function(def, value) {
    return def.getOptionText(value)
}

Choice.sort = function(def, a, b) {
    if (def.getOptionText(a) === def.getOptionText(b)) return 0
    else return def.getOptionText(a) > def.getOptionText(b) ? 1 : -1
}


Choice.filter = (def, filterValue, rowValue, rowData) => {
    filterValue = filterValue.toLowerCase()
    if(rowValue && def.getOptionText(rowValue) && (rowValue.toLowerCase().includes(filterValue) || def.getOptionText(rowValue).toLowerCase().includes(filterValue))) return true
  
    return false
}