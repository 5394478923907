import { Definition } from '../../../../framework/infra'
import { Period } from '../../../../framework/utils'
import { AdjustmentService, RemittanceService } from '../../../../services'
import { Remittance } from '../../../../entities'
import ReportLayout from '../../../../framework/components/page/ReportLayout'
import { RemittanceBusiness } from '../../../../business'

/**
 * Contributions Detail Log
 */
export default class RemittancesSummaryReport extends ReportLayout {
    reportDesc = 'Remittances'
    entity= Remittance
    headers = [
        'period', 'employer.code', 'employer.plan.jurisdiction', 
        'regular', 
        'maternity', 'longTerm', 'self',  'eeCurYearAdjustments', 'totalEeContribsCurYr', 'voluntary',
        'erContribs', 'erCurYearAdjustments', 'totalErContribs', 'erAdjustmentCmt',
         'totalPriorEeAdjustments', 'totalPriorErAdjustments', 'priorErAdjustmentCmt',
        'solvency', 
        'solAdjustments', 'solAdjustmentsCmt',
        'interest', 'intAdjustments', 'intAdjustmentsCmt', 'creditUsed', 'total', 'prevBalance',
        'totalPayments', 'balance', 'payments.desc', 'payments.cmt',
        'importedDate',
    ]
    headerProps = {
        'totalEeContribsCurYr': {width: 188},
        'erContribs': {width: 186},
        'intAdjustments': {width: 170},
        'solAdjustments': {width: 176},
        'period': {minWidth: 92},
        'employer.code': {minWidth: 78},
        'employer.plan.jurisdiction': {minWidth: 84},
        'importedDate': {minWidth: 152},
        'payments.desc': {title: 'Payments (Ref # | Receive Date | Amount)', minWidth: 400},
        'payments.cmt': {minWidth: 400},
        'creditUsed': {overrideText: 'Credit to offset Employer Contributions & Interest during a holiday'},
        'total': {overrideText: 'Total Contributions Owing'},
        'voluntary': {overrideText: 'Voluntary Contributions'}
    }
    tableSort = 'employer.code'
    filters = {
        'employer.plan.jurisdiction': {
            nullable: true, 
            initialValue: this.props.params && this.props.params.employer.plan.jurisdiction
        },
        'employer': {
            sortBy: 'code', 
            display: 'longDesc', 
            nullable: true, initialValue: this.props.params && this.props.params.employer
        },
    }
    params = {
        from: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "From Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                ),
            }
        },
        to: {
            definition: {
                type: Definition.types.PERIOD, 
                text: "To Period", 
                options: Period.getPeriods(
                    Period.getLaunchPeriod().dec(1), 
                    Period.getCurrentPeriod(), 
                    true
                )
            }
        }
    }

    async execQuery(queryParams) {
        RemittanceService.invalidateCache();
        AdjustmentService.invalidateCache();
        
        const remittances = await RemittanceService.getAll();
        const adjustments = await AdjustmentService.getAll();

        const remittancesWithAdjustments = remittances.map(remittance => {
            
            const adjustmentsForRemittance = adjustments.filter(adjustment => 
                adjustment.remittance.keyValue === remittance.keyValue
            );
            remittance.adjustments = adjustmentsForRemittance;
            remittance.priorYearsAdjustments = [];

            return remittance;
        }).map((remittance, index, remittanceList) => {
            const employerRemittances = remittanceList.filter(rem => 
                rem.employer.keyValue === remittance.employer.keyValue
            );
            if (!remittance.validated) {
                RemittanceBusiness.refreshBalances(employerRemittances);
            }
            return remittance;
        });  

        const filteredRemittancesWithAdjustments = remittancesWithAdjustments.filter(rem => 
            rem.period.isSameOrBefore(queryParams.to) && rem.period.isSameOrAfter(queryParams.from)
        ).sort((a,b)=> a.period.isAfter(b.period) ? 1 : -1);

        filteredRemittancesWithAdjustments.forEach(remWithAdj => {
            const remittancesWithPreviousYearsAdjustments = filteredRemittancesWithAdjustments.filter(y => 
                // remittance period is already in selected periods, and remittance's adjustment period is same as remittance's period, so no need to filter it
                y.employer.code === remWithAdj.employer.code &&  y.adjustments?.find(z => 
                    // targetPeriod in previous years
                    z.targetPeriod?.year && remWithAdj.effPeriod?.year && Number(z.targetPeriod.year) < Number(remWithAdj.effPeriod.year) 
                    // target period is same month
                    && ((!z.targetPeriod?.month && !remWithAdj.effPeriod?.month) || z.targetPeriod?.month === remWithAdj.effPeriod?.month)
                )
            );

            remWithAdj.priorYearsAdjustments = remittancesWithPreviousYearsAdjustments.map(y => y.adjustments.filter(z => 
                // targetPeriod in previous years
                z.targetPeriod?.year && remWithAdj.effPeriod?.year && Number(z.targetPeriod.year) < Number(remWithAdj.effPeriod.year) 
                // target period is same month
                && ((!z.targetPeriod?.month && !remWithAdj.effPeriod?.month) || z.targetPeriod?.month === remWithAdj.effPeriod.month)
                    )
            ).flat();

            remWithAdj.curYearAdjustments = remWithAdj.adjustments.filter(z => 
                // targetPeriod in same years
                z.targetPeriod?.year && remWithAdj.period?.year && Number(z.targetPeriod.year) === Number(remWithAdj.period.year) 
            );
        });

        return filteredRemittancesWithAdjustments;
    }
}