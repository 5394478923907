import React from 'react'
import { Spinner, Alert } from "../controls"
import { Container, Row, Card, Modal } from "../containers"
import { Wait } from "../components"

export default class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: (typeof this.load === "function") ? true : false, 
            busy: false
        }
        this.notifyRef = React.createRef();
    }
    
    componentDidMount() {
        if (typeof this.load === "function") { 
            var loadedState = {}
            Promise.all([
                this.wrapperLoad(),
                this.load(),
            ]).then(([wrapperState, state]) => {
                Object.assign(loadedState, wrapperState, state)
            }).catch((error) => {
                console.log(error)
                Object.assign(loadedState, {error: error})
            }).finally(() => {
                this.setState(Object.assign({loading: false}, loadedState))
            })
        }
    }

    render() {
        var view
        if (this.state.error) {
            view = <Error error={this.state.error}/>
        } else if (this.state.loading ) {
            view = <Loading/>
        } else if (this.state.busy) {
            view = <Wait/>
        } else {
            view = this.view()
        }
        return this.wrapper(view)
    }
    
    wrapper(view) {
        return view
    }
    wrapperLoad(view) {
        return Promise.resolve({})
    }

    reset() {
        this.componentDidMount()
    }
    
    redraw() {
        this.setState({redraw: true})
    }
    
    busy(promise){
        this.setState({busy:true})
        setTimeout(() => promise().finally(() => {
            this.setState({busy:false})
        }))
    }
    processing(tag, promise){
        this.setState({processing:tag})
        setTimeout(() => promise().finally(() => {
            this.setState({processing: ''})
        }))
    }
    
    notify(message, severity) {
        this.notifyRef && this.notifyRef.current && this.notifyRef.current.notify && this.notifyRef.current.notify(message, severity)
    }


}

function Busy(props) {
    return (
        <Modal className='glass-pane' dialogClassName="glass-pane" show={props.show} centered animation={false} size='sm'>
            <span className="sr-only">Loading...</span>
        </Modal>
    )
}
function Loading(props) {
    return (
        <Container fluid className="vh-50 d-flex">
            <Row className="justify-content-center align-self-center w-100 text-center">
                <span>Loading...</span>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </Row>
        </Container>
    )
}
function Error(props) {
    return (
        <Card>
            <Card.Body>
                <Alert variant="danger">
                    <div className="alert-message">
                    <h4 className="alert-heading">{props.error.code}</h4>
                    <p>{props.error.message}</p>
                    </div>
                </Alert>
            </Card.Body>
        </Card>
    )
}

