import React from 'react'
import moment from 'moment'
import { formatAmount } from '../../utils/formating'

export default class EDisplay extends React.Component {
    render() {
        const { name, instance, className, cn, labelOnly, valueOnly, lcn, ccn, value } = this.props
        var ctrlLabel = this.props.label
        var displayValue = value ?? instance[name]
        const definition = instance.constructor.definitions[name]
     
        if (definition) {
            if (!ctrlLabel) ctrlLabel = definition.text
            if (definition.options) displayValue = definition.getOptionText(displayValue)
            if (definition.isBool()) {
                displayValue = displayValue ? 'Yes' : 'No'
             }
            if (definition.isDate()) {
                displayValue = displayValue ? moment(displayValue).format('LL') : ''
            }
            if (definition.isCurrency()){
                displayValue = displayValue || displayValue === 0 ? formatAmount(displayValue) : ''
            }
            if (this.props.children) {displayValue = this.props.children}
            //TODO handle other definition types
        }

        return <span className={className || cn}>
            {!valueOnly && <label className={'title ' + lcn || ''}>{ctrlLabel}</label>}
            {!labelOnly && <label className={ccn || ''}>{valueOnly ? displayValue : ':  ' + displayValue}</label>}
        </span >
    }
}

