import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class RefundBreakdownType extends RefEnum {
    constructor(key, defaultDesc, lockedIn) {
        super(key, defaultDesc)
        this.lockedIn = lockedIn
    }

    static types = deepFreeze({
        PF: new RefundBreakdownType('PF', 'Pension Formula', true),
        AB: new RefundBreakdownType('AB', 'Additional Benefit', false),
        EC: new RefundBreakdownType('EC', 'Excess Contributions', false),
        VC: new RefundBreakdownType('VC', 'Voluntary Contributions', false)
    })
    
    static definitions = {
        desc: { type: Definition.types.STRING, text: 'Account Type' },
        lockedIn: { type: Definition.types.BOOLEAN, text: 'Locked-In', text_fr: '' }
    }
}