import { RefHistorical, RefEvent } from "../../../framework/infra";
import { newEmployerChecklistConfig } from "./NewEmployerConfig";

export class NewEmployerChecklistEvent extends RefEvent {
    get config() {
        return {
            ...newEmployerChecklistConfig["default"],
            ...newEmployerChecklistConfig[this.code],
        };
    }

    static messages = [
        // prior
        [
            "determineRegistration",
            "Determine employer's jurisdiction (see Notes for determination)",
        ],
        [
            "emailABC",
            "Emailed (a) Application Form; (b) Fact Sheet; (c) Employee Booklet",
        ],

        // received
        [
            "appSubmitted",
            "Application submitted by employer (see Notes if any follow-up required)",
        ],
        [
            "emailToWC",
            "Email completed Application to Working Sub-Committee {Email to WC}",
        ],
        ["deadlineToWC", "Deadline give to Working Sub-Committee"],
        [
            "appApproved",
            "Working Sub-Committee unanimously approved application",
        ],

        // accepted
        [
            "acceptedWC",
            "Informed Working Sub-Committee of approval {Accepted-WC}",
        ],
        [
            "acceptedMercer",
            "Informed Actuary of results of approval {Accepted-Mercer}",
        ],
        ["acceptedER", "Informed potential employer of approval {Accepted-ER}"],
        [
            "emailERInstructions",
            "Emailed Employer Instructions and reference documentation",
        ],
        ["emailER1", "Email 1: Adhesion Requirements"],
        ["emailER2", "Email 2: Enrolment Requirements"],
        ["emailER3", "Email 3: Retirement Committee Member Appointment"],
        ["emailER4", "Email 4: Requirement Contributions"],
        ["emailER5", "Email 5: Administration Forms"],
        ["emailER6", "Email 6: Reference Documentation"],

        // requirements
        ["isRequirementsMet", "Have requirements for joining been met?"],
        ["adhesionForm", "Adhesion Form submitted"],
        ["erSubmitted", "Employer Resolution to adhere submitted"],
        ["enrolmentForms", "Enrolment Forms submitted for all new members"],
        [
            "rcSubmitted",
            "Retirement Committee Member Appointment Resolution submitted",
        ],

        // finalize
        ["signature", "Adhesion Form signed by Committee Chair"],
        [
            "amendmentSignature",
            "If required, Amendment (prepared by actuary) signed by Committee Chair",
        ],
        [
            "amendmentFiled",
            "If required, Amendment filed (by actuary) with pension authorities",
        ],
    ];

    static groups = {
        prior: "Prior to Application",
        received: "Application Process",
        accepted: "Application Approved",
        requirements: "Employer Requirements for Adhesion",
        finalize: "Finalize Legal Requirements",
    };

    static definitions = {
        config: { abstract: true },
    };

    static messagesOption(group) {
        return Object.values(this.messages)
            .filter((opt) =>
                group
                    ? newEmployerChecklistConfig[opt.key].group === group
                    : true
            )
            .map((opt) => ({
                key: opt.key,
                value: opt,
            }));
    }
}

export class NewEmployerChecklistEvents extends RefHistorical {
    pushEvent(eventData, params) {
        const event = this.create(eventData);
        this.push(event);
        if (event.config.next) event.config.next(params);
    }

    static ref = NewEmployerChecklistEvent;
}
