import React from 'react'
import { Component, Question } from '../../framework/components'
import { Table, Icon } from '../../framework/controls'
import { Modal, FieldSet } from '../../framework/containers'
import { renderComponent } from '../../framework/utils/renders'

import RefundBreakdown from '../../entities/pension/refund/RefundBreakdown'
import ParticipationRefundForm from "./ParticipationRefundForm"

export default class RefundsList extends Component {
    view() {
        const { participation, refundAccounts, readOnly } = this.props 
        const { selected, addRefund, editRefund, deleting} = this.state
    
        const payout = participation.payout
        const columns = new Table.Headers(RefundBreakdown, 'type.desc, principleAmount, interestRate, total')
        columns['delete'] = { visible: !readOnly, width: 50, align:"center", cellClick: this.handleDelete.bind(this), headerClick: this.handleAddRefund.bind(this), format: () => renderComponent(<Icon icon='minus-circle' large/>),  headerSort: false , titleFormatter: () => renderComponent(<Icon icon='plus-circle' large className="v-bottom"/>)}

        return <FieldSet className='mt-3 pt-1'>
            <Table
                id="refunds-table"
                data={[...payout.breakdown.all]}
                entity={RefundBreakdown}
                columns={columns}
                onTableLoad={(table) => this.table = table}
                onSelect={!readOnly && this.handleSelect.bind(this)}
                noPlaceHolder
                selectable
                fitHeightToContent
            />
            {selected && (addRefund || editRefund) && <Modal className='h-60 modal-bg-color'>
                <ParticipationRefundForm refundAccounts={refundAccounts} refund={selected} onOK={this.handleRefundConfirm.bind(this)} onCancel={this.handleCancel.bind(this)} />
            </Modal>}
            {deleting && <Question title='Are you sure you want to delete this item?' onAnswer={this.handleConfirmDelete.bind(this)} />}
        </FieldSet>
    }
  
    handleCancel() { this.setState({selected: null, addRefund: false}) }
    handleSelect(row) { this.setState({selected: row, editRefund: true}) }
    handleAddRefund() { this.setState({selected: new RefundBreakdown({elapsedDays: this.props.participation.payout.elapsedDays}), addRefund: true}) }
    handleRefundConfirm() {
        const { participation } = this.props
        if (this.state.addRefund) { participation.payout.breakdown.push(this.state.selected) }
        this.setState({addRefund: false, editRefund: false})
        if (this.props.onChange) this.props.onChange()
    }
    handleDelete(e, cell) {
		e.preventDefault()
		e.stopPropagation()
		this.setState({deleting: cell.getRow().getData()})
	}
    handleConfirmDelete(confirmed) {
		if(confirmed) this.props.participation.payout.breakdown.pull(this.state.deleting)
		this.setState({deleting: null})
        if (this.props.onChange) this.props.onChange()
	}
}