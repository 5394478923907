import EmailForm from '../../entities/frontend/EmailForm'
import { Service } from '../../framework'

class EmailService extends Service{
    constructor() {
        super(EmailForm, 'email')
    }

    async send(email) {
        let content = await email.getContent();
        return this.persistence.callApi(this.serviceName + '_SendEmail', {}, content);
    }
}

const instance = new EmailService()
export default instance
