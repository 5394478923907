import { useState, useEffect } from 'react'
import Auth from '../framework/infra/Auth'
import Menu from '../framework/infra/Menu'
import routes from '../routes'
import { useHistory } from 'react-router-dom'

const HeaderMenu = () => {
    var history = useHistory();
    const [menuItems, setMenuItems] = useState();

    useEffect(()=> {
        let isMounted = true;

        Auth.getUserActions().then(actions => {
            const items = Object.values(routes.pages).map((item) => {
                if ((!item.access || actions.includes('*') || actions.includes(item.access)) && !item.hide) {
                    return item;
                }
            }, [])
            if (isMounted) {
                setMenuItems(items)
            } 
        })

        return () => { isMounted = false };
    },[])

    const handleSelect = (item) => { 
        if (routes.pages[item.key]) {
            history.push(routes.pages[item.key].path)
        }    
    }

    if(!menuItems) return <></>
    
    return <Menu menu={menuItems} variant='line' onSelect={handleSelect} />
}

export default HeaderMenu;
