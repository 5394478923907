import React from "react"
import LoginWrapper from './LoginWrapper'
import Auth from '../Auth'

export default class SignIn extends LoginWrapper {

    handleSignIn(event) {
        event.preventDefault()
        const userName = event.target.userName.value.toLowerCase()
        const password = event.target.password.value

        return Auth.signIn(userName, password).then(data => {
            window.location.reload()
        }).catch(err => {
            if (err.errorCode === 'newPasswordRequired') {
                this.props.onStatusChanged('newPasswordRequired', userName)
            } else if (err.errorCode === 'resetPasswordRequired') {
                this.props.onStatusChanged('resetPasswordRequired', userName)
            } else {
                this.setState({ message: err })
            }
        })
    }

    handleForgotPassword(e) {
        e.preventDefault()
        this.props.onStatusChanged('requestSecurityCode')
    }

    view() {
        return (
            <form noValidate validated={ this.state.validated ? this.state.validated : undefined } onSubmit={this.handleSignIn.bind(this)} className="login-form">
                <div className="md-form">
                    <input className="form-control" id="userName" name="userName" label="Username" type="text" required/>
                    <label className="active" htmlFor="userName" data-error="" data-success="" id="" aria-labelledby="">Username</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="password" name="password" label="Password" type="password" required/>
                    <label className="active" htmlFor="password" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                
                <p className="font-small blue-text d-flex justify-content-end">
                    <a href="#!" className="ml-1 forgot-password" onClick={this.handleForgotPassword.bind(this) }>Forgot Password?</a>
                </p>
                <div className="text-center sign-in-no-opacity">
                    <button className="btn sign-in" type="submit">Sign in</button>
                </div>
            </form>
        )
    }
}
