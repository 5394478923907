import React, { useState } from 'react'
import { FieldSet, Row, Title } from '../../framework/containers'
import Loading from '../../components/containers/Loading'
import Button from '../../framework/controls/Button';
import { EInput, Question } from '../../framework/components';
import { Period } from '../../framework/utils';
import { RatesService } from '../../services';

const TITLES = {
    que: 'Quebec',
    fed: 'Federal',
    pol: 'Police (EEPF)',
}
const WARNINGS = {
    delete: 'Are you sure you want to delete these rates? Deleting rates may have an impact on calculations.',
    new: 'Are you sure you want to add these rates? New rates may have an impact on calculations. ',
    update: 'Are you sure you want to update these rates? New rates may have an impact on calculations. ',
}

const PeriodicRatesEdit = ({selectedRates, handleClose}) => {
        
    const [warning, setWarning] = useState();
    const [errors, setErrors] = useState({});
    const [draft] = useState(selectedRates.clone());
    const period = draft?.effPeriod?.text;
    const maxPeriod = (new Period()).inc(12);
    const minPeriod = new Period('199001');
    const isNew = draft.id === '';

    const handleDeleteWarning = () => setWarning(<Question title={WARNINGS.delete} onAnswer={(answer) => handleDelete(answer)} />);
    const handleEditWarning = () =>  { if (isValid()) setWarning(<Question title={WARNINGS.update} onAnswer={(answer) => handleUpdate(answer)} />)};
    const handleAddWarning = () => { if (isValid()) setWarning(<Question title={WARNINGS.new} onAnswer={(answer) => handleAdd(answer)} />)};
    
    const isValid =  () => {
        const errors = RatesService.validate(draft);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    }

    const handleAdd = async (answer) => {
        if (answer) await RatesService.createRates(draft);
        close(answer);
    }
    const handleDelete = async (answer) => {
        if (answer) await RatesService.deleteRates(draft);
        close(answer);
    }
    const handleUpdate = async (answer) => {
        if (answer) await RatesService.updateRates(draft);
        close(answer);
    }
    const close = (answerIsYes) => {
        setWarning();
        if (answerIsYes) { 
            handleClose();
        }
    }

    return !draft ? <Loading /> : (<>
        <Title title={(isNew ? 'New ' : 'Editing ') + TITLES[draft.jurisdiction] + ' Rates ' + (period ? '(' + period + ')' : '') } onHide={handleClose}/>
        <Row>
            <EInput 
                cn='col-3'
                name='effPeriod' 
                instance={draft} 
                showRequiredLabelMarker
                from={minPeriod}
                to={maxPeriod}
                error={errors.effPeriod}
            />
        </Row>
        <FieldSet title="Rates" className="mt-2" variant="field">
            <div className="grid-ellipsis">
                <EInput name="deductionRate" instance={draft} />
                <EInput name="increassedRate" instance={draft}/>
                <EInput name="employerContribution" instance={draft}/>
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="ympe" instance={draft}/>
                <EInput name="maxContributions" instance={draft}/>
                <EInput name="maxVoluntaryCQPP" instance={draft}
                />
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="maxVoluntaryNonCQPP" instance={draft}/>
                <EInput name="moneyPurchaseLimit" instance={draft}/>
                <EInput name="definedBenefitLimit" instance={draft}/>
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="fundRateOfReturn" instance={draft}/>
                <EInput name="reduceAccrual" instance={draft} />
                <EInput name="reduceAccrualIncreased" instance={draft}
                />
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="monthlyInterestRate" instance={draft}/>
                <EInput name="valuationRate" instance={draft}/>
                <EInput name="maternityDuration" instance={draft}/>
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="solRetroChangeInt" instance={draft}/>
                <EInput name="erContribRetroChangeInt" instance={draft}/>
                <EInput name="aiwRate" instance={draft}/>
            </div>
        </FieldSet>
        <div className='footer-buttons mt15 button-row g15'>
            {!isNew && <Button key='delete' className='btn-secondary' onClick={handleDeleteWarning}>Delete</Button>}
            <Button key='close' className='btn-secondary' onClick={handleClose}>Close</Button>
            <Button key='save' className='btn-primary' onClick={isNew ? handleAddWarning : handleEditWarning}>Save</Button>
            {warning}
        </div>
    </>)
}

export default PeriodicRatesEdit;