import deepFreeze from 'deep-freeze'
import { replaceParams } from '../utils/helper'

export default class Lng {
    static current = 'en'
    static default = 'en'

    static getText(i18nText, params) {
        if (!i18nText) return ''
        if (typeof i18nText !== 'object') return String(i18nText)
        return replaceParams(i18nText[this.current] || i18nText[this.default], params)
    }
}
deepFreeze(Lng.default)
