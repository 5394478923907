import { create } from 'zustand';
import { EmployerService, EmploymentService } from '../services';
import { array2Object } from '../framework/utils/helper';

export const useEmployerStore = create((set) => ({
    employers: {},
    storeEmployers: async () => {
        EmployerService.invalidateCache();
        let list = (await EmployerService.getEmployers({ load: false }))?.all ?? [];
        set({ employers: array2Object(list.map(x=> ({code: x.code, id: x.keyValue, name: x.name })), 'code')})
    },
}));