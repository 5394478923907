import { Service, Persistence } from '../../framework'
import { Membership, Person } from '../../entities'
import { EmploymentService, PersonService }  from '..'
import { MembershipBusiness } from '../../business'
import { BeneficiaryService, ParticipationService } from '.';

class MembershipService extends Service {
    constructor() {
        super(Membership, "Membership");
    }

    /**
     * Update the membership
     * 
     * Will check if we need to add a RTW event to the employments of the updated participation, and add it if needed
     * @param {Membership} membership 
     * @param {string | undefined} updatedParticipationNo The no value of the participation updated by this update call (in case this update function was called to update just one participation)
     * @returns 
     */
    async update(membership, updatedParticipationNo){
        const participationToUpdate = updatedParticipationNo ? membership.participations.find(pp => pp.no === updatedParticipationNo) : undefined;
        const beforeParticipationUpdateChecks = participationToUpdate ? await ParticipationService.checksBeforeUpdate(participationToUpdate) : undefined;
        const result = await super.update(membership);
        const updatedParticipation = updatedParticipationNo ?  result?.participations.find(pp => pp.no === updatedParticipationNo) : undefined;
        if(participationToUpdate && updatedParticipation && beforeParticipationUpdateChecks) {
            await ParticipationService.onAfterUpdate(updatedParticipation, beforeParticipationUpdateChecks);
        }

        return result
    }

    getMembership(id, options) {
        return this.persistence.callApi("membership_GetMembership", { id }).then((mem) => {

            let member = new Membership(mem);
            if (!member.person?.id) member.person.id = id;
           
            return this.load(member, { ...options, includeEmployments: true}).then((loadedMember) => {
                return this.init(loadedMember);
            });
        });
    }

    getAllMemberships(options) {
        return this.persistence.callApi("membership_GetMemberships", options).then(resp => console.log('memberships:', resp));
    }

    getMemberships(options) {
        return PersonService.getAll().then(() => this.getAll(options, true));
    }

    getMembershipsForEmployer(employerId, options) {
        return this.getBy(
            "GetMembershipsForEmployer",
            { id: employerId },
            options
        );
    }

    async getNewMembersReport(startDate, endDate) {
        const action = "GetNewMemberReport";
        return this.getBy(action, {startDate, endDate}, {includeEmployments: true});
    }

    /**
     * 
     * @param {*} membership 
     * @param {{includeEmployments?: boolean;}} options 
     * @returns 
     */
    async load(membership, options = {}) {
        let person =  await PersonService.get(membership.person.id);

        let employments = options.includeEmployments ? await EmploymentService.getMemberEmployments(membership.person.id, options) : [];
        membership.person = person;
        membership.participations.forEach((pp) => {
            pp.membership = membership;
            if (options.includeEmployments) {
                pp.employments.pushList(employments.filter((e) => e.keysValues.participation === pp.keyValue));
            }
            pp.payout.distribution.forEach((distrib) => {
                distrib.account = membership.refundAccounts.find(
                        (account) => account.keyValue === distrib.account.keyValue) || distrib.account;
            });
        });
        
        if(membership.beneficiaries.length > 0) {
            const benefs = await BeneficiaryService.get(membership.person.id);
            for (let participation of membership.participations.all) {
                participation.beneficiaries.reset();
                participation.beneficiaries.pushList(benefs.filter(x=> x.participationNo === participation.no));
            }
        }
        membership.participations.resetMapKeys(); //since we only set the membership after the map is created then the keys are incomplete, this methods reset them

        for (let participation of membership.participations.all) {
            await ParticipationService.load(participation, options);
        }
        return membership;
    }

    init(membership) {
        return MembershipBusiness.validate(membership);
    }
}

const singelton = new MembershipService()
export default singelton
