import React from 'react'
import { StyleSheet } from '@react-pdf/renderer'
import { Page, Document, Container, Text, Row, Currency, Separator, AmountDetail, getText } from '../../../framework/utils/pdf' 
import { group as AdjustmentCategories } from "../../../entities/pension/adjustment/AdjustmentConfigs";

import moment from 'moment'
import { formatPercentage } from '../../../framework/utils/formating'

import PDFHeader from './PDFHeader'
import PDFPaymentMethod from './PDFPaymentMethod'
import PDFAddendum from './PDFAddendum'
import { getPDFTexts } from './textPDF/textPDFInvoiceOrStatement';
import { add } from '../../../framework/utils/helper';
import { AdjustmentService } from '../../../services';

const InvoiceOrStatementPdf = ({ config, employer, isStatement, remittance, employerAdjustments, yearEndPaymentTotal }) => {
    const isYearEnd = remittance.period.yearEnd === true;
    const missingCredits = AdjustmentService.getMissingStartCreditsForAllTypes(remittance.period, employerAdjustments);
    const texts = getPDFTexts(isStatement);
    const remittancePeriod = isYearEnd ? getText(texts['year-end-adjs'], '', {year: remittance.period.value}) : remittance.period.desc;
    const duePeriod = remittance.period.inc();
    const duePeriodFormated = remittance.period.inc().desc;
    const dueDateFormated = duePeriod.numberOfDaysInPeriod + ' ' + duePeriodFormated;
    const totalPayments = isYearEnd ? yearEndPaymentTotal : remittance.totalPayments
    const paymentsText = isYearEnd ? texts['year-end-payments'] : texts['payment-received'];
    const paymentsParams = isYearEnd ? {year: remittance.period.value} : {duePeriodFormated: duePeriodFormated};

    const percentage = formatPercentage(remittance.rates.employerContribution);
    const statementNo = isStatement ? employer.code + remittance.period.value + 'v01' : undefined;
    const adjustmentCheck =
        remittance.adjustments.filter(
            (adj) => adj.category === AdjustmentCategories.CONT
        ).length !== 0 || missingCredits.length !== 0;

    const creditTotal = employerAdjustments
        .filter(adj => adj.period.isSame(remittance.period) && adj.type.config.isCredit && !adj.type.config.isCancelUsedCredit && !adj.isCancelCredit)
        .reduce((total, adj) => add(adj.total, total), 0);

    const totalSolvencyCredit = remittance.adjustments
        .filter(adjustment => 
            adjustment.type.config?.isCredit 
            && adjustment.total < 0 
            && adjustment.type.config?.targetAccounts?.[0] === 's'
        )
        .reduce((prev, adjustment) => prev + adjustment.total, 0);

        // erAdjustedContribs: erContribs + erAdjustments (sum of employerContribution in all adjustments)
        const employerAmount = remittance.erAdjustedContribs + remittance.intAdjustments - creditTotal;
        const currentContributions = remittance.eeAdjustedContribs + employerAmount + remittance.voluntary;

        // erAdjustments: sum of employerContribution in all adjustments
        const erAdjustmentsAmount = remittance.erAdjustments + remittance.intAdjustments - creditTotal;

    const creditUsedText = {
        en: remittance.period.longDesc + " " + texts['credit-used'].en,
        fr: remittance.period.longDesc + " " + texts['credit-used'].fr,
    }

    const styles = StyleSheet.create({
        invoiceInfoLabel: { width: 70, fontFamily: 'Roboto-Bold' },
        header: { width: '100%', textAlign: 'center', fontFamily: 'Roboto-Italic', color: !isStatement && '#0092BD' },
        headerTitle: { fontSize: 13, fontFamily: 'Roboto-Bold-Italic' },
        levelOne: { color: '#5B5B5B' },
        levelTwo: { color: '#4d4d4d', fontSize: 9, fontFamily: 'Roboto-Bold' },
        levelThree: { fontSize: 11, fontFamily: 'Roboto-Bold' },
        levelFour: { fontSize: 15, fontFamily: 'Roboto-Bold' },
        paymentNotice: { fontFamily: 'Roboto-Italic', textAlign: 'center', marginTop: 5, marginBottom: 5, color: '#EA0E0E' },
        reference: {fontFamily: 'Roboto-Italic', fontSize: 8},
        reportWarning: { fontSize: 8, textAlign: 'center', fontFamily: 'Roboto-Italic' },
        underline: { borderBottom: '1 solid #4d4d4d' },
        small: { fontSize: 8 }
    });

    return (
        <Document>
            <Page>
                <PDFHeader />
                <Separator className='mt-2'/>
                <Row className='mt-2 space-between'>
                    <Container>
                        <Text>{employer.admin && employer.admin.person.desc}</Text>
                        <Text className='mt-1'>{employer.name}</Text>
                        <Text className='mt-1'>{employer.address && employer.address.desc}</Text>
                    </Container>
                    <Container>
                        {isStatement && 
                            <Row>
                                <Text text={texts['statement-no']} style={styles.invoiceInfoLabel} />
                                <Text>:   {statementNo}</Text>
                            </Row>
                        }
                        <Row>
                            <Text text={texts['issue-date']} style={styles.invoiceInfoLabel} />
                            <Text>:   {moment().format('DD MMMM YYYY')}</Text>
                        </Row>
                        <Row>         
                            <Text text={texts['period']} style={styles.invoiceInfoLabel} />
                            <Text>:   {remittancePeriod}</Text>
                        </Row>
                        <Row>
                            <Text text={texts['due-date']} style={styles.invoiceInfoLabel} />
                            <Text>:   {dueDateFormated}</Text>
                        </Row>
                        <Row>
                            <Text text={texts['balance']} style={styles.invoiceInfoLabel} />
                            <Text>:   <Currency>{remittance.balance}</Currency></Text>
                        </Row>
                    </Container>
                </Row>
                
                <Container className='mt-3' style={styles.header}>
                    <Text text={texts['report-title']} style={styles.headerTitle} />
                    {!isStatement && <Text text={texts['report-subtitle']} className='mt-2' />}
                </Container>
                <Separator className ='mt-2' />
            
                <Container>
                    <AmountDetail text={texts['previous-balance']} amount={remittance.prevBalance} className='mt-2' style={styles.levelThree} />
                    <AmountDetail text={texts['interest']} amount={remittance.interest} className='mt-2' style={styles.levelThree} />
                    <AmountDetail text={texts['current-contrib']} amount={currentContributions} className='mt-2' style={styles.levelThree} />
                    <AmountDetail text={texts['employee']} amount={remittance.eeAdjustedContribs} className='mt-3 pl-3 w-80' style={styles.levelTwo} amountStyle={styles.underline} />
                    <AmountDetail text={texts['payroll-deductions']} amount={remittance.regular} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['er-paid-maternity']} amount={remittance.maternity} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['er-paid-ltd']} amount={remittance.longTerm} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['self-contributions']} amount={remittance.self} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['adjustments']} amount={remittance.eeAdjustments} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['employer']} amount={employerAmount} className='mt-3 pl-3 w-80' style={styles.levelTwo} amountStyle={styles.underline} />
                    <AmountDetail text={texts['er-contrib-factor']} params={{percentage: percentage}} amount={remittance.erContribs} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['adjustments']} amount={erAdjustmentsAmount} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['voluntary']} amount={remittance.voluntary} className='mt-3 pl-3 w-80' style={styles.levelTwo} amountStyle={styles.underline} />
                    <AmountDetail text={texts['solvency-deficit']} amount={remittance.solAdjusted - totalSolvencyCredit} className='mt-3' style={styles.levelThree} />
                    <AmountDetail text={texts['required-monthly-payment']} amount={remittance.solvency} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={texts['adjustments']} amount={remittance.solAdjustments - totalSolvencyCredit} className='mt-2 pl-4 w-80' style={styles.levelOne} blankForZero />
                    <AmountDetail text={paymentsText} params={paymentsParams} amount={-totalPayments} className='mt-3' style={styles.levelThree} important />
                    {remittance.totalOwing != remittance.creditUsed && <AmountDetail text={creditUsedText} amount={-remittance.creditUsed} className='mt-2' style={styles.levelThree} />}
                    <AmountDetail text={texts['total-owing']} amount={remittance.totalOwing} className='mt-2' style={styles.levelThree} />
                    <AmountDetail text={texts['total-balance']} amount={remittance.balance} className='mt-3' style={styles.levelFour} />
                    {remittance.balance !== 0 && 
                        <Container>
                            <Text className='mt-2 ml-2' >{remittance.finalDistrBalance.descBalance}</Text>
                            {remittance.prevBalance !== 0 && 
                                <Text className='mt-1 ml-3' text={texts['refer-statement']} style={styles.reference} />
                            }
                        </Container>
                    }
                    {adjustmentCheck && 
                        <Text className='mt-1 ml-3' text={texts['refer-adjustments']} style={styles.reference} />
                    }
                    <Text className='mt-1 ml-2' style={styles.small}>{remittance.cmt}</Text>
                </Container>

                <Separator className='mt-2 mb-1'/>
                <Text text={texts['notice']} style={styles.paymentNotice} />
                <Text text={texts['report-warning']} style={styles.reportWarning} />
                <PDFPaymentMethod config={config} employer={employer} />
            </Page>
            {adjustmentCheck && <PDFAddendum remittance={remittance} config={config} invoice={!isStatement} statementNo={statementNo} employerAdjustments={employerAdjustments} />}
       </Document>
    );
}

export default InvoiceOrStatementPdf;