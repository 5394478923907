import SustainedNewEmp from './SustainedNewEmp';
import SendEnrollInfo from './SendEnrollInfo';

export const emailTemplates = {
    sustainedNewEmp : {
        subject: (params) => `JBCNPP Membership sustained with new employer ${params.jurChanged ? '{Important change of Jurisdiction}' : ''} | ${params.employment.person.firstName} ${params.employment.person.lastName}`,
        body: <SustainedNewEmp/>,
    },
    sendEnrollInfo : {
        subject: (params) => 'Receipt Confirmation: Your JBCNPP Enrolment Form',
        body: <SendEnrollInfo/>
    }, 
}

export const emailFooter = {
    html: <div >
        <img width='272' height='47' src={window.location.origin + "/img/logo.png"} alt="A.S.Hayes Consulting Inc." />
        <div style={{fontSize: '10pt', color: '#76923c'}}><i>Coordinators of the James Bay Cree-Naskapi Pension Plan</i></div>
        <div style={{fontSize: '10pt', color: '#2d7b97'}}>205-4150 rue Ste-Catherine Ouest, Westmount (QC) H3Z 2Y5</div>
        <div style={{fontSize: '10pt', color: '#2d7b97'}}>tel: 514-933-9909 | email: admin@ashayes.ca</div>
        <img width='199' height='107' src={window.location.origin + "/img/carbon-footer.png"} alt='Carbon Neutral Organisation'/>
    </div>
}

export const emailSource = {
    default : 'admin@ashayes.ca',
    financial: 'mybenefits@ashayes.ca'
}

