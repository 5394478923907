import React from "react";
import { EInput } from "../../../framework/components";
import { Row, Card } from "../../../framework/containers";

const BankEFTForm = ({ eft }) => {
    const { address, address_account, account_fed, account_que } = eft;

    return (
        <Card className="px-3 py-3 mb-3">
            <h3>EFT</h3>
            <div className="d-flex justify-content-between">
                <EInput name="bank" instance={eft} cn="col-4" />
                <EInput name="bank_code" instance={eft} cn="col-4" />
                <EInput name="transit" instance={eft} cn="col-3" />
            </div>
            <hr />
            <h5>Address</h5>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="civic" instance={address} cn="col-9" />
                <EInput name="poBox" instance={address} cn="col-2" />
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="city" instance={address} cn="col-3" />
                <EInput name="prov" instance={address} cn="col-3" />
                <EInput name="country" instance={address} cn="col-3" />
                <EInput name="pc" instance={address} cn="col-2" />
            </div>
            <hr />
            <h5>Account Address</h5>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="civic" instance={address_account} cn="col-9" />
                <EInput name="poBox" instance={address_account} cn="col-2" />
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="city" instance={address_account} cn="col-3" />
                <EInput name="prov" instance={address_account} cn="col-3" />
                <EInput name="country" instance={address_account} cn="col-3" />
                <EInput name="pc" instance={address_account} cn="col-2" />
            </div>
            <hr />
            <h5>Federal Account Information</h5>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="name" instance={account_fed} cn="col-6" />
                <EInput name="number" instance={account_fed} cn="col-5" />
            </div>
            <hr />
            <h5>Quebec Account Information</h5>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="name" instance={account_que} cn="col-6" />
                <EInput name="number" instance={account_que} cn="col-5" />
            </div>
            <hr />
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="notice" instance={eft} cn="col-9" />
                <EInput name="disabled" instance={eft} cn="col-2" />
            </div>
        </Card>
    );
};

export default BankEFTForm;
