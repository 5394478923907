import { Definition, RefList } from '../../../framework/infra'
import Beneficiary from './Beneficiary'

export default class Beneficiaries extends RefList {
    get desc() { return this.latestGroup.map(beneficiary => '• ' + beneficiary.details).join('\n') }
    set desc(val) {}

    get beneficiariesGroupedByDate() {
        this._list.sort((a, b) => b.designationReceivedDate - a.designationReceivedDate);
        let groups = {};
        this._list.forEach(beneficiary => {
            if (!groups[beneficiary.effDt]) {
                groups[beneficiary.effDt] = [];
            }
            groups[beneficiary.effDt].push(beneficiary);
        });

        return groups;
    }

    get latestGroup() {
        const groups = this.beneficiariesGroupedByDate;
        if (Object.keys(groups).length === 0) return [];
        const mostRecentDate = Object.keys(groups)[0];
        return groups[mostRecentDate] ?? [];
    }

    get totalShare() {
        const group = this.latestGroup;
        if (group.find(beneficiary => beneficiary.isLegacy)) return -1;
        return group.reduce((prev, beneficiary) => {return (Number(beneficiary.shareOfProceeds) + prev) }, 0).toString();
    }

    removeById(id) {
        this._list = this._list.filter(x=>x.id !== id);
        return this._list;
    }

    static definitions = {
        totalShare: {
            type: Definition.types.PERCENTAGE,
            text: 'Total Share of Proceeds'
        },
    }
    static ref = Beneficiary
}