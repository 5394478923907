import React, { useState } from 'react'
import { EInput } from '../../framework/components'
import { Modal, Row, Title, FieldSet } from '../../framework/containers'
import { Form, Button } from '../../framework/controls'

import { Phone, Email, CompanyContact } from '../../entities'
import { PersonService, EmployerService } from '../../services'

import ContactList from './ContactList'
import ContactForm from './ContactForm'
import AddressDetails from '../address/AddressDetails'
import useNotification from '../../hooks/useNotification'
import Loading from '../../components/containers/Loading'
import { Card } from 'react-bootstrap'

const Contacts = ({ employer }) => {
	const { addMessage } = useNotification();
    const [selected, setSelected] = useState();
    const [isAddContact, setIsAddContact] = useState(false);
    const [isEditContact, setIsEditContact] = useState(false);
    const [isEditInfo, setIsEditInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const contacts = employer.contacts;

    const handleAddContact = () => { 
        setSelected(new CompanyContact());
        setIsAddContact(true);
    }

    const handleSelectContact = (row) => { 
        setSelected(row);
        setIsEditContact(true);
    }

    const handleCancelContact = () => { 
        setIsAddContact(false);
        setIsEditInfo(false);
        setIsEditContact(false);
    }

    const handleSaveContact = (contact, originalContact) => {
        setIsLoading(true);
        PersonService.update(contact.person).then((updatedPerson) => {
            contact.person = updatedPerson;
            if (isAddContact) {
                contacts.push(contact);
            }
            EmployerService.update(employer).then(() => {
                setIsAddContact(false);
                setIsEditContact(false);
                setIsLoading(false);
                addMessage("Contact successfully updated", "success");
            });
        });
    }
   
    const handleEditInfo = () => { 
        setIsEditInfo(true);
    }
    const handleCancelInfo = () => { 
        setIsEditInfo(false);
    }
    const handleSaveInfo = (employer) => {
        setIsLoading(true);
        EmployerService.update(employer).then(() => {
            setIsEditInfo(false);
            setIsLoading(false);
            addMessage("Headquarter info successfully updated", "success");
        })
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="modal-component-body">
                    <Card>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>
                                <b>Headquarters</b>
                            </h4>
                            <Button
                                key="edit-contact"
                                className="btn btn-secondary"
                                onClick={handleEditInfo}
                            >
                                Edit Headquarter Info
                            </Button>
                        </div>
                        <Row className="input-spacing-3">
                            <EInput
                                readOnly
                                name="email"
                                entity={Email}
                                instance={employer.email}
                            />
                            <EInput
                                readOnly
                                name="number"
                                entity={Phone}
                                instance={employer.phone}
                            />
                            <EInput
                                readOnly
                                label="Fax Number"
                                name="number"
                                entity={Phone}
                                instance={employer.fax}
                            />
                            <EInput readOnly name="lng" instance={employer} />
                        </Row>
                        <AddressDetails
                            readOnly
                            className="mt-2"
                            address={employer.address}
                        />
                        <EInput
                            name="cmt"
                            instance={employer}
                            className="mt-2"
                            variant="textarea"
                            readOnly
                        />
                    </Card>
                    <Card className="mt-4">
                        <div className="d-flex justify-content-between align-items-end mb-2">
                            <h4>
                                <b>Contacts</b>
                            </h4>
                            <Button
                                key="new-contact"
                                className="btn btn-secondary"
                                onClick={handleAddContact}
                            >
                                Add Contact
                            </Button>
                        </div>
                        <ContactList
                            contacts={contacts}
                            onSelect={handleSelectContact}
                        />
                    </Card>
                    {isEditInfo && (
                        <Modal className="w-100 h-70 modal-bg-color">
                            <Title
                                title={`${employer.name} Contacts`}
                                onHide={handleCancelInfo}
                            />
                            <Form
                                id="CompanyInfoForm"
                                className="h-100"
                                data={employer}
                                onSave={handleSaveInfo}
                                onCancel={handleCancelInfo}
                            >
                                <Row className="input-spacing-2">
                                    <EInput
                                        name="email"
                                        entity={Email}
                                        instance={employer.email}
                                    />
                                    <EInput
                                        name="number"
                                        entity={Phone}
                                        instance={employer.phone}
                                    />
                                    <EInput
                                        label="Fax Number"
                                        name="number"
                                        entity={Phone}
                                        instance={employer.fax}
                                    />
                                    <EInput name="lng" instance={employer} />
                                </Row>
                                <AddressDetails
                                    className="mt-2"
                                    address={employer.address}
                                />
                                <EInput
                                    name="cmt"
                                    instance={employer}
                                    className="mt-4"
                                    variant="textarea"
                                />
                            </Form>
                        </Modal>
                    )}
                    {selected && (isAddContact || isEditContact) && (
                        <Modal className="w-100 h-80 modal-bg-color">
                            <ContactForm
                                contact={selected}
                                person={selected.person}
                                onSave={handleSaveContact}
                                onCancel={handleCancelContact}
                            />
                        </Modal>
                    )}
                </div>
            )}
        </>
    );
};

export default Contacts;
