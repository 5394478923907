import React from 'react'

import { Table } from '../../framework/controls'
import { Component } from '../../framework/components'
import { LegacyService } from '../../services'
import LegacyMember from '../../entities/membership/LegacyMember'

export default class LegacyParticipations extends Component {
	load() {
		return LegacyService.get(this.props.membership.keyValue).then(legacyMem => {
			return {data: legacyMem.participations.map(participation => {
				return {treeVal: participation.reorderedPpNo, _children: participation.history.all}
			})}
		})
	}

	view() {
		const {data } = this.state	
		const columns = new Table.Headers(LegacyMember.LegacyStatus, 'treeVal,GROUP_NO,JOIN_DATE,STATUS,STATUS_TYPE,CHG_DATE,PROC_DATE,SWITCH_TO,REMARK1')
		columns['treeVal'].title = 'No'
		columns['treeVal'].width = 70
		columns['GROUP_NO'].width = 120
		columns['JOIN_DATE'].width = 120
		columns['STATUS'].width = 80
		columns['STATUS_TYPE'].width = 80
		columns['CHG_DATE'].width = 120
		columns['JOIN_DATE'].width = 120
		columns['PROC_DATE'].width = 120
		columns['SWITCH_TO'].width = 90
		
		return <>
			<Table id="participation-list-table" 
				data={data} 
				columns={columns} 
				dataTree={true}
				dataTreeStartExpanded={true}
			/>
		</>
	}
}
