import React from 'react'
import { Form } from 'react-bootstrap'
import { Control } from '.'

export default class Text extends Control {
    render() {
        const { variant } = this.props 
        return this.wrapper(<Form.Control {...this.controlProps} type={variant} ></Form.Control>)
    }

    handleChange(event){
        if(this.props.onChange) this.props.onChange(event.target.value)
    }
}
