import { round } from './helper'


export default class Progress {
	constructor(total, step = 100, displayStepUps) {
		this.total = total
		this.step = step
		this.count = 0
		this.steps = 0
		this.startTs = Date.now()
		this.currentTs = 0
		this.displayStepUps = displayStepUps
	}

	get completedPercentage() { return round(this.steps / this.step * 100, 2) }
    get elapsedTime() { return round((this.currentTs - this.startTs) / 1000, 0) }
    get averageStepMillis() { return round(this.steps && (this.currentTs - this.startTs) / this.steps, 0) }
    get estimatedTime() { return round(this.step * this.averageStepMillis / 1000, 0)}
    get estimatedTimeLeft() { return round((this.step - this.steps) * this.averageStepMillis / 1000, 0)}
    
    stepUp(increase = 1) {
		this.count += increase
		const steps = Math.floor(this.count / this.total * this.step)
		if (steps > this.steps) {
            this.steps = steps
            this.currentTs = Date.now()
			if(this.displayStepUps) this.consoleLog()
			if(this.onStepUp) this.onStepUp(this.estimatedTimeLeft)
			return true
		}
		return false
	}
	
	consoleLog() {
		console.log(this.completedPercentage + '    ' + this.elapsedTime  + '   ' + this.estimatedTimeLeft)
	}

}
