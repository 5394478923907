import { RefList } from '../../framework/infra'
import { map } from '../../framework/utils/helper'

import Contributions from './contributions/Contributions'
import { Earnings } from '../financial'
import RemittanceDetail  from '../pension//RemittanceDetail'
import moment from 'moment/moment'
import Remittance from './Remittance'
import { Period } from '../../framework/utils'

export default class RemittanceDetails extends RefList {
    
    get contributionsTotals() { return Contributions.sum(this.map(detail => detail.contributions)) }
    get earningTotals() { return Earnings.sum(this.map(detail => detail.earnings)) }
    get earningAdjustmentsTotals() { return Earnings.sum(this.map((detail) => detail.adjustmentEarnings)); }
    get contributionAdjustmentsTotals() { return Contributions.sum(this.map((detail) => detail.adjustmentContributions)); }

    hasFinancialInfo() {
        return (
            this.length > 0 &&
            (this.contributionsTotals.total !== 0 ||
                this.earningTotals.total !== 0 ||
                this.earningTotals.hours !== 0 ||
                this.contributionAdjustmentsTotals.total !== 0 ||
                this.earningAdjustmentsTotals.total !== 0 ||
                this.earningAdjustmentsTotals.hours !== 0)
        );
    }

    resetAllContributionsAndEarnings() {
        this.forEach(detail => {
            detail.earnings.reset();
            detail.contributions.reset();
        });
    }

    assignEarningTypes(earningTypes) { this.forEach(detail => { detail.earnings.assignEarningTypes(earningTypes) }) }
    appendMissingEarningTypes(earningTypes) { this.forEach(detail => { detail.earnings.appendMissingEarningTypes(earningTypes) }) }

    setYtps() {
        this.setEarningYtps();
        this.setContribYtps();
        this.setCreditedYtps();
        this.sort();
        return this
    }

    //Year to period earnings - YTP, YTD includes current detail
    setCreditedYtps() {
        this.sort().reduce((prev, det) => {
            det.ytpCreditedService = 0
            if(prev && prev.period.isSameYear(det.period)) {
                det.ytpCreditedService = prev.ytdCreditedService
            }
            return det;
        }, null)
        return this
    }
    //Year to period earnings - YTP, YTD includes current detail
    setEarningYtps() {
        this.sort().reduce((prev, det) => {
            det.ytpEarnings.reset();
            if(prev && prev.period.isSameYear(det.period)) {
                det.ytpEarnings.add(prev.ytpEarnings);
                const currentPPEvents = prev.employment.participation.eventStatuses.getDuring(prev.period.timestampAtPeriodStart, prev.period.timestampAtPeriodEnd);
                //This calc is defined in the back-end as well in RemittanceDtail.setYtps
                if (prev.ppStatus.isActive() || prev.isPartiallyActive(currentPPEvents) || prev.isPartiallyRetired(currentPPEvents)) { 
                    det.ytpEarnings.add(prev.earnings)
                    det.ytpEarnings.add(prev.adjustmentEarnings)
                }
            }
            return det;
        }, null)
        return this
    }
    setContribYtps() {
        this.sort().reduce((prev, det) => {
            det.ytpPrevYearAdjustment = 0
            det.ytpContributions.reset();
            if(prev && prev.period.isSameYear(det.period)) {
                det.ytpContributions.add(prev.ytpContributions)
                prev.adjustments.forEach(adj => {
                    if(moment(adj.effDate).format('YYYY') === det.period.year){
                       det.ytpContributions.addAjdContrib(adj);
                }})
                det.ytpContributions.add(prev.contributions)
            }
            return det;
        }, null)
        return this
    }

    setYMPEReached() {
        this.sort().reduce((prev, det) => {
            if (prev && prev.period.isSameYear(det.period)) {
                const ympeAlreadyReached = this.all.find((detail) =>
                    detail.period.isSameYear(det.period) && detail.isYMPEReached
                );
                if (
                    !ympeAlreadyReached &&
                    det.ytdEarnings.pensionable >= det.rates.ympe
                ) {
                    det.isYMPEReached = true;
                }
            }
            return det;
        }, null);
        return this;
    }

    sort(func) {
        if (!func) func = (det1, det2) => { 
            if(det1.period.isAfter(det2.period)) return 1
            if(det1.period.isBefore(det2.period)) return -1
            return 0
        }
        return this._list.sort(func)
    }

    getByPersonSIN() {
        return map(this.all, 'employment.participation.membership.person.sin')
    }

    //creates one if it does not exist
    getByEmployment({employment, remittance}) {
        var detail = this.find((det)=> det.employment.keyValue === employment.keyValue && det.period.isSame(remittance.period));
        if (!detail) {
            detail = new RemittanceDetail({ 
                remittance: remittance,
                employment: employment, 
                participation: employment.participation,
            });
            detail._sts = 'N'
        }
        return detail;
    }
        
    getRelevantDetailsForRemittance({employments = [], remittance}) {
        //We want to create ghost details for employments with active participations
        if (remittance) {
            for (let employment of employments) {
                let detail = this.getByEmployment({employment, remittance});
                if(!detail.isNew()) continue;
                if(detail.ppStatus.key !== '' && detail.employmentStatus.key !== '' && !detail.ppStatus.isClose()) {
                    this.push(detail)
                }
            }
        }

        return this.getFiltered((detail) => detail.isRelevant())
    }

    static getClonedForEmploymentByPeriods({
        employment,
        startPeriod, 
        endPeriod,
        details,
    }) {
        const detailsCloned = new RemittanceDetails();
        Period.getPeriods(startPeriod, endPeriod).forEach((period) => {
            const existingDetail = details.find((det) => det.period.isSame(period));
            if (existingDetail) {
                existingDetail.employment = employment;
                detailsCloned.push(existingDetail.clone());
            } else {
                const rem = new Remittance({ employer: employment.employer, period });
                const det = detailsCloned.getByEmployment({
                    employment,
                    remittance: rem,
                });
                detailsCloned.push(det);
            }
        });

        detailsCloned.forEach((det) => {
            det.contributions.pullFilter((contrib) => !contrib.type.isDeemed());
            det.ytpContributions.pullFilter(
                (contrib) => !contrib.type.isDeemed()
            );
            det.earnings.removeEarning((earning) =>
                earning.earningType.isDeemedEarningType()
            );
            det.adjustmentEarnings.reset();
            det.adjustmentContributions.reset();
            return det;
        });
        return detailsCloned;
    }

    static definitions = {
        contributionsTotals: { abstract: true, ref: Contributions, text: 'Total Contributions' },
        earningsTotals: { abstract: true, ref: Earnings, text: 'Total Earnings' },
        earningAdjustmentsTotals: { abstract: true, ref: Earnings, text: 'Total Earning Adjustments' },
        contributionAdjustmentsTotals: { abstract: true, ref: Earnings, text: 'Total Contribution Adjustments' },
    }

}


