import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Control } from '.'

export default class Currency extends Control {
    render() {
        return <MaskedInput mask={defaultMaskOptions} {...this.controlProps} className={(this.props.className || '') + ' form-control'}/>
    }

    handleChange(event){
        if(this.props.onChange) this.props.onChange(event.currentTarget.value, event)
    }
    handleBlur(event){
        if(this.props.onChange) this.props.onChange(Currency.parseCurrency(event.currentTarget.value), event)
    }
}

Currency.parseCurrency = function(value) {
  return Number(String(value).replace(/[$,]+/g, ""))
}

const defaultMaskOptions = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 10, // limit length of integer numbers
    allowNegative: true,
    allowLeadingZeroes: true,
})

