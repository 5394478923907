import React from 'react'
import { StyleSheet } from '@react-pdf/renderer'

import { Container, Text, Row } from '../../../framework/utils/pdf' 

import { Component } from '../../../framework/components'


export default class PDFPaymentMethod extends Component {
	view() {
		const { config, employer } = this.props
        const { wire, eft, cheque } = config.bank
        const wireAccountName = employer.plan.isQuebec() ? wire.beneficiary.account_que.name : wire.beneficiary.account_fed.name
        const wireAccountNumber = employer.plan.isQuebec() ? wire.beneficiary.account_que.number : wire.beneficiary.account_fed.number
        const eftAccountName = employer.plan.isQuebec() ? eft.account_que.name : eft.account_fed.name
        const eftAccountNumber = employer.plan.isQuebec() ? eft.account_que.number : eft.account_fed.number

        const styles = StyleSheet.create({
            bankInfoTitle: { fontSize: 12, fontFamily: 'Roboto-Bold' },
            bold: { fontFamily: 'Roboto-Bold' },
            small: { fontSize: 8 },
            paymentHeader: {
                width: '80%',
                fontSize: 9,
                display: 'flex', 
                flexDirection: 'row',
                marginTop: 5,
                marginBottom: 1,
                marginLeft: 10,
                fontFamily: 'Roboto-Bold',
                color: '#0092BD'
            },
            paymentNotice: { 
                fontFamily: 'Roboto-Italic',
                textAlign: 'center',
                marginTop: 5,
                marginBottom: 5,
                color: '#EA0E0E',
            }
        })
        
		return (
            <Container> 
                <Text text={texts['payment-method']} className="mt-2" style={styles.bankInfoTitle} />
                { !wire.disabled && <Container className="mt-1"> 
                    <Text style={styles.paymentHeader}>SWIFT:</Text>
                    <Container style={styles.small} className='ml-3'>
                        <Row className="mt-1">
                            <Text text={texts['intermed-bank']} style={styles.bold} /> 
                            <Text><Text text={texts['swift-code']} style={styles.bold}/> {wire.account_intermediary.swift}  </Text>
                            <Text text={texts['bank']} style={styles.bold} />
                            <Text> {wire.account_intermediary.bank}  </Text>
                            <Text text={texts['address']} style={styles.bold} />
                            <Text>  {wire.account_intermediary.address.civic}, </Text>
                            <Text>{wire.account_intermediary.address.city}, {wire.account_intermediary.address.prov}  {wire.account_intermediary.address.pc}</Text>
                        </Row>
                        <Row className="mt-1">
                            <Text text={texts['account-bank']} style={styles.bold} />   
                            <Text><Text text={texts['swift-code']} style={styles.bold}/> {wire.account.swift}  </Text>
                            <Text text={texts['bank']} style={styles.bold} />
                            <Text> {wire.account.bank}  </Text>
                            <Text text={texts['address']} style={styles.bold} />
                            <Text>{wire.account.address.civic},  </Text>
                            <Text>{wire.account.address.city},  {wire.account.address.prov}  {wire.account.address.pc}</Text>
                        </Row>
                        <Row className="mt-1" >
                            <Text text={texts['beneficiary']} style={styles.bold} /> 
                            <Text><Text text={texts['account-name']} style={styles.bold} />  {wireAccountName}  </Text>
                            <Text><Text text={texts['account-number']} style={styles.bold} />{wireAccountNumber} </Text>
                        </Row>
                        <Text className="mt-1"><Text text={texts['address']} style={styles.bold} /> {wire.beneficiary.address.civic}, {wire.beneficiary.address.city}, {wire.beneficiary.address.prov} {wire.beneficiary.address.pc}</Text>
                    </Container>
                </Container> }

                { !eft.disabled && <Container> 
                    <Text text={texts['eft']} style={styles.paymentHeader} />   
                    <Container style={styles.small} className='ml-3'>
                        <Row className="mt-1">
                            <Text text={texts['bank']} style={styles.bold} />
                            <Text> {eft.bank}  </Text>
                            <Text><Text text={texts['address']} style={styles.bold} />{eft.address.civic},  {eft.address.city}, {eft.address.prov} {eft.address.pc}  </Text>
                            <Text><Text text={texts['account-name']} style={styles.bold} /> {eftAccountName} </Text>
                        </Row>  
                        <Row className="mt-1">
                            <Text><Text text={texts['bank-code']} style={styles.bold} /> {eft.bank_code}   </Text> 
                            <Text><Text text={texts['transit']} style={styles.bold} /> {eft.transit}  </Text>  
                            <Text><Text text={texts['account-number']} style={styles.bold} />  {eftAccountNumber}  </Text>       
                            <Text><Text text={texts['account-address']} style={styles.bold}/> {eft.address_account.civic}, {eft.address_account.city}, {eft.address_account.prov} {eft.address_account.pc}</Text>
                        </Row> 
                    </Container>
                </Container>}

                { !cheque.disabled && <Container>
                    <Text style={styles.paymentHeader}>Cheque:</Text>
                    <Text className='ml-3'>{cheque.addressee}, {cheque.address.civic}, {cheque.address.city}, {cheque.address.prov} {cheque.address.pc} </Text>
                    <Text className='ml-3' style={styles.paymentNotice}>{cheque.notice}</Text>
                </Container>}
                
            </Container>
		)
	}
}

const texts = {
    "payment-method": {
        "en" : "Payment Method",
        "fr" : "Mode de Paiement",
    },
    "intermed-bank": {
        "en" : "Intermediary Bank (Field 56): ",
        "fr" : "Banque Intermédiare (Domaine 56): "
    },
    "swift-code" : {
        "en" : "Swift Code: ",
        "fr" : "Code Swift: "
    },
    "bank" : {
        "en" : "Bank: ",
        "fr" : "Banque: "
    },
    "address" : {
        "en" : "Address: ",
        "fr" : "Adresse: "
    },
    "account-bank" : {
        "en" : "Account with Bank (Field 57): ",
        "fr" : "Compte avec Banque (Domaine 57): "
    },
    "beneficiary" : {
        "en" : "Beneficiary (Field 58/59): ",
        "fr" : "Bénéficiaire (Domaine 58/59): "
    },
    "account-name" : {
        "en" : "Account Name: ",
        "fr" : "Nom du Compte: "
    },
    "account-number" : {
        "en" : "Account Number: ",
        "fr" : "Numéro de Compte: "
    },
    "account-address" : {
        "en" : "Account Address: ",
        "fr" : "Adresse du Compte: "
    },
    "eft" : {
        "en" : "EFT: ",
        "fr" : "TEF: "
    },
    "bank-code" : {
        "en" : "Bank Code: ",
        "fr" : "Code Bancaire: "
    },
    "transit" : {
        "en" : "Transit: ",
        "fr" : "Transit: "
    }
}