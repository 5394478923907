import { Ref, Definition, RefMap }  from '../../framework/infra'
import HistoricRates from './HistoricRates'
import Rates from './Rates'

export default class Plan extends Ref {
    get desc() { return this.code === 'que' ? 'Quebec' : 'Federal' }
    isFederal() { return this.code === 'fed' || this.code === 'pol'}
    isQuebec() { return this.code === 'que' }

    get jurisdiction() { return this.code === 'pol' ? 'fed' :this.code }
    set jurisdiction(val) { this.code = val}
    
    get currentRates() { return this.historicRates.currentRates }
    
    static key = 'code'
    static refMap = RefMap
    static definitions = {
        code: {type: Definition.types.STRING, text: 'Code', text_fr: ''},
        jurisdiction: { abstract: true, type: Definition.types.CHOICE, text: 'Jurisdiction', text_fr: 'Jurisdiction', options: [
            { key: 'fed', text: 'Federal', text_fr: 'Federal'},
            { key: 'que', text: 'Quebec', text_fr: 'Quebec'},
        ]},
        name: {type: Definition.types.STRING, text: 'Plan Name', text_fr: ''},
        accntNumber: {type: Definition.types.STRING, text: 'Account Number', text_fr: ''},
        craRegistrationNumber: { type: Definition.types.STRING, text: 'Pension Plan CRA Registration'  }, //abstract
        osfiRegistratioNumber: { type: Definition.types.STRING, text: 'Pension Plan OSFI Registration Number' }, //abstract
        benefitNumber: { type: Definition.types.STRING, text: 'RBC Benefit Account Number' },
        
        historicRates: { transient: true, ref: HistoricRates, text: 'Plan Rates'},
        currentRates: { abstract: true, ref: Rates, text: 'Current Rates'}
    }
}
