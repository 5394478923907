import { Ref, Definition, Entity } from '../../../framework/infra'
import { Address } from '../../address'
import { concatStr } from '../../../framework/utils/helper'
import RefundAccountType from './RefundAccountType'

export default class RefundAccount extends Ref {
    get searchDesc() { return  concatStr(this.transit, '-') + this.number + ', ' + this.name  }

    // see schema in src/model/membership/Participation.js in Services
    static definitions = {
        number: { type: Definition.types.STRING, text: 'Account Number', text_fr: '' },
        address: { ref: Address, text: 'Account Address', text_fr: '' },
        name: { type: Definition.types.STRING, text: 'Bank Name', text_fr: '' },
        transit: { type: Definition.types.STRING, text: 'Transit', text_fr: '' },
        searchDesc: { abstract: true, type: Definition.types.STRING, text:'Deposit Account'},
        accountType: {ref: RefundAccountType, text: 'Account Type'},
        eft: { type: Definition.types.STRING, text: 'EFT Bank', text_fr: '' }

    }
    static key = ['transit', 'number']
}