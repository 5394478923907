import React from 'react'
import Container from './Container'
import ContainerChild from './ContainerChild'

export default class Card extends Container {
    static cn = 'card'
    render() {
        var variantClassName = []
        if (this.props.framed ) variantClassName.push('framed')
        return this.wrapper(this.props.children, variantClassName.join(' '))
    }
}

Card.Title = class Card extends ContainerChild {
    static cn = 'card-title'
    render() { return <div className='card-title'> {this.props.title || ''} </div>}
}