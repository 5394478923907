import { UpdateStack } from "../../../../services/ServiceRegistry"

/**
 * Result of an action
 */
export default class ActionResult {
    constructor() {
        this.updateStack = new UpdateStack()
        this.message = '' //message to display
        this.content = {} //additional content to display
    }
}