import { Ref } from "../../framework/infra";
import { YearEndMessageEvents } from "./YearEndMessageEvent";

export default class Validations extends Ref {
    static getGroups = () => {
        return Object.values(this.definitions)
            .filter(
                (message) =>
                    ![
                        "cmt",
                        "desc",
                        "help",
                        "longDesc",
                        "shortDesc",
                        "tip",
                        "_del",
                        "_sts",
                    ].includes(message.name)
            )
            .map((message) => ({ text: message.text, value: message.name }));
    }
}

Validations.definitions = {
    persInfo: { ref: YearEndMessageEvents, text: "Personal Validation" },
    sts: { ref: YearEndMessageEvents, text: "Status Validation" },
    monthlyEarnings: { ref: YearEndMessageEvents, text: "Monthly Earnings Validation" },
    reqContrib: { ref: YearEndMessageEvents, text: "Required Contribution Validation" },
    deeContrib: { ref: YearEndMessageEvents, text: "Deemed Contribution Validation" },
    maxContrib: { ref: YearEndMessageEvents, text: "Maximum Contribution Validation" },
    creSrv: { ref: YearEndMessageEvents, text: "Credit Service Validation" },
    annErn: {
        ref: YearEndMessageEvents,
        text: "Annualized Earnings based on Regular Earnings/Hours",
    },
    result: {
        ref: YearEndMessageEvents,
        text: "Validation Results",
        text_fr: "Prior Year Annualized Earnings",
    },
};