import Mapper from "./Mapper";
import SpouseHistory from '../entities/person/SpouseHistory';

export default class SpouseHistoryMapper extends Mapper {   
    constructor (data, options = {}) {
        super (data, options);
        this.spouseHistory = new SpouseHistory();
    }

    async map () {
        await this.load('people', {load: false});
        this.spouseHistory.pushList((await this.load('spouses')));
        return this.spouseHistory;
    }
}