import { Workbook } from 'exceljs/dist/exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { Choice, Period } from '../components/eComp/inputs' //move to definitions
import { arrayFromCsv, moment, toSearchString } from './helper'
import { renderStringByPattern } from './renders'

export default class Excel {
    constructor (fileName) {
        this.workBook = new Workbook()
        this.fileName = fileName
        return this
    }

    loadFile(file) {
        const reader = new FileReader()
          
        return new Promise((resolve, reject) => {
            if(!file) reject(new ExcelException(errors.noFileSelected))
            if (!supportedTypes.includes(file.type)) {
                console.log(file.type)
                reject(new ExcelException(errors.fileNotSupported));
            }
            
            reader.onerror = (error) => {
                reader.abort()
                reject(new ExcelException(errors.parsingExcelFile));
            }
          
            reader.onload = () => {
                const buffer = reader.result
                this.workBook.xlsx.load(buffer).then(workBook => {
                    resolve(this)
                })
            }
            reader.readAsArrayBuffer(file)
        })
    }

    addSheet(headers, data, sheetName, 
        setHeadersFormat = false, setEmptyCurrencyValueTo0 = false,
        ignoreColumnOnRow) {
        const ws = this.workBook.addWorksheet()
        if (sheetName) ws.name = sheetName
        addHeaders(ws, headers, 0, setHeadersFormat)
        addTopCalc(ws, headers, data)
        if (data.length > 0) {
            this.addDataRows(ws, headers, data, undefined, setEmptyCurrencyValueTo0, ignoreColumnOnRow)
        }
        return ws
    }

    /**
     * Add the Contributions sheet (for the Termination report)
     * @param data Array of rows data
     */
    addContributionsSheet(data) {
        const ws = this.workBook.addWorksheet();
        ws.name = "Contributions"
        if (data.length > 0) {
            this.addCustomDataRows(ws, data)
        }
        return ws
    }

    /**
     * Add the Personal Info sheet (for the Termination report)
     * @param data Array of rows data
     */
    addPersonalInfoSheet(data) {
        const ws = this.workBook.addWorksheet();
        ws.name = "Personal Info"
        if (data.length > 0) {
            this.addCustomDataRows(ws, data)
        }
        return ws
    }
  
    // transpose(sheet) {
    //     const ws = this.workBook.addWorksheet() //CR: sheet may not be in last position, insert in same position as 'sheet'
    //     ws.name = sheet.name
    //     Object.keys(sheet._merges).forEach((key) => sheet.unMergeCells(key))
    //     sheet.eachRow( (row, rowNumber) => {
    //         ws.getColumn(rowNumber).values = row.values
    //     })
    //     this.workBook.removeWorksheet(ws[1]) //CR: sheet may not be in first position, just remove 'sheet'
    //     return ws
    // }
    //Don't remove before ask Pablo
    transpose(sheet) {
        this.workBook.removeWorksheet(sheet.id)
        const ws = this.workBook.addWorksheet(sheet.name)
        Object.keys(sheet._merges).forEach((key) => sheet.unMergeCells(key))
        sheet.eachRow( (row, rowNumber) => {
            ws.getColumn(rowNumber).values = row.values
        })
        return ws
    }

    findSheet(sheetName) { return this.workBook.worksheets.find(sheet => toSearchString(sheet.name) === toSearchString(sheetName)) }
    
    setHeaders(sheet, headers, shift) {
        return addHeaders(sheet, headers, shift)
    }

    addPrintedTime(sheetname) {
        const ws = this.findSheet(sheetname);
        ws.addRows( [[], [`Printed On: ${moment().format('LLLL')}`]]);
        return ws;
    }

    addLine(sheet, line) {
        sheet.addRows( [[], [line]]);
        return sheet;
    }

    addDataRows(sheet, headers, data, initialRow, setEmptyCurrencyValueTo0 = false, ignoreColumnOnRow) {
        if (!sheet || data.length === 0) return
        initialRow = initialRow || sheet.addRow().number
        const rowHeight = sheet.getRow(initialRow).height 
        if (headers instanceof Excel.Headers) {headers = headers.namedHeadersList}
        data.forEach((item, index) => {
            const row = sheet.getRow(index + initialRow);
            row.commit()
            headers.forEach((col, index) => {
                if(col.name.startsWith('skip-') || ignoreColumnOnRow?.(col, item)) return;
                const cell = row.getCell(index + 1)
                var value = _.get(item, col.name ?? col)
                cell.value = col.format ? col.format(value, item, cell) : value;
                if(col._def?.isCurrency?.()) {
                    cell.numFmt = '"$"#,##0.00';
                    if(!cell.value && setEmptyCurrencyValueTo0) cell.value = 0;
                }
                if (col.adjustHeight) row.height = Math.max(row.height || 0, rowHeight, String(cell.value).split('\n').length * rowHeight)
                if (col.render) col.render(cell, value, item)
            })
        },[])
    }

    addCustomDataRows(sheet, data) {
        if (!sheet || data.length === 0) return;
        const initialRow = sheet.addRow().number;
        data.forEach((dataItem, rowIndex) => {
            if(Array.isArray(dataItem.row)) {
                const row = sheet.getRow(rowIndex + initialRow);
                row.commit();
                dataItem.row.forEach((cellItem, cellIndex) => {
                    const cell = row.getCell(cellIndex + 1);
                    cell.value = cellItem;
                    if(dataItem.isBold || dataItem.boldCols?.includes(cellIndex)) {
                        cell.font = { bold: true };
                    }
                });
                if(dataItem.mergeCols) {
                    // merge by start row, start column, end row, end column
                    sheet.mergeCells(rowIndex + initialRow,1,rowIndex + initialRow,dataItem.mergeCols);
                    const cell = row.getCell(1);
                    cell.alignment = { horizontal:'center', vertical:'middle'}
                }
            }
        });
    }
    
    download() {
        return this.workBook.xlsx.writeBuffer().then(buf => {
            return saveAs(new Blob([buf]), this.fileName + '.xlsx')
        })
    }
}

function addHeaders(ws, headers, shift = 0, setHeadersFormat = false) {
    if (headers instanceof Excel.Headers) headers = headers.list
    const columns = []
    var depth = 0
    procColNames(headers, 0)
    function procColNames(cols, level) {
        depth = level > depth ? level : depth 
        cols.forEach(col => {
            if (col.name) columns.push(col)
            if (col.headers) procColNames(col.headers.list, level + 1)
        })
    }
    const headersLabels = Array.from(Array(depth + 1)).map(() => {
        return Array.from(Array(columns.length)).map(() => '')
    })
    var index = 0
    const merges = []
    procHeaderLabels(headers, 0, [])
    function procHeaderLabels(cols, level) {
        var nbCols = cols.length
        cols.forEach(col => {
            headersLabels[level][index] = col.title
            if (col.headers) {
                const merge = [level + 1, shift + index + 1, level + 1, shift + index + 1]
                const nbSubCols = procHeaderLabels(col.headers.list, level + 1)
                if (nbSubCols > 1) {
                    merge[3] += nbSubCols - 1
                    merges.push(merge)
                }
                nbCols += nbSubCols - 1
            } else {
                if (level < depth) {
                    merges.push([level + 1, shift + index + 1, depth + 1, shift + index + 1])
                }
                index++
            } 
        })
        return nbCols
    }
    headersLabels.forEach((header, index) => {
        const row = ws.getRow(index + 1)
        row.font = { bold: true }
        let rowTextLines = 1;
        const rowHeight = row.height || 15;
        header.forEach((headerLabel, index) => {
            const cell = row.getCell(shift + index + 1)
            cell.value = headerLabel
            if(setHeadersFormat) {
                if (cell.value && String(cell.value || '').includes('\n')) cell.alignment = {wrapText: true};
                if(headers.list?.find?.(x => x.title === headerLabel)?.minWidth) cell.width = headers.list?.find?.(x => x.title === headerLabel)?.minWidth;

                if (cell.value && String(cell.value || '').includes('\n') && String(cell.value || '').split('\n').length > rowTextLines) rowTextLines = String(cell.value || '').split('\n').length;

            }
            
        });
        if(setHeadersFormat) {
            if(rowTextLines > 1) row.height = Math.max(row.height || 0, rowHeight, rowTextLines * rowHeight);
        }
    })
    merges.forEach(merge => {
        ws.mergeCells(...merge); // top,left,bottom,right
    })
    return headers
}

function addTopCalc(ws, headers, data) {
    if (headers instanceof Excel.Headers) headers = headers.list
    if (headers.find(header => header.topCalc )) {
        const sumRow = ws.addRow()
        headers.forEach((header, index) => {
            if (header.topCalc) {
                ws.getCell(Excel.toAddress(sumRow.number - 1, index)).value = {formula : `SUM(${Excel.toAddress(sumRow.number, index )} : ${Excel.toAddress(sumRow.number + data.length - 1, index)})`} 
            }
        })
        return ws
    }
}

Excel.Headers = class Headers {
    constructor (entity, propNames = [], defaultHeaderProps) {
        this._propNames = arrayFromCsv(propNames)
        this._entity = entity
        this._defaultHeaderProps = defaultHeaderProps
        this._propNames.forEach(propName => this.addHeader(propName))
    }

    get list() {
        return Object.getOwnPropertyNames(this).reduce((headers, propName) => {
            if (!propName.startsWith('_')) headers.push(this[propName])
            return headers
        }, [])
    }

    addHeader(propName, options) {
        this[propName] = new Excel.Header(this._entity, propName, options)
    }

    reset() {
        const originalCols = _.pick(this, this._originalProps)
        this.clear()
        Object.assign(this, originalCols)
    }
    
    clear() {
        return Object.getOwnPropertyNames(this).forEach(propName => {
            if (!propName.startsWith('_')) delete this[propName]
        })
    }
    
    get namedHeadersList() {
        return this.list.reduce((namedHeaders, header) => {
            if (header.name) namedHeaders.push(header)
            if (header.headers) namedHeaders = namedHeaders.concat(header.headers.namedHeadersList)
            return namedHeaders
        }, [])
    }
}

Excel.Header = class Header { 
    constructor (entity, propName, options = {}) {
        this._entity = entity
        this._options = options
        this._def = entity && entity.getDefinition(propName)
        this.name = propName
        this.setTitle()
        this.setFormat()
    }
    
    setTitle(title) {
        if (title) this.title = title
        else if (this._options.title) this.title = this._options.title
        else if (this._def) this.title = this._def.text
        else this.title = this.name
    }

    setFormat(format) {
        if (format) this.format = format
        else if (this._options.format) this.format = this._options.format
        else if (this._def) {
            if (this._def.isNumeric()) {
                this.format = (val) => val || ''
            } else if (this._def.isBool()) {
                this.format = (val) => val ? 'Yes' : 'No'
            } else if (this._def.isDate()) {
                this.format = (val) => val ? new Date(val) : ''
                this.parse = (value) => value ? (Excel.toDate(value)).valueOf() : null
            } else if (this._def.isTimestamp()) {
                this.format = (val) => val ? new Date(val) : ''
                this.parse = (value) => value ? (Excel.toDate(value)).valueOf() : null
            } else if (this._def.isChoice()) {
                this.format = Choice.format.bind(this, this._def)
            } else if (this._def.isPeriod()) {
                this.format = Period.format.bind(this, this._def)
            } else if (this._def.hasMask()) {
                this.format = renderStringByPattern.bind(this, this._def.type.mask)
            } else if (this._def.isRefMessage() || this._def.isRefList()) {
                this.format = (val) => !val ? '' : ((val.all?.find(item => item.code === 'ok') ? '✓ ' : '') + val.desc)
                this.render = (cell, val) => {
                    const containsCodeOK = val?.all?.find(item => item.code === 'ok');
                    if (containsCodeOK) { 
                        cell.font = {italic: true, bold: true, size: '16', color:{argb:'FF00B050'}} //set background to white
                        cell.alignment = { horizontal:'center', vertical:'middle'}
                    }
                }
            }
        }
    }
    convertToGroupHeader(headers, title) {
        this.name = ''
        this.headers = headers
        if (title !== undefined) this.title = title
    }
}
Excel.lettersToNumber = function lettersToNumber(letters) {
    for(var p = 0, n = 0; p < letters.length; p++){
        n = letters[p].charCodeAt() - 64 + n * 26;
    }
    return n;
}
Excel.numberToLetters = function numberToLetters(num) {
    for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret
    }
    return ret
}
Excel.toAddress = function toAddress(indexRow, indexCol) {
    indexCol++
    for (var ret = '', a = 1, b = 26; (indexCol -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((indexCol % b) / a) + 65) + ret
    }
    return ret + (indexRow + 1)
}

Excel.getSheetData = function getSheetData(sheet, startingDataRow = 1) {
    return sheet && sheet._rows ? sheet._rows.slice(startingDataRow - 1).map(row => row.values) : []
}

class ExcelException extends Error{}

const supportedTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '']
const errors = {
    noFileSelected: {text:'No File Selected'},
    fileNotSupported: {text:'File Type Not Supported'},
    unexpectedError: {text:'Error while loading file, please try again'},
    parsingExcelFile: {text:'Problem parsing input file'}
}

Excel.toDate = function(value) {
    return value ? new Date(Math.round((value - 25569)*86400*1000)).toISOString().slice(0, 10) : ''
}