import React from 'react'
import { Currency } from '../../../controls'

export default class Amount extends React.Component {
    handleChange(value, event) {
        if (!this.props.isCustomOnChange) {
            this.props.instance[this.props.realName] = value
        }
        if(this.props.onChange) this.props.onChange(value, event)
        this.setState({touch:true })
    }

   render() {
        const { instance, realName, value } = this.props;
        return <Currency {...this.props} value={value ?? instance[realName]} onChange={this.handleChange.bind(this)} />
    }
}
