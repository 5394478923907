
 class KeyPressHandler {
	constructor() {
		this.keys = {}
		this.listeners = {}
		document.addEventListener("keydown", this.handleKeyDown.bind(this))
        document.addEventListener("keyup", this.handleKeyUp.bind(this))
	}

	register(name, keys, callback) { this.listeners[name] = {name: name, keys: [].concat(keys), callback: callback} }
	unregister(name) { delete this.listeners[name] }

	handleKeyDown(e) {
		// MetaLeft ControlLeft AltLeft ShiftLeft KeyA...
		if (this.keys[e.code]) return //avoid broadcasting when the key remains down
        this.keys[e.code] = true
		const keys = Object.getOwnPropertyNames(this.keys)
		 Object.values(this.listeners).filter(l => l.keys.length === keys.length && l.keys.every(key => keys.includes(key))).forEach(l => {
			l.callback(l.name)
		})
    }
    handleKeyUp(e) {
		if (e.code === 'MetaLeft') this.keysDown = {} //NOTE when MetaLeft is pressed: the keyup for the second key is not triggered
        else delete this.keys[e.code]
    }
}

const instance = new KeyPressHandler()
export default instance