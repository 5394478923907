import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/containers/Loading";
import CustomDropdown from "../../../../components/form/Dropdown";
import Validations from "../../../../entities/yearEnd/Validation";
import { YearEndMessageEvents } from "../../../../entities/yearEnd/YearEndMessageEvent";
import { Table } from "../../../../framework/controls";
import { Definition, Ref } from "../../../../framework/infra";
import { YearEndService } from "../../../../services";

const ValidationTable = ({
    employer,
    year,
}) => {
    const [yeValidations, setYEValidations] = useState();
    const [groupFilter, setGroupFilter] = useState("all");
    const [typeFilter, setTypeFilter] = useState("all");
    const [isLoading, setIsLoading] = useState(true);
    const messageGroupOptions = [ {text: "All", value: "all"}, ...Validations.getGroups()];
    const messageTypeOptions = [ {text: "All", value: "all"}, ...YearEndMessageEvents.getTypeOptions()];

    const columns = new Table.Headers(Report, [
        "treeVal",
        "name",
        "messageGroup",
        "messageType",
        "message",
        "resolution",
        "reportedUser",
        "reportedDate",
    ]);
    columns.treeVal.headerFilter = true;
    columns.name.headerFilter = true;
    columns.message.minWidth = 400;
    columns.messageType.format = (value) => {
        let type = value; 

        if (value === 'e') {
            type = "Error";
        } else if (value === 'w') {
            type = "Warning"; 
        } else if (value === 'i') { 
            type = "Message";
        }

        return type;
    };

    useEffect(() => {
        let isMounted = true;

        YearEndService.getData({ employer, year }).then((reports) => {
            if (isMounted) {
                setYEValidations(Report.getData(reports.details));
                setIsLoading(false);
            }
        });

        return () => { isMounted = false };
    }, []); 

    const formatRow = (row) => {
        if (row.getData().messageType === "e")
            row.getElement().className += " light-error";
        else if (row.getData().messageType === "w")
            row.getElement().className += " late";
        else if (row.getData().messageType === "i")
            row.getElement().className += " balance";
    };

    const filterRows = () => {
        return yeValidations.map(report => report.clone()).filter((report) => {
            const messages = report._children.filter(
                (message) => (groupFilter === "all" ||
                        !groupFilter ||
                        message.messageGroup ===
                            messageGroupOptions.find(
                                (group) => group.value === groupFilter
                            )?.text) &&
                    (typeFilter === "all" ||
                        !typeFilter ||
                        message.messageType ===
                            messageTypeOptions.find(
                                (type) => type.value === typeFilter
                            )?.value)
            );
            report._children = messages;
            report.getMessageCounts();

            return messages.length > 0;
        });
    }

    return (
        <div className="h-100">
            <div className="d-flex">
                <CustomDropdown
                    buttonText="Message Group"
                    options={messageGroupOptions}
                    onSelect={setGroupFilter}
                />
                <CustomDropdown
                    buttonText="Message Type"
                    options={messageTypeOptions}
                    onSelect={setTypeFilter}
                />
            </div>
            {isLoading ? 
                <Loading />
            : (
                <Table
                    id="validation-list-table"
                    className="mih-400"
                    data={filterRows()}
                    columns={columns}
                    dataTree
                    rowFormatter={formatRow}
                />
            )}
        </div>
    );
};

export default ValidationTable;

class Report extends Ref {
    static getData(detailList) {
        return detailList?.reduce((rows, detail) => {
            const row = new Report({
                treeVal: detail.sin,
                sin: detail.sin,
                name: detail.name,
                messageGroup: "",
                messageType: "",
                message: "",
            });
            row._children = [];
            Object.values(detail.validation)
                .filter(msg => msg.desc)
                .sort((a, b) => {
                    if (a.severity === b.severity) return 0;
                    else if (a.severity === "e") return -1;
                    else if (b.severity === "e") return 1;
                    else if (a.severity === "w") return -1;
                    else if (b.severity === "w") return 1;
                    else return 0;
                })
                .forEach((message) => {
                    row._children.push(
                        new Report({
                            treeVal: detail.sin,
                            sin: detail.sin,
                            name: detail.name,
                            messageGroup: Validations.definitions[message.config?.group]?.text ?? "",
                            messageType: message.severity,
                            message: message.desc,
                            reportedUser: (detail.validation.userName ?? ""),
                            reportedDate: (detail.validation.rptDt ?? moment().format("YYYY-MM-DD")),
                        })
                    );
                });
            row.getMessageCounts();
            rows.push(row);

            return rows;
        }, []);
    }

    getMessageCounts() {
        this.messageGroup = `${
            this._children.filter((message) => message.messageType === "e").length
        } error(s)`;
        this.messageType = `${
            this._children.filter((message) => message.messageType === "w")
                .length
        } warning(s)`;
        this.message = `${
            this._children.filter((message) => message.messageType === "i")
                .length
        } message(s)`;
    }

    static definitions = {
        treeVal: { type: Definition.types.STRING, text: "SIN" },
        sin: { type: Definition.types.SIN, text: "SIN" },
        name: { type: Definition.types.STRING, text: "Name" },
        messageGroup: { type: Definition.types.STRING, text: "Group" },
        messageType: { type: Definition.types.STRING, text: "Message Type" },
        message: { type: Definition.types.STRING, text: "Message" },
        resolution: { type: Definition.types.STRING, text: "Resolution" },
        reportedUser: { type: Definition.types.STRING, text: "Reported User" },
        reportedDate: { type: Definition.types.DATE, text: "Reported Date" },
    };
}
