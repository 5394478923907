import { Ref, Definition } from '../../framework/infra'
import { round } from '../../framework/utils/helper'
import { formatAmount } from '../../framework/utils/formating'
// renderAmountEmptyFor0
const { AMOUNT, PERIOD, BOOLEAN } = Definition.types

export default class RemittanceDistribution extends Ref {
    get total() { return round(RemittanceDistribution.codes.reduce((sum, code) => sum += this[code], 0)) }
    get balance() { return round(RemittanceDistribution.codes.reduce((sum, code) => sum += this[code + 'Bal'], 0)) }
    get descBalance() {
        return RemittanceDistribution.codes.reduce((text, code) => {
            if (this[code + 'Bal'] !== 0) text += RemittanceDistribution.definitions[code].text + ' [' + formatAmount(this[code + 'Bal']) + '] + '
            return text
        }, '* ').slice(0, -2)
    }

    isEqualBalance(distrib) { return !RemittanceDistribution.codes.find(code => this[code + 'Bal'] !== distrib[code + 'Bal']) }
    isEmpty() { return !RemittanceDistribution.codes.find(code => this[code]) }
    hasOutstandingBalance() { return RemittanceDistribution.codes.find(code => this[code + 'Bal'] > 0) }
    isSolvencyPaid() { return this['solBal'] <= 0 }
    areContribsPaid(erOwedAmount) { return this['eeBal'] <= 0 && this['erBal'] <= round(erOwedAmount * 0.5) }
    isNoBalanceEmpty() { return !RemittanceDistribution.codes.find(code => this[code + 'Bal']) }
    add(balance) { RemittanceDistribution.codes.forEach(code => this[code] = round(this[code] + balance[code])); return this }
    substract(balance) { RemittanceDistribution.codes.forEach(code => this[code] = round(this[code] - balance[code])); return this }
    negate() { RemittanceDistribution.codes.forEach(code => this[code] = -this[code]); return this }
    setBalance(prevBalance) { RemittanceDistribution.codes.forEach(code => this[code + 'Bal'] = round(prevBalance[code + 'Bal'] + this[code])); return this }

    static codes = ['ee', 'er', 'vol', 'sol', 'int']
    static createFromAccDistr(accDistr, prevDistr) {
        const data = RemittanceDistribution.codes.reduce((distr, code) => {distr[code] = accDistr[code + 'Amount']; return distr}, {period: prevDistr.period.value})
        //TODO distribute interest from account distribution
        return RemittanceDistribution.create(data).negate().setBalance(prevDistr)
    }
    
    static create(data = {}) {
        RemittanceDistribution.codes.forEach(code => data[code + 'Bal'] = data[code])
        return new RemittanceDistribution(data)
    }
   
    static definitions = {
        isLocked: { type: BOOLEAN, text: 'Locked',text_fr: 'Locked' },

        period: { transient: true, type: PERIOD, text: 'Period' },
        ee: { transient: true, type: AMOUNT, text: 'Employee',text_fr: 'Employee' },
        er: { transient: true, type: AMOUNT, text: 'Employer',text_fr: 'Employer' },
        vol: { transient: true, type: AMOUNT, text: 'Voluntary',text_fr: 'Voluntary' },
        sol: { transient: true,  type: AMOUNT, text: 'Solvency',text_fr: 'Solvency' },
        int: { transient: true,  type: AMOUNT, text: 'Interest',text_fr: 'Interest' },
        total: { abstract: true, type: AMOUNT, text: 'Total',text_fr: 'Total' },

        eeBal: { type: AMOUNT, text: 'Employee',text_fr: 'Balance EE', format: (v) => formatAmount(v, '0') },
        erBal: {  type: AMOUNT, text: 'Employer',text_fr: 'Balance ER', format: (v) => formatAmount(v, '0') },
        volBal: { type: AMOUNT, text: 'Voluntary',text_fr: 'Balance Vol', format: (v) => formatAmount(v, '0') },
        solBal: { type: AMOUNT, text: 'Solvency',text_fr: 'Balance Sol', format: (v) => formatAmount(v, '0') },
        intBal: { type: AMOUNT, text: 'Interest',text_fr: 'Balance Int', format: (v) => formatAmount(v, '0') },
        balance: {  abstract: true, type: AMOUNT, text: 'Balance',text_fr: 'Balance', format: (v) => formatAmount(v, '0') },
    }
}