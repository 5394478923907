import Contacts from "../../views/company/Contacts";
import EmploymentsPage from "../../views/employee/EmploymentsPage";
import EmployerDetails from "../../views/employer/EmployerDetails";
import EmployerReportPage from "../../views/employer/EmployerReportPage";
import EarningTypes from "../../views/financial/earnings/EarningTypes";
import Payments from "../../views/financial/payments/Payments";
import Remittances from "../../views/financial/remittance/Remittances";
import Adjustments from "../../views/pension/adjustment/Adjustments";

const BASE = "/employer";

export const employerTabsConfig = {
    remittances: {
        path: (code) => `${BASE}/${code}/remittances`,
        name: "Remittances",
        Page: Remittances,
    },
    payments: {
        path: (code) => `${BASE}/${code}/payments`,
        name: "Payments",
        Page: Payments,
    },
    employees: {
        path: (code) => `${BASE}/${code}/employees`,
        name: "Employees",
        Page: EmploymentsPage,
    },
    contacts: {
        path: (code) => `${BASE}/${code}/contacts`,
        name: "Contacts",
        Page: Contacts,
    },
    details: {
        path: (code) => `${BASE}/${code}/details`,
        name: "Details",
        Page: EmployerDetails,
    },
    adjustments: {
        path: (code) => `${BASE}/${code}/adjustments`,
        name: "Adjustments",
        Page: Adjustments,
    },
    earningTypes: {
        path: (code) => `${BASE}/${code}/earningTypes`,
        name: "Earning Types",
        Page: EarningTypes,
    },
    report: {
        path: (code) => `${BASE}/${code}/report`,
        name: "Report",
        Page: EmployerReportPage,
    },
};
