import React from 'react';
import { Form } from 'react-bootstrap';

export default class Text extends React.Component {
    constructor(props) {
        super(props);
        // Create a ref for the Form.Control
        this.inputRef = React.createRef();
        this.state = { touch: false };
    }

    componentDidMount() {
        // Automatically focus the input if focusOnMount is true
        if (this.props.focusOnMount) {
            this.inputRef.current.focus();
        }
    }

    handleChange(event) {
        this.props.instance[this.props.realName] = event.target.value;
        if (this.props.onChange) this.props.onChange(event);
        this.setState({ touch: true });
    }

    render() {
        const { name, required, className, variant, placeholder, instance, realName, readOnly, disabled, maxLength, hint } = this.props;
        
        return (
            <>
                <Form.Control 
                    ref={this.inputRef}
                    className={className}
                    as={variant}
                    required={required}
                    name={name}
                    key={name}
                    value={instance[realName]}
                    onChange={this.handleChange.bind(this)}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    onClick={this.props.onClick}
                />
                {hint && <Form.Text muted>{hint}</Form.Text>}
            </>
        );
    }
}
