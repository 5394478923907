import React from "react"

import LoginWrapper from './LoginWrapper'
import Auth from '../Auth'

export default class ResetPasswordRequired extends LoginWrapper {
    constructor(props) {
        super(props)
        this.state.message = {
            severity: 'info', 
            text: 'New password required',
            content: 'Click on the link below to obtain a new verification code'
        }
        this.title = 'Reset Password Required'
    }
    
    handleResetPassword(event) {
        event.preventDefault();
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({validated: true})
        } else {
            const { code, newPassword, confirmPassword } = event.target
            if (newPassword.value !== confirmPassword.value) {
                this.setState({message: {errorCode: 'noMatchingPasswords', text:"Password didn't match, please try again"}})
            } else {
                return Auth.resetPassword(this.props.userName, code.value, newPassword.value).then(data => {
                    Auth.signIn(this.props.userName, newPassword.value).then(data => {
                        window.location.reload()
                    })
                }).catch(err => {
                    console.log('handleResetPassword error: ' + err)
                    this.setState({message:err})
                })
            }
        }
    }

    handleRequestCode(event) {
        event.preventDefault()
        Auth.requestCode(this.props.userName)
        this.setState( {message: {infoCode: 'codeRequested', text: 'Confirmation code sent to your email', severity:'info'}} )
    }

    view() {
        return (<>
            <form noValidate validated={this.state.validated} onSubmit={this.handleResetPassword.bind(this)}>
                <div className="md-form">
                    <input className="form-control" id="code" name="code" label="Request new verification code" type="text" required/>
                    <label className="active" for="code" data-error="" data-success="" id="" aria-labelledby="">Username</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="newPassword" name="newPassword" label="New Password" type="password" required/>
                    <label className="active" for="newPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="confirmPassword" name="confirmPassword" label="Confirm New Password" type="password" required/>
                    <label className="active" for="confirmPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="text-center mt-3">
                    <button className="btn sign-in" type="submit">Change Password</button>
                </div>
            </form>
        </>)
    }
}