import { Ref, Definition } from '../../../framework/infra'

import  RefundDistributionType  from './RefundDistributionType'
import RefundAccount from './RefundAccount'

export default class RefundDistribution extends Ref {
   
    static definitions = {
        account: { ref: RefundAccount, text: 'Deposit To' },
        amount: { type: Definition.types.AMOUNT, text: 'Amount', text_fr: '' },
        type: {ref: RefundDistributionType, text: 'Refund Distribution Type'},
    }

}