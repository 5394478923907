import React from 'react'
import ReactDOM from 'react-dom'
import { Form, Button } from 'react-bootstrap'
import _ from 'lodash'

export default class EForm extends React.Component {
    constructor (props){
        super(props)
        this.state = { 
            validated: false
        }
    }

    render() {
        const { isDirty, isValid, id, children } = this.props
        return (
            <Form ref={id + '_form'} id={id + '_form'} className='e-form' noValidate onChange={this.handleChange.bind(this)} validated={this.state.validated}>
                <div className='e-form-body'>
                    {children}
                </div>      
                <div className='line'> 
                    <div className='line-stretch'/>
                    <Button key="cancel" variant="link" className='text-primary ml-auto' onClick={this.handleCancel.bind(this)}>Cancel</Button> 
                    <Button key="save" disabled={!isDirty || !isValid} className='ml-3 mr-2' type="button" onClick={this.handleSave.bind(this)}>Save</Button> 
                </div>
            </Form>
        )
    }

    handleCancel() {
        this.props.onCancel && this.props.onCancel()
    }

    handleSave() {
        const form = ReactDOM.findDOMNode(this.refs[this.props.id + '_form'])
        if (form.checkValidity() === false) {
            this.setState({validated: true})
        } else {
            this.props.onSave(formFields2Object(form))    
        }
    }

    handleChange(event) {
        event.preventDefault()
        event.stopPropagation()
        this.props.onChange && this.props.onChange(this.getData())
    }
    
    getData() {
        return this.refs && this.refs[this.props.id + '_form'] && formFields2Object(ReactDOM.findDOMNode(this.refs[this.props.id + '_form']))
    }
}

function formFields2Object(target) {
    const fields = Array.prototype.slice.call(target).filter(el => el.name).reduce((form, el) => ({...form, [el.name]: el.checked || el.value }), {})
    return Object.getOwnPropertyNames(fields).reduce((ret, propName) => {
        _.set(ret, propName, fields[propName])
        return ret
    }, {})
}