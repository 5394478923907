import { Service } from '../../framework'
import EventResults from '../../entities/search/EventSearch/EventResults';
import { EmployerService } from '..';

class EventSearch extends Service {
    constructor() {
        super(EventResults, 'Employments', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    async load(eventResults, options) {
        const documents = eventResults.documents.all || [];
        for (const document of documents) {
            const employer = await EmployerService.get(document.employerId);
            document.employer = employer;
        }
        return eventResults
    }
}

const instance = new EventSearch()
export default instance

