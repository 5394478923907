import React, { useEffect, useState } from 'react'

import { Button } from '../../../framework/controls'
import { Modal, Tabs } from '../../../framework/containers'

import Payment from '../../../entities/financial/trustee/Payment'
import PaymentList from './PaymentList'
import PaymentForm from './PaymentForm'
import PaymentDistributions from './PaymentDistributions'
import { RemittanceService } from '../../../services'
import useNotification from '../../../hooks/useNotification'
import Loading from '../../../components/containers/Loading'
import useEmployer from '../../../hooks/useEmployer'

const Payments = ({ employer }) => {
    const { employerId } = useEmployer();
    const { addMessage } = useNotification();
    const [isAddClicked, setIsAddClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [remittances, setRemittances] = useState();
    const [selected, setSelected] = useState();

    useEffect(() => {
        let isMounted = true;

        if (employerId) {
            RemittanceService.getEmployerRemittances(employerId).then(
                (rems) => {
                    rems.distributePayments();
                    if (isMounted) {
                        setRemittances(rems);
                        setIsLoading(false);
                    }
                }
            );
        }

        return () => { isMounted = false };
    }, [employerId]);

    const handleSelect = (payment) => {
        setSelected(payment);
    }
    
    const handleAddPayment = () => {
        setSelected(new Payment());
        setIsAddClicked(true);
    }

    const handleSave = () => {
        RemittanceService.getEmployerRemittances(employerId, {
            refresh: true,
        }).then((rems) => {
            remittances.distributePayments();
            setSelected();
            setIsAddClicked(false);
            setRemittances(rems);
            addMessage("Payment successfully saved", "success");
        });
    }

    const handleDelete = (remittance) => {
        RemittanceService.getEmployerRemittances(employerId, {
            refresh: true,
        }).then((rems) => {
            setSelected();
            setIsAddClicked(false);
            setRemittances(rems);
            addMessage("Payment successfully deleted", "success");
        });
    }

    const handleCancel = () => {
        setSelected();
        setIsAddClicked(false);
    }

    return <>
        {selected && (
            <Modal
                title="Payments"
                onHide={handleCancel}
                className="h-100 w-100 modal-bg-color"
            >
                <PaymentForm
                    payment={selected}
                    isNew={isAddClicked}
                    employer={employer}
                    remittances={remittances}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                />
            </Modal>
        )}
        <Tabs
            initial="payments"
            className="h-100"
            extraTabContent={
                <Button
                    className="btn btn-secondary"
                    onClick={handleAddPayment}
                >
                    Add Payment
                </Button>
            }
        >
            <Tabs.Tab name="payments" title="Payments">
                {!isLoading && remittances ? (
                    <div className="modal-component-body">
                        <PaymentList
                            employer={employer}
                            selected={selected && selected.no}
                            onSelect={handleSelect}
                            remittances={remittances}
                        />
                    </div>
                ) : (
                    <Loading />
                )}
            </Tabs.Tab>
            <Tabs.Tab name="tree" title="Distr. Balances">
                <PaymentDistributions employer={employer}/>
            </Tabs.Tab>
        </Tabs>
    </>
};

export default Payments;