import { useCallback, useEffect, useState } from "react";
import { Icon } from "../../framework/controls";

const CollapsibleCard = ({title, children, key, disabled, validated}) => {

    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        setIsOpen((!validated && !disabled));
    }, [validated, disabled])

    return <div className={"transfer-card "  + (disabled ? 'lcard-disabled' : '')} key={key}>
        <div className={"transfer-card-col " + (disabled ? '' : 'clickable')} key={key} onClick={() => setIsOpen(!isOpen)}>
            <div className="line justify-content-between">
                <div> <span className="bold">{title}</span> {validated && <Icon icon="check" className="text-success ml-1" />} </div>
                {!disabled && <div> <Icon icon={"chevron-" + (isOpen ? 'up' : 'down')}/> </div>}
            </div>
        </div>
        <div className={'transfer-card-col toggler ' + (isOpen ? 'isOpen' : 'isClosed') } key={key}>
            {!disabled && children}
        </div>
    </div> 
}

export default CollapsibleCard;