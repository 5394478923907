import React from "react"
import { Button } from "react-bootstrap"
import { Icon } from "../../controls"
import { isObject } from "../../utils/helper"
import EInput from "../eComp/EInput"

const TaskBase = ({ event, onChange, onUndo, canUndo, children, validated, message, resolutionMessage, instance, skippable = false, skipLabel = 'Skip', onSkip }) => {
    
    let isSkipped = isObject(event.metadata) && event.metadata.hasOwnProperty('skipped');

    let text = message ?? '';
    if (resolutionMessage && validated) {
        text = resolutionMessage(instance, event);
    }

    const handleSkip = () => {
        if (!isSkipped && onSkip && !validated) {
            onSkip(event);
        }
    }

    return <>
                {text !== '' && <p className="info-panel">{text}</p>}
                <div className="line g10 mb5 line-bottom">
                    <EInput onChange={onChange} name='effDt' instance={event} className='data-no-mar'/>
                    <EInput onChange={onChange} name='rptDt' readOnly={true} instance={event} className='data-no-mar'/>
                    {children}
                    {skippable && <Button onClick={handleSkip} className={isSkipped ? 'btn-primary' : 'btn-secondary'}>{skipLabel}</Button>}
                    {canUndo && <Button className='btn-secondary btn-icon' onClick={() => onUndo(event.code)}><Icon icon={"undo"}/> Undo</Button>}
                </div>
                <div className="line g10">
                    <EInput variant="textarea" onChange={onChange} name='cmt' instance={event}/>
                </div>
            </> 
}

export default TaskBase;

