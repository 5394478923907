import React from 'react'
import { Modal, Row } from '../containers'
import { Button } from '../controls'

const Question = ({
    title,
    onAnswer,
}) => {
    const handleAnswer = (answer) => {
        if (onAnswer) onAnswer(answer);
    }

    return <Modal show={true} onHide={() => handleAnswer(false)} centered className='s-question'>
        <Row>
            <span><i className="far fa-bell"></i></span>
            <span className="ml-3">{title}</span>
        </Row>
        <Row className='mt-4'>
            <div className='col'/>
            <Button key="No" onClick={() => handleAnswer(false)}>No</Button> 
            <Button key="Yes" onClick={() => handleAnswer(true)}>Yes</Button> 
        </Row>
    
    </Modal>
};

export default Question;