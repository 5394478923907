import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankCheque extends  Ref {
    
}

const { BOOLEAN, REF, STRING } = Definition.types

// "cheque": {
//     "address": {
//       "city": "Westmount",
//       "civic": "205-415 Sant Catherine Street West",
//       "country": "Canada",
//       "pc": "H3Z 2Y5",
//       "poBox": "",
//       "prov": "Quebec"
//     },
//     "addressee": "RBC Investor Services c/o A.S. Hayes Consulting Inc.",
//     "notice": "",
//     "disabled": true
//   },

ConfigBankCheque.definitions = {
    address: { ref: require('../../address/Address'), text: 'Address', text_fr: 'Adresse' },
    addressee: { type: STRING, text: 'Addressee', text_fr: 'Destinataires' },
    disabled: { type: BOOLEAN, text: 'Disabled', text_fr: 'Désactivé' },
    notice: { type: STRING, text: 'Notice', text_fr: 'Avis' },
}
