import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { emailTemplates } from '../../../templates/email-templates/email';
import useNotification from "../../../hooks/useNotification";
import RichTextEditor from "../../inputs/Editor/RichTextEditor";
import EInput from "../eComp/EInput";
import { Icon } from "../../controls";
import TaskBase from "./TaskBase";
import { isObject } from "../../utils/helper";
const SKIP_LABEL = 'Sent manually';

const EmailResolution = (props) => {

    const {message, emailTemplate, templateInfo, attachment, event, flowEvent, instance, onSave, onChange, validated = false, text = '', skippable = true, onSkip, isDirty, onFinalize} = props;
    const { addMessage } = useNotification();
    const emailFormClass = require('../../../entities/frontend/EmailForm');
    const data = templateInfo(instance, flowEvent);

    const emailForm = new emailFormClass.default({ 
        subject: emailTemplates[emailTemplate].subject(data),
        templateKey: emailTemplate,
        templateInfo: data,
        attachment: attachment,
    });

    emailForm.initEditorState()
    const [form, setForm] = useState(emailForm);
    const [body, setBody] = useState();

    useEffect(() => {
        setBody(form.body);
    }, [form])

    const handleBodyChange = (newBody) => {
        form.body = newBody;
        setForm(form.clone());
    }

    const handleSend = async () => {

        if (!form.isValid()) {
            setForm(form.clone()); //re-render with errors
            return;
        }

        const mailingService = require('../../../services/config/EmailService').default;
        let response = await mailingService.send(form);
        
        if(response.MessageId){
            event.metadata = { messageId: response.MessageId };
            if (onSave) onSave(event);
        } else {
            addMessage(response.message ?? 'Coudln\'t send email. An issue occured.', 'danger');
        }        
    }

    let isSkipped = isObject(event.metadata) && event.metadata.hasOwnProperty('skipped');

    return <> 
        {validated ? <p className="info-panel">{text}</p> : message}
        {(!validated && body) && <><div className="line g10 mb5">
            <EInput error={form.errors?.to} onChange={onChange} name='to' instance={form} className='data-no-mar w-100' />
            <EInput error={form.errors?.cc} onChange={onChange} name='cc' instance={form} className='data-no-mar w-100'/>
        </div>
        <div className="line g10 mb15">
            <EInput error={form.errors?.subject} onChange={onChange} name='subject' instance={form} className='data-no-mar w-100' />
        </div>
        <div className="line g10 mb10">
            <RichTextEditor editorState={body} onChange={handleBodyChange} decorators={form.getDecorators()}/>
        </div>
        <div className="line g10 line-end">
            {/* {isDirty && <Button onClick={() => onFinalize()} className={'btn-secondary'}>Save Undo</Button>} TBD */}
            <Button onClick={handleSend} className={'btn-secondary'}><Icon icon={"paper-plane"}/> Send</Button>
            {skippable && <Button onClick={() => onSkip(event)} className={'btn-secondary'}>{SKIP_LABEL}</Button>}
        </div></> }
        {validated && <TaskBase {...props} skippable={skippable} skipLabel={SKIP_LABEL}> 
            <Button className={isSkipped ? 'btn-secondary' : 'btn-primary'}>Sent</Button>
        </TaskBase>}
    </> 
}


export default EmailResolution;

