import React, { useState } from 'react'
import { Modal } from "../../../framework/containers";
import { Button } from "../../../framework/controls";
import EmployerInfoForm from './EmployerInfoForm'

const EmployerInfo = ({ employer, onChange }) => {
    const [isEditClicked, setIsEditClicked] = useState(false);

    const handleSave = (employer) => { 
        setIsEditClicked(false);
        if (onChange) onChange(employer);
    }

    return (
        <>
            <div className="modal-component-body">
                <EmployerInfoForm
                    employer={employer}
                    readOnly
                />
            </div>
            <div className="modal-bottom">
                <Button
                    key="edit"
                    className="btn-secondary"
                    onClick={() => setIsEditClicked(true)}
                >
                    Edit
                </Button>
            </div>
            {isEditClicked && (
                <Modal className="w-90 modal-bg-color">
                    <EmployerInfoForm
                        employer={employer}
                        onSave={handleSave}
                        onCancel={() => setIsEditClicked(false)}
                    />
                </Modal>
            )}
        </>
    );
};

export default EmployerInfo;
