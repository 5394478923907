import { useMemo } from "react";

const usePagination = ({total, pageSize, currentPage, totalPages}) => {
    const firstPageIndex = 1;
    const pageCountExtremes = 5;
    const leftSiblingIndex = Math.max(currentPage - 1, firstPageIndex); 
    const rightSiblingIndex = Math.min(currentPage + 1, totalPages); 

    const getRange = (start, end) => {
        let length = end - start + 1;
        return Array.from({ length }, (item, index) => index + start);
    };

    const checkShouldSkipPagesToTheRight = () => {
        let leftRange = getRange(1, pageCountExtremes);
        return [...leftRange, skipPages, totalPages];
    }

    const checkShouldSkipPagesToTheLeft = () => {
        let rightRange = getRange(totalPages - pageCountExtremes + 1, totalPages); 
        return [firstPageIndex, skipPages, ...rightRange];
    }

    const checkShouldSkipBothSides = () => {
        let middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, skipPages, ...middleRange, skipPages, totalPages];
    }

    const pagesToShow = useMemo(() => {
        const skipLeftPages = pageCountExtremes <= currentPage; 
        const skipRightPages = currentPage <= (totalPages - pageCountExtremes + 1);
        
        // no page skips
        if ((totalPages <= pageCountExtremes) || (!skipLeftPages && !skipRightPages)) return getRange(1, totalPages);

        // skip pages to the right
        if (!skipLeftPages && skipRightPages) return checkShouldSkipPagesToTheRight();
        
        // skip pages to the left
        if (skipLeftPages && !skipRightPages) return checkShouldSkipPagesToTheLeft();

        // skip pages to the left and right
        if (skipLeftPages && skipRightPages) return checkShouldSkipBothSides();
        
     }, [total, pageSize, currentPage]);

    return pagesToShow;
}
export const skipPages = 'skip';
export default usePagination;
