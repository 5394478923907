import { Service, Persistence } from '../../framework'

import { ConfigBank, ConfigCorporate, ConfigCommittee, Trustee } from "../../entities/config"

class ConfigService extends Service {
    constructor() {
        super()

        this.persistences = {
            'bank': new Persistence(ConfigBank),
            'corporate': new Persistence(ConfigCorporate),
            'trustee': new Persistence(Trustee),
            'committee': new Persistence(ConfigCommittee),
        }
    }

    getConfig(id, refresh) {
        const promise = () => this.persistences[id].get('config_GetConfig', id)
        return this.useCache('config_' + id, promise, refresh)
    }

    updateConfig(id, config) {
        return this.useCache('config_' +  id, () => this.persistences[id].update('config_UpdateConfig', config), true)

    }
}

const instance = new ConfigService()
export default instance
