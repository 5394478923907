const { BeneficiaryService, MembershipService, SpouseService, RemittanceService, PersonService, EmploymentService, RemittanceDetailService, AdjustmentService, EmployerService } = require(".");

// Stack of updates to be committed
export class UpdateStack {
    
    static types = {
        update: 'update',
        delete: 'delete'
    }
    
    static services = {
        beneficiaries: 'beneficiaries',
        spouses: 'spouses',
        remittances: 'remittances',
        people: 'people',
        memberships: 'memberships',
        employments: 'employments',
        details: 'details',
        adjustments: 'adjustments',
        employers: 'employers'
    }
    
    constructor() {
        this.stack = [];
    }

    /**
     * Updates or replaces a value in the stack for future commit
     * @param {String} service
     * @param {String} type
     * @param {String} key
     * @param {Object} value
     */
    async upsert(service, type, key, value) {
        if (!UpdateStack.services[service]) throw new Error('Service not found in registry');
        if (!UpdateStack.types[type]) throw new Error('Invalid update type');

        let item = this.stack.find(x => x.service === service && x.key === key);
        if (item) {
            item.value = value;
        } else {
            this.stack.push({ service, key, value, type });
        }
    }

    /**
     * Commits the stack of updates to the backend services 
     * using the specified service registry and method
     * @returns {Promise<Array>} results
     */
    async commit() {
        const results = [];
        const serviceRegistry = new ServiceRegistry();
        for (let item of this.stack) {
            let service = serviceRegistry[item.service];
            let result = await service[item.type](item.value);
            results.push(result);
        }
        return results;
    }
}

class ServiceRegistry {
    constructor() {
        this.beneficiaries = BeneficiaryService;
        this.spouses = SpouseService;
        this.remittances = RemittanceService;
        this.people = PersonService;
        this.memberships = MembershipService;
        this.employments = EmploymentService;
        this.details = RemittanceDetailService;
        this.adjustments = AdjustmentService;
        this.employers = EmployerService;
    }

    async loadPointer(pointer) {
        let service = this[pointer.registryKey];
        if (service) return await service.get(pointer.instanceKey);
    }
}

export default new ServiceRegistry();