import { RefList } from "../framework/infra";
import Mapper from "./Mapper";
import Employers from './../entities/employment/Employers';
export default class EmployerMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.employer = new Employers();
    }

    async map () {
        await this.load('spouses');
        await this.load('people');
        this.employer.pushList((await this.load('employers')));
        return this.employer.all[0];
    }
}