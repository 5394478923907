import { PageTitle } from '../../framework/components'
import { Col, Row } from '../../framework/containers'
import PersonSearch from './PersonSearch'

export default function PeoplePage(props) {
    
	return <>
        <Row className='align-items-end' >
            <Col><PageTitle className='mt-1'>People</PageTitle></Col>
        </Row>
        <div className="h-90 content-container members-container">
            <PersonSearch persistFilters={true} includeTitle={false} canAdd={false}/>
        </div>
    </>
    

}