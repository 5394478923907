import React from 'react';
import { Table } from '../../../../framework/components';
import { Definition, Ref } from '../../../../framework/infra';
import { EmploymentEvent } from '../../../employment';
import ParticipationEvent from '../ParticipationEvent';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../framework/utils/helper';

const CorrectedEventsTable = ({event}) => {

    const HEADER_COLS = ['date', 'eventDescription', 'cmt', 'employerCode']
    const data = Report.getData(event.metadata?.corrections);
    const columns = new Table.Headers(Report, HEADER_COLS);

    return <>
        <div class="mt-2">
            <label>Corrected events</label>
            <Table id="correction-table" className="mah-300" data={data} columns={columns} />
        </div>
    </>
}

class Report extends Ref {

    static getData(events = []) {
        return events.reduce((rows, row) => {
            const isEmploymentEvent = row.employer !== undefined;
            const event = isEmploymentEvent ? new EmploymentEvent({code: row.code}) : new ParticipationEvent({code: row.code});
			rows.push(new Report({ date: moment.utc(row.ets).format(DATE_FORMAT), eventDescription: event.eventDesc, cmt: row.cmt, employerCode: row.employer }));
            return rows;
        }, [])
    }
    
    static definitions = {
        date: { type: Definition.types.STRING, text: 'Effective Date'},
        eventDescription: { type: Definition.types.STRING, text: 'Event Description'},
        cmt: { type: Definition.types.STRING, text: 'Comment'},
        employerCode: { type: Definition.types.STRING , text: 'Employer'},
    }
}

export default CorrectedEventsTable;