import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { Container, Text, Row, Separator, AmountDetail } from '../../../framework/utils/pdf' 
import { texts } from './textPDF/textPDFAddendum';
import { sum } from '../../../framework/utils/helper';
import config from '../../../utils/config';
import { Period } from '../../../framework/utils';

const PDFAdjustment = ({ adjustment, employerAdjustments }) => {
    const styles = StyleSheet.create({
        header: { width: '100%', textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '13', marginTop: '30'},
        subtitle: {textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '11', marginTop: '13', marginBottom: '13'},
        label: { fontSize: '11', fontFamily: 'Roboto-Bold' }
    });

    const getEmployee = () => adjustment.keysValues.participation !== '' && 
        <Row className='mb-3'>
            <Text className='w-40' text={texts['ee-name']} style={styles.label} />
            <Text className='w-40'>{adjustment.employment.person.name}</Text>
        </Row>

    const getDistributionAmounts = () => 
        Object.values(adjustment.distributionContribution.all)
            .filter(distrib => distrib.am !== 0)
            .map(distrib => 
                <Row className='mb-3'>
                    <Text 
                        className='w-40' 
                        text={texts[`${distrib.ta}${adjustment.type.config?.isCredit ? '-remaining' : ''}`]} 
                        style={styles.label} 
                    />
                    <AmountDetail 
                        amount={adjustment.type.config?.isCredit
                            ? adjustment.leftOverCredit 
                            : distrib.am
                        } 
                    />
                </Row>
        )

    // Same calculation done in execQuery() in src/views/financial/remittance/reports/RemittancesLogReport.jsx
    const getTotal = () => {
        const adjustmentKey = adjustment.type.key;

        const getTotalAmount = () => {
            const isUsedCredit = adjustment?.type?.config?.isCredit && !adjustment?.type?.config?.isStartCredit;

            if(adjustment.isCancelCredit || isUsedCredit) {
                const adjKeyToFind = adjustment.isCancelCredit ? `${adjustment.type.key}` : `${adjustment.type.key}`.slice(0, -1);
                const employerAdjustmentsCredits = employerAdjustments.getFiltered(adj => 
                    adj.period.isSameOrAfter(new Period(config.totalOwingStartPeriod))
                    && adj.period.isSameOrBefore(adjustment.period)
                    && adj.type.key === adjKeyToFind
                    && !adj.isCancelCredit
                );
                const totalSum = sum(employerAdjustmentsCredits, 'total');
                return totalSum;
            } else {
                return adjustment?.total;
            }
        }

        const totalAmount = getTotalAmount();
        return <Row className='mb-3'>
            <Text className='w-40' text={texts['total-amount']} style={styles.label} />
            <AmountDetail amount={totalAmount} 
            />
        </Row>
    }

    // Same calculation done in execQuery() in src/views/financial/remittance/reports/RemittancesLogReport.jsx
    const getCreditLayout = () =>  {
        const adjustmentKey = adjustment.type.key;

        const getAdjustmentCreditUsed = () => {
            const isUsedCredit = adjustment?.type?.config?.isCredit && !adjustment?.type?.config?.isStartCredit;
            if(isUsedCredit) {
                const adjKeyToFind = `${adjustment.type.key}`.slice(0, -1);
                const employerAdjustmentsCredits = employerAdjustments.getFiltered(adj => 
                    adj.period.isSameOrAfter(new Period(config.totalOwingStartPeriod))
                    && adj.period.isSameOrBefore(adjustment.period)
                    && adj.type.key === adjKeyToFind
                    && !adj.type.config.isCancelCredit
                );
                const totalSum = sum(employerAdjustmentsCredits, 'total');
                const adjustmentCreditUsed = totalSum - adjustment.leftOverCredit;
                return adjustmentCreditUsed;
            } else {
                const creditUsed = adjustment.total - adjustment.leftOverCredit;
                return creditUsed;
            }
        }

        const creditUsed = getAdjustmentCreditUsed();

        return  <>
            <Row className='mb-3'>
                <Text className='w-40' text={texts['adjustmentType']} style={styles.label} />
                <Text className='w-40'>{adjustment.type.config.invoiceDescription ?? adjustment.type.desc}</Text>
            </Row>
            {getEmployee()}
            {getTotal()}
            <Row>
                <Text className='w-40' text={texts['adjustmentCreditUsed']} style={styles.label} />
                <AmountDetail amount={creditUsed} 
                />
            </Row>
            <Row  className='mb-3'>
                <Text className='w-40' text={texts['adjustmentCreditUsed2']} style={styles.label} />
                <AmountDetail amount={0} blankForZero />
            </Row>
            {getDistributionAmounts()}
        </>;
    }

    const getReversingCreditLayout = () =>  {
        const adjustmentKey = adjustment.type.key;

        const getAdjustmentCreditUsed = () => {
            const adjKeyToFind = `${adjustment.type.key}R`;
            const employerAdjustmentsCreditsUsed = employerAdjustments.getFiltered(adj => 
                adj.period.isSameOrAfter(new Period(config.totalOwingStartPeriod))
                && adj.period.isSameOrBefore(adjustment.period)
                && adj.type.key === adjKeyToFind
                && !adj.isCancelCredit 
            );
            const totalSum = sum(employerAdjustmentsCreditsUsed, 'total');
            return -Math.abs(totalSum);
        }

        const creditUsed = getAdjustmentCreditUsed();


        return  <>
            <Row className='mb-3'>
                <Text className='w-40' text={texts['adjustmentType']} style={styles.label} />
                <Text className='w-40'>{adjustment.type.config.invoiceDescription ?? adjustment.type.desc}</Text>
            </Row>
            {getEmployee()}
            {!adjustment.type?.config?.isCancelUsedCredit && <>
                <Row>
                    <Text className='w-40' text={texts['adjustmentCreditUsed']} style={styles.label} />
                    <AmountDetail amount={adjustment.total} blankForZero />
                </Row>
                <Row  className='mb-3'>
                    <Text className='w-40' text={texts['adjustmentCreditUsed2']} style={styles.label} />
                    <AmountDetail amount={0} blankForZero />
                </Row>
            </>}
            {!adjustment.type?.config?.isCancelUsedCredit && getDistributionAmounts()}
            {adjustment.type?.config?.isCancelUsedCredit && <Row  className='mb-3'>
                <Text className='w-40' text={texts['adjustmentUsedCreditCancel']} style={styles.label} />
                <AmountDetail amount={adjustment.total} blankForZero />
            </Row>}
        </>;
    }

    const getAdjustmentLayout = () => 
        <>
            {adjustment.type.config.invoiceDescription && <Row className='mb-3'>
                <Text className='w-40' text={texts['adjustmentType']} style={styles.label} />
                <Text className='w-40'>{adjustment.type.config.invoiceDescription}</Text>
            </Row>}
            {getEmployee()}
            {getDistributionAmounts()}
            {getTotal()}
        </>

    return (
        <Container className="pl-5 ml-6" key={`addendum-adj-${adjustment.keyValue}`}>
            <Separator className='mb-3 mt-3 w-80' />
            {adjustment.isCancelCredit || adjustment.type.config.isCancelUsedCredit ? getReversingCreditLayout() : adjustment.type.config?.isCredit ? getCreditLayout() : getAdjustmentLayout()}
            {adjustment.cmt.length !== 0 && 
                <Row className='mb-3'>
                    <Text className='w-40' text={texts['comment']} style={styles.label}/>
                    <Text className='w-50'>{adjustment.cmt}</Text>
                </Row>
            }
        </Container>
    );
}

export default PDFAdjustment;

