import React from 'react'
import _ from 'lodash'
import { EInput } from '../../components'
import { Button } from '../../controls'
import { Row } from '../../containers'

export default class EList extends React.Component{
    handleNew() {
        const instance = new this.entity({})
        this.props.onNew && this.props.onNew(instance)
        this.data.push(instance)
        this.setState({touch: true})
    }

    handleDelete(index) {
        const instance = this.data[index]
        if (this.props.softDelete) instance.delete()
        else this.data.splice(index,1)
        this.props.onDelete && this.props.onDelete(instance)
        this.setState({touch: true})
        this.props.onTouch && this.props.onTouch(instance)
    }

    handleRetrieve(index) {
        const instance = this.data[index]
        if (this.props.softDelete) instance.unDelete()
        this.props.onRetrieve && this.props.onRetrieve(instance)
        this.setState({touch: true})
        this.props.onTouch && this.props.onTouch(instance)
    }
    
    handleChange(index, name, eventOrValue, event) {
        if (eventOrValue && (eventOrValue.currentTarget || eventOrValue.target)) console.log('WARNING: a control should return value as first parameter and event as second', name )
        this.data.at(index)[name] = eventOrValue && (eventOrValue.currentTarget ? eventOrValue.currentTarget.value : eventOrValue) //Added .all since Email and Phone is not RefList but this.data GenericRefList
        if(this.props.onChange) this.props.onChange(eventOrValue, event)
    }

    render() {
        const { name, columns, className, reference, instance, entity, disabled, readOnly, modify, retrievable } = this.props
        this.entity = entity || _.get(instance.constructor.definitions, name).getRef()
        this.data = _.get(instance, name)
        var defs = this.entity.definitions || {}
        if (this.entity && this.entity.isRefList) { 
            this.data = this.data._list
            this.entity = this.entity.ref
        }

        return (<div className={className}>
            <Row>
                {!readOnly && !modify && <Button variant='plus' className='col-1 plus' onClick={this.handleNew.bind(this)} />}
                {columns.map(col => {
                    return <div className={col.className + ' mr-2 align-self-center e-list-header label'}>{defs[col.name] ? defs[col.name].text : col.name}</div>
                })}
                <div class='w-100'/>
                {this.data && this.data.map((item, index) => {
                    //strict items should not be editable
                    if (item?.strict && !readOnly) return null;
                    return <>
                        {!readOnly && !modify && <Button 
                            variant= {retrievable && item.isDeleted() ? 'plus': 'minus'}
                            className={'col-1 minus' + (item.isDeleted() && !retrievable ? ' d-none' : '')} 
                            onClick={ item.isDeleted() ? this.handleRetrieve.bind(this, index) : this.handleDelete.bind(this, index)} 
                        />}
                        {columns.map(col => {
                            if(col.className) col.className = col.className + ' mr-2'
                            return <EInput 
                                name={col.name} 
                                definition={defs[col.name]} 
                                instance={item}
                                value={item[col.name]} 
                                reference={(reference || '') + name + '[' + index + '].'} 
                                onChange={this.handleChange.bind(this, index, col.name)}
                                className={item.isDeleted() && !retrievable ? ' d-none' : col.className}
                                noLabel={true}
                                disabled={disabled}
                                readOnly={readOnly || (retrievable && item.isDeleted())}
                                {...col.props}
                            />
                        })}
                        <div class='w-100'/>
                    </>
                })}  
            </Row>
        </div>)
    }
}
