import React from 'react';
import { EmploymentEvent } from '../../entities/employment';
import StringInput from './StringInput';

const EmploymentEventInput = ({onEnter, instance, property, existingFilters}) => {

    const events = [];
    for (const [key] of Object.entries(EmploymentEvent.messages)) {
        const event = new EmploymentEvent({code: key});
        if (event.config.status) {
            events.push({key, value: key, text: event.desc });
        }
    }

    return <StringInput property={property} instance={instance} options={events} existingFilters={existingFilters} onEnter={onEnter}/>;
}

export default EmploymentEventInput;