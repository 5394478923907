import React from 'react'
import Config from '../../utils/config'

export default class ContainerChild extends React.Component {
    static get cn() { return this._cn }
    static set cn(cn) { this._cn = cn }
    
    static isChild(child) { return child && child.type && child.type.cn === this._cn }

    static filter(children) {
        return [].concat(children).filter(child => this.isChild(child) && !Config.modules[child.props.module]?.unreleased)
    }
    static filterOut(children) {
        return [].concat(children).filter(child => !this.isChild(child))
    }

}