import { useState } from "react";
import { Button, EInput } from "../../../../../framework/components";
import { Row, Title } from "../../../../../framework/containers";
import { Select } from "../../../../../framework/controls";
import { UpdateStack } from "../../../../../services/ServiceRegistry";
import ActionResult from "../ActionResult";

const ACTION_TITLE = 'Revert Close'

/**
 * This action reverts the close status of a participation. It is only available for participations that are currently closed. 
 * The action will remove all closing events and condense them into a single history correction event
 * Employment and partcipation closing events will be removed and the participation status will return to active
 * @param {Participation} participation
 * @param {Function} onCancel
 * @param {Function} onSubmit
 */
const ActionRevertClose = ({participation, onCancel, onSubmit}) => {

    const choices = participation.employments.all
        .filter(employment => employment.events.all.find(x=>x?.status.isTerminated()))
        .map(employment => {
            return {
                text: employment.employer.code,
                key: employment.keyValue,
                value: employment.keyValue
            }
        });

    const [choice, setChoice] = useState(choices[0].key);

    return <>
        <Title title={ACTION_TITLE} />
        <Row className="">
            <div>
                In order to revert the close status of a participation, you must select the employment that is not terminated. By applying this action, all closing events will be removed and a history correction event will be created.
            </div> 
        </Row>
        <Row className="mt-4">
            <EInput name='employerId' label='Which employment is not terminated?' instance={participation}>
                <Select options={choices} value={choice} onChange={setChoice} />
            </EInput> 
        </Row>
    
        <Row className="mt-2">
            <div className="col" />
            <Button key="Yes" onClick={() => onSubmit({choice})}>
                Apply
            </Button>
            <Button key="No" onClick={onCancel}>
                Cancel
            </Button>
        </Row>
    </>
}

export default {
    text: ACTION_TITLE,
    form: <ActionRevertClose />,
    condition: (participation) => {
        return participation.status.isClose()
    },
    apply: ({participation, values}) => {
        const result = new ActionResult();
        const metadata = {
            corrections : []
        } 

        const employment = participation.employments.find(x=>x.keyValue === values.choice);

        //figure out termination type from participation (termination, retired, deceased)
        const lastCloseEvent = participation.events.find(x=>x.status?.isClose());
        if (!lastCloseEvent) { throw new Error('No close event found') }

        const eventGroup = lastCloseEvent.config.groups.find(x=>['Termination','Death', 'Retirement'].includes(x));
        if (!eventGroup) { throw new Error('No event group found') }

        //reverse list to remove last employment event matching termination type
        const employmentEvents = employment.events.all;
        const employmentEvent = employmentEvents.reverse().find(x=>matchesParticipationStatus(lastCloseEvent, x));
        if (!employmentEvent) { throw new Error('No termination employment event found') }

        employment.deleteEvent(employmentEvent);
        metadata.corrections.push({code: employmentEvent.code, cmt: employmentEvent.cmt, ets: employmentEvent.ets, rts: employmentEvent.rts, employer: employment.employer.code});
        
        //remove all closing events from participation including pending events
        let events = participation.events.copy().all;
        for (let event of events) {
            if (event.config.groups?.includes(eventGroup) || event.config.groups?.includes('Payment')) {
                participation.deleteEvent(event);
                metadata.corrections.push({code: event.code, cmt: event.cmt, ets: event.ets, rts: event.rts});
            }
        }
        
        //create history correction event will all corrected events as metadata
        participation.addEvent({
            code: 'corrected', 
            ets: Date.now(), rts: Date.now(),
            metadata: metadata
        });

        result.updateStack.upsert(UpdateStack.services.memberships, UpdateStack.types.update, participation.membership.keyValue, participation.membership);
        result.updateStack.upsert(UpdateStack.services.employments, UpdateStack.types.update, employment.keyValue, employment);

        return result;
    }
}

/**
 * We want to match the termination type from the participation status to the employment status
 * @param {*} partcipationEvent 
 * @param {*} employmentEvent 
 * @returns 
 */
function matchesParticipationStatus(partcipationEvent, employmentEvent) {
    if (partcipationEvent.status.isDeceased() && employmentEvent.status.isDeceased()) { 
        return true;
    } else if (partcipationEvent.status.isReceivingPension() && employmentEvent.status.isRetired()) {
        return true;
    } else if (partcipationEvent.status.isTerminated() && employmentEvent.status.isTerminated()) {
        return true;
    }
    return false;
}
