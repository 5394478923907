import EmailResolution from "../../../framework/components/taskValidation/EmailResolution";
import { Title } from "../../../framework/containers";

const getJurisdictionTemplateInfo = (employment) => {
    const employer = employment.employer;
    const cc = employment.employer.contacts?.find(contact => contact.isAdministrator())?.getWorkEmail()?.email;
    const to = employment.person.emails?.find(email => email.usage === 'work')?.email ?? cc;
    const admin = employer.contacts.find(contact => contact.isAdministrator());
    const employments = employment.participation.employments.filter(x=>x.keyValue !== employment.keyValue);
    const previousEmp = employments[employments.length-1];
    const sameJur = employment.employer.plan.jurisdiction === previousEmp.employer.plan.jurisdiction;
    const nrlForm = employment.participation.hasEnrollmentForm();

    return {
        to: to ?? cc,
        cc: to?.includes(cc) ? '' : cc, //make employer default to if no member emails exists
        employment: employment,
        lastEmployment: employments[employments.length-1],
        admin: admin.person,
        jurChanged: !sameJur,
        nrlForm: nrlForm,
    }
}

export const MemSustainedTask = ({instance, onSave, onClose}) => {

    const info = getJurisdictionTemplateInfo(instance);

    let title = 'Send Membership Email'
    let text = 'Send Membership sustained with new employer {Important change of Jurisdiction} notice to employee and employer.';
    if (!info.jurChanged) text = 'Send Membership sustained with new employer notice to employee and employer.';

    const handleSave = (event) => {
        instance.addEvent(event);
        onSave(instance);
    }

    let emailProps = {
        event: instance.events.findOrCreate('code', {code: 'memS'}),
        emailTemplate: 'sustainedNewEmp',
        templateInfo: getJurisdictionTemplateInfo,
        resolutionMessage: (instance, event) => {
            return 'An email has been sent to the employer and the member.';
        },
        onClose: onClose,
        onSave: handleSave,
        onSkip: handleSave,
        instance: instance,
        message: <p className="info-panel">{text}</p>,
    }

    return <>
        <Title title={title} onHide={onClose} />
        <EmailResolution {...emailProps}/>
    </> 

        
}