import { RefEvent, RefHistorical } from "../../framework/infra";
import { SubEventConfig } from "./SubEventConfig";

export class SubEvent extends RefEvent {
    get config() {return {...SubEventConfig['default'], ...SubEventConfig[this.code],}}

    static messages = [
        //Onboarding Flow
        ['reqEnrlFrm', 'Enrolment form requested.' ],
        ['rcvEnrlFrm', 'Enrolment form received'],
        ['issEnrlFrm', '{issues} with Enrolment form '],
        ['enrlInfUpd', 'Enrolment information updated'],
        ['enrlInfSent', 'Enrolment information confirmation was sent to employee'],
        ['enrlInfRcv', 'Enrolment information confirmation was received'],
        ['enrlFrmAct', 'Enrolment Form sent to Actuary'],
        ['waivSent', 'Waiver letter has been requested'],
        ['waivRecv', 'Waiver letter has been received'],
        ['issJoinEnrlFrm', '{issues} with Join Date'],
        //Active 60 Flow 
        ['notELigRedPen', 'Member is NOT Eligible to unreduced pension while continuing to work. {employer} informed. Continue deductions'],

    ]

    static definitions = {
        config: { abstract: true },
    }

    static getTaskList(instance, flowEvent) {
        const getTask = (key) => {
            const displayConditions = SubEventConfig[key].getDisplayConditions(instance, flowEvent);
            if (!displayConditions.include) return; 
            const hasEvent = flowEvent.subEvent.find(ev => ev.code === key);
            
            return {
                key: key,
                config: SubEventConfig[key],
                validated: hasEvent ? true : false,
                disabled: !displayConditions.enabled,
            }
        }

        //recursively go down children tree until orphans
        const getChildren = (key) => {
            let children = []
            if (!SubEventConfig[key].children) return children;
            SubEventConfig[key].children.forEach(childKey => {
                let task = getTask(childKey);
                if (task) {
                    children.push(task);
                    children = children.concat(getChildren(task.key));
                }
            });
            return children;
        }

        let flowSubEvents = [];
        for (const [key, config] of Object.entries(SubEventConfig)) {

            if (!config.rootEvent) continue; //only interested in root level items
            if (!config.group.includes(flowEvent.config.flowKey)) continue;

            let task = getTask(key); //root is always pushed
            flowSubEvents.push(task);
            flowSubEvents = flowSubEvents.concat(getChildren(key));
        }
        return flowSubEvents;
    }
}


export class SubEvents extends RefHistorical {
    pushEvent(eventData){
        const event = this.create(eventData);
        this.push(event);
    }
    
    static ref = SubEvent;
}
