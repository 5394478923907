import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Tabs from "../../components/containers/CustomTabs";
import Title from "../../components/ContentHeaderTitle";
import { employerTabsConfig } from "../../entities/company/EmployerTabConfig";
import useEmployer from "../../hooks/useEmployer";

const EmployerWrapper = ({ children }) => {
    const { employerCode } = useParams();
    const { employerName } = useEmployer();
    const { pathname } = useLocation();
    const pathKey = pathname.split("/").slice(-1);
    const isTabKey = Object.keys(employerTabsConfig).includes(pathKey[0]);
    const tabs = Object.values(employerTabsConfig);

    return (
        <div className="d-flex flex-column w-100 h-100">
            <Title
                titleText={`${employerCode} ${
                    employerName ? `- ${employerName}` : ""
                }`}
                showBackButton
            />
            <Tabs
                data={employerCode}
                tabs={tabs}
                defaultKey={isTabKey ? pathKey : "remittances"}
            />
            <div className="h-90 content-container">{children}</div>
        </div>
    );
};

export default EmployerWrapper;
