import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankRegistrationAccount extends  Ref {
    
}

const { STRING } = Definition.types

// "eft": {
//     "accounts": {
//       "fed": "James Bay Cree Canada Plan 5042015",
//       "que": "James Bay Cree Quebec Plan 5041512"
//     },
//     "address": {
//       "city": "Toronto",
//       "civic": "155 Wellington St. West, 4th Floor",
//       "country": "Canada",
//       "pc": "M5V 3L3",
//       "poBox": "",
//       "prov": "Ontario"
//     },
//     "bank": "Royal Bank of Canada",
//     "bank_code": "0003",
//     "notice": "",
//     "disabled": false,
//     "transit": "09504"
//   },


ConfigBankRegistrationAccount.definitions = {
    name: { type: STRING, text: 'Account Name', text_fr: 'Nom du compte' },
    number: { type: STRING, text: 'Account Number', text_fr: 'Numéro du compte' },
}


