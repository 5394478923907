import { RefMessage } from '../../../framework/infra'
import { ParticipationMessageConfig } from './ParticipationMessageConfig'

export default class ParticipationMessage extends RefMessage {
    static messages = [
        ['noDob', 'Member date of birth is missing - Confirm Date of Birth', 'Member missing date of birth', 'w'],
        ['dob', 'temp', 'temp', 'w'],
        ['trf', 'Transfer - Missing hire date in ER2', 'Inconsistent transfer', 'w'],
        ['dfr', 'Member is deferred. Pension documents requested', 'Member is deferred', 't'],
    ]

    static validationMessage() {
        return Object.values(this.messages).filter(mess => ParticipationMessageConfig[mess.key])
    }
}
