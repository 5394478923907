import { RefEnum } from '../../framework/infra'
import { remittanceStatusConfig } from './RemittanceStatusConfig'

export default class RemittanceStatus extends RefEnum {
    
    isStarted() { return this.key === 's' }
    isNew() { return this.key === 'n' }
    isValidated() { return this.key === 'v' }
    hasErrors() { return this.key === 'e' }

    get config() { return { ...remittanceStatusConfig[this.key] }}

    static definitions = {
        config: {abstract: true},
    }

    static types = {
        n: new RemittanceStatus('n', 'New'),
        s: new RemittanceStatus('s', 'Started/Open'),
        e: new RemittanceStatus('e', 'Contains Errors'),
        m: new RemittanceStatus('m', 'Submitted'),
        v: new RemittanceStatus('v', 'Validated'),
    }
    static default = RemittanceStatus.types.n
    static NEW = RemittanceStatus.types.n //TODO make automatic - use diferent types.keys and the object key
    static STARTED = RemittanceStatus.types.s
    static ERROR = RemittanceStatus.types.e;
}
