import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class RefundAccountType extends RefEnum {
    constructor(key, defaultDesc, lockedIn) {
        super(key, defaultDesc)
        this.lockedIn = lockedIn
    }

    static types = deepFreeze({
        CHK: new RefundAccountType('CHK', 'Checking', false),
        SAV: new RefundAccountType('SAV', 'Saving', false),
        RRSP: new RefundAccountType('RRSP', 'RRSP', false),
        LIRA: new RefundAccountType('LIRA', 'LIRA', true),
        LIRRSP: new RefundAccountType('LIRRSP', 'LI-RRSP', true),
        LIF: new RefundAccountType('LIF', 'LIF', true),
    })
    
    static definitions = {
        desc: { type: Definition.types.STRING, text: 'Account Type' },
        lockedIn: { type: Definition.types.BOOLEAN, text: 'Locked-In', text_fr: '' }
    }
}