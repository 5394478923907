import React from "react";
import Employer from "../../entities/employment/Employer";
import CustomDropdown from "../../components/form/Dropdown";
import { Excel } from "../../framework/utils";

const ExportEmployers = ({
    employers
}) => {
    const employersOptions = [
        { text: "All", value: "all" },
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
    ];

    const handleExport = (statusToExport) => {
        const employersToExport =
            statusToExport !== "all"
                ? employers.all.filter((employer) =>
                      statusToExport === "active" ? employer.act : !employer.act
                  )
                : employers.all;

        const sortedEmployers = employersToExport.sort((a, b) => {
            let sort = 0;
            if (a.code < b.code) {
                sort = -1;
            } else if (a.code > b.code) {
                sort = 1;
            } 
            return sort;
        })

        const excelHeaders = new Excel.Headers(
            Employer,
            "code, name, plan.jurisdiction, act"
        );
        const excel = new Excel(`Employers-${statusToExport}`);
        excel.addSheet(excelHeaders.list, sortedEmployers);
        excel.download();
    }

    return (
        <CustomDropdown
            buttonText="Export employers"
            options={employersOptions}
            onSelect={handleExport}
        />
    );
};

export default ExportEmployers;
