import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Row, Title } from "../../containers";
import { toDBDate } from "../../utils/helper";

const YesNoQuestion = ({instance, onSave, onYes, onNo, onClose, message, isWarning, datePicker}) => { 
    const [comment, setComment] = useState('');
    const [date, setDate] = useState(toDBDate());

    const handleConfirmEvent = async (confirmed) => {
      if(confirmed){
        if(onYes) await onYes(instance, comment, date)
        onSave(instance);

      }else{
        if(onNo){ 
          await onNo(instance, comment);
          onSave(instance);
        }
        else if(onClose) onClose();
      }
      
    }
   
    return <><Title title={message} onHide={onClose} isWarning={isWarning}/>
        <div className='input-spacing-2'>
            {datePicker && (
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type='date' value={date} onChange={(ev) => setDate(ev.target.value)}></Form.Control>
              </Form.Group>
            )}
            <Form.Group >
              <Form.Label>Comment</Form.Label>
              <Form.Control value={comment} onChange={(newValue) => setComment(newValue.target.value)}/>
            </Form.Group>
        </div>
        <Row className='mt-4'>
            <div className='col'/>
            <Button key="Yes" onClick={() => handleConfirmEvent(true)}>Yes</Button> 
            <Button key="No" onClick={() => handleConfirmEvent(false)}>No</Button>     
        </Row> 

    </>
 }

 export default YesNoQuestion;