import React from "react"
import { Button } from "react-bootstrap"
import TaskBase from "./TaskBase"

const TaskItemCalendar = (props) => {
    const { event, onSave, validated = false, saveLabel = 'Save'} = props;

    const handleSave = () => {
        if (onSave) onSave(event);
    }

    return <>
            <TaskBase {...props}>
                <Button onClick={handleSave} className={(validated ? 'btn-primary' : 'btn-secondary')}>{saveLabel}</Button>
            </TaskBase>
        </> 
}

export default TaskItemCalendar;

