import { Definition,  Ref } from '../../framework/infra'

export default class ConfigBank extends Ref {
    static key = ["id"];
}

const { REF } = Definition.types
ConfigBank.definitions = {
    id: {},
    cheque: { ref: require('./Bank/ConfigBankCheque'), text: 'Cheque', text_fr: 'Cheque' },
    eft: { ref: require('./Bank/ConfigBankEft'), text: 'EFT', text_fr: 'EFT' },
    wire: { ref: require('./Bank/ConfigBankWire'), text: 'Wire', text_fr: 'Wire' },
}
