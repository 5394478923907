import { RefList, Definition } from '../../../framework/infra'
import { sum, round } from '../../../framework/utils/helper'

import RefundBreakdown from './RefundBreakdown'

export default class RefundBreakdowns extends RefList {
    get liTotal() { return round(sum(this.filter(refund => refund.type.lockedIn), 'total')) }
    
    assignElapsedDays(elapsedDays) { this._list.forEach(refund => refund.elapsedDays = elapsedDays) }
   
    static definitions = {
        elapsedDays: { transient: true, type: Definition.types.NUMBER, text: 'Elapsed Days', inherited: true },
        
        liTotal: { abstract: true, type: Definition.types.AMOUNT, text: 'Locked-In Total' },
        overITA: { abstract: true, type: Definition.types.AMOUNT, text: 'Over ITA' }
    }

    static ref = RefundBreakdown
}

