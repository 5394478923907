import React, { useEffect, useState } from 'react'
import { Ref, Definition } from '../../../framework/infra'
import { Icon, Table } from '../../../framework/controls'

import { RemittanceDistribution, Payment } from '../../../entities'
import { AdjustmentService, RemittanceService } from '../../../services'
import useUser from '../../../hooks/useUser'
import Loading from '../../../components/containers/Loading'
import { renderComponent } from '../../../framework/utils/renders'
import BalanceForm from './BalanceForm'
import { Modal } from '../../../framework/containers'
import { RemittanceBusiness } from '../../../business'


const PaymentDistributions = ({employer}) => {

    const [remittances, setRemittances] = useState();
    const [selectedDist, setSelectedDist] = useState();

    const user = useUser();

    const fetch = async () => {
        RemittanceService.invalidateCache();
        const remittances = await RemittanceService.getEmployerRemittances(employer.id);
        const adjustments = await AdjustmentService.getAdjustmentsForEmployer(employer.id);
        remittances.setAdjustmentsOnRemittanceList(adjustments);
       const remittancesRefreshed = RemittanceBusiness.refreshBalances(remittances)
        setRemittances(remittancesRefreshed);
    }

    useEffect(() => fetch(), []);

    if(!remittances || !user) return <Loading/>

    const headers = Report.getHeaders()

    const actionIcon = (value, instance) => {
        if (!instance._children) return;

        const isLocked = instance.balances.isLocked;
        const icon = isLocked ? 'lock' : 'unlock-alt';
        const tooltip = isLocked ? 'Distribution locked' : 'Dynamically Distributed';
        return renderComponent(<Icon 
            tooltip={tooltip} 
            icon={icon} 
            tooltip-left 
            large 
        />);
    }

    const handleSave = async () => { 
        setRemittances();
        await fetch();
    }
    const handleClose = () => setSelectedDist();

    const optionsSelected = async (e, cell) => {
        e.preventDefault();
        e.stopPropagation();
        const cellRemittance = cell.getRow().getData();
        setSelectedDist(<Modal className='w-60 modal-bg-color'>
            <BalanceForm balances={cellRemittance.balances.clone()} employer={employer} onSave={handleSave} onClose={handleClose} period={cellRemittance.period}/> 
        </Modal>)
        console.log(cellRemittance);
    }

    headers.actions = {
        width: 60,
        align: "center",
        cellClick: optionsSelected,
        format: actionIcon,
        headerSort: false,
    };

    const tableProps = { rowFormatter: (row) => {
        if (row.getData().type === 'lat') row.getElement().className += ' late'
        else if (row.getData().type === 'fin') row.getElement().className += ' balance'
    }}

    var data = Report.getTreeReport(remittances);
    tableProps.dataTree = true;
    tableProps.dataTreeStartExpanded = false;

    return <>
        <Table id="rem-pay_dtr" data={data} columns={headers} {...tableProps}/>
        {selectedDist}
    </>
}
                      
export default PaymentDistributions;

const { STRING, PERIOD, AMOUNT, CHOICE, NUMBER } = Definition.types
class Report extends Ref {

    static getTreeReport(remittances) {
        const rows = []
        remittances.reduce((prev, rem) => { 
            const start = prev ? prev.clone() : RemittanceDistribution.create({er: rem.prevBalance})
            const current = RemittanceDistribution.create({ ee: rem.eeAdjustedContribs, er: rem.erAdjustedContribs + rem.intAdjusted - rem.creditUsed, vol: rem.volAdjustedContribs, sol: rem.solAdjusted, int: 0}).setBalance(start)
            const end = RemittanceDistribution.create().setBalance(current)
            
            const row = new Report( {treeVal: rem.period.longDesc, period: rem.period, type: 'fin', remBalance: rem.balance} )
            rows.push(row)
            row._children = [new Report( {treeVal: 'Remittance Contributions', period: rem.period, type: 'due', balances: current} )]
            
            remittances.filter(r => r.period.isSameOrBefore(rem.period)).forEach(r => r.payments.nonRejectedPayments.forEach(pmt => {
                if (rem.period.isSame(pmt.period)) {
                    const pmtRemDistr = pmt.distribution ? RemittanceDistribution.createFromAccDistr( pmt.distribution.getDistribution(rem.period), end) : undefined
                    if (!pmtRemDistr?.isEmpty()) {
                        row._children.push(new Report( {treeVal: 'Payment [ ' + pmt.no + ' ]', period: rem.period, type: 'pay', balances: pmtRemDistr, payment: pmt} ))
                        end.setBalance(pmtRemDistr)
                    }
                }
            }))
            row._children.push(new Report( {treeVal: 'Balance at Due-Date', period: rem.period, type: 'bal', balances: end, cmt: end.descBalance}))

            const final = RemittanceDistribution.create().setBalance(end)

            if(rem.lockedBalance.isLocked) {
                final.isLocked = true;
                final.cmt = rem.lockedBalance.cmt;
                final.setBalance(rem.lockedBalance);
            }

            row.balances = final;
            row._children.reverse()
            return final
        }, null)
        return rows.reverse()
    }

    static getHeaders(onClick, options) {
        const fields = ['treeVal', 'balances.ee', 'balances.vol', 'balances.sol', 'balances.er', 'balances.int', 'balances.total', 'balances.eeBal', 'balances.volBal', 'balances.solBal', 'balances.erBal', 'balances.intBal', 'balances.balance', 'remBalance', 'cmt']
        const headers = new Table.Headers(Report, fields, options || {width: 110, headerSort: false, hideIfEmpty: false})
        headers['cmt'].width = null
        headers['cmt'].hideIfEmpty = false
        headers['balances.total'].className = 'table-col-separator'
        headers['balances.total'].hideIfEmpty = false
        headers['balances.balance'].hideIfEmpty = false
        headers['remBalance'].hideIfEmpty = false
        headers['treeVal'].width = 240
        headers['treeVal'].headerSort = true
        headers['treeVal'].sorter = (a, b) => (!a || !a.order || (b && b.order && a.order <= b.order)) ? -1 : 1
       
        return headers
    }

    static definitions = {
        treeVal: { type: STRING, text: 'Period' },
        period: { type: PERIOD, text: 'Period' },
        payment: { ref: Payment },
        balances: { ref: RemittanceDistribution },
        type: { type: CHOICE, text: 'TXN Type', options: [
            { key: 'year', text: 'year' },
            { key: 'str', text: 'Start Balance'},
            { key: 'due', text: 'Due' },
            { key: 'pay', text: 'Payment'},
            { key: 'bal', text: 'Due-Date Bal'},
            { key: 'lat', text: 'Late Payment'},
            { key: 'fin', text: 'Balance'},
        ]},
        cmt: { type: STRING, text: 'Comment' },
        remBalance: { type: AMOUNT, text: 'Remittance Balance' },

        order: { transient: true, type: NUMBER},
        month: { abstract: true, type: STRING, text: 'Month' },
        year: { abstract: true, type: STRING, text: 'Year' },
    }
    
    get month() { return this.period && this.period.value }
    get year() { return this.period && this.period.year }
    
}