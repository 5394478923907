import { Definition, Ref } from '../../framework/infra'
import Person from '../person/Person'

export default class CompanyContact extends Ref{
    isAdministrator() {return this.adm === 'yes'}

    getWorkEmail() { return this.person?.emails?.find(email => email.isWorkEmail?.())}
    getWorkPhone() { return this.person?.phones?.find(phone => phone.isWorkPhone?.())}
}

const { STRING, CHOICE } = Definition.types
CompanyContact.definitions = {
    person: { ref: Person },
    cmt: { type: STRING, text: "Comment" },
    adm: { type: CHOICE, text: 'Administrator', text_fr: 'Administrator', options: [
        { key: '', text: '', text_fr: ''},
        { key: 'yes', text: 'Yes', text_fr: 'Oui'},
        { key: 'cc', text: 'CC', text_fr: 'CC'},
    ]},
    trr: { type: CHOICE, text: 'Treasurer', text_fr: 'treasurer', options: [
        { key: '', text: '', text_fr: ''},
        { key: 'yes', text: 'Yes', text_fr: 'Oui'},
        { key: 'cc', text: 'CC', text_fr: 'CC'},
    ]},
    cme: { type: CHOICE,text: 'Cmte Mbr', options: [
        { key: '', text: '', text_fr: ''},
        { key: 'yes', text: 'Yes', text_fr: 'Oui'},
        { key: 'cc', text: 'CC', text_fr: 'CC'},
    ]},
    pos: { type: STRING, text: 'Position', suggestions: [
        { key: 'CEO', text: 'CEO', text_fr: 'Oui'},
        { key: 'CFO', text: 'CFO', text_fr: 'CC'},
        { key: 'Chief', text: 'Chief', text_fr: ''},
        { key: 'Consultant', text: 'Consultant', text_fr: 'Oui'},
        { key: 'Controller', text: 'Controller', text_fr: 'CC'},
        { key: 'COO', text: 'COO', text_fr: ''},
        { key: 'Corporate Secretary', text: 'Corporate Secretary', text_fr: 'Oui'},
        { key: 'Director General', text: 'Director General', text_fr: 'CC'},
        { key: 'HR', text: 'HR', text_fr: 'CC'},
        { key: 'HR Director', text: 'HR Director', text_fr: ''},
        { key: 'Payroll', text: 'Payroll', text_fr: 'Oui'},
        { key: 'Treasurer', text: 'Treasurer', text_fr: 'CC'},
    ] }
}
