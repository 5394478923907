import { useAPIError } from "../hooks/useAPIError";
import { 
    EmploymentService, 
    ParticipationService, 
    PersonService,
    MembershipService,
    EmployerService,
    RemittanceDetailService,
    RemittanceService,
    AdjustmentService,
    BeneficiaryService,
    SpouseService,
} from "../services";

//Invalidates cache on all relevant services
const CacheGuard = ({ children}) => {

    console.debug('[CacheGuard] Invalidating caches...');
    const deleteAPIError = useAPIError((state) => state.deleteError);
    
    PersonService.invalidateCache();
    EmploymentService.invalidateCache();
    MembershipService.invalidateCache();
    ParticipationService.invalidateCache();
    RemittanceDetailService.invalidateCache();
    RemittanceService.invalidateCache();
    AdjustmentService.invalidateCache();
    BeneficiaryService.invalidateCache();
    SpouseService.invalidateCache();
    deleteAPIError();

    return <>{children}</>;
}
export default CacheGuard;