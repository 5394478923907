import React from 'react'
import { Button } from 'react-bootstrap'

export default class MyButton extends React.Component {
    render() {
        const { className, variant, onClick, text, type, disabled, startIcon } = this.props;
        if (variant) {
            return (
                <Button variant='link' className={className} onClick={onClick.bind(this)} disabled={disabled}>
                    <i className={"fas fa-" + variant + "-circle"}/>     
                </Button>   
            )   
        } 
        return (
            <Button variant={type} className={className} disabled={disabled} onClick={onClick} onMouseLeave={this.props.onMouseLeave} >{startIcon ? <><i className={"fas fa-" + startIcon}/>{" "}</> : null}{text || this.props.children}</Button>   
        )
    }
}