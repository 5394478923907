import React from "react";
import { Tabs } from "../../framework/containers";
import EmployerChecklist from "./details/EmployerChecklist";
import EmployerInfo from "./details/EmployerInfo";

const EmployerDetails = ({ employer }) => {

    return (
        <Tabs initial="details" className="h-100">
            <Tabs.Tab name="details" title="Details">
                <EmployerInfo employer={employer} />
            </Tabs.Tab>
            <Tabs.Tab name="checklist" title="Adhesion">
                <EmployerChecklist employer={employer} />
            </Tabs.Tab>
        </Tabs>
    );
};

export default EmployerDetails;
