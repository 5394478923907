import React from 'react'
import { PageTitle, Component } from '../../framework/components'

export default class Refresh extends Component {
    load(){
        const query = new URLSearchParams(this.props.location.search);
        const id = query.get('id')
        
        setTimeout(
            function() {
                this.props.history.push(id);
            }
            .bind(this),
            1000
        )
    }
    view() { 
        
        return <>
            <div className='row'>
                <div className='col align-self-center'>
                    <PageTitle>Hold Please</PageTitle>
                </div>
            </div>

            <div className='row'>
                We are completing the transaction
            </div>
        </>
    }
}