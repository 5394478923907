import React from 'react'
import { Component, EInput } from "../../framework/components"
import { Row, Col, FieldSet } from "../../framework/containers"
import { Button } from "../../framework/controls"
import { Check } from '../../framework/inputs'
import { Excel } from '../../framework/utils'

import { RefundAccount, RefundBreakdown, RefundDistribution } from '../../entities/pension'
import { Payout } from '../../entities/membership/participation'
import RefundAccountList from './RefundAccountList'
import RefundsList from './RefundsList'
import RefundDistributionList from './RefundDistributionList'

export default class PayoutDetails extends Component {
   
    view() {
        const { participation, readOnly } = this.props
        
        return <>
            <FieldSet className='input-spacing-2'>
                <Row className='align-items-end'>
                    <EInput name='issueDate' instance={participation.payout} readOnly={readOnly}/>
                    <EInput name='calculationDate' instance={participation.payout} readOnly={readOnly}/>
                    <EInput name='expireDate' instance={participation.payout} readOnly={readOnly}/>
                    <EInput name='maxLIPortion' instance={participation.payout} readOnly={readOnly}/>
                    <Check name='lockedIn' value={participation.payout.lockedIn} label='Locked-In' lcn='checkbox-inline' onChange={this.handleLIChange.bind(this)} readOnly={readOnly}/>
                    <Col/>
                    <Button key='export' type="link" onClick={this.handleExport.bind(this, participation)}>Export</Button>
                    {!readOnly && <Button key='calculate' className='btn btn-secondary' onClick={this.handleCalculate.bind(this, participation)}>Distribute</Button>}
                </Row>
            </FieldSet>
            <RefundsList participation={participation} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
            <RefundAccountList membership={participation.membership} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
            <RefundDistributionList payout={participation.payout} refundAccounts={participation.membership.refundAccounts} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
        </>
    }
    
    handleCalculate(data) {
        this.props.participation.payout.distribute(this.props.membership.refundAccounts)
        this.props.participation.payout.distribution.forEach(dist => dist.ts = Date.now()) //HACK to refresh the RefundDistributionList
        this.setState({isDirty: true})
    }
    
    handleChange() { if(this.props.onChange) this.props.onChange() }
   
    handleLIChange(val) { 
        this.props.participation.payout.lockedIn = val
        this.setState({isDirty: true}) 
    }
    handleExport() {
        const {participation, membership} = this.props
        const payout = participation.payout
        
        const excel = new Excel('Payout')
        const headers = new Excel.Headers(Payout, 'issueDate, calculationDate, expireDate, maxLIPortion, lockedIn' )
        const refundHeaders = new Excel.Headers(RefundBreakdown, 'type.desc, principleAmount, interestRate, total')
        const accountHeaders = new Excel.Headers(RefundAccount, 'name, address.desc, transit, eft, number, accountType.desc')
        const distributionHeaders = new Excel.Headers(RefundDistribution, 'type.desc, amount, account.searchDesc, account.address.desc')
        excel.addSheet(headers, [payout], 'Dates')
        excel.addSheet(refundHeaders, payout.breakdown, 'Refunds')
        excel.addSheet(distributionHeaders, payout.distribution, 'Distribution')
        excel.addSheet(accountHeaders, membership.refundAccounts, 'Bank Account')
     
        return excel.download()
    }
}
