export const adjustmentValidationConfig = {
    expected: {
        title: "Expected",
        subtitle: "(Amounts that should be in app)",
        earnings: (amounts) => amounts.expected?.total?.earnings ?? 0,
        hours: (amounts) => amounts.expected?.total?.hours ?? 0,
        contributions: (amounts) => amounts.expected?.total?.contributions ?? 0,
    },
    actual: {
        title: "Actual",
        subtitle: "(Currently in app)",
        earnings: (amounts) => amounts.actual?.earnings ?? 0,
        hours: (amounts) => amounts.actual?.hours ?? 0,
        contributions: (amounts) => amounts.actual?.contributions ?? 0,
    },
    difference: {
        title: "Difference",
        subtitle: "(Difference between actual and expected)",
        earnings: (amounts) => amounts.difference?.earnings?.deemed ?? 0,
        hours: (amounts) => amounts.difference?.earnings?.deemedHours ?? 0,
        contributions: (amounts) => amounts.difference?.contributions?.deemed ?? 0,
    },
}

export const adjustmentValidationTabsConfig = {
    earnings: {
        key: "EARN",
        title: "Earnings/Hours Adjustment",
    },
    contributions: {
        key: "CONT",
        title: "Contributions Adjustment"
    }
}