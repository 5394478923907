import { Business } from "../framework/infra"

export default class MembershipBusiness extends Business {
    //TBD
    // static validateSpouseInfo(membership) {
    //     if (!task) {
    //         if (membership.person?.missingSpouseInfo()) return membership.tasks.pushNew({code: 'missingSpouseInfo', params: [{key: 'maritalSts', value: membership.person.maritalStatus}]})
    //     } else {
    //         if (!membership.person?.missingSpouseInfo()) return membership.tasks.remove(message => message.code === 'missingSpouseInfo') //NOTE: The flow is not defined yet, so we remove the message for now until possible events are defined
    //     }
    // }
    // static validateBeneficiaries(membership) {

    //     const task = membership.tasks.find(message => message.code === 'submitBdf')
    //     if (!task) {
    //         if (membership.hasNoLegacyBeneficiary() || membership.person?.isMaritalStsEmpty()) return membership.tasks.pushNew({code: 'submitBdf'})
    //     } else {
    //         if (!membership.hasNoLegacyBeneficiary() || !membership.hasNoBeneficiary() || !membership.person?.isMaritalStsEmpty()) { membership.tasks.remove(mess => mess.code === 'submitBdf')} //NOTE: The flow is not defined yet, so we remove the message for now until possible events are defined
    //     }
    // }
    static validate(membership) {
        //TBD
        // membership.tasks.reset();
        // this.validateSpouseInfo(membership)
        // this.validateBeneficiaries(membership)
        return membership
    }

    //This is to be done because of awkward circular nesting structure with employment -> pp -> employments -> pp
    static reassignNestedParticipations(membership) {
        membership.participations.forEach(participation => {
            participation.employments.forEach(employment => {
                employment.participation = membership.participations[employment.participation.keyValue];
            })
        });

        return membership;
    }

}
    