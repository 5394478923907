import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { Page, Text, Separator, getText } from '../../../framework/utils/pdf' 
import { sum, round } from '../../../framework/utils/helper';
import { texts } from './textPDF/textPDFAddendum';
import PDFHeader from './PDFHeader';
import PDFAdjustment from './PDFAdjustment';
import { AdjustmentService } from "../../../services";

const PDFAddendum = ({ config, invoice, remittance, statementNo, employerAdjustments }) => {
    const missingCredits = AdjustmentService.getMissingStartCreditsForAllTypes(remittance.period, employerAdjustments, false);
    const missingReversingCredits = AdjustmentService.getMissingReversingCreditsForAllTypes(remittance.period, employerAdjustments);
    const missingReversingUsedCredits =  AdjustmentService.getMissingReversingUsedCreditsForAllTypes(remittance.period, employerAdjustments);
    const adjustments = [...remittance.adjustments.all, ...missingCredits, ...missingReversingCredits]
        .filter((adj, index, list) => 
        {
            // reversing credit: keep only if same period
            if(adj.isCancelCredit) {
                return adj.period.isSame(remittance.period);
            }
            // start credit: keep only if never used (and not completely reversed in a previous period), and if not reversed in this period
            if(adj.type.config.isStartCredit) {
                const isReversedInThisPeriod = list.find(a => a.isCancelCredit && a.period.isSame(remittance.period) && a.type.key === adj.type.key);
                const neverUsed = !list.find(a => a.type.key === `${adj.type.key}R`);
                const completelyReversed = adj.total !== 0 && (Math.abs(round(adj.leftOverCredit)) - Math.abs(round(sum(missingReversingCredits.filter(a => a.type.key === adj.type.key), 'total'))) === 0);
                return (neverUsed && !completelyReversed) && !isReversedInThisPeriod;
            }
            // other credits (credits used): keep if it's not a prev used credit of a credit that has been completely reversed
            if(adj.type.config.isCredit) {
                const startCreditKey = `${adj.type.key}`.slice(0, -1);
                /** adjs in this period that are reversing credits or reversing used credits */
                const reversingCreditsSamePeriod = remittance.adjustments.all.filter(a => (a.type.key === startCreditKey && a.isCancelCredit) || (a.type.key === `${startCreditKey}C` && a.type.config.isCancelUsedCredit));
                /** missing reversing credits (MECH/RCR adjs with positive amounts, from previous periods) */
                const reversingStartCredits = missingReversingCredits.filter(a => a.type.key === startCreditKey && a.isCancelCredit);
                /** adjs in previous periods that are reversing used credits */
                const reversingUsedCreditsBefore = missingReversingUsedCredits.filter(a => a.type.key === `${startCreditKey}C` && a.type.config.isCancelUsedCredit);
                const reversingCredits = [...reversingStartCredits, ...reversingCreditsSamePeriod, ...reversingUsedCreditsBefore];
                const reversingCreditsSum = sum(reversingCredits, 'total');
                const completelyReversed = adj.total !== 0 && reversingCredits.length > 0 && round(adj.leftOverCredit) === -(round(reversingCreditsSum));
                return !(adj.period.isBefore(remittance.period) && completelyReversed);
            }
            // others: keep
            else {
                return true;
            }
        }
        )
        // sort by credit, and then by reversing credit
        // TODO FIXME
        .sort((a,b) => {
            return (Boolean(a.isCancelCredit) === Boolean(b.isCancelCredit))? 0 : Boolean(a.isCancelCredit)? 1 : -1;
        })
        .sort((a,b) => {
            const isCreditA = a.type.config.isCredit && !a.isCancelCredit;
            const isMECHB = b.type.config.isMandatoryCredit && !b.isCancelCredit;
            return isCreditA ? (isMECHB ? 1 : -1) : 1;
        })
        ;
    const remittancePeriod = remittance.period.yearEnd === true 
        ? getText(texts['year-end-adjs'], '', {year: remittance.period.value}) 
        : remittance.period.desc;
    const addendumTitle = invoice 
        ? getText(texts['addendum-invoice-title'], '', {remittancePeriod}) 
        : getText(texts['addendum-statement-title'], '', {statementNo});
    const styles = StyleSheet.create({
        header: { width: '100%', textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '13', marginTop: '30'},
        subtitle: {textAlign: 'center', fontFamily: 'Roboto-Bold', fontSize: '11', marginTop: '13', marginBottom: '13'},
        label: { fontSize: '11', fontFamily: 'Roboto-Bold' }
    })

    return (
        <Page>
            <PDFHeader config={config} />  
            <Separator className='mt-2'/>
            <Text style={styles.header}>{addendumTitle}</Text>
            <Text text={texts['addendum-subtitle']} style={styles.subtitle} />
            {adjustments
                .filter(adj => adj.category === "CONT")
                .map(adjustment => <PDFAdjustment adjustment={adjustment} employerAdjustments={employerAdjustments} />)
            }
         </Page>
    );
}

export default PDFAddendum;

