import { useState, useEffect } from 'react'
import { Route, Switch, withRouter } from "react-router-dom"
import { SignIn, NewPasswordRequired, ResetPasswordRequired, RequestSecurityCode, ChangePassword } from './framework/infra/login'
import { Auth } from './framework'
import { Lng } from './framework/infra'
import routes from './routes'
import DashboardLayout from './layouts/DashboardLayout'
import CacheGuard from './guards/CacheGuard'
import { useEmployerStore } from './hooks/useEmployerStore'
import ErrorBoundary from './components/containers/ErrorBoundary'

const App = () => {
    const storeEmployers = useEmployerStore((state) => state.storeEmployers);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [context, setContext] = useState();
    const [isReady, setIsReady] = useState(false);
    const [loginStatus, setLoginStatus] = useState();
    const [userName, setUsername] = useState();

    const handleLoginStatusChanged = (status, userName) => {
        setLoginStatus(status);
        setUsername(userName);
    }

    useEffect(() => {
        let isMounted = true;

        storeEmployers(); //stores all employers by code in zustand store
        Auth.getCurrentUser().then(user => {
            const resources = user.attributes['custom:resources'] ? user.attributes['custom:resources'].split(';') : []
            const actions = user.attributes['custom:actions'] ? user.attributes['custom:actions'].split(';') : []
            const context = {
                userAction: actions,
                userResources: resources,
                isAdmin: user.attributes['custom:role'] === 'ADMIN',
                isSuperAdmin: user.attributes['custom:super'] === 'true',
                lng: Lng.current
            }

            if (isMounted) {
                setIsAuthenticated(true);
                setIsReady(true);
                setContext(context);
            }
        }).catch(err => {
            if (isMounted) {
                setIsAuthenticated(false);
                setIsReady(true);
            }
        })

        return () => { isMounted = false };
    }, [])

    if (isReady) {
        if(isAuthenticated) {
            return (
                <DashboardLayout
                    context={context}
                    onContextChanged={setContext}
                >
                    <Switch>
                        {Object.values(routes.pages).map((route) => (
                            <Route
                                key={route.key}
                                path={route.path}
                                render={(props) => (
                                    <CacheGuard>
                                        <ErrorBoundary>
                                            <Route
                                                exact
                                                path={`${props.match.path}`}
                                                render={() => (
                                                    <route.Page
                                                        context={context}
                                                        onContextChanged={
                                                            setContext
                                                        }
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            {route.children &&
                                            route.children.map((subRoute) => (
                                                        <Route
                                                            key={subRoute.key}
                                                            path={`${props.match.path}${subRoute.path}`}
                                                            render={() => (
                                                                <subRoute.Page
                                                            context={context}
                                                                    onContextChanged={
                                                                        setContext
                                                                    }
                                                                    {...props}
                                                                />
                                                            )}
                                                        />
                                                    )
                                                )}
                                        </ErrorBoundary>
                                    </CacheGuard>
                                )}
                            />
                        ))}
                    </Switch>
                </DashboardLayout>
            );
        } else {
            if (loginStatus === 'newPasswordRequired') { 
                return <NewPasswordRequired userName={userName} onStatusChanged={handleLoginStatusChanged} />
            } else if (loginStatus === 'resetPasswordRequired') { 
                return <ResetPasswordRequired userName={userName} onStatusChanged={handleLoginStatusChanged} />
            } else if (loginStatus === 'requestSecurityCode') { 
                return <RequestSecurityCode userName={userName} onStatusChanged={handleLoginStatusChanged} />
            } else if (loginStatus === 'changePassword') { 
                return <ChangePassword userName={userName} onStatusChanged={handleLoginStatusChanged} />
            } else {
                return <SignIn onStatusChanged={handleLoginStatusChanged} />
            }
        }
    }
    
    return <div></div>

}
export default withRouter(App)