import _ from 'lodash'

import ActionRevertClose from './ActionsConfig/ActionRevertClose'
import HistoryAction from './HistoryAction';

/**
 * Class to manage the available actions for a participation
 * @class
 */
export default class HistoryActions  {
    
    /**
     * Define all available actions
     * @returns {Array<HistoryAction>} - list of all available actions
     */
    static _actions() {
        return [
            new HistoryAction(ActionRevertClose)
        ]
    }

    /**
     * Get all actions that are possible for the participation
     * @param {Participation} participation
     * @returns {Array<HistoryAction>}
     */
    static getActions(participation) {
        if (!participation) return [];
        return this._actions().filter(action => {
            try {
                return action.condition(participation);
            } catch (e) {
                console.error('Error checking action condition', e)
                return false;
            }
        })
    }
}



