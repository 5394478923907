export const newEmployerChecklistConfig = {
    default: {},
    determineRegistration: {
        group: "prior",
    },
    emailABC: {
        group: "prior",
    },

    appSubmitted: {
        group: "received",
    },
    emailToWC: {
        group: "received",
    },
    deadlineToWC: {
        group: "received",
    },
    appApproved: {
        group: "received",
    },

    acceptedWC: {
        group: "accepted",
    },
    acceptedMercer: {
        group: "accepted",
    },
    acceptedER: {
        group: "accepted",
    },
    emailERInstructions: {
        group: "accepted",
        secondCheckboxes: [
            "emailER1",
            "emailER2",
            "emailER3",
            "emailER4",
            "emailER5",
            "emailER6",
        ],
    },
    emailER1: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },
    emailER2: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },
    emailER3: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },
    emailER4: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },
    emailER5: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },
    emailER6: {
        group: "accepted",
        isSecondCheckBox: true,
        hideDate: true,
    },

    isRequirementsMet: {
        group: "requirements",
    },
    adhesionForm: {
        group: "requirements",
        isSecondCheckBox: true,
    },
    erSubmitted: {
        group: "requirements",
        isSecondCheckBox: true,
    },
    enrolmentForms: {
        group: "requirements",
        isSecondCheckBox: true,
    },
    rcSubmitted: {
        group: "requirements",
        isSecondCheckBox: true,
    },

    signature: {
        group: "finalize",
    },
    amendmentSignature: {
        group: "finalize",
    },
    amendmentFiled: {
        group: "finalize",
    },
};
