import React from 'react'
import { EInput } from '../../../framework/components'
import { Form } from '../../../framework/controls'
import { Card } from '../../../framework/containers'

const ConfigCorporateForm = ({ config, onCancel, onSave }) => {
    const { address } = config;
    const colsEmail = [
        { name: "email", className: "col-6" },
        { name: "usage" },
    ];
    const colsPhone = [
        { name: "number", className: "col-6" },
        { name: "usage" },
    ];

    const handleSave = (config, original) => {
        if (onSave) onSave('corporate', config, original);
    }

    const handleCancel = () => {
        if (onCancel) onCancel('corporate');
    }

    return (
        <Form
            data={config}
            onSave={handleSave}
            onCancel={handleCancel}
            className="w-100 bg-color"
        >
            <Card className="px-3 py-3 my-3">
                <h3>Info</h3>
                <EInput name="name" instance={config} cn="col-12" />
                <hr />
                <h3>Address</h3>
                <div className="d-flex flex-wrap mt-2">
                    <EInput name="civic" instance={address} cn="col-4 mr-4" />
                    <EInput name="city" instance={address} cn="col-4 mr-4" />
                    <EInput name="prov" instance={address} cn="col-3 mr-4" />
                </div>
                <div className="d-flex flex-wrap mt-2">
                    <EInput name="poBox" instance={address} cn="col-4 mr-4" />
                    <EInput name="country" instance={address} cn="col-4 mr-4" />
                    <EInput name="pc" instance={address} cn="col-3" />
                </div>
            </Card>
            <Card className="px-3 py-3 my-3">
                <h3>Emails</h3>
                <EInput
                    name="emails"
                    instance={config}
                    columns={colsEmail}
                    className="h-auto"
                />
            </Card>
            <Card className="px-3 py-3 my-3">
                <h3>Phones</h3>
                <EInput
                    name="phones"
                    instance={config}
                    columns={colsPhone}
                    className="h-auto"
                />
            </Card>
        </Form>
    );
};

export default ConfigCorporateForm;