import React from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { Page, Document, Container, Image, Text, Row, Col, Separator, AmountDetail } from '../../../framework/utils/pdf' 
import { Period } from '../../../framework/utils'
import moment from 'moment'

import { Solvency } from '../../../entities'
import { AdjustmentService } from '../../../services'

export default class RetroContribAdjustmentPdf extends React.Component {
    render() {
        const { employer } = this.props
        const solvEffPeriod = Period.create('202106')
        const contrEffPeriod = Period.create('202107')
        const retroPeriod = Period.create('202101')
        const currSolvency = employer.solvencies.getSolvencyAtPeriod(solvEffPeriod) || new Solvency()
        const prevSolvency = employer.solvencies.getSolvencyAtPeriod(solvEffPeriod.dec()) || new Solvency()
        const lastClosed = employer.remittances.lastClosed
        const rcr = AdjustmentService.calculateRCR(employer, contrEffPeriod, retroPeriod, 1.76, 0.047)
        const lastClosedMonth = lastClosed ? lastClosed.period.longDesc : ''
        const lastClosedMonthLong = lastClosed ? lastClosed.period.monthLong : ''
        
        const sumSolvPaid = prevSolvency.amount * 5
        const sumSolvReq = currSolvency.amount * 5
        const diffSolv = sumSolvReq - sumSolvPaid
        const solvPlusContribs = diffSolv + rcr.diff
        const tot = solvPlusContribs + rcr.interest
        const totalIsNegative = tot < 0 ? true : false
        const solvencyIsSame = currSolvency.amount === prevSolvency.amount ? true : false

        const styles =StyleSheet.create({
			signature: { width: '60', height: '30' },
            sideHeaderLogo: { width: '100', height: '400' },
            planLogo: { width: '80', height: '80', paddingRight: '8' },
			letter: { fontSize: '10'},
            listItem: { height: '3', width: '3', backgroundColor: 'black', marginTop: '4', marginRight: '13' },
            bold: { fontFamily: 'Roboto-Bold' },
            annotation: { fontSize: '8', textAlign: 'left'},
            listTitle: { border: '1 solid black', fontFamily: 'Roboto-Bold', padding: '3' },
            listSubTitle: { borderBottom: '1 solid black', fontFamily: 'Roboto-Bold' },
            separator: { borderTop: '1 solid black'},
            tableLine: { borderBottom: '1 solid black', textAlign: 'center', fontSize: '9', marginTop: '6'},
			left: {textAlign: 'left'},
            right: {alignItems: 'flex-end'},
            superscript: { fontSize: '6'}
		})
        const adminIsCommittee = employer.admin && employer.admin.cme === 'yes'
       
        return <Document><Page>
			<Row>
                <Col className='w-20'><Image style={styles['sideHeaderLogo']} src="/img/jbccnpp-sideheader-logo.png" /></Col>
                {/* <PDFSideHeader /> */}
				<Col style={styles.letter} className='ml-2 mt-7 w-70'>
                    <Text>{moment().format('LL')}</Text>
                    <Text className='mt-4'>{employer.admin && employer.admin.desc}</Text>
                    <Text>{employer.formatedName}</Text>
                    {employer.address.poBox.length !== 0 && <Text>P.O.Box {employer.address.poBox}</Text>}
                    <Text>{employer.address.civic}</Text>
                    <Text>{employer.address.city} ({employer.address.prov}) {employer.address.pc}</Text>
                    <Row className='mt-4' style={styles['bold']}>
                        <Text className='pr-4'>Re:</Text>
                        <Col>
                            <Text>Required Contributions under the {employer.plan.name} {'\n'} ("the Plan")</Text>
                        </Col>
                    </Row>
                    <Container>
                        <Text className='mt-5'>Dear {employer.admin && employer.admin.desc.trim()},</Text>
                        <Text className='mt-3'>The actuarial valuation of the Plan as at December 31, 2020 has now been completed by Mercer, the actuaries of the Plan, and the report has been filed with government pension authorities. The report reveals:</Text>
                        <Row className='mt-3'>
                            <Col><Text style={styles['listItem']}/></Col>
                            <Col className='w-90'>
                                <Text>a solvency surplus of $7,815,000 which is an improvement as compared to the solvency surplus of $1,994,000 as at December 31, 2019. However, as solvency payments are based on a three-year average solvency ratio, solvency payments are still required in 2021.</Text>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col><Text style={styles['listItem']}/></Col>
                            <Col>
                                <Text>an increase in the employers’ current service cost <Text style={styles['bold']}>from 167% to 176% of members’ contributions.</Text></Text>
                            </Col>
                        </Row>
                        <Text className='mt-4'>Adjustments to employer contributions are therefore required effective January 1, 2021, including an adjustment on a prospective basis and a retroactive adjustment to reflect lower required solvency payments and higher current service cost. A single net retroactive adjustment is calculated as described below.</Text>
                    </Container>
                    <Container className='mt-5'>
                        <Row style={styles['listTitle']}>
                            <Col className='mr-3'><Text>A.</Text></Col>
                            <Col>
                                <Text>NEW PAYMENTS FROM THE NEXT PAYMENT DATE (PROSPECTIVE BASIS)</Text>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='mr-3'><Text>1.</Text></Col>
                            <Col>
                                <Text style={styles['listSubTitle']}>Ongoing Monthly Solvency Payments</Text>
                                <Text className='w-90 mt-1'>Effective from your remittance for the month of <Text style={styles['bold']}>June 2021</Text>, 
                                {solvencyIsSame ?
                                    <Text> you must continue paying <AmountDetail amount={currSolvency.amount} amountStyle={styles['bold']}/> <Text style={styles['bold']}>each month</Text> (NB: there is no change from last year).</Text>
                                    : <Text> you must start paying <AmountDetail amount={currSolvency.amount} amountStyle={styles['bold']}/> <Text style={styles['bold']}>each month</Text> instead of <AmountDetail amount={prevSolvency.amount}/> (amount determined in the previous valuation). </Text>
                                }
                                These solvency payments are required until a new actuarial valuation is filed. You will then be notified.</Text>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='mr-3'><Text>2.</Text></Col>
                            <Col>
                                <Text style={styles['listSubTitle']}>Ongoing Monthly Remittances for Current Service Cost</Text>
                                <Text className='w-90 mt-1'>Based on information available as at July 1, 2021, ongoing monthly payments for current service cost were remitted for the period up to the end of {lastClosedMonth}. Starting with your next remittance, you must start using the new employers’ service cost of 1.76.</Text>
                                <Text className='w-90 mt-2'>Regular Monthly Employee Contributions x 1.76 = Employer portion</Text>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Page>
        <Page>
            <Row>
                <Col  className='w-20 pl-4 mt-3'><Image style={styles['planLogo']} src="/img/jbccnpp-logo-trial.png" /></Col>
                <Col style={styles.letter} className='ml-2 mt-5 w-70'>
                    <Text>{employer.admin && employer.admin.desc}</Text>
                    <Text>{employer.name} </Text>
                    <Container className='mt-5'>
                        <Row style={styles['listTitle']}>
                            <Col className='mr-3'><Text>B.</Text></Col>
                            <Col>
                                <Text text={texts['sectionB-title']}/>
                            </Col>
                        </Row>
                        <Separator style={styles['separator']} className='mt-3'/>
                        <Container >
                        <Row style={styles['tableLine']}>
                            <Col className='w-30' />
                            <Col className='w-20 pr-3' style={styles['right']}><Text text={texts['amount-paid']}/></Col>
                            <Col className='w-30 end'><Text text={texts['amount-required']} /></Col>
                            <Col className='w-20 end'><Text text={texts['adjustment-solvency-deficit']}/></Col>
                        </Row>
                        <Row style={styles['tableLine']}>
                            <Col className='w-30'><Text text={texts['solvency-deficit']} style={styles['left']}/></Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={sumSolvPaid} /></Col>
                            <Col className='w-30 mb-2 start'><AmountDetail amount={sumSolvReq} /></Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={diffSolv}/></Col>
                        </Row>
                        <Separator style={styles['separator']} className='mt-3'/>
                        <Row style={styles['tableLine']}>
                            <Col className='w-30' />
                            <Col className='w-20 pr-3' style={styles['right']}><Text text={texts['amount-paid-lastClosed']} params={{month: lastClosedMonthLong}}/></Col>
                            <Col className='w-30'><Text text={texts['amount-required-same-period']}/></Col>
                            <Col className='w-20'><Text text={texts['adjustment-current-service']}/></Col>
                        </Row>
                        <Row style={styles['tableLine']}>
                            <Col className='w-30'><Text text={texts['current-service-cost']} style={styles['left']}/></Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={rcr.retro} /></Col>
                            <Col className='w-30 mb-2 start'><AmountDetail amount={rcr.adjusted} /></Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={rcr.diff} /></Col>
                        </Row>
                        <Row style={styles['tableLine']}>
                            <Col className='w-80 mb-2'><Text text={texts['net-adjustment']} style={styles['left']}/></Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={solvPlusContribs}/></Col>
                        </Row>
                        <Row style={styles['tableLine']}> 
                            <Col style={styles['bold']} className='w-80 mb-2'>
                                <Row  style={styles['left']}>
                                    <Text text={texts['net-adjustment-tobe-paid']}/><Text style={styles['superscript']}>1</Text>
                                </Row>
                            </Col>
                            <Col className='w-20 mb-2 start'><AmountDetail amount={tot} amountStyle={styles['bold']}/></Col>
                        </Row>
                        <Row><Text style={styles['superscript']}>1</Text><Text text={texts['annotation']} style={styles['annotation']}/></Row>
                        </Container>
                    </Container>
                    <Container>
                        {totalIsNegative ?
                            <Text className='mt-4'>Your combined adjustment is a credit so no payment is due. Your credit will be applied to your next remittance.</Text>
                            : <Text className='mt-4'>Please remit your electronic payment to cover the net adjustment. Payments should be received by July 31, 2021.</Text>
                        }
                        <Text className='mt-3'>If you have remitted contributions since July 1, 2021, these amounts are not reflected in this letter. You should then proceed with the payments as per the above. We will then notify you of any further adjustment.</Text>
                    </Container>
                    <Container>
                        <Text className='mt-3'>Should you have any questions or require further information, please do not hesitate to contact us.</Text>
                        <Text className='mt-4'>Yours very truly,</Text>
                        {/* <Image style={styles.signature} src="/img/signature.jpg" /> */}
                        <Text className='mt-3'>A.S. Hayes Consulting Inc.</Text>
                        {!adminIsCommittee && <Text className='mt-3'>Copy: {employer.committee && employer.committee.desc}, <Text text={texts['retirementMember']}/></Text>}
                    </Container>
                </Col>
            </Row>
        </Page>
        </Document>
    }
}

const texts = {
    "sectionB-title" : { 
		"en" : "NET RETROACTIVE ADJUSTMENTS",
		"fr" : "" 
	},
    "amount-paid" : { 
		"en" : "Amount paid \n (January to \n May)",
		"fr" : "" 
	},
    "amount-paid-lastClosed" : { 
		"en" : "Amount paid \n (January to \n {month})",
		"fr" : "" 
	},
    "amount-required" : { 
		"en" : "Amount required \n following new valuation",
		"fr" : "" 
	},
    "adjustment-solvency-deficit" : { 
		"en" : "Adjustment \n Solvency Deficit (A)",
		"fr" : "" 
	},
    "solvency-deficit" : { 
		"en" : "Solvency Deficit",
		"fr" : "" 
	},
    "amount-required-same-period" : { 
		"en" : "Amount required \n following new valuation \n for the same period",
		"fr" : "" 
	},
    "adjustment-current-service" : { 
		"en" : "Adjustment \n Current Service Cost (B)",
		"fr" : "" 
	},
    "current-service-cost" : { 
		"en" : "Current Service Cost",
		"fr" : "" 
	},
    "net-adjustment" : { 
		"en" : "Net adjustment (A) + (B)",
		"fr" : "" 
	},
    "net-adjustment-tobe-paid" : { 
		"en" : "Net adjustment to be paid",
		"fr" : "" 
	},
    "annotation" : { 
		"en" : "As prescribed by legislation, includes interest based on a rate of 4.7% per annum",
		"fr" : "" 
	},
    "retirementMember" : {
        "en": "Retirement Committee Member",
        "fr": ""
    }         
}