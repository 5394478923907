import React, { useMemo, useState } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import { EInput } from '../../framework/components'
import { Form } from '../../framework/controls'
import { Title } from '../../framework/containers'
import CommunicationDetails from '../address/CommunicationDetails'
import AddressDetails from '../address/AddressDetails'
import { Person } from '../../entities'

const ContactForm = ({ contact, onCancel, onSave }) => {
    const [commentValue, setCommentValue] = useState(contact.cmt ?? "");
    const personInfo = useMemo(
        () => (contact.person.isNew() ? new Person() : contact.person),
        [contact.person]
    );

    const handleSave = (contactInfo, originalContact) => {
        contact.cmt = commentValue;
        contact.person = personInfo;
        if (onSave) onSave(contact, originalContact);
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
    }

    return (
        <>
            <Title
                title={
                    contact.isNew()
                        ? "New Contact"
                        : `${personInfo.desc} Contact`
                }
                onHide={handleCancel}
            />
            <Form
                id="ContactForm"
                data={contact}
                onSave={handleSave}
                onCancel={handleCancel}
            >
                <div className="d-flex align-items-end mb-4">
                    <EInput
                        instance={personInfo}
                        name="firstName"
                        className="col-4 mr-3"
                        required
                        showRequiredLabelMarker
                    />
                    <EInput
                        instance={personInfo}
                        name="lastName"
                        className="col-4 mr-3"
                        required
                        showRequiredLabelMarker
                    />
                    <EInput instance={personInfo} name="lng" />
                </div>
                <div className="d-flex align-items-end mb-4">
                    <EInput
                        instance={contact}
                        name="pos"
                        className="col-3 mr-3"
                    />
                    <EInput
                        instance={contact}
                        name="adm"
                        className="col-3 mr-3"
                    />
                    <EInput
                        instance={contact}
                        name="cme"
                        className="col-3 mr-3"
                    />
                    <EInput instance={contact} name="trr" />
                </div>
                <CommunicationDetails className="mt-3" instance={personInfo} />
                <AddressDetails className="mt-3" address={personInfo.address} />
                <div className="mt-2">
                    <label for="contact-comment">Comment</label>
                    <TextareaAutosize 
                        id="contact-comment"
                        minRows={4} 
                        maxRows={10}
                        onChange={(e) => setCommentValue(e.target.value)} 
                        value={commentValue}
                        className="w-100 textarea" 
                    /> 
                </div>
            </Form>
        </>
    );
};

export default ContactForm;