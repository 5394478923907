import React from 'react';
import { ParticipationEvent } from '../../entities';
import StringInput from './StringInput';

const ParticipationEventInput = ({onEnter, instance, property, existingFilters}) => {

    const events = [];
    for (const [key] of Object.entries(ParticipationEvent.messages)) {
        const event = new ParticipationEvent({code: key});
        if (event.config.status || event.config.isPendingEvent) {
            events.push({key, value: key, text: event.desc });
        }
    }

    return <StringInput property={property} instance={instance} options={events} existingFilters={existingFilters} onEnter={onEnter}/>;
}

export default ParticipationEventInput;