import { useState } from "react"
import { promisify } from "util"
import { ExcelRenderer } from 'react-excel-renderer'
import { RefMessage, Ref, Definition } from "../../framework/infra";
import { Modal, Title } from '../../framework/containers';
import { Table, UploadButton,  } from '../../framework/controls';
import { toSearchString, isValidSIN, parseExcelDate, DATE_FORMAT } from '../../framework/utils/helper';
import { Participation, Person } from '../../entities';
import { MembershipService, PersonService, ParticipationService } from '../../services';

import moment from "moment";
import Loading from "../../components/containers/Loading";
import useNotification from "../../hooks/useNotification";
import { Button } from "react-bootstrap";
import { Question } from "../../framework/components";

const SUPPORTED_TYPES = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const { STRING } = Definition.types;

const MercerKeyUpload = () => {
	const { addMessage } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isSavePromptOpen, setIsSavePromptOpen] = useState(false);
    const [tableData, setTableData] = useState();
    const [participationsToUpdate, setParticipationsToUpdate] = useState();

    const columns = new Table.Headers(FileRow, 'person.sin, joinDate, mercerKey, message');
    columns.joinDate.format = (val) => val ? val.format(DATE_FORMAT) : "";
    columns['person.sin'].width = 200;
    columns.joinDate.width = 250;
    columns.mercerKey.width = 250;

	const handleMercerUpload = async (inputFile) => {
        setIsLoading(true);
        setIsOpen(true);
        if (!inputFile) return this.error('noFileSelected');
        if (!SUPPORTED_TYPES.includes(inputFile.type)) return this.error('fileNotSupported');

        const file = await promisify(ExcelRenderer)(inputFile);
		const fileHeaders = file.rows[0];
		const fileSubheaders = file.rows[1];
		const matchesHeaders = matchHeaders(fileHeaders);	
		const matchesSubheaders = matchHeaders(fileSubheaders);
        const hasSubheaders = matchesSubheaders.length > 0;
        const matches = hasSubheaders ? matchesSubheaders : matchesHeaders;
		const matchesForPerson = matches.filter(match => match.entityName === 'Person');
		const matchesForNone = matches.filter(match => match.entityName === 'None');
        const rows = file.rows.filter(row => row.length > 0).slice(hasSubheaders ? 2 : 1);
        let tabData = [];
        let ppsToUpdate = [];
		let processedSins = [];

        for (let fileRow of rows) {
            const row = new FileRow();
            try {
                matchesForPerson.forEach(match => { row.person[match.name] = match.format ? match.format(fileRow[match.index]) : fileRow[match.index] });
                matchesForNone.forEach(match => { row[match.name] = match.format ? match.format(fileRow[match.index]) : fileRow[match.index] });

                // validations
                if (!row.person.sin) row.message = new MercerKeyUploadMessage({code: 'missingSIN'});
                else if (!isValidSIN(row.person.sin)) row.message = new MercerKeyUploadMessage({code: 'invalidSIN'});
                else if (processedSins.find(x=> x === row.person.sin)) row.message = new MercerKeyUploadMessage({code: 'duplicateSIN'});
                else if (!row.joinDate.isValid()) row.message = new MercerKeyUploadMessage({code: 'invalidJoinDate'});
                else if (!row.mercerKey) row.message = new MercerKeyUploadMessage({code: 'missingMercerKey'});

                if (row.message.code) {
                    tabData.push(row);
                    continue;
                }
                
                // Get person, we need person id
                const person = await PersonService.getPersonBySin(row.person.sin);
                if (!person) {
                    row.message = new MercerKeyUploadMessage({code: 'missingPerson'});
                    tabData.push(row);
                    continue;
                }

                row.person = person;

                // Get membership and participations
                const membership = await MembershipService.getMembership(row.person.id);
                if (!membership) {
                    row.message = new MercerKeyUploadMessage({code: 'missingMembership'});
                    tabData.push(row);
                    continue;
                }

                // Find participation with join date and set mercer key
                const participation = membership.participations.find(pp => moment(pp.joinDt).isSame(row.joinDate));
                if (participation && row.mercerKey) {
                    participation.mercerKey = String(row.mercerKey);
                    ppsToUpdate.push(participation);
                }
                if(!participation){
                    row.message = new MercerKeyUploadMessage({code: 'missingParticipation'});
                    tabData.push(row);
                    continue;
                }
            } catch (e) {
				console.log(e);
				row.message = new MercerKeyUploadMessage({code: 'unknownError'});
            }
            if (!row.message.code) row.message = new MercerKeyUploadMessage({code: 'ok'});
            if (row.person.sin) processedSins.push(row.person.sin); 
            tabData.push(row);
        }
        setParticipationsToUpdate(ppsToUpdate);
        setTableData(tabData);
        setIsLoading(false);
	}

    const handleSave = async () => {
        setIsLoading(true);
        for (let pp of participationsToUpdate) {
            await ParticipationService.updateParticipation(pp);
        }

        setParticipationsToUpdate();
        setIsLoading(false);
        setIsOpen(false);
        addMessage(`Mercer keys successfully uploaded (${participationsToUpdate.length} keys)`, "success");
    }

    const handleCancel = () => {
        if (participationsToUpdate) setIsSavePromptOpen(true);
        else setIsOpen(false);
    }

    const handleConfirmSave = async (isAnswerYes) => {
        if (isAnswerYes) await handleSave();
        setIsSavePromptOpen(false);
        setIsOpen(false);
    }
	
	return <>
        <UploadButton id="mercer-key" onUpload={handleMercerUpload} text='Import Mercer Keys' accept='.xls,.xlsx,.csv' />
        {isOpen && <Modal backdrop={true} className="h-100 w-80 modal-bg-color" onHide={handleCancel}>
            <Title title="Upload Mercer Keys" onHide={handleCancel}/>
            {isLoading ? <Loading /> : <Table id='upload-mercer-keys-table' data={tableData} columns={columns}  />}
            <div className="d-flex justify-content-end">
                <Button className="btn-primary" onClick={handleSave}>Save</Button>
                <Button className="btn-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Modal>}
        {isSavePromptOpen && <Question title="You are leaving without saving. Do you wish to save?" onAnswer={handleConfirmSave} />}
	</> 
}
export default MercerKeyUpload;


const fields = [
	{entityName: 'Person', name: 'sin', title: 'SIN', matches: ['sin', 'sin'], key: true },
	{entityName: 'None', name: 'joinDate', title: 'Join Date', matches: ['joindate', 'joineddate', 'ppeffectivedate'], format: (val) => moment(parseExcelDate(val))}, 
    {entityName: 'None', name: 'mercerKey', title: 'Mercer Key', matches: ['mercerkey'], format: (val) => val ?? ""},
]

const matchHeaders = (fileHeaders) => {
	fileHeaders = fileHeaders.map(h => toSearchString(h));
	return fields.reduce((mapped, field) => {
		const index = fileHeaders.findIndex(header => field.matches.includes(header));
		if(index >= 0) mapped.push(Object.assign({}, field, {index}));
		return mapped;
	}, [])
}

class MercerKeyUploadMessage extends RefMessage {
	static messages = [
		['missingSIN', 'No SIN provided. Mercer key will not be uploaded.', '', 'e'],
		['duplicateSIN', 'This SIN already appears in this file', '', 'e'],
		['invalidSIN', 'Invalid SIN number. Mercer key will not be uploaded.', '', 'e'],
		['missingPerson', 'Person not found. Mercer key will not be uploaded.', '', 'e'],
		['missingMembership', 'Membership not found. Mercer key will not be uploaded.', '', 'e'],
		['missingParticipation', 'Participation not found. Mercer key will not be uploaded.', '', 'e'],
		['invalidJoinDate', 'Invalid join date. Mercer key will not be uploaded.', '', 'e'],
		['missingMercerKey', 'A mercer key is required to link it to the participation.', '', 'e'],
		['unknownError', 'An unknown error occured. Mercer key will not be uploaded.', '', 'e'],
		['ok', 'No issues found', '', 'i'],
	]
}

class FileRow extends Ref {
	constructor(data) {
        super(data);
    }
	static definitions = {
		person: { ref: Person, text: 'Person' },
        participation: { ref: Participation, text: 'Participation' },
		joinDate: { type: STRING, text: 'Join Date' },
        mercerKey: { type: STRING, text: 'Mercer Key' },
		message: { ref: MercerKeyUploadMessage, text: 'Message' }
	}
}