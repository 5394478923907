import React, { useState, useEffect } from "react"
import Toast from 'react-bootstrap/Toast';

const NotificationMessage = ({ notificationMessage }) => {
    const { message, severity, ts } = notificationMessage;
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    //when setting state with same string we need to trigger re-render
    //using timestamp as trigger
    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        setShow(true);
      }
      return () => { isMounted = false };
    }, [ts])

    return (
        <Toast 
          className={`notification-toast bg-${severity}`}
          bg={severity} 
          onClose={handleClose} 
          show={show} 
          delay={3000} 
          autohide
        >
          <Toast.Header>
            <strong key={ts} className='me-auto text-white'>{message}</strong>
          </Toast.Header>
        </Toast>
    );
}

export default NotificationMessage;

