import { isObject } from "lodash";
import { Button } from "react-bootstrap"
import TaskBase from "./TaskBase";

const TaskMultipleChoice = (props) => {
    
    const { event, onSave, instance, getChoices } = props

    const handleSave = (answer) => {
        event.metadata = { answer };
        if (onSave) onSave(event);
    }

    let choices = getChoices({instance});
    let hasAnswer = isObject(event.metadata) && event.metadata.hasOwnProperty('answer');

    return <TaskBase {...props}>
            { choices.map(((choice) => 
                <Button onClick={() => handleSave(choice.value)} className={(hasAnswer && event.metadata.answer === choice.value ? 'btn-primary' : 'btn-secondary')}>{choice.label}</Button>
            ))}
        </TaskBase>
}

export default TaskMultipleChoice;