import { Definition } from '../../../framework/infra'

import { moment } from '../../../framework/utils/helper'
import { EmploymentService } from '../../../services'
import { Employment } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'

const DATE = Definition.types.DATE

export default class RBCPensionReport extends ReportLayout {
    reportDesc = `RBC Report - Pensioner Taxation Status`
    entity = Employment
    headers = ['employer.plan.benefitNumber', 'person.sin', 'person.name', 'isTP', 'isN',
    'participation.reorderedPpNo', 
    'participation.lastStatusEvent.status.desc', 'participation.lastStatusEvent.desc', 'participation.lastStatusEvent.effDt']
    headerProps = {
        'participation.lastStatusEvent.desc': {title: 'PP Event Desc'},
        'participation.lastStatusEvent.status.desc': {title: 'PP Status Desc'}
    }
    tableSort = 'person.name'
    filters = {
        'employer.plan.jurisdiction': {nullable: true, className: 'col-3'},
    }
    params = {
        from: {definition: {type: Definition.types.DATE, text: "From", default: moment().startOf('year').format('YYYY-MM-DD')}},
        to: {definition: { type: Definition.types.DATE, text: "To", default: moment().format('YYYY-MM-DD')}}
    }
   
    execQuery(queryParams) {
        return EmploymentService.getAllEmployments().then(employments => {
            return employments.filter(employment => {
                return employment.participation.lastStatusEvent.status.isReceivingPension() && DATE.isInRange(employment.participation.lastStatusEvent.effDt, queryParams.from, queryParams.to)
            })
        })
    }
}