import React, { useEffect, useMemo, useState } from 'react'

import { EInput } from '../../../framework/components'
import { Row, Col, Title } from '../../../framework/containers'
import { Currency, Icon} from '../../../framework/controls'
import { Button, Form as BForm } from "react-bootstrap";
import { Period } from '../../../framework/utils'
import {
    round,
    toBool,
    pick,
} from "../../../framework/utils/helper";
import { formatAmount } from "../../../framework/utils/formating";
import useNotification from '../../../hooks/useNotification'
import { AdjustmentService } from '../../../services'

import RemittanceService from '../../../services/pension/RemittanceService'
import Checkbox from '../../../components/form/Checkbox'
import { Formik, Form } from 'formik'
import CustomDropdown from '../../../components/form/Dropdown';
import PaymentDistributionForm from './PaymentDistributionForm';
import { AccountDistribution, Adjustments, RemittanceDistribution } from '../../../entities';
import Loading from '../../../components/containers/Loading';

const PaymentForm = ({
    employer,
    payment,
    isNew,
    remittances,
    onCancel,
    onDelete,
    onSave,
}) => {
	const { addMessage } = useNotification();
    const [remittance, setRemittance] = useState();
    const [remittanceList, setRemittanceList] = useState(remittances);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [adjustmentList, setAdjustmentList] = useState();
    const [isRefNumUnique, setIsRefNumUnique] = useState(true);
    const [isClosed, setIsClosed] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [outBalance, setOutBalance] = useState();
    const [formValues, setFormValues] = useState();
    const editedPayment = useMemo(() => payment.clone(), []);
    const [distributionList, setDistributionList] = useState(editedPayment.distribution.all);
    const [overpayError, setOverpayError] = useState();
    
    const creditAdjustment = useMemo(
        () =>
            adjustmentList?.filter(
                (adjustment) =>
                    adjustment.period.isSame(remittance?.period) &&
                    adjustment.type.config?.isCredit &&
                    adjustment.total < 0
            ),
        [adjustmentList]
    );
    const isDistributionModifiable = creditAdjustment?.length === 0 || isNew;

    useEffect(() => {
        let isMounted = true;

        AdjustmentService.getAdjustmentsForEmployer(employer.keyValue).then(
            (adjustments) => {
                remittances.forEach(rem => {
                    rem.adjustments = new Adjustments(adjustments.filter(adj => adj.period.isSame(rem.period)));
                })
                remittances.distributePayments();
                const targetRemittance = remittances._list.find(
                    (rem) => rem.period.value === editedPayment.period.value
                );
                const lastClosedRem = remittances.lastClosed;
                const isReadOnly =
                    !isNew &&
                    targetRemittance.isClose() &&
                    !lastClosedRem.period.isSame(
                        remittance?.period ?? new Period()
                    ) &&
                    !editedPayment.isYearEnd;
                if (isMounted) {
                    setRemittanceList(remittances);
                    setRemittance(targetRemittance);
                    setIsReadOnly(isReadOnly);
                    setAdjustmentList(adjustments);
                    setIsLoading(false);
                }
            }
        );

        return () => { isMounted = false };
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setIsClosed(remittance?.isClose() && !remittance.period.yearEnd);

        return () => { isMounted = false };
    }, [remittance]);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) handleChange();

        return () => { isMounted = false };
    }, [formValues, editedPayment.rcvDate]);

    const handleFormChanged = () => {
        if (formValues?.isYearEnd && formValues?.yearEndValue !== "") {
            editedPayment.isYearEnd = formValues?.isYearEnd;
            editedPayment.yearEndValue = formValues?.yearEndValue;
        }
        const prevRcvPeriod =
            formValues?.isYearEnd && formValues?.yearEndValue !== ""
                ? Period.fromDate(formValues.yearEndValue)
                : Period.fromDate(editedPayment.rcvDate).dec(1);
        const remittanceFound = remittanceList._list.find(
                (rem) => rem.period.value === prevRcvPeriod.value
            ) ?? remittanceList.last;
        
        if (adjustmentList) {
            remittanceFound.adjustments._list = adjustmentList.filter(adj => adj.period.isSame(prevRcvPeriod));
        }
        setIsRefNumUnique(editedPayment.isReferenceNumUnique(remittanceFound, editedPayment));
        setRemittance(remittanceFound);
    };

    const handleChange = () => {
        handleFormChanged();
        if (remittance?.totalNegativeCredit < 0) checkOverpaid();
        setIsDirty(true);
    };

    const handleBalanceChange = (value) => {
        const currentBalance =
            remittance.balance - Currency.parseCurrency(value);
        setOutBalance(round(currentBalance));
    };

    const checkOverpaid = () => {
        let overpaidMessage = "The target period has an adjustment credit, therefore no over payments will be allowed. "
        let isOverpaid = false;
        const clonedBuckets = editedPayment.distribution.clone().getFiltered(item => item.period.isSame(remittance.period));
        clonedBuckets._list.forEach(item => {
            item.amount = Currency.parseCurrency(item.amount);
        });

        RemittanceDistribution.codes.forEach(code => {
            const bucketAmount = clonedBuckets[code + "Amount"];
            const contributionAmount = remittance.finalDistrBalance[code + "Bal"];
            if (bucketAmount > contributionAmount && bucketAmount > 0) {
                isOverpaid = true;
                const bucketName = RemittanceDistribution.definitions[code].text;
                overpaidMessage += `${bucketName} bucket payment: ${formatAmount(bucketAmount)} - ${bucketName} contribution bucket: ${formatAmount(contributionAmount)}. `
            }
        });

        setOverpayError(isOverpaid ? overpaidMessage : "");
    }

    const handleDistributionChange = () => {
        editedPayment.distribution._list = distributionList;
        editedPayment.distribution.setRemaining(editedPayment.amount);
        handleChange();
    };

    const handleAutoDistribution = () => {
        setIsLoading(true);
        RemittanceService.getEmployerRemittances(employer.id).then(
            (remittanceList) => {
                editedPayment.distribution._list = distributionList;
                editedPayment.distribution.setRemaining(editedPayment.amount);
                remittanceList.calculatePaymentDistributions(editedPayment);
                handleChange();
                setIsLoading(false);
            }
        );
    };

    const handleSave = () => {
        setIsLoading(true);
        editedPayment.distribution._list = distributionList;
        editedPayment.isYearEnd = formValues?.isYearEnd;
        editedPayment.yearEndValue = formValues?.isYearEnd ? formValues?.yearEndValue : "";
        const isChanged =
            !isNew && 
            (payment.period.value !== editedPayment.period.value 
                || payment.isYearEnd !== editedPayment.isYearEnd);
        const isEditedInSameRemittance = editedPayment.hasChanged(payment); 

        if (isNew || isChanged) {
            remittance.payments.push(editedPayment);
        } else if (isEditedInSameRemittance) {
            remittance.payments.pullFilter((p) => p.no !== payment.no);
            remittance.payments.push(editedPayment);
        }

        RemittanceService.update(
            remittance,
            "payments",
            isChanged
                ? {
                      oldRemittanceKey: remittanceList._list.find((rem) =>
                          rem.period.isSame(payment.period)
                      ).keyValue,
                      oldPayment: pick(payment, [
                          ...payment.getPayloadInclusions(),
                      ]),
                  }
                : undefined
        ).then(() => {
            if (onSave) onSave(remittance);
            addMessage(`Payment saved successfully`, "success");
        });
    };

    const handleDelete = () => {
        setIsLoading(true);
        remittance.payments.remove((pmt) => pmt === payment);
        RemittanceService.update(remittance,  'payments').then(() => {
            setIsLoading(false);
            addMessage(`Payment deleted successfully`, "success");
            if (onDelete) onDelete();
        });
    }

    const handleCancel = () => {
        editedPayment.setData(payment, false);
        if (onCancel) onCancel();
    }

    return (
        <>
            <Title title="Payment Details" onHide={handleCancel} />
            {!isLoading ? (
                <Formik
                    initialValues={{
                        isYearEnd: editedPayment.isYearEnd ?? false,
                        yearEndValue: editedPayment.yearEndValue ?? "",
                    }}
                    onSubmit={handleSave}
                >
                    {({ values, setFieldValue }) => {
                        setFormValues(values);
                        return (
                            <Form
                                as={BForm}
                                className="h-100 d-flex flex-column justify-content-between modal-component-body"
                            >
                                <Row className="input-spacing-2">
                                    <Col span="4">
                                        <EInput
                                            className="mb-3"
                                            required
                                            instance={editedPayment}
                                            name="no"
                                            readOnly={isReadOnly}
                                            showRequiredLabelMarker
                                            onChange={handleChange}
                                        />
                                        <EInput
                                            className="mb-3"
                                            required
                                            instance={editedPayment}
                                            name="amount"
                                            onChange={handleBalanceChange}
                                            readOnly={isReadOnly}
                                            showRequiredLabelMarker
                                        />
                                        <EInput
                                            className="mb-3"
                                            instance={editedPayment}
                                            name="rejected"
                                            onChange={handleChange}
                                            readOnly={isReadOnly}
                                        />
                                        {editedPayment.rejected ||
                                            (toBool(editedPayment.rejected) && (
                                                <EInput
                                                    className="mb-3"
                                                    instance={editedPayment}
                                                    name="rejectionReason"
                                                    readOnly={isReadOnly}
                                                    onChange={handleChange}
                                                />
                                            ))}
                                    </Col>
                                    <Col span="4">
                                        <EInput
                                            required
                                            instance={editedPayment}
                                            name="rcvDate"
                                            readOnly={isReadOnly}
                                            showRequiredLabelMarker
                                            onChange={handleChange}
                                        />
                                        <EInput
                                            instance={editedPayment}
                                            name="sentDate"
                                            readOnly={isReadOnly}
                                        />
                                        <div className="d-flex mt-5">
                                            <div className="mr-4">
                                                <Checkbox
                                                    name="isYearEnd"
                                                    label={`target year end period`}
                                                    className="mr-2"
                                                    disabled={isReadOnly}
                                                />
                                                <Icon
                                                    icon="exclamation-circle"
                                                    tooltip="This will let a payment be applied to a year end period. It will override the default logic for the target period."
                                                />
                                            </div>
                                            {values.isYearEnd && (
                                                <div className="move-up">
                                                    <CustomDropdown
                                                        buttonText={`Year: ${values.yearEndValue}`}
                                                        options={remittanceList
                                                            .filter(
                                                                (rem) =>
                                                                    rem.period
                                                                        .yearEnd
                                                            )
                                                            .map((rem) => ({
                                                                text: rem.period
                                                                    .text,
                                                                value: rem
                                                                    .period
                                                                    .value,
                                                            }))}
                                                        onSelect={(value) =>
                                                            setFieldValue(
                                                                "yearEndValue",
                                                                value
                                                            )
                                                        }
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    {remittance && (
                                        <Col span="3" className="mt-4 ml-2 pl-4 border-left">
                                            <div className="d-flex">
                                                <b className="mr-2">Target Period:</b>
                                                <p>{remittance.period.text}</p>
                                            </div>
                                            <div className="d-flex">
                                                <b className="mr-2">Previous Balance:</b>
                                                <p>{formatAmount(remittance.prevBalance)}</p>
                                            </div>
                                            <div className="d-flex">
                                                <b className="mr-2">Current Balance:</b>
                                                <p>{formatAmount(outBalance ?? remittance.balance)}</p>
                                            </div>
                                            <div className="d-flex">
                                                <b className="mr-2">Total Owing: </b>
                                                <p>{formatAmount(remittance.totalOwing)}</p>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <PaymentDistributionForm
                                    distributions={distributionList}
                                    handleDelete={(index) => setDistributionList((prev) => prev.filter((item, i) => i !== index))}
                                    handleAdd={() => setDistributionList((prev) => [...prev, new AccountDistribution.ref({period: remittance.period})])}
                                    handleChange={handleDistributionChange}
                                    disabled={!isDistributionModifiable}
                                />
                                {isDistributionModifiable && (
                                    <Row className="pt-1">
                                        <Col right>
                                            <Button onClick={handleAutoDistribution}>
                                                Auto-Distribute
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <EInput
                                        instance={editedPayment}
                                        name="cmt"
                                        className="col-6 mt-4"
                                    />
                                </Row>
                                {!isRefNumUnique && isNew && (
                                    <div className="d-flex align-items-center text-danger">
                                        <Icon
                                            icon="exclamation-triangle"
                                            className="mr-1"
                                        />
                                        {"This reference ID already exists in the " + Period.fromDate(editedPayment.rcvDate).dec(1).text +
                                            " period. Enter a unique reference ID."}
                                    </div>
                                )}
                                {isClosed && isNew && (
                                    <div className="d-flex align-items-center text-danger">
                                        <Icon
                                            icon="exclamation-triangle"
                                            className="mr-1"
                                        />
                                        {"The " + Period.fromDate(editedPayment.rcvDate).dec(1).text +
                                            " remittance is closed. A payment cannot be added to a closed period."}
                                    </div>
                                )}
                                {(remittance.totalNegativeCredit < 0 && overpayError) && (
                                    <div className="d-flex align-items-center text-danger">
                                        <Icon
                                            icon="exclamation-triangle"
                                            className="mr-1"
                                        />
                                        {overpayError}
                                    </div>
                                )}
                                {(!isReadOnly || isDistributionModifiable) && (
                                    <div className="d-flex justify-content-end">
                                        {!isNew &&
                                            (!isDistributionModifiable ||
                                                !isClosed) && (
                                                <Button variant="secondary" onClick={handleDelete}>
                                                    <Icon icon="trash" className="mr-2"/>
                                                    Delete
                                                </Button>
                                            )}
                                        <Button type="submit" variant="primary" disabled={!isDirty || (remittance.totalNegativeCredit < 0 && overpayError)}>
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default PaymentForm;