import { Employments, Membership, Participation, Person, Remittance } from "..";
import { Entity } from "../../framework/infra";
import { Adjustment, Adjustments } from "./adjustment";
import RemittanceDetail from "./RemittanceDetail";
import RemittanceDetails from "./RemittanceDetails";

export default class RemittanceDetailSummary extends Entity {
    static definitions = {
        employments: {
            ref: Employments,
            text: "Employments",
        },
        remittanceDetails: {
            ref: RemittanceDetail,
            list: true,
            text: "Remittance Details",
        },
        people: {
            ref: Person,
            list: true,
            text: "List of people",
        },
        participations: {
            ref: Participation,
            list: true,
            text: "List of participations",
        },
        memberships: {
            ref: Membership,
            list: true,
            text: "List of memberships",
        }
    };
}
