import React from 'react'
import Container from './Container'
import ContainerChild from './ContainerChild'

export default class SideBar extends Container {
    static cn = 'sidebar'
    render() {
        return this.wrapper(this.props.children)
    }
}

SideBar.Left = class Left extends ContainerChild {
    static cn = 'sidebar-header-left'
    render() { 
        return <div className={['sidebar-header-left', this.props.className].join(' ')}>{this.props.children || ''} </div>}
}
SideBar.Body = class Body extends ContainerChild {
    static cn = 'sidebar-body'
    render() { 
        return <div className='sidebar-body col'>{this.props.children || ''} </div>}
}