import React from 'react'
import { Table, Icon, Button } from '../../framework/controls'
import { Component, Question } from '../../framework/components'
import { Modal, Title } from '../../framework/containers'
import { renderComponent } from '../../framework/utils/renders'

import { RefHistorical } from '../../framework/infra'
import HistoricalForm from './HistoricalForm'
import { getSafe } from '../../framework/utils/helper'


export default class HistoricalList extends Component {
	view() {
		const { name, instance, readOnly } = this.props;
		const values = instance[name + 'History'];
		const definition = getSafe(instance, 'constructor.definitions.' + name);
		this.initializeValueDesc(values, definition);

		const { adding, editing, deleting, showForm } = this.state;
		const columns = new Table.Headers(RefHistorical.Item, 'effDt, historyDesc, cmt, rptDt');
		if (!readOnly) {
			columns['delete'] = { 
				width: 50, 
				align:"center", 
				cellClick: this.handleDelete.bind(this), 
				headerClick: this.handleAdd.bind(this), 
				format: () => renderComponent(<Icon icon='minus-circle' large/>),  
				headerSort: false, 
				titleFormatter: () => renderComponent(<Icon icon='plus-circle' large className="v-bottom"/>)
			};
		}
		columns['effDt'].maxWidth = 150;
		columns['effDt'].title = 'Effective Date';
		columns['historyDesc'].title = 'Description';
		columns['cmt'].title = 'Comment';
		columns['rptDt'].maxWidth = 150;
		columns['rptDt'].title = 'Reported Date';

		return <><div className='h-100'>
			<Title title={`${instance.constructor.definitions[name].desc} History`} onHide={this.handleClose.bind(this)}/>
			<Table 
				data={[...values.all]} 
				columns={columns}
				id="historical-table" 
				onSelect={!readOnly && this.handleEdit.bind(this)}  
				sort='effDt' 
				fitHeightToContent 
			/>
			{showForm && <Modal className='w-40 h-60 modal-bg-color'>
				<HistoricalForm 
					selected={adding || editing} 
					name={name} 
					instance={instance} 
					disableZeroValue={name === 'baseEarnings'}
					onOK={this.handleOK.bind(this)} 
					onCancel={this.handleCancel.bind(this)}
				/>
			</Modal>}
			{deleting && <Question title='Are you sure you want to delete this?' onAnswer={this.handleConfirmDelete.bind(this)} />}
		</div>
         {!readOnly && <div className='line'> 
            <div className='line-stretch'/>
			<Button key='close' className='btn-secondary' onClick={this.handleClose.bind(this)}>Close</Button>
        </div>}
        </>
	}

	handleOK(data) {
		const { instance, name, onUpdate } = this.props;
		instance[name + 'History'].addNewHistoricalItem(data);
        this.setState({showForm: false, adding: null, editing: null});
		if (onUpdate) onUpdate();
	}
	handleCancel() { this.setState({showForm: false, adding: null, editing: null}) }
    handleClose() { if(this.props.onClose) this.props.onClose() }
	handleAdd() {
		this.setState({adding: this.props.instance[this.props.name + 'History'].create(), showForm: true})
	}
	handleEdit(selected) {
		this.setState({editing: selected, showForm: true})
	}
	handleDelete(e, cell) {
		e.preventDefault()
		e.stopPropagation()
		this.setState({deleting: cell.getRow().getData()?.ets})
	}
	handleConfirmDelete(confirmed) {
		if (confirmed) this.props.instance[this.props.name + 'History'].pullByEts(this.state.deleting);
		this.setState({deleting: null});
		if (this.props.onUpdate) this.props.onUpdate();
	}

	initializeValueDesc(values, definition){
		values.forEach(value => {
			value.historyDesc = (value.value.desc ?? definition.getOptionText(value.value)) || value.value;
			value.cmt = value.cmt || (value.value.cmt ?? "");
		})
	}
}
