import Mapper from "./Mapper";
import Beneficiaries from '../entities/pension/member/Beneficiaries';

export default class BeneficiaryMapper extends Mapper {   
    constructor (data, options = {}) {
        super (data, options);
        this.beneficiaries = new Beneficiaries();
    }

    async map () {
        await this.load('people', {load: false});
        this.beneficiaries.pushList((await this.load('beneficiaries')));
        return this.beneficiaries;
    }
}