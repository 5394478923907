import React from 'react'
import Container from './Container'

export default class TableList extends Container {
    static cn = 'tableList'
    
    render() {
		const { entity, data, headerList } = this.props
		
        return this.wrapper(
            <table> 
				{headerList.map(headerProp => {
					return <tr>
					<th>{entity.getDefinition(headerProp).text}</th>
						{data.map((prop) => {
							return (prop[headerProp] === '' ? <td>&nbsp;</td> : <td>{prop[headerProp]}</td>)
						})}
					</tr>
				})} 
			</table>
        )
    }
}