import React from 'react';
import APIErrorBoundary from './APIErrorBoundary';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: "",
            errorStack: "",
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errorMessage: error.message,
            errorStack: error.stack,
        };
    }

    render() {
        return (
            <APIErrorBoundary
                hasRenderError={this.state.hasError}
                renderStack={this.state.errorStack}
                renderErrorMessage={this.state.errorMessage}
            >
                {this.props.children}
            </APIErrorBoundary>
        );
    }
}
