import ActionResult from "./ActionResult"
import React from 'react'

/**
 * Represents an action that can be taken on a participation history 
 * @param {Object} options
 * @param {string} options.text - text to display in action list
 * @param {JSX.Element} options.form - optional form to display
 * @param {Function} options.condition - function to check if the action will be present in the list
 * @param {Function} options.apply - function to mutate the participation
 */
export default class HistoryAction  {

    constructor({text, form, condition, apply}) {

        if (!text) throw new Error('Action must have text')
        if (!apply) throw new Error('Action must have apply function')
        if (!condition) throw new Error('Action must have condition function')

        this.text = text // text to display
        this.form = form // jsx form to display
        this.condition = condition // function to check if the action is possible
        this.apply = apply // function to execute the action
    }

    /**
     * Execute the action, will open the form if it exists before applying the action
     * @param {Object} options
     * @param {Participation} options.participation - the participation to apply the action to
     * @param {Function} options.formContainerSetter - function to set the form container
     * @param {Function} options.callback - function to call after action is complete
     * @param {Function} options.onError - function to call if an error occurs
     */
    async execute({participation, formContainerSetter, callback, onError}) {

        const setFormContainer = formContainerSetter;
        // apply the action with error handling
        const _apply = async (values) => {
            try {
                this._result = this.apply(values)
                await this.finalize(callback)
            }
            catch (e) {
                console.error('Error executing action', e)
                if (onError) onError(e)
            }
        }

        if (this.form) {
            // create clone of form and add submit handler
            const form = React.cloneElement(this.form, {
                participation,
                onSubmit: async (values) => {
                    setFormContainer(null)
                    _apply({participation, values})
                },
                onCancel: () => setFormContainer(null)  
            })
            setFormContainer(form)
        } else {
            _apply({participation})
        }
    }

    /**
     * Finalize the action, will call the callback with the result if it exists
     * @param {Function} callback - function to call with the result
     */
    async finalize(callback) {
        if (!this._result && !(this._result instanceof ActionResult)) throw new Error('Action did not return a result')
        if (callback) callback(this._result)
    }
}