import React from 'react'
import { Ref, Definition } from '../../framework/infra'
import { Component } from "../../framework/components"
import { Form, Button } from "../../framework/controls"
import { Tabs, Row, Title, FieldSet } from "../../framework/containers"
import { Modal } from '../../framework/containers'
import EventPage from '../../framework/components/page/EventPage'

import { EmploymentService, MembershipService, ParticipationService} from '../../services'
import { ParticipationEvent } from '../../entities'

import Pension from './Pension'
import PayoutDetails from './PayoutDetails'
import ParticipationHistory from './ParticipationHistory'
import Warning from '../../framework/components/Warning'


export default class MemberParticipationsForm extends Component {
    load() {
        const pp = this.props.participation
        return EmploymentService.getParticipationEmployments(pp.keyValue).then(emps => {
            return { employments: emps, statusEvent: pp.events?.last?.effDt && pp.events?.last?.code ? new StatusEvent({ effDt: pp.events?.last?.effDt, groupName: getGroupName(pp), eventKey: pp.events?.last?.code }) : undefined}
        })
    }

    view() {
        const { participation, readOnly } = this.props
        const { employments, newEvent, isDeleting, warningMessage } = this.state
        const activeTab = this.state.activeTab || 'history'
        const isDeletable = employments.length <= 1 && participation.membership.participations.length !== 1 && participation.status.isPotential();

        return <><Title title={`${participation.membership.person.desc}`} midtitle={`Participation no# ${participation.reorderedPpNo}`} subtitle={`${participation.statusDesc}`} onHide={this.handleCancel.bind(this)}/>
            <Form className='h-100' id="MemberParticipationForm" data={participation} onSave={!readOnly && this.handleSave.bind(this)} onCancel={!readOnly && this.handleCancel.bind(this)} deleteMessage="Are you sure you want to delete this participation?" onDelete={isDeletable && this.handleDeletePP.bind(this)} ref={(ref) => this._form = ref}>
                <Tabs initial={activeTab} onChange={tab => this.setState({activeTab: tab})} className='mt-3'>
                    <Tabs.Tab name='history' title='History'>
                        <ParticipationHistory participation={participation} onChange={this.handleEventChange.bind(this)} notify={this.props.notify}/>
                    </Tabs.Tab>
                    <Tabs.Tab name='payout' title='Payout'>
                        <PayoutDetails participation={participation} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
                    </Tabs.Tab>
                    <Tabs.Tab name='pension' title='Pension'>
                        <Pension membership={participation.membership} pension={participation.pension} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
                    </Tabs.Tab> 
                </Tabs>
                {isDeleting && <Modal centered>
                    <p>Deleting...</p>
                </Modal>}
                {warningMessage && <Warning message={warningMessage} onClose={() => this.setState({warningMessage: null})}/>}
            </Form>
        </>
    }

    handleCancel() { if (this.props.onCancel) this.props.onCancel() }
    handleChange(val) {
        this._form.forceDirty()
        if(this.props.onChange) this.props.onChange()
    }
    handleEventChange() {
        this._form.forceDirty()
    }

    handleDeletePP(){
        this.setState({isDeleting: true})
        return ParticipationService.deleteParticipation(this.props.participation).then((response) => {
            if (response.code === '103') {
                this.setState({isDeleting: false, warningMessage: 'Unable to delete participation due to existing remittance details.'});
            } else {
                return this.props.handleDelete(this.props.participation).then(() => {
                    this.setState({isDeleting: false});
                })
            }
        });
    }

    handleSave() {
        const { participation } = this.props
        const { newEvent } = this.state
        return MembershipService.update(participation.membership).then(() => {
            if (newEvent) this.setState({ newEvent: null})
            if (this.props.onSave) this.props.onSave()
        })
    }

    handleEditStatus() { this.setState({edit: true}) }
    handleGroupSelect(val) {
        const statusEvent = this.state.statusEvent;
        statusEvent.groupName = val
        this.setState({touch: true})
    }
    handleStatusSelect(val) {
        const statusEvent = this.state.statusEvent;
        statusEvent.eventKey = val
        this._form.forceDirty()
        this.setState({touched: true}) 
    }
}
class StatusEvent extends Ref {
    static definitions = {
        effDt: { type: Definition.types.DATE, text: "Effective Date" },
        groupName: { type: Definition.types.STRING, text: "Groups" },
        eventKey: { type: Definition.types.STRING, text: "Key" },
        eventDesc: { type: Definition.types.STRING, text: 'Description'}
    }
}
function getGroupName(participation) { //I put the event in if condition to avoid crashing page since we have a non existent event ---> [‘lgcyTri’, ‘TRI – Transferred-In’, ‘’, ‘i’],
    const event = ParticipationEvent.messages[participation.events.last.code]
    if (event && !event.config) return ''
    if (event && event.config.groups) return event.config.groups.length > 1 ? '' : event.config.groups[0]
}