import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row } from '../../framework/containers'

export default class PersonSummaryInfo extends Component {
    
	view() {
		const { person, readOnly, errors } = this.props

		return <>
			<Row className='input-spacing-2'>
				<EInput name='sin' error={errors?.sin} instance={person} readOnly={readOnly}/> 
				<EInput name='firstName' instance={person}  readOnly={readOnly}/>
                <EInput name='lastName' instance={person} readOnly={readOnly}/>
			</Row>
		</>
	}
}