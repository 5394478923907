import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row, Col, FieldSet } from '../../framework/containers'

export default class AddressDetails extends Component {
    view() {
        const { address, noPoBox, className, readOnly, label } = this.props
        
        return <FieldSet className={className} title={label ? label : 'Address Details'} variant='field'>
            <Row className='spacing-3'>
                {!noPoBox && <EInput name='poBox' instance={address} reference='address.' disabled={readOnly}/>}
                <EInput name='civic' instance={address} reference='address.' disabled={readOnly}/>
                <EInput name='city' instance={address} reference='address.' onChange={this.props.onChange} disabled={readOnly}/>
            </Row>
            <Col/>
            <Row className='spacing-3'>
                <EInput name='prov' instance={address} reference='address.' onChange={this.props.onChange} disabled={readOnly}/>
                <EInput name='country' instance={address} reference='address.' disabled={readOnly}/>
                <EInput name='pc' instance={address} reference='address.' disabled={readOnly}/>
            </Row>
        </FieldSet>
    }
}

