import { Definition, Ref } from '../../../framework/infra'
import { concatStr, DATE_FORMAT } from '../../../framework/utils/helper'
import { renderPercentage } from '../../../framework/utils/renders'
import moment from 'moment';

export const TYPES = {
    LEGACY: 'Legacy',
    PERSON: 'Person',
    UNKNOWN: 'Person (No SIN)',
}

export default class Beneficiary extends Ref {

    get effDt() { return moment.utc(this.designationReceivedDate).format(DATE_FORMAT) }
    set effDt(dt) { this.designationReceivedDate = moment(dt).utc().valueOf() }

    get reportDesc() {
        return concatStr(this.person.desc, ' | ') + renderPercentage(this.shareOfProceeds, ' | ') + concatStr(this.person.dob)
    }

    get details() {
        let details = '';
        switch (this.type) {
            case TYPES.PERSON:
                details += concatStr(this.person.name, ' - ') + concatStr(this.relationship, ' - ') + renderPercentage(this.shareOfProceeds, ' - ') + (this.person.dob ? ' - ' + moment(this.person.dob).utc().format(DATE_FORMAT) : ' ')
                break;
            case TYPES.UNKNOWN:
                details += concatStr(this.unknownPerson.name, ' - ') + concatStr(this.relationship, ' - ') + renderPercentage(this.shareOfProceeds, ' - ') + (this.unknownPerson.dob ? ' - ' + moment(this.unknownPerson.dob).utc().format(DATE_FORMAT) : ' ')
                break;
            case TYPES.LEGACY:
                details += concatStr(this.legacy); 
                break;
            default: 
                break;
        }
        return details;
    }

    get type() {
        if (this.isLegacy) { return TYPES.LEGACY }
        else if (this.person.id !== '') 
        { return TYPES.PERSON; }
        else
        { return TYPES.UNKNOWN; }
    }

    get key() {
        return `${this.parent}_${this.id}_${(this.person.id !=='' ? this.person.id : '')}`;
    }
}

Beneficiary.definitions = {
    person: { ref: require('../../person/Person') },
    unknownPerson: { ref: require('../../person/UnknownPerson') },
    noSin: { type: Definition.types.BOOLEAN },
    legacy: { type: Definition.types.STRING, text: 'Legacy Beneficiary' },
    isLegacy: { type: Definition.types.BOOLEAN, text: 'Is Legacy' },
    type: { abstract: true, type: Definition.types.STRING, text: 'Type' },
    details: { abstract: true, type: Definition.types.STRING, text: 'Details' },
    id: { type: Definition.types.STRING },
    parent: { type: Definition.types.STRING },        
    participationNo: { type: Definition.types.STRING },
    effDt: { abstract: true, type: Definition.types.DATE, text: "Designation Received Date" },
    relationship: {
        type: Definition.types.STRING,
        text: 'Relationship'
    },
    shareOfProceeds: {
        type: Definition.types.PERCENTAGE,
        text: 'Share of Proceeds'
    },
    designationReceivedDate: {
        type: Definition.types.TIMESTAMP,
        text: 'Designation Received Date',
        shortText: 'Date'
    },
    rts: { type: Definition.types.TIMESTAMP },
    key: { abstract: true },

}

