import { Adjustments } from "../entities";
import Mapper from "./Mapper";

export default class AdjustmentListMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.adjustments = new Adjustments();
    }

    async map () {
        await this.load('spouses');
        await this.load('people');
        await this.load('remittances');
        await this.load('beneficiaries');
        await this.load('memberships');
        await this.load('employments');
        await this.load('details');
        this.adjustments.pushList(await this.load('adjustments'));
        return this.adjustments;
    }
}