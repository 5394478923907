import Definition from './Definition'
import Ref from './Ref'

export default class Entity extends Ref {
    
    static definitions = {
        id: { type: Definition.types.STRING },
        cts: { type: Definition.types.TIMESTAMP },
        mts: { type: Definition.types.TIMESTAMP },
        mur: { type: Definition.types.USER },
    }

    static isEntity = true
    static key = 'id'
}
