import { Ref, RefMap, RefHistorical, Definition, Entity } from '../../framework/infra'

class LegacyParticipationStatus extends Ref {
    static definitions = {
        GROUP_NO: { type: Definition.types.STRING, text: 'Employer'},
        EMP_NO: { type: Definition.types.STRING, text: 'Emp No'},
        PENSION_NO: { type: Definition.types.STRING, text: 'Pension No'},
        JOIN_DATE: { type: Definition.types.DATE, text: 'Join Date'},
        STATUS: { type: Definition.types.STRING, text: 'Sts'},
        STATUS_TYPE: { type: Definition.types.STRING, text: 'Sub'},
        CHG_DATE: { type: Definition.types.DATE, text: 'Chg Date'},
        PROC_DATE: { type: Definition.types.DATE, text: 'Proc Date'},
        SWITCH_TO: { type: Definition.types.STRING, text: 'Switch'},
        REMARK1: { type: Definition.types.STRING, text: 'Comment'}, 
    }
}

class LegacyParticipationHistory extends RefHistorical {
    static ref = LegacyParticipationStatus
}

class LegacyParticipation extends Ref {
    static definitions = {
        no: { type: Definition.types.STRING, text: 'Participation #' },
        history: { ref: LegacyParticipationHistory, text: 'History', text_fr: 'History' },
    }
    static key = 'no'
}

class LegacyParticipations extends RefMap {
    static ref = LegacyParticipation
}

export default class LegacyMember extends Entity {
    static definitions = {
        participations: { ref: LegacyParticipations, text: 'Particiaptions', text_fr: 'Participations' },
        legacyBeneficiaries: {type: Definition.types.STRING, text: 'Legacy Beneficiaries'},
    }
    
    static LegacyStatus = LegacyParticipationStatus
}
