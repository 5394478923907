import React, { useEffect, useState } from "react"
import { SystemService } from '../../framework/infra'
import { Button } from '../../framework/controls'
import { Excel } from '../../framework/utils'
import { Component, PageTitle } from '../../framework/components'
import { Row, Col, SideBar } from '../../framework/containers'
import Menu from "../../framework/infra/Menu"
import DuplicatedSinReport from '../person/DuplicatedSinReport'
import SimilarPeopleReport from '../person/SimilarPeopleReport'
import RBCPensionReport from '../employee/reports/RBCPensionReport'
import CQPPStatusReport from "../member/reports/CQPPStatusReport"
import NewMembersReport from "../member/reports/NewMembersReport"
import RemittancesSummaryReport from "../financial/remittance/reports/RemittancesSummaryReport"
import RemittancesLogReport from "../financial/remittance/reports/RemittancesLogReport"
import RBCPaymentsContribsReport from "../financial/payments/RBCPaymentsContribsReport"
import MessageWarningReport from '../member/reports/MessageWarningReport'
import EmployerContactsReport from '../employer/reports/EmployerContactsReport'
import TransferReport from "../member/reports/TransferReport"
import EventReport from "../member/reports/EventReport"
import { useParams, useHistory } from "react-router-dom"


const ReportsPage = () => {
    const getMenu = () => {
        const menu = {
            members: {key: 'members', text: 'Members', open: false, subMenu: [
                {key: 'warnings', text: 'Tasks', page: MessageWarningReport},
                {key: 'events', text: 'Event Report', page: EventReport, route: '/reports/events/'},
                {key: 'cqppStatus', text: 'Member CQPP/Native/Tax Status', page: CQPPStatusReport},
                {key: 'transfer', text: 'Transfer Report', page: TransferReport}
            ]},
            remSummaries: {key: 'remSummaries', text: 'Remittances', open: false, subMenu: [
                {key: 'contributionDetailLog', text: 'Contribution Detail Log', page: RemittancesSummaryReport, refreshWithButton: true},
                {key: 'processingLog', text: 'Processing Log', page: RemittancesLogReport, refreshWithButton: true}
            ]},
            mercer: {key: 'mercer', text: 'Mercer', open: false, subMenu: [
                {key: 'newMembers', text: 'New Members', page: NewMembersReport},
            ]}, 
            rbc: {key: 'rbc', text: 'RBC', open: false, subMenu: [
                {key: 'rbcPension', text: 'RBC Report - Pensioner Taxation Status', page: RBCPensionReport},
            ]},
            auditor: {key: 'auditor', text: 'Auditor', open: false, subMenu: [
                {key: 'payment', text: 'RBC Payment and Contributions', page: RBCPaymentsContribsReport}
            ]},
            dataIntegrity: {key: 'dataIntegrity', text: 'Data Integrity', open:false, subMenu: [
                {key: 'similarPeopleReport', text: 'Possible Duplicates', page: SimilarPeopleReport, params: {year: '2020'}},
                {key: 'duplicatedSin', text: 'Duplicated SIN\'s', page: DuplicatedSinReport, params: {year: '2020'}},
                {key: 'integrityWarnings', text: 'Integrity Warnings', page: IntegrityWarningsReport}
            ]},
            employers: {key: 'employers', text: 'Employers', open: false, subMenu: [
                {key: 'employersContacts', text: 'Employers contacts', page: EmployerContactsReport}
            ]},
        }
       
        return menu
    }

    let menu = getMenu();

    const { type } = useParams();
    const history = useHistory();
 
    //find meny item base on subkey
    let urlSelected;
    if (type) {
        for (const key in menu) {
            const item = menu[key].subMenu.find(sub => sub.key === type);
            if (item) {
                urlSelected = item;
            }
        }
    }

    const [uniqueKey, setUniqueKey] = useState(0); 
    const [selectedMenu, _setSelectedMenu] = useState(urlSelected);
    const [exportDirectly, setExportDirectly] = useState(false);

    const setSelectedMenu = (menu) => {
        if (menu.route) {
            history.replace(menu.route);
        }else {
            history.replace('/reports/:type');
        }
        //set new key on menu click to destroy last component
        setUniqueKey(uniqueKey+1);
        _setSelectedMenu(menu);
    };

    return <>
            <Row className='align-items-end' >
                <PageTitle className='mt-1'>Reports
                </PageTitle> 
            </Row>
            <SideBar>
                <SideBar.Left className='w-15'>
                    <Menu menu={Object.values(menu)} active={selectedMenu} variant="sidebar" onSelect={(item) => setSelectedMenu(item)}/> {/** we put active = selectedMenu when we're using this.busy() to keep the selectedMEnu*/}
                </SideBar.Left>
                <SideBar.Body> 
                    {selectedMenu && selectedMenu.page && <selectedMenu.page key={uniqueKey} exportDirectly={exportDirectly} query={selectedMenu.query} params={selectedMenu.params} reportTitle={selectedMenu.reportTitle} refreshWithButton={selectedMenu.refreshWithButton}/>}
                </SideBar.Body>
            </SideBar>
        </>
}
export default ReportsPage;
class IntegrityWarningsReport extends Component {
    load() {
        return {report: SystemService.getDataIntegrityWarnings()}
    }
    view() {
        return <> 
            <Row><Col right><Button key="export" type="link" onClick={this.handleExport.bind(this)} >Export</Button></Col></Row>
            {this.state.report.map(warning => <Row>{warning}</Row>)}
        </>
    }
    handleExport() {
        this.busy(() => {
            const headers = [{ title: 'Warnings', name: "warning"}]
            const values = this.state.report.map(warning => ({warning}))
            const excel = new Excel('Data_integrity_warning')
            excel.addSheet(headers, values, 'Warnings')
            return excel.download()
        })
    }
}

