import { Ref, Definition } from '../../../framework/infra'
import EarningCategory from './EarningCategory'

export default class EarningType extends Ref {
    get category() { return EarningType.categories[this.code && this.code.charAt(0)] || EarningCategory.default }
    getIndex() { return Number(this.code.slice(1)) }
    isDeemedEarningType() {
        return this.category.isDeemed();
    }

    getHayesCode() { return 'ASHA_' + this.category.key }
    getHayesLabel() { return 'A.S. Hayes Adjustment ' + this.category.key }
    static key = 'code';
}

const { CHOICE, STRING, BOOLEAN } = Definition.types
EarningType.definitions = {
    code: { type: STRING },
    category: { abstract: true, ref: require('./EarningCategory'), text: 'Earnings Type Category'},
    alias: { type: STRING, text: 'Code',  text_fr: 'Code'},
    strict: { type: BOOLEAN, text: 'Strict',  text_fr: 'Strict'}, //bucket not editable or deletable
    label: { type: STRING, text: 'Label',  text_fr: 'Label'},
    hoursOrRate: { type: CHOICE, text: 'Hours/Rate', text_fr: 'Heures/Taux', default: 'hours', options: [
        { key: 'hours', text: 'Hours'},
        { key: 'rate', text: 'Rate'},
    ]},
}

EarningType.splitEarningsTypesByCategory = function(earningTypes) {
    return Object.getOwnPropertyNames(EarningCategory.types).reduce((categories, catKey) => {
        categories[catKey] = {
            text: EarningCategory.types[catKey].desc,
            earningTypes: earningTypes.filter(type => type.category.key === catKey) 
        }
        return categories
    }, {})
}

EarningType.categories = {
    r: EarningCategory.types.REG,
    o: EarningCategory.types.OVR,
    t: EarningCategory.types.OTE,
    n: EarningCategory.types.NPE,
    m: EarningCategory.types.MAT,
    l: EarningCategory.types.LTD,
    s: EarningCategory.types.SLF,
    y: EarningCategory.types.Y19,
}