import { Ref, Definition } from '../../framework/infra'

export default class Invoice extends Ref {

}

const { AMOUNT, STRING, DATE, REF, BOOLEAN } = Definition.types
Invoice.definitions = {
    amount: { type: AMOUNT, text: 'Amount', text_fr: 'Amount' },
    no: { type: STRING, text: 'Reference #', text_fr: 'No. de reference' },
    rcvDate: { type: DATE, text: 'Received', text_fr: 'Reçu' },
    distr: { ref: require('./Distribution'), text: 'Distribution' },
    isSolvency: { type: BOOLEAN, text: 'Solvency' },
}

