import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

export default class AutoSuggest extends React.Component {
    handleInputChange(text, e) {
        this.props.instance[this.props.realName] = text
        if(this.props.onChange) this.props.onChange(text)
    }
    handleChange(selection) {
        const val = (selection && selection[0] && selection[0].key) || ''

        this.props.instance[this.props.realName] = val
        if(this.props.onChange) this.props.onChange(val)
    }

    render() {
        const { name, required, className, instance, suggestions, realName, readOnly, disabled } = this.props
        return (
            <Typeahead
                allowNew
                className={className}
                // ref={this.myRef}
                labelKey="text"
                name = {name}
                id = {name}
                key = {name}
                value = {instance[realName]}
                defaultInputValue = {instance[realName]}
                options={suggestions}
                required={required}
                emptyLabel={null}
                readOnly={readOnly}
                disabled={disabled}
                onChange={this.handleChange.bind(this)}
                onInputChange={this.handleInputChange.bind(this)}
            />
        )
    }
}