import React from "react";
import { EInput } from "../../../framework/components";
import { Row, Card } from "../../../framework/containers";

const BankChequeForm = ({ cheque }) => {
    const { address } = cheque;
    return (
        <Card className="px-3 py-3 my-3">
            <h3>Cheque</h3>
            <EInput name="addressee" instance={cheque} cn="col-12" />
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="civic" instance={address} cn="col-9" />
                <EInput name="poBox" instance={address} cn="col-2" />
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="city" instance={address} cn="col-3" />
                <EInput name="prov" instance={address} cn="col-3" />
                <EInput name="country" instance={address} cn="col-3" />
                <EInput name="pc" instance={address} cn="col-2" />
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <EInput name="notice" instance={cheque} cn="col-9" />
                <EInput name="disabled" instance={cheque} cn="col-2" />
            </div>
        </Card>
    );
};

export default BankChequeForm;
