import React, { useState, useEffect } from "react";
import { FieldSet, Row, Title } from "../../framework/containers";
import { Button, EInput } from "../../framework/components";
import { Select } from "../../framework/controls";
import Loading from "../../components/containers/Loading";
import { EmployerService, EmploymentService } from "../../services";
import PersonSummary from "../person/PersonSummary";
import { Employment, Person } from "../../entities";
import moment from "moment";
import useNotification from "../../hooks/useNotification";
import { useHistory } from "react-router-dom";
import { EMPLOYMENT_SOURCE } from "../../entities/employment/Employment";

const NewEmploymentForm = ({onCancel, employer}) => {

    const [choices, setChoices] = useState();
    const [selectedEmployer, setSelectedEmployer] = useState();
    const [hiredDate, setHiredDate] = useState();
    const [selectedPerson, setSelectedPerson] = useState(new Person());
    const [mockResults, setMockResults] = useState();
    const [employment, setEmployment] = useState();
    const { addMessage } = useNotification();
    const history = useHistory();
    

    useEffect(async () => {
        const employers = !employer ? (await EmployerService.getAll()) : [employer];
        const choices = employers.map(e => {
            return {
                text: `${e.code} - ${e.name}`,
                key: e.id,
                value: e.id,
                employer: e
            }
        });

        setChoices(choices);
        setSelectedEmployer(choices[0].key);
    }, []);

    //if values change, create a new employment object
    useEffect(() => {
        if (selectedEmployer && hiredDate && selectedPerson.id !== "") {

            const newEmployment = new Employment({
                employer: choices.find(x => x.key === selectedEmployer).employer,
            });

            newEmployment.participation.membership.person = selectedPerson;
            newEmployment.addEvent({ code: 'hrd', ets: moment(hiredDate).valueOf() });

            setEmployment(newEmployment);
        }
    }, [selectedEmployer, hiredDate, selectedPerson]);
    
    //if employment changes, trigger creation of mock results
    useEffect(async () => {
        if (employment) {
            const results = await EmploymentService.createEmployment(employment.clone(), EMPLOYMENT_SOURCE.MANUAL, { noCommit: true });
            setMockResults(results);
        }
    }, [employment]);

    if (!choices) return <Loading/>

	const handlePersonChange = (person) => {
		setSelectedPerson(person);
	}

    const handleDateChange = (e) => {
        setHiredDate(e.target.value);
    }

    const handleCreate = async () => {
        try {
            const results = await EmploymentService.createEmployment(employment, EMPLOYMENT_SOURCE.MANUAL );
            let code = choices.find(x => x.key === selectedEmployer).employer.code;
            history.push(`/employer/${code}/employment/${results.employment.keyValue}`);
        } 
        catch (e) {
            console.error(e);
            addMessage('Error creating employment', 'danger');
        }

    }

    const disabled = (!mockResults || mockResults.noAction) ? true : false;
    const dateInstance = new Employment();

    return (
        <div>
            <Title title={"New Employment"} onHide={onCancel} />
            { mockResults?.warning && <Row > <div className="attention simple-card w-100">{mockResults?.warning}</div> </Row> }
            <Row className="mt-2">
                <PersonSummary
                    cn='w-100'
                    title={<Row> Person Details </Row>} 
                    allowPersonChange={true} 
                    person={selectedPerson} 
                    onSwitchPerson={handlePersonChange} 
                    onNewPerson={handlePersonChange}/> 
            </Row>
            <Row className="mt-2">
                <FieldSet title="Employment Details" className="w-100">
                    <Row>
                        <EInput name='hiredDate' label='Hired Date' instance={dateInstance} onChange={handleDateChange} value={hiredDate} isControlled={false}/>
                        <EInput name='employerId' label='Employer' instance={choices[0].employer}  >
                            <Select options={choices} value={selectedEmployer} onChange={setSelectedEmployer} readOnly={choices.length === 1}/>
                        </EInput> 
                    </Row>
                </FieldSet>
            </Row>
            <Row className="mt-2">
                <div className="col" />
                <Button key="Yes" onClick={handleCreate} disabled={disabled}>
                    Create
                </Button>
                <Button key="No" onClick={onCancel}>
                    Cancel
                </Button>
            </Row>
        </div>
    );
}

export default NewEmploymentForm;