
import React from 'react'
import { Control } from '.'
import Period from '../utils/Period'
import Select from './Select'

export default class PeriodSelect extends Control {
    render() {
        const { name, label, value, from, to, includeYearEnd, definition, options, readOnly } = this.props;
        const periodOptions = (options || definition.options || Period.getPeriods(from, to, includeYearEnd)).map(p => ({key: p.value, text: p.text}));
        return this.wrapper(<Select name={name} value={value ? value.value : ''} label={label} options={periodOptions} readOnly={readOnly} searcheable={false} onChange={this.handleChange.bind(this)} />);
    }

    handleChange(value) {
        if(this.props.onChange) {
            this.props.onChange(Period.create(value));
        }
    }
}
