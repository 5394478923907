import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EmployerService } from "../../services";
import { employerTabsConfig } from "../../entities/company/EmployerTabConfig";
import EmployerWrapper from "../../components/containers/EmployerWrapper";
import Loading from "../../components/containers/Loading";
import useEmployer from "../../hooks/useEmployer";

const EmployerPage = () => {
    const { pathname } = useLocation();
    const { employerId } = useEmployer();
    const [isLoading, setIsLoading] = useState(true);
    const [employer, setEmployer] = useState();
    const pathKey = pathname.split("/").slice(-1);
    const isTabKey = Object.keys(employerTabsConfig).includes(pathKey[0]);
    const Page = isTabKey
        ? employerTabsConfig[pathKey].Page
        : employerTabsConfig.remittances.Page;

    useEffect(() => {
        let isMounted = true;
        if (employerId) {
            EmployerService.getEmployer(employerId, {
                ignorePeopleFetch: pathKey[0] === "remittances" || !isTabKey,
            }).then((data) => {
                if (isMounted) {
                    setEmployer(data);
                    setIsLoading(!employerId);
                }
            });
        }
        return () => { isMounted = false };
    }, [employerId]);

    return (
        <EmployerWrapper>
            {!isLoading && employer && employer.keyValue ? (
                <Page employer={employer} />
            ) : (
                <Loading />
            )}
        </EmployerWrapper>
    );
}

export default EmployerPage;