import React from 'react'

export default class InputControl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {value: props.value}
        this.onChange = this.handleChange.bind(this)
    }
    
    onChange (event) {
        event.preventDefault()
        event.stopPropagation()
        this.setState({value: event.target.value})
        if (this.props.onChange) this.props.onChange(event)
    }
    
    render() {
        const { name, required, className,readOnly } = this.props
       
        return (
            <input 
                className={className}
                required = {required}
                name= {name}
                key= {name}
                value = {this.state.value}
                onChange={this.onChange} 
                readOnly={readOnly}
            />
        )
    }
}
