import RefMessage from './RefMessage'
import Definition from './Definition'
import RefDescription from './RefDescription'
import {  deepFreeze } from '../../utils/helper'
import { ParticipationTaskConfig } from '../../../entities/membership/participation/ParticipationTaskConfig'

export default class RefTask extends RefMessage {
    
    get done() { if (this.resEvent.code) return true }

    static get messages() { return this._messages || {} }
    static set messages(messageList) {
        this._messages = deepFreeze(messageList.reduce((types, msg) => { 
            const msgDesc = types[msg[0]] = new RefDescription({key: msg[0], text: msg[1], name: msg[2], severity: msg[3], options: msg[4]})
            this[msgDesc.key] = msgDesc
            return types 
        }, {}))
    }

    static definitions = {
        resEvent: { ref: require('./RefEvent'), text: 'Resolution' },
        flowCode: { type: Definition.types.String },
        done: { abstract: true, type: Definition.types.BOOLEAN, text: "Completed" },
    }
    static isRefTask = true

    static events = []
}
