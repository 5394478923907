export const getPDFTexts = (isStatement) => ({
    "year-end-adjs": {
        "en" : "{year} Year-End Adjustments",
        "fr" : "Ajustments fin-d'année {year}"
    },
    "report-title": {
        "en" : isStatement ? "MONTHLY CONTRIBUTIONS STATEMENT" : "SUMMARY REPORT",
        "fr" : isStatement ? "RELEVÉ MENSUEL DES COTISATIONS" : "SOMMAIRE DU BILAN",
    },
    "report-subtitle": {
        "en" : "This report describes your most recent modifications. An official monthly statement will be available on the first day of the the month.",
        "fr" : "Ce rapport illustre vos dernières modifications. Un relevé mensuel officiel sera disponible le premier jour du mois."
    },
    "issue-date": {
        "en" : "Issue Date",
        "fr" : "Date d'émission"
    },
    "period": {
        "en" : "Period",
        "fr" : "Période"
    },
    "due-date": {
        "en" : "Due Date",
        "fr" : "Date d'échéance"
    },
    "balance": {
        "en" : "Balance",
        "fr" : "Solde à payer"
    },
    "previous-balance": {
        "en" : "Previous Balance",
        "fr" : "Solde précédent"
    },
    "interest": {
        "en" : "Interest",
        "fr" : "Intérêt sur le solde précédent"
    },
    "current-contrib": {
        "en" : "Current Contributions",
        "fr" : "Total des cotisations de service courant"
    },
    "employee": {
        "en" : "Employee",
        "fr" : "Employé"
    },
    "payroll-deductions": {
        "en" : "Payroll Deductions",
        "fr" : "Retenues sur la paie"
    },
    "er-paid-maternity": {
        "en" : "Employer-Paid Maternity",
        "fr" : "Maternité payée par l'employeur"
    },
    "er-paid-ltd": {
        "en" : "Employer-Paid LTD",
        "fr" : "ILD payée par l'employeur"
    },
    "self-contributions": {
        "en" : "Self-Contributions",
        "fr" : "Indépendantes"
    },
    "adjustments": {
        "en" : "Adjustments",
        "fr" : "Ajustements"
    },
    "employer": {
        "en" : "Employer",
        "fr" : "Employeur"
    },
    "er-contrib-factor": {
        "en": "Employer Contribution Factor: {percentage}%",
        "fr": "Taux de cotisation payé par l'employeur: {percentage}%"
    },
    "voluntary": {
        "en" : "Voluntary",
        "fr" : "Volontaire"
    },
    "solvency-deficit": {
        "en" : "Solvency Deficit",
        "fr" : "Total des cotisations de solvabilité"
    },
    "required-monthly-payment": {
        "en" : "Required Monthly Payment",
        "fr" : "Paiement mensuel requis"
    },
    "payment-received": {
        "en" : "Payments Received in {duePeriodFormated}",
        "fr" : "Paiements reçu en {duePeriodFormated}"
    },
    "total-balance": {
        "en" : "Balance",
        "fr" : "Montant total à payer"
    },
    "refer-statement": {
        "en": "Refer to the previous statement for breakdown",
        "fr": "Veuillez-vous référer au relevé précédent pour la description du montant"
    },
    "refer-adjustments": {
        "en": "Refer to Addendum",
        "fr": "Veuillez-vous référer à l'appendice"
    },
    "notice": {
        "en" : "Advise A.S Hayes Consulting of the date and amount of your transfer or the funds will not be deposited",
        "fr" : "Avisez A.S. Hayes Consulting de la date et du montant de votre virement ou les fonds ne seront pas déposés"
    },
    "report-warning": {
        "en" : "Overdue amounts are subject to 1% penalty charges and will be added to your next Statement as interest",
        "fr" : "Les montants en souffrance sont sujets à des frais de pénalité de 1% et seront ajoutés à votre prochain relevé à titre d'intérêts"
    },
    "statement-no": {
        "en" : "Statement No",
        "fr" : "Relevé No"
    },
    "credit-used": {
        "en" : "Employer Contributions used to offset credit",
        "fr" : "Cotisations de l'employeur utilisées pour compenser le crédit"
    },
    "total-owing": {
        "en" : "Total Owing",
        "fr" : "Total dû"
    },
    "year-end-payments": {
        "en": "Payments Received for Year End {year}",
        "fr": "Paiements fin-d'année {year}"
    },
});