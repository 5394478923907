import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import Loading from "../../../components/containers/Loading";
import { EInput } from "../../../framework/components";
import { FieldSet, Row, Title } from '../../../framework/containers'
import { Form } from '../../../framework/controls'
import { useEmployerStore } from '../../../hooks/useEmployerStore';
import useNotification from '../../../hooks/useNotification'
import { EmployerService, PlanService } from '../../../services'

const EmployerInfoForm = ({ employer, readOnly, onCancel }) => {

    let history = useHistory();
    const allEmployers = useEmployerStore((state) => state.employers);
    const refreshEmployerStore = useEmployerStore((state) => state.storeEmployers);
    const { addMessage } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [plan, setPlan] = useState(employer.plan);
    const [errors, setErrors] = useState({});
    const [originalCode] = useState(employer.code);
    const handleClose = () => { if (onCancel) onCancel() };

    const handlePlanChange = async (rst) => {
        const plan = await PlanService.getPlan(rst); 
        employer.plan = plan;
        setPlan(plan);
    }
    
    const handleSave = async (employer) => {
        const codeExists = originalCode !== employer.code && allEmployers[employer.code];
        if (codeExists) {
            setErrors({code: 'This code already exists.'});
            return;
        }

        if (employer.isNew()) {
            const response = await EmployerService.create(employer);
            if (response.errors) {
                setErrors(response.errors);
                setIsLoading(false);
                return;
            } else {
                addMessage("Employer successfully added", "success");
            }
        } else {
            await EmployerService.update(employer);
            addMessage("Employer successfully updated", "success");
        }

        await refreshEmployerStore();
        history.push(`/employer/${employer.code}/details`);
        handleClose();
    };

    return (
        <>
            {!readOnly && <Title title="Employer Info" onHide={handleClose} />}
            {isLoading ? (
                <Loading />
            ) : (
                <Form
                    id="EmployerInfoForm"
                    data={employer}
                    onSave={!readOnly && handleSave}
                    onCancel={!readOnly && handleClose}
                >
                    <FieldSet
                        title="Employer Information"
                        className="mt-2"
                        variant="field"
                    >
                        <Row>
                            <EInput 
                                showRequiredLabelMarker
                                name="code"
                                instance={employer}
                                readOnly={readOnly}
                                error={errors.code}
                            />
                            <EInput
                                name="name"
                                showRequiredLabelMarker
                                instance={employer}
                                readOnly={readOnly}
                                error={errors.name}
                            />
                            <EInput
                                name="act"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                name="rst"
                                showRequiredLabelMarker
                                instance={employer}
                                readOnly={readOnly}
                                onChange={handlePlanChange}
                                error={errors.rst}
                            />
                        </Row>
                        <Row className="mt-2">
                            <EInput
                                name="joinDt"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                name="withDrawDt"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                name="eligibilityRestriction"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                name="participatingEeGroup"
                                instance={employer}
                                readOnly={readOnly}
                            />
                        </Row>
                    </FieldSet>
                    <FieldSet
                        title="Registration Numbers"
                        className="mt-2"
                        variant="field"
                    >
                        <Row className="">
                            <EInput
                                name="businessNumber"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                name="craBusinessNumber"
                                instance={employer}
                                readOnly={readOnly}
                            />
                            <EInput
                                label="Mercer Division ID"
                                name="mercerDiv"
                                instance={employer}
                                readOnly={readOnly}
                            />
                        </Row>
                    </FieldSet>
                    <FieldSet
                        title="Plan information"
                        className="mt-2"
                        variant="field"
                    >
                        <Row className="">
                            <EInput
                                name="benefitNumber"
                                instance={plan}
                                readOnly={true}
                            />
                            <EInput
                                name="osfiRegistratioNumber"
                                instance={plan}
                                readOnly={true}
                            />
                            <EInput
                                name="craRegistrationNumber"
                                instance={plan}
                                readOnly={true}
                            />
                        </Row>
                    </FieldSet>
                </Form>
            )}
        </>
    );
};

export default EmployerInfoForm;