import React from 'react'

export default class Control extends React.Component {
    render() {
        return this.__render(this.props.children)
    }
    
    wrapper(content) {
        return this.__render(content)
    }
    
    __render(content) {
        const className = [this.className, this.props.className, this.props.cn].join(' ')
        return <div className={className}>
            {this.props.label && <label className={this.props.lcn}>{this.props.label}</label>}
            {content}
        </div> 
    }

    get controlProps() {
        const { name, value, required, readOnly, ccn, placeholder, disabled } = this.props
        return { 
            id: name,  
            key: name, 
            required: required, 
            readOnly: readOnly, 
            disabled: disabled,
            className: ccn, 
            placeholder: placeholder, 
            onChange: this.handleChange.bind(this),
            onBlur: this.handleBlur.bind(this),
            value: value || ''
        }
    }
    
    handleChange(value) {
        if(this.props.onChange) this.props.onChange(value)
    }
    handleBlur() {}
}
