const Card = ({ title, actions=[], children, cn= '', style = {}}) => (
    <div className={"card-panel full-framed " + cn} style={style}>
        <div className="d-flex justify-content-between card-label">
            <div className="bold">{title}</div>
            <div className="d-flex g10"> 
                {actions.map((action, index) => (
                    <div key={index} className="card-action">
                        {action}
                    </div>
                ))}
            </div>
        </div>
        <div className="card-panel-body">
            {children}
        </div>
    </div>
)

export default Card;