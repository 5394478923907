import React from 'react'
import { FastField } from "formik";
import { Card, Form } from "react-bootstrap";
import Checkbox from '../../../../components/form/Checkbox';
import { NewEmployerChecklistEvent } from '../../../../entities/company/newEmployer/NewEmployerChecklistEvent';
import { newEmployerChecklistConfig } from '../../../../entities/company/newEmployer/NewEmployerConfig';


const EmployerChecklistItem = ({ groupKey, index }) => {
    return (
        <Card className="mt-4">
            <div className="d-flex justify-content-between">
                <h5 className="bold">
                    {NewEmployerChecklistEvent.groups[groupKey]}
                </h5>
                {index === 0 && (
                    <h5 className="w-25 d-flex justify-content-center">Date</h5>
                )}
            </div>
            {NewEmployerChecklistEvent.messagesOption(groupKey).map((item, i) => (
                <div className="new-employer checklist-item" key={`${item.key}-${i}`}>
                    <Checkbox
                        name={`${item.key}.completed`}
                        label={item.value.text}
                        className={`mr-2 ${
                            newEmployerChecklistConfig[item.key]
                                ?.isSecondCheckBox
                                ? "ml-4"
                                : ""
                        }`}
                    />
                    {!newEmployerChecklistConfig[item.key]?.hideDate && (
                        <Form.Group
                            className="w-25"
                            controlId={`${item.key}-date-group`}
                        >
                            <FastField
                                as={Form.Control}
                                aria-label="Date"
                                name={`${item.key}.date`}
                                type="date"
                            />
                        </Form.Group>
                    )}
                </div>
            ))}
        </Card>
    );
};

export default EmployerChecklistItem;
