import { Service } from '../../framework'

import Plan from "../../entities/pension/Plan"
import RatesService from './RatesService'

class PlanService extends Service {
    constructor() {
        super(Plan, 'Plan', 'config')
    }
    
    getPlans(options = {}) { 
        const promise = () => Promise.all([
            this.getAll(), 
            RatesService.getRates()
        ]).then(([plans, rates]) => {
            if (plans['fed']) {
                const planPol = plans['fed'].clone()
                planPol.code = 'pol'
                plans.push(planPol)
            }
            plans.forEach(plan => plan.historicRates = rates[plan.code])
            return plans
        })
        return this.useCache('_plans', promise, options.refresh) 
    }

    getPlan(rst, options) {
        return this.getPlans(options).then(allRates => allRates[rst])
    }

}

const instance = new PlanService()
export default instance
