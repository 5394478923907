import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankEft extends  Ref {
    
}

const { BOOLEAN, REF, STRING } = Definition.types

// "eft": {
//     "accounts": {
//       "fed": "James Bay Cree Canada Plan 5042015",
//       "que": "James Bay Cree Quebec Plan 5041512"
//     },
//     "address": {
//       "city": "Toronto",
//       "civic": "155 Wellington St. West, 4th Floor",
//       "country": "Canada",
//       "pc": "M5V 3L3",
//       "poBox": "",
//       "prov": "Ontario"
//     },
//     "bank": "Royal Bank of Canada",
//     "bank_code": "0003",
//     "notice": "",
//     "disabled": false,
//     "transit": "09504"
//   },


ConfigBankEft.definitions = {
    account_fed: { ref: require('./ConfigBankRegistrationAccount'), text: 'Federal Account', text_fr: 'Compte Quebec' },
    account_que: { ref: require('./ConfigBankRegistrationAccount'), text: 'Quebec Account', text_fr: 'Numero Compte Quebec' },
    address: { ref: require('../../address/Address'), text: 'Address', text_fr: 'Adresse' },
    address_account: { ref: require('../../address/Address'), text: 'Account Address', text_fr: 'Adresse du Compte' },
    bank: { type: STRING, text: 'Bank', text_fr: 'Banque' },
    bank_code: { type: STRING, text: 'Bank Code', text_fr: 'Code bancaire' },
    transit: { type: STRING, text: 'Transit', text_fr: 'Transit' },
    disabled: { type: BOOLEAN, text: 'Disabled', text_fr: 'Désactivé' },
    notice: { type: STRING, text: 'Notice', text_fr: 'Avis' },
}


