import { RefList } from "../framework/infra";
import Mapper from "./Mapper";

export default class PeopleMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.people = new RefList();
    }

    async map () {
        await this.load('people', {load: false});
        await this.load('spouses');
        this.people.pushList(await this.load('people'));
        return this.people;
    }
}