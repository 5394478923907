import { Icon } from "../../controls";
import { toSearchString } from "../helper";
import { renderComponent } from "../renders";
import { fields } from "./eligibilityUploadFields";
import { INVALID_FIELD_STRING } from "./eligibilityUploadFormatters";
import { toCleanString } from "./eligibilityUploadFormatters";
import { updateOtherEmploymentEventFromStatus } from "./eligibilityUploadUpdaters";

/**
 * Assigns a comment to an event if available and not empty
 * @param {ParticipationEvent | EmploymentEvent} event
 * @param {string} property
 * @returns {ParticipationEvent | EmploymentEvent}
 */
export function assignComment(event, property, loadedInstance) {
	const comment = loadedInstance[property + 'Comment'];

	if (comment) {
		event.cmt = comment;
	}
	return event;
}


/**
 * Gets all fields and extra dynamic fields such as comments for events
 * @returns {array}
 */
export const getFields = () => {
	const allFields = [];
	fields.forEach(field => {
		allFields.push(field);
		if (field.event) {
			allFields.push({
				entityName: field.entityName,
				name: field.name + 'Comment',
				title: field.title + ' Comment',
				alias: field.alias.map(a => a + 'comment'),
				format: toCleanString,
			})
		}
	});
	return allFields;
}

export const matchHeaders = (fileHeaders) => {
	fileHeaders = fileHeaders.map(h => toSearchString(h))
	const mapped =  getFields().reduce((mapped, field) => {
		const index = fileHeaders.findIndex(header => {
			return field.alias.includes(header) || toSearchString(field.title) === header
		})
		if(index >= 0) mapped.push(Object.assign({}, field, {index}))
		return mapped
	}, [])
	
	return mapped;

}

/**
 * Goes through each update function configured in fields array by property,
 * will mark the merged+ employment as touched if any changes are found. 
 * @param {string} entityName For example `Person` or `Employment` or `Participation`
 * @param {Person | Employment | Participation} entity 
 * @param {FileRow} row 
 * @param {string} dest For example `merged` or `new`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 */
export const updateProperties = (entityName, entity, row, dest = '', optionalInstances) => {
	//need to copy to avoid changing the cached object
	const draft = entity.clone();
	const isNew = dest === 'new';

	getFields().filter(field => field.entityName === entityName).forEach((field) => {
		// if the entity exists already and the field is blocked from updating, skip
		if (!isNew && field.isUpdateBlocked) return;
		if (field.update) {
			const updateResponse = field.update(draft, row['loaded' + entityName], field.setter ?? field.name, optionalInstances);
			if (updateResponse.updated) {
				row[dest + entityName] = draft.clone();
				row[dest + entityName].touch();
				if (dest === 'merged') //only for merged items, not for new items
					row.updatedProperties[dest + entityName +'.'+ field.name] = updateResponse.output;
			}
		}
	});

	if(entityName === 'Employment') {
		const updateOtherEventsResponse = updateOtherEmploymentEventFromStatus(draft, row['loaded' + entityName], optionalInstances);
		if (updateOtherEventsResponse?.updated) {
			row[dest + entityName] = draft.clone();
			row[dest + entityName].touch();
			if (dest === 'merged') //only for merged items, not for new items
			{
				Object.keys(row.extraData?.employment ?? {}).forEach((extraDataFieldName) => {
					row.updatedProperties[dest + entityName +'.'+ extraDataFieldName] = updateOtherEventsResponse.output;
				});
			}
		}
	}
}

/*
	Will render a tooltip containing the value before the update to visualize changes
*/
export const hasOldValue = (key, value, instance) => {
	if (instance.updatedProperties.hasOwnProperty(key)) {
		const val = instance.updatedProperties[key]?.oldVal;
		const date = instance.updatedProperties[key]?.date;
		const isEmpty = val === '' && val !== 'null' && val !== undefined;
		return renderComponent(<><Icon 
				icon={isEmpty ? 'plus-circle' : 'exclamation-circle'}
				tooltip={(isEmpty ? '' : 'Was ') + "'" + (isEmpty ? 'New value' : val)  + "'" + (date ? ' at ' + date : '')}
				tooltip-right
				className={'text-primary' + (isEmpty ? '-dim' : '')}
			/> {instance.updatedProperties[key]?.newVal ?? value}</>)
	}
	return value;
}

/**
 * Gets the number of invalid fields in a row (per person)
 * @param {*} row table row
 * @returns {number} number of invalid fields
 */
export const getInvalidFieldCount = (row) => {
	const invalidFields = getFields().filter(field => {
		const value = row['loaded' + field.entityName]?.[field.name];
		if (field.format(value) === INVALID_FIELD_STRING) {
			return true;
		}
		return false;
	});
	return invalidFields.length;
}

