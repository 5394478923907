import React from 'react'

export default class Container extends React.Component {
    static cn = 'container'
    
    render() {
        return this._render(this.props.children, this.props.className)
    }
    
    wrapper(content, className) {
        return this._render(content, className)
    }
    
    _render(content, className) {
        return <div className={[this.props.className, className, this.constructor.cn].join(' ')}>{content}</div> 
    }
}
