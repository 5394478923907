import { RefHistorical, RefEvent } from '../../../framework/infra';
import { yeCertificationConfigs } from './YECertificationConfig';

export class YECertificationEvent extends RefEvent {
    get config() { 
        return { ...yeCertificationConfigs['default'], ...yeCertificationConfigs[this.code]}
    }

    static messages = [
        ['personalInformation', 'Personal Information'],
        ['pensionableEarnings', 'Pensionable Earnings (excluding Deemed)'],
        ['requiredContributions', 'Required Contributions'],
        ['voluntaryContributions', 'Voluntary Contributions'],
        ['creditedService', 'Credited Service'],
        ['pensionAdjustments', 'Pension Adjustments'],
        ['statusAtDec31', 'Status at December 31, '],
        ['bme', 'Base Monthly Earnings'],
    ]

    static definitions = {
        config: { abstract: true },
    }

    static get messagesOption() {
        return Object.values(this.messages).map(opt => ({key: opt.key, value: opt}));
    }
}

export class YECertificationEvents extends RefHistorical {
    pushEvent(eventData, params){
        const event = this.create(eventData);
        this.push(event);
        if(event.config.next) event.config.next(params);
    }
    
    static ref = YECertificationEvent;
}
