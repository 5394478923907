import React from "react"

import LoginWrapper from './LoginWrapper'
import Auth from '../Auth'

export default class ChangePassword extends LoginWrapper {
    handleResetPassword(event) {
        event.preventDefault();
        
        if (event.currentTarget.checkValidity() === false) {
            this.setState({validated: true})
        } else {
            const { code, newPassword, confirmPassword } = event.target
            if (newPassword.value !== confirmPassword.value) {
                this.setState({message: {errorCode: 'noMatchingPasswords', text:"Password didn't match, please try again"}})
            } else {
                return Auth.resetPassword(this.props.userName, code.value, newPassword.value).then(data => {
                    Auth.signIn(this.props.userName, newPassword.value).then(data => {
                        window.location.reload()
                    })
                }).catch(err => {
                    this.setState({message:err})
                })
            }
        }
    }
    
    handleCancel(event) {
        event.preventDefault()
        window.location.reload()
    }

    view() {
        return (
            <form noValidate validated={this.state.validated} onSubmit={this.handleResetPassword.bind(this)} className="login-form">
                <div className="alert alert-info">A Verification Code has been sent to your inbox</div>
                <div className="md-form">
                    <input className="form-control" id="code" name="code" label="Verification Code" type="text" required/>
                    <label className="active" for="code" data-error="" data-success="" id="" aria-labelledby="">Username</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="newPassword" name="newPassword" label="New Password" type="password" required/>
                    <label className="active" for="newPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="confirmPassword" name="confirmPassword" label="Confirm New Password" type="password" required/>
                    <label className="active" for="confirmPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="text-center sign-in-no-opacity">
                    <button className="btn sign-in change-password" type="submit">Change Password</button>
                </div>
                <p className="font-small blue-text d-flex justify-content-end">
                    <a href="#!" className="ml-1 forgot-password" onClick={this.handleCancel.bind(this) }>Cancel</a>
                </p>
            </form>
        )
    }
}