import { Definition,  Ref } from '../../framework/infra'

export default class ConfigCorporate extends Ref {
    getNumberByUsage(usage) {
        let p = this.phones.find((x) => x.usage === usage);
        return p && p.number;
    }

    getEmailByUsage(usage) {
        let p = this.emails.find((x) => x.usage === usage);
        return p && p.email;
    }
    static key = ["id"];
}

const { STRING, REF } = Definition.types
ConfigCorporate.definitions = {
    id: {},
    address: { ref: require('../address/Address'), text: 'Address', text_fr: 'Adress' },
    emails: { ref: require('../address/Email'), list: true, text: 'Emails', text_fr: 'Courriels' },
    name: { type: STRING, text: 'Name', text_fr: 'Nom' },
    phones: { ref: require('../address/Phone'), list: true, text: 'Numbers', text_fr: 'Numeros' },
}
