import { Tabs, Title } from "../../../containers";
import { useEffect, useState } from "react";
import Loading from "../../../../components/containers/Loading";
import { AdjustmentService } from "../../../../services";
import AdjustmentValidationItem from "./AdjustmentValidationItem";

const AdjustmentValidation = ({
    instance, // employment
    title,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [previous, setPrevious] = useState();
    const [current, setCurrent] = useState();

    useEffect(() => {
        let isMounted = true;
        const fetch = async () => {
            const { prevYear, currentYear } = await AdjustmentService.createDeemedAdjustment(instance);
            setPrevious(prevYear);
            setCurrent(currentYear);
		}
		fetch().then(() => {if(isMounted) setIsLoading(false);});
		return () => { isMounted = false };
    }, []);

    return <>
        <Title title={title} onHide={onClose} />
        {!isLoading ? 
            <Tabs className="mt-1 w-100">
                <Tabs.Tab name="prevYear" title="Previous Year"><AdjustmentValidationItem data={previous} instance={instance} onClose={onClose} /></Tabs.Tab>
                <Tabs.Tab name="currentYear" title="Current Year"><AdjustmentValidationItem data={current} instance={instance} onClose={onClose} /></Tabs.Tab>
            </Tabs>
            : <Loading />}
    </>
}

export default AdjustmentValidation;