import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row, Col, Card, FieldSet } from "../../framework/containers"
import { Select } from "../../framework/controls"
import { RefundAccount } from '../../entities/pension'
import RefundAccountList from './RefundAccountList'

export default class Pension extends Component {
    view() {
        const { pension, membership, readOnly } = this.props
        console.log(pension)
        const refundAccounts = membership.refundAccounts
        const accountOptions = pension ? getFilteredAccount(refundAccounts.all) : [] 
       
        return <><FieldSet className='input-spacing-2 mt-2'>
            <Row className='pb-1'>
                <EInput name='retirementDate' instance={pension} onChange={this.handleCalculate.bind(this)} readOnly={readOnly}/>
                <EInput name='paymentStartDate' instance={pension} onChange={this.handleCalculate.bind(this)} readOnly={readOnly}/>
                <EInput name='pensionAmount' instance={pension} onChange={this.handleCalculate.bind(this)} readOnly={readOnly}/>
                <Col/>   
                <EInput name='retroactiveAmount' instance={pension} readOnly={true}/>
            </Row>
            </FieldSet>
            <Card className='ml-3 mt-3'>
                <Row className='spacing-3'>
                    <EInput name='pensionType' instance={pension} onChange={() => this.props.onChange()} readOnly={readOnly}/>
                    <EInput name='authType' instance={pension} readOnly={readOnly}/>
                    <EInput name='payeeType' instance={pension} onChange={() => this.props.onChange()} readOnly={readOnly}/>
                </Row>
                <Row className='spacing-3'>
                    <EInput name='indexationDate' instance={pension} readOnly={readOnly}/>
                    <EInput name='guaranteeEndDate' instance={pension} readOnly={readOnly}/>
                    <EInput name='bankAccount' instance={pension}><Select name="name" className="col" readOnly={readOnly} nullable={true} options={accountOptions} value={pension.bankAccount.keyValue} onChange={this.handleAccountSelect.bind(this)} /></EInput>
                </Row>
            </Card>
            <RefundAccountList membership={membership} onChange={this.handleChange.bind(this)} readOnly={readOnly}/>
        </> 
    }
    handleCancel() { this.setState({selected: null, editAccount: false}) }
    handleCalculate() { this.setState({ isDirty: true }) }
    handleEditAccount() { this.setState({ editAccount: true }) }
    handleChange() { 
        this.setState({touched: true})
        if(this.props.onChange) this.props.onChange() 
    }
    handleAccountSelect(key, data) {
        this.props.pension.bankAccount = data ? data : new RefundAccount()
        this.setState({switched: true})
        if(this.props.onChange) this.props.onChange()
    }
  
}
function getFilteredAccount(accounts) {
    return accounts.reduce((filteredList, acc) => {
        if (!acc.accountType.lockedIn) {
            filteredList.push({key: acc.keyValue, text: acc.searchDesc, value: acc})
        }
        return filteredList
    }, [])
}