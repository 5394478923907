import { Editor, EditorState, RichUtils } from "draft-js";

const StyleButton = ({style, onToggle, active, label}) => {
	const onMouseDown = (e) => {
			e.preventDefault();
			onToggle(style);
	}
	 
	const className = !active ? 'RichEditor-styleButton' : 'RichEditor-styleButton RichEditor-activeButton'
	
	return (
		<span className={className} onMouseDown={onMouseDown}>
			{label}
		</span>
	);

}


const BLOCK_TYPES = [
	{label: 'H1', style: 'header-one'},
	{label: 'H2', style: 'header-two'},
	{label: 'H3', style: 'header-three'},
	// {label: 'H4', style: 'header-four'},
	// {label: 'H5', style: 'header-five'},
	// {label: 'H6', style: 'header-six'},
	// {label: 'Blockquote', style: 'blockquote'},
	{label: 'UL', style: 'unordered-list-item'},
	{label: 'OL', style: 'ordered-list-item'},
	// {label: 'Code Block', style: 'code-block'},
];


const BlockStyleControls = ({editorState, onToggle}) => {
	const selection = editorState.getSelection();
	const blockType = editorState
	.getCurrentContent()
	.getBlockForKey(selection.getStartKey())
	.getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) =>
							 <StyleButton
								 key={type.label}
								 active={type.style === blockType}
								 label={type.label}
								 onToggle={onToggle}
								 style={type.style}
								 />
							)}
		</div>
	);
};

const INLINE_STYLES = [
	{label: 'Bold', style: 'BOLD'},
	{label: 'Italic', style: 'ITALIC'},
	{label: 'Underline', style: 'UNDERLINE'},
	{label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = ({editorState, onToggle}) => {
	var currentStyle = editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map(type =>
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
					/>
			)}
		</div>
	);
};


const RichTextEditor = ({editorState, onChange, decorators}) => {
    const toggleBlockType = (blockType) => {
		onChange(
			RichUtils.toggleBlockType(
				editorState,
				blockType
			));
	}

	const toggleLink = () => {
		const selection = editorState.getSelection();
		if(!selection.isCollapsed()){
			const url = window.prompt('Enter the URL of the link:');
			if (url) {
				const content = editorState.getCurrentContent();
				const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url });
				const entityKey = contentWithEntity.getLastCreatedEntityKey();
				const newEditorState = EditorState.set(editorState, { currentContent: contentWithEntity });
				onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
			}
		}
	}

    const toggleInlineStyle = (inlineStyle) => {
		onChange(
			RichUtils.toggleInlineStyle(
				editorState,
				inlineStyle
			)
		);
	}

	const handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
		  this.onChange(newState);
		  return true;
		}
		return false;
	  }


    return(
        <div className="RichEditor-root">
            <div className="d-flex">
				<BlockStyleControls editorState={editorState} onToggle={toggleBlockType}/>
				<StyleButton label='link' onToggle={toggleLink}/>
			</div>

            <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle}/>
			
            <div className="RichEditor-editor">
                <Editor editorState={editorState} onChange={onChange} decorators={decorators} handleKeyCommand={handleKeyCommand}/>
            </div>
        </div>
    )
}

export default RichTextEditor;