export const texts = {
    "year-end-adjs": {
        "en": "{year} Year-End Adjustments",
        "fr": "Ajustments fin-d'année {year}"
    },
    "addendum-invoice-title": {
        "en": "Addendum to Summary ({remittancePeriod})",
        "fr": "Appendice ({remittancePeriod})",
    },
    "addendum-statement-title": {
        "en": "Addendum to Statement REF #{statementNo}",
        "fr": "Appendice #{statementNo}",
    },
    "addendum-subtitle": {
        "en": "Adjustment",
        "fr" : "Ajustement"
    },
    "ee-name": {
        "en": "Name",
        "fr": "Nom",
    },
    "e": {
        "en": "Employee Contribution",
        "fr": "Cotisation employé",
    },
    "em": {
        "en": "Employee Maternity",
        "fr": "Maternité payée par l'employeur",
    },
    "es": {
        "en": "Employee Self",
        "fr": "Indépendante",
    },
    "el": {
        "en": "Employee LTD",
        "fr": "ILD payée par l'employeur",
    },
    "ev": {
        "en": "Employee Voluntary",
        "fr": "Volontaire",
    },
    "r": {
        "en": "Employer Contribution",
        "fr": "Cotisation employeur",
    },
    "r-remaining": {
        "en": "Remaining Employer Contribution",
        "fr": "Cotisation employeur restante",
    },
    "s": {
        "en": "Solvency",
        "fr": "Cotisation d'équilibre de solvabilité ",
    },
    "s-remaining": {
        "en": "Remaining Solvency",
        "fr": "Cotisation d'équilibre de solvabilité restant",
    },
    "i": {
        "en": "Interest",
        "fr": "Intérêt sur le solde précédent",
    },
    "total-amount": {
        "en": "Total Amount",
        "fr": "Montant total à payer"
    },
    "comment": {
        "en": "Comment",
        "fr": "Commentaire"
    },
    "adjustmentType": {
        "en": "Type",
        "fr": "Type",
    }, 
    "adjustmentCreditUsed": {
        "en": "Cumulative Employer Contributions",
        "fr": "Cotisations de l'employeur cumulatives",
    }, 
    "adjustmentCreditUsed2": {
        "en": "used to offset credit",
        "fr": "utilisées pour compenser le crédit",
    }, 
    "adjustmentUsedCreditCancel": {
        "en": "Amount",
        "fr": "Montant",
    }, 
};