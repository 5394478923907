import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

const Tabs = ({ data, defaultKey, tabs }) => {
    return (
        <Nav variant="tabs" fill activeKey={defaultKey}>
            {tabs.map((tab) => {
                return (
                    <Nav.Item key={tab.name}>
                        <Nav.Link
                            as={NavLink}
                            to={tab.path(data)}
                            eventKey={tab.name.toLowerCase()}
                            replace
                        >
                            {tab.name}
                        </Nav.Link>
                    </Nav.Item>
                );
            })}
        </Nav>
    );
};

export default Tabs;
