
export default class Business {
    constructor (instance) {
        this._instance = instance
    }
    
    validate() { return [] }

    isValid(validations) {
        const errors = validations.reduce((errors, rule) => {
            const message = rule()
            if (message && message.severity === 'e') errors.push(message)
            return errors
        }, [])
        return errors.length > 0
    }
}
    