import React from 'react'
import Control from './Control'

export default class Check extends Control {
    render() {
         return this.wrapper(<input {...this.controlProps} type='checkbox' checked={this.controlProps.value}/>)
    }

    handleChange(event, value){
        if(this.props.onChange) this.props.onChange(!this.props.value)
    }
}
