import { Definition, Ref } from '../../framework/infra'
const { STRING } = Definition.types

class Treasury extends  Ref {
    // get email() { return this.emails && this.emails[0]}
}
Treasury.definitions = {
    emails: {  list: true, ref: require('../address/Email'), text: '', text_fr: ''}, // something wrong, 'abstract: true' not working
    attn: {type: STRING, text: '', text_fr: ''},
    address: {  ref: require('../address/Address'), text: 'Address' },
    departmentName: {type: STRING, text: '', text_fr: ''}
}

export default class Trustee extends  Ref{}

Trustee.definitions = {
    treasury: {  ref: Treasury, text: '', text_fr: ''}
}
