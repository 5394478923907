//Class to apply various defined constraints on the participation events history
class ParticipationConstraints {

    constructor () {
        this.constraints = {
            //Rule for eligibility events, they should never come after an active join event
            //if they do, they should be replaced by an event with no ELIG status and vice versa
            eligibility: ({participation}) => { 
                let joinEvent = participation.getActiveStatusEvent();
                let eligibilityEvents = participation.eligibilities;
                let alreadyEligibleEvents = participation.events.getFiltered(x=>x.config.isAlreadyEligible);

                for (const eligEvent of eligibilityEvents.all) {
                    if (joinEvent && eligEvent.ets > joinEvent.ets) eligEvent.code = 'aElig';
                }

                for (const aEligEvents of alreadyEligibleEvents.all) {
                    if (!joinEvent || aEligEvents.ets <= joinEvent.ets) aEligEvents.code = 'metEligDate';
                }
            }
        };
    }

    apply({participation}) {
        for (const [key, constraint] of Object.entries(this.constraints)) {
            constraint({participation});
        }
    }

}

export default new ParticipationConstraints();