import React, { useState } from 'react'

import { Table  } from '../../framework/controls'
import CompanyContact from '../../entities/company/CompanyContact'

const ContactList = ({ contacts, selected, onSelect }) => {
    const [selectedContact, setSelectedContact] = useState(selected); 
    const columns = new Table.Headers(
        CompanyContact,
        "person.name, pos, adm, cme, trr, person.lng, person.alternatePhone, person.alternateEmail, cmt"
    );
    columns.pos.width = 200;
    columns.adm.width = 145;
    columns.cme.width = 120;
    columns.trr.width = 120;
    columns["person.name"].width = 250;
    columns["person.lng"].width = 120;
    columns["person.alternatePhone"].width = 220;
    columns["person.alternateEmail"].minWidth = 250;
    columns["cmt"].minWidth = 250;

    const handleSelect = (row) => {
        setSelectedContact(row);
        if (onSelect) onSelect(row);
    };

    return (
        <Table
            id="contacts-table"
            data={[...contacts.all]}
            entity={CompanyContact}
            columns={columns}
            dataKey="id"
            selected={selectedContact && selectedContact.id}
            className="header-no-gap"
            onSelect={handleSelect}
            fitHeightToContent
        />
    );
};

export default ContactList;