import React from 'react'
import Container from './Container'
import ContainerChild from './ContainerChild'

export default class FieldSet extends Container {
    static cn = ''
    constructor(props) {
        super(props)
        this.state = { open: this.props.open !== false   }
    }

    render() {
        const { collapsable, title, variant, className } = this.props
        const { open } = this.state

        return this.wrapper(<fieldset className={(className || '') + (!open ? ' fieldset-close' : '')}>
            <legend className={collapsable ? 'collapsable' : ''} hidden={!title}>
                <div className={('legend-' + variant) || ''}>{title || ''}</div> 
                {collapsable && <>
                    <div className='stretch fieldset-top-line'/>
                    <div onClick={() => this.setState({open: !open})} className={(open ? 'icon-noun-dropdown-up' : 'icon-noun-dropdown') + ' collapse-icon v-center'} />
                </>}
            </legend>
            {open && this.props.children}
        </fieldset>)
    }       

}