import { EmploymentService, ParticipationService } from '../../../../services';
import CalendarValidation from '../CalendarValidation';


const CalendarDetailResolution = ({
    isEmployment,
    dateLabel,
    disableComment,
    message, 
    params,
    remittanceDetail,
    taskSave,
    onCancel,
    onSave,
    messageParams,
    definedDate,
}) => {
    const handleOnSave = (instance) => {
        const promise = () => isEmployment 
            ? EmploymentService.updateEmployment(instance)
            : ParticipationService.updateParticipation(instance);
        if (onSave) onSave(remittanceDetail, promise);
    };

    return (
        <CalendarValidation
            dateLabel={dateLabel}
            onClose={onCancel}
            onSave={handleOnSave}
            instance={
                isEmployment
                    ? remittanceDetail.employment
                    : remittanceDetail.employment.participation
            }
            startDate={definedDate ? messageParams[definedDate].value : undefined}
            taskSave={taskSave}
            message={message}
            params={params}
            disableComment={disableComment}
        />
    );
};

 export default CalendarDetailResolution;