import React from 'react'
import { Form } from 'react-bootstrap'

import Choice from './Choice'

export default class Check extends React.Component {
    
    handleChange(event) {
        this.props.instance[this.props.realName] = !this.props.instance[this.props.realName]
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }
    
    render() {
        const { name, required, className, instance, realName, readOnly, disabled, variant } = this.props;
        if (variant === 'dropdown') {
            const options = [
                { key: 'y', text: 'Yes'},
                { key: 'n', text: 'No'}
            ]
            return <Choice {...this.props} options={options} />
        } else {
            return <Form.Control 
                type="checkbox" 
                className = {className}
                name = {name}
                key = {name}
                required = {required}

                onChange = {this.handleChange.bind(this)}
                checked = {instance[realName]}
                readOnly={readOnly} 
                disabled={disabled}
            />
        }
    }
}