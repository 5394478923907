
import React from "react"
import { Component } from '../../framework/components'
import { Table, Button } from '../../framework/controls'
import { Row, Col } from '../../framework/containers'
import { Excel, Progress } from '../../framework/utils'
import { toSearchString, round, compareTwoStrings } from '../../framework/utils/helper'

import { Person } from '../../entities'
import { PersonService } from '../../services'

const SIN_ACCURACY = 0.80, NAME_ACCURACY = 0.70

export default class SimilarPeopleReport extends Component {
    load() {
        return PersonService.getAll().then(people => {
            const toCompare = people.map(per => ({ per: per, sin: !per.sin ? '' : String(per.sin).trim(), ln: toSearchString(per.lastName), fn: toSearchString(per.firstName) }))
            const processed = {}
            const progress = new Progress(toCompare.reduce((count, p, i) => count + i, 0), 100, true)
	        const similar = toCompare.reduce((res, p1, index) => {
                toCompare.slice(index+1).forEach((p2) => {
                    progress.stepUp()
                    const rSin = round(p1.sin && p2.sin ? compareTwoStrings(p1.sin, p2.sin) : -1)
                    if (rSin > SIN_ACCURACY) {
                        const rFirst = round(p1.fn && p2.fn ? compareTwoStrings(p1.fn, p2.fn) : -1 )
                        const rLast = round(p1.ln && p2.ln ? compareTwoStrings(p1.ln, p2.ln) : -1, 2)
                        if (rFirst > NAME_ACCURACY || rLast > NAME_ACCURACY) {
                            if(!processed[p1.per.id]) res.push({ group: p1.per.id, person: p1.per })
                            res.push({ group: p1.per.id, person: p2.per, rSin: rSin, rFirst: rFirst, rLast: rLast })
                            processed[p1.per.id] = p1.per.id
                        }
                    }
                })
                return res
            }, [])
            return {report: { potentialDuplicates: similar }}
        })
    }

    view() {
        const { report } = this.state
        const headers = new Table.Headers(Person, ['person.sin', 'person.firstName', 'person.middleName' ,'person.lastName', 'person.dob'])
        const groupText = (value, count, data, groupComponent) => {
            return data[0].person.formatedSin + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>"
        }

        return <> 
            <Row><Col right><Button key="export" type="link" onClick={this.handleExport.bind(this)} >Export</Button></Col></Row>
            <Table id='similars' data={report.potentialDuplicates} columns={headers} groupBy="group" groupText={groupText}/>
        </>
    }

    handleExport() {

        this.busy(() => {
            const headers = new Excel.Headers(Person, ['person.sin', 'person.firstName', 'person.middleName' ,'person.lastName', 'person.dob'])
            const excel = new Excel('Similar People Report')
            excel.addSheet(headers, this.state.report.potentialDuplicates, 'Similar People Report')
            return excel.download()
        })
    }
}
