import React from 'react';
import { CompositeDecorator, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { convertToHTML } from "draft-convert";
import { isEmptyObject } from 'jquery';
import { renderToString } from 'react-dom/server';
import { Ref, Definition }  from '../../framework/infra'
import { findLinkEntites, Link } from '../../framework/inputs/Editor/LinkEditor';
import { isEmailValid, isEmpty, safeEmail } from '../../framework/utils/helper';
import { emailFooter, emailSource, emailTemplates } from '../../templates/email-templates/email';
import { Employment } from '../employment';
import ReactDOMServer  from "react-dom/server";

export default class EmailForm extends Ref{

    constructor(data) {
        super(data)
        
        this.to = this.getEmailArray(data.templateInfo.to ?? '', true).join(', ');
        this.cc = this.getEmailArray(data.templateInfo.cc ?? '', true).join(', ');
        this.from = emailSource.default;

        const body = emailTemplates[data.templateKey].body;
        const htmlComponent = React.cloneElement(body, data.templateInfo);
        const html = ReactDOMServer.renderToString(htmlComponent) + ReactDOMServer.renderToString(data.attachment);

        this.defaultBody = html;
    }

    initEditorState() {
        const content = stateFromHTML(this.defaultBody);
        const decorators = this.getDecorators();
        this.body = EditorState.createWithContent(content, new CompositeDecorator(decorators));
        return this.body;
    }

    getDecorators() {
        return [{ strategy: findLinkEntites, component: Link }];
    }

    async getContent() {
        let htmlBody = this.getHtmlBodyWithFooter();
        htmlBody = await this.encodeImages(htmlBody);

        return { 
            source: this.from, 
            to: this.getEmailArray(this.to), 
            cc: this.getEmailArray(this.cc), 
            subject: this.subject, 
            body: htmlBody,
        }
    }

    isValid() {
        this.errors  = {};

        this.validateEmpty('to');
        this.validateEmpty('subject');
        this.validateEmails('to');
        this.validateEmails('cc');
        
        if (isEmptyObject(this.errors)) {
            this.errors = null; 
            return true;
        }
        return false;
    }

    validateEmpty(prop) {
        if (isEmpty(this[prop] ?? '')) {
            this.errors[prop] = 'Required field.';
        }
    }

    validateEmails(prop) {
        const emails = this.getEmailArray(this[prop]);

        if (emails.find((email) => !isEmailValid(email))) { 
            this.errors[prop] = 'Invalid email'; 
        }
    }

    getEmailArray(emails = '', safe = false) {
        let newEmails = (emails?.replace(' ', '').split(',') ?? []).filter(x=>x !== '')
        if (safe) return safeEmail(newEmails);
        return newEmails;
    }

    getHtmlBodyWithFooter() {
        const html = convertToHTML({
            entityToHTML: (entity, originalText) => {
              if (entity.type === 'LINK') { return <a href={entity.data.url}>{originalText}</a> }
              return originalText;
            }
        })(this.body.getCurrentContent());

        const footer = renderToString(emailFooter.html);

        return`<div className="m-2">
            <div>${html}</div>
            ${footer}
        </div>`
    }

    async encodeImages(html) {
        let newHtml = html;
        const rex = /<img[^>]+src="([^">]+)/g
        let results, urls = [];

        while (rex.global && (results = rex.exec(newHtml))) {
            urls.push(results[1])
        }

        for (const url of urls) {
            const result = await fetch(url);
            const imgBlob = await result.blob();
            const base64 = new Promise((resolve)=>{
                let reader = new FileReader();
                reader.readAsDataURL(imgBlob); 
                reader.onloadend = () => resolve(reader.result);      
            })
            newHtml = newHtml.replace(url, await base64);
        }

        return newHtml;
    }   
}

EmailForm.definitions = {
    employment: { ref: Employment },
    from: { type: Definition.types.STRING, text: 'From' },
    to: { type: Definition.types.STRING, text: 'To' },
    cc: { type: Definition.types.STRING, text: 'Cc' },
    subject: { type: Definition.types.STRING, text: 'Subject' },
}
