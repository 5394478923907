import { useState } from "react"
import { promisify } from "util"
import { ExcelRenderer } from 'react-excel-renderer'
import { RefMessage, Ref } from "../../framework/infra";
import { Col, Row, Tabs  } from '../../framework/containers';
import { Button, Table, UploadButton } from '../../framework/controls';
import { Excel } from '../../framework/utils';
import { renderToString } from '../../framework/utils/renders'
import EmploymentBusiness from "../../business/EmploymentBusiness"
import { array2Object, setSafe, isValidDate, isValidSIN } from '../../framework/utils/helper';
import { Employment, Membership, Participation, Person } from '../../entities';
import { EmployerService, EmploymentService, MembershipService, PersonService, ParticipationService } from '../../services';
import { EmploymentEvent } from "../../entities/employment";
import EmploymentTasks from "../../entities/employment/EmploymentTasks";
import Loader from "../../components/containers/Loader";
import { EMPLOYMENT_SOURCE } from "../../entities/employment/Employment";
import MercerKeyUpload from "./MercerKeyUpload";
import { getFields, matchHeaders, updateProperties } from "../../framework/utils/eligibilityUpload/eligibilityUploadHelpers";
import { INVALID_FIELD_STRING, renderValue, toEmploymentEvent } from "../../framework/utils/eligibilityUpload/eligibilityUploadFormatters";
import { tabs } from "../../framework/utils/eligibilityUpload/eligibilityUploadFields";

const SUPPORTED_TYPES = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const MemberUpload = () => {
	const [rows, setRows] = useState([]);
	const [activeTab, setActiveTab] = useState('uploaded');
	const [isLoading, setIsLoading] = useState();
	const [processed, setProcessed] = useState(0);
	const [totalItems, setTotalItems] = useState();
	const [file, setFile] = useState();
	const [success, setSuccess] = useState(false);

	const dynamicFields = getFields();
	const personFields = dynamicFields.filter(field => field.entityName === 'Person');
	const employmentFields = dynamicFields.filter(field => field.entityName === 'Employment');
	const participationFields = dynamicFields.filter(field => field.entityName === 'Participation');

	let headers = []
	switch (activeTab) {
		case 'newPeople':
		case 'updatedPeople':
			headers = personFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name)); break;
		case 'newEmployments':	
		case 'updatedEmployments':
			const base = ['person.sin', 'person.name'];
			if (activeTab === 'updatedEmployments') base.push('employment.statusDesc');
			headers = [...base, ...employmentFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name))]; 
			break;
		case 'newParticipation':
		case 'updatedParticipation':
			headers = ['person.sin', 'person.name', ...participationFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name))]; 
			break;
		case 'tasksAfterSave':
			headers = ['person.sin', 'person.name', 'newEmployment.employer.code', 'empTasks']
			break;
		default:
			headers = getFields().map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name));
	}

	//Columns configuration
	const columns = new Table.Headers(FileRow, headers)
	for (const [key] of Object.entries(columns)) {
		if(rows.length > 0)  { 
			columns[key].hideIfEmpty = true; //hide all columns with no data
			columns[key].format = (val, inst) => renderValue(key, val, inst); //render icon showing previous value
		}
		
		const keySplit = key.split('.');
		const name = keySplit[keySplit.length-1];
		const field = getFields().find(x=>x.name === name); 
		if (field) columns[key].title = field.title; //update title based of value in fields and not the entity
	}
	columns['message'] = {name:'message.desc', title: 'Error', width:'200', hideIfEmpty: true};
	columns['empTasks'] = { name:'message.desc', title: 'Employment tasks', /* width:'300', */ hideIfEmpty: activeTab !== 'tasksAfterSave'};
	columns['empTasks'].format = (val, inst) => { // inst: FileRow
		const tasksMessageEls = inst.empTasks?.all?.map?.(x => x.title)?.filter(x => Boolean(x))?.map(x => <li>{x}</li>);
		return tasksMessageEls?.length ? renderToString(<ul>{tasksMessageEls}</ul>) : '';
	};
	const filterData = tabs[activeTab].filter ? rows.filter(tabs[activeTab].filter) : rows;

	const handleUpload = async (file) => {

		//Clear all service caches
		clearCache();

        if (!file) return this.error('noFileSelected');
        if (!SUPPORTED_TYPES.includes(file.type)) return this.error('fileNotSupported');

		const [excelFile, employers] = await Promise.all([promisify(ExcelRenderer)(file), EmployerService.getEmployers()]);
		const employersByCode = array2Object(employers, 'code')
		const fileHeaders = excelFile.rows.splice(0, 1)[0]
		const matches = matchHeaders(fileHeaders)	
		const matchesForPerson = matches.filter(match => match.entityName === 'Person')
		const matchesForEmployment = matches.filter(match => match.entityName === 'Employment')
		const matchesForParticipation = matches.filter(match => match.entityName === 'Participation')
		const activeRows = [];
		const excelRows = excelFile.rows.filter(row => row.length > 0);
		const processedSins = [];
		let processedCount = 0;

		setIsLoading(true);
		setTotalItems(excelRows.length);
		setProcessed(processedSins.length);

		for (let fileRow of excelRows) {
			const row = new FileRow();

			matchesForParticipation.forEach(match => { 
				const val = match.format ? match.format(fileRow[match.index]) : fileRow[match.index];
				if (val === INVALID_FIELD_STRING) row.message = new MemberUploadMessage({code: 'invalidField'});
				if (match.event && val) {
					row.loadedParticipation.events.push(val);
				} else {
					setSafe(row.loadedParticipation, match.name, val)
				}
			})
			matchesForPerson.forEach(match => { 
				const val = match.format ? match.format(fileRow[match.index]) : fileRow[match.index];
				if (val === INVALID_FIELD_STRING) row.message = new MemberUploadMessage({code: 'invalidField'});
				row.loadedPerson[match.name] = val;
			})
			matchesForEmployment.forEach(match => { 
				const commentMatch = matchesForEmployment.find(x => x.name === `${match.name}Comment`);
				const commentValue = commentMatch ? fileRow[commentMatch.index] : undefined;
				const val = match.format ? match.format(fileRow[match.index], commentValue) : fileRow[match.index];
				if (val === INVALID_FIELD_STRING) row.message = new MemberUploadMessage({code: 'invalidField'});

				if (match.event && val instanceof EmploymentEvent) {
					row.loadedEmployment.events.push(val);
				} else {
					setSafe(row.loadedEmployment, match.name, val)
				}
			});
			// add event from Status fields (event that needed several fields from the row)
			const otherEmpEvent = row.getOtherEmploymentEventFromStatus();
			if(otherEmpEvent) {
				row.loadedEmployment.events.push(otherEmpEvent);
			}
			
			if (!row.loadedPerson.sin) row.message = new MemberUploadMessage({code: 'missingSIN'})
			else if (!isValidSIN(row.loadedPerson.sin)) row.message =  new MemberUploadMessage({code: 'invalidSIN'})
			else if (processedSins.find(x=> x === row.loadedPerson.sin)) row.message = new MemberUploadMessage({code: 'duplicateSIN'})
			else if (!row.loadedEmployment.employer.code ||(row.loadedEmployment.employer.code && !employersByCode[row.loadedEmployment.employer.code])) row.message = new MemberUploadMessage({code: 'invalidErCode'})
			// note: don't check for required person name here, because if it's an update we only need the SIN
			
			if (!row.message.code) {

				//Find new person and person updates
				const employer = employersByCode[row.loadedEmployment.employer.code];
				const existingPerson = await PersonService.getPersonBySin(row.loadedPerson.sin);
				row.person = existingPerson;

				if (!existingPerson) {
					row.newPerson = row.loadedPerson.clone();
					row.newPerson.touch();
					row.person = row.newPerson;
				} else {
					updateProperties('Person', existingPerson, row, 'merged');
					if (row.mergedPerson.isTouched()) row.person = row.mergedPerson;
					if (row.loadedPerson.dob && row.loadedPerson.dob !== row.person.dob && !row.message.code) row.message = new MemberUploadMessage({code: 'dobChanged'});
				}

				//Find new employments and employment updates
				const existingMembership = row.person.id && await MembershipService.get(row.person.id, { includeEmployments: true });
				const existingEmployment = row.person.id && existingMembership && existingMembership.lastParticipation?.employments.find(ee => ee.employer.code === row.loadedEmployment.employer.code);
				const isClosedOrPending = existingEmployment?.participation.isPendingOrClosed();
				const isNewEmployment = !existingEmployment || isClosedOrPending;
				const employmentDest = isNewEmployment ? 'new' : 'merged';

				// if new employment
				if (isNewEmployment) {
					row.newEmployment = row.loadedEmployment.clone();
					row.newEmployment.participation.membership = row.newPerson.isTouched() ? new Membership({person: row.newPerson}) : existingMembership.clone();
					row.newEmployment.employer = employer;
                    row.newEmployment.employer.plan = employer?.plan;
					row.newEmployment.touch();

					if (!isValidDate(row.newEmployment.hiredDate)) {
						row.message = new MemberUploadMessage({code: 'noHireDate'})
					}

					updateProperties('Employment', row.newEmployment, row, employmentDest);

					if (row.newPerson.isTouched() || !existingMembership.person) {
						//if the person doesn't exist, no need to go into the flow. We need a new of everything
						row.newEmployment.participation.membership = new Membership({person: row.newPerson})
						row.newParticipation = row.loadedParticipation.clone();
						row.newParticipation.touch();
					} else {
						const lastPPno = existingMembership?.lastParticipation?.no;
						const res = await EmploymentService.createEmployment(row.newEmployment.clone(), EMPLOYMENT_SOURCE.IMPORT, {noCommit: true}); //don't want to update a direct reference so clone, set noCommit to true
						if (res.membership.lastParticipation.no !== lastPPno) { // a new PP was added
							row.newParticipation = res.membership.lastParticipation.clone();
							row.newParticipation.touch();
						} else {
							row.newEmployment.participation = existingMembership?.lastParticipation?.clone();
						}
					}
					row.employment = row.newEmployment;

				// if employment exists already
				} else {
					row.employment = existingEmployment.clone();
					row.participation = existingEmployment.participation.clone();
					updateProperties('Employment', existingEmployment, row, employmentDest);
					if (row.mergedEmployment.isTouched()) row.employment = row.mergedEmployment;
					if (row.loadedEmployment.hiredDate && row.loadedEmployment.hiredDate !== row.employment.hiredDate) row.message = new MemberUploadMessage({code: 'hiredDateChanged'});
				}

				// if new participation
				if (row.newParticipation.isTouched()) {
					updateProperties('Participation', row.newParticipation, row, 'new', {
						employmentInstance: row.employment,
					});
				} 

				// if participation exists already
				else {
					updateProperties('Participation', row.employment.participation, row, 'merged', {employmentInstance: row.employment});
					if (row.mergedParticipation.isTouched()) {
						row.participation = row.mergedParticipation;
					}
					if (row.loadedParticipation.joinDt && row.loadedParticipation.joinDt !== row.participation.joinDt) row.message = new MemberUploadMessage({code: 'joinDateChanged'});
				}

				processedSins.push(row.loadedPerson.sin);
			}

			processedCount++;
			setProcessed(processedCount)
			activeRows.push(row);
		}

		setFile(file);
		setRows(activeRows);
		setIsLoading(false);
	}

	const handleReset = () => { 
		setSuccess(false);
		setFile(null)
		setRows([]) 
		setActiveTab('uploaded');
	}

	const handleSave = async () => {

		//Clear all service caches
		clearCache();
		
		let processedCount = 0;
		setIsLoading(true);
		setProcessed(processedCount);

		for (let row of rows) {
			try {
				// checks before updating data
				if (row.newPerson.isTouched()) {
					if(!row.newPerson.name || !row.newPerson.firstName || !row.newPerson.lastName) row.message = new MemberUploadMessage({code: 'invalidName'});
				}
				if (row.message.code) continue;

				if (row.newPerson.isTouched()) {
					console.log('MembersUpload handleSave Creating person', row.newPerson)
					const createPersonResult = await PersonService.create(row.newPerson)
					if(createPersonResult?.errors && Array.isArray(createPersonResult.errors)){
						console.error(`Error while creating Person ${row.newPerson.sin}${createPersonResult.errors[0] ? `: ${createPersonResult.errors[0]}` : ''}`);
						throw new Error(createPersonResult.errors[0] || 'Unknown error while creating Person');
					}
				} else if (row.mergedPerson.isTouched()) {
					console.log('MembersUpload handleSave Updating person', row.mergedPerson)
					const updatePersonResult = await PersonService.update(row.mergedPerson)
					if(updatePersonResult?.errors && Array.isArray(updatePersonResult.errors)){
						console.error(`Error while updating Person ${row.mergedPerson.sin}${updatePersonResult.errors[0] ? `: ${updatePersonResult.errors[0]}` : ''}`);
						throw new Error(updatePersonResult.errors[0] || 'Unknown error while updating Person');
					}
				}
				if (row.mergedEmployment.isTouched()) {
					console.log('MembersUpload handleSave Updating employment', row.mergedEmployment)
					const updateEmploymentResult = await EmploymentService.update(row.mergedEmployment)
					if(updateEmploymentResult?.errors && Array.isArray(updateEmploymentResult.errors)){
						console.error(`Error while updating Employment ${row.mergedEmployment?.employer?.code} of member ${row.person.sin} (PP: ${row.mergedEmployment?.participation?.ppNo})${updateEmploymentResult.errors[0] ? `: ${updateEmploymentResult.errors[0]}` : ''}`);
						throw new Error(updateEmploymentResult.errors[0] || 'Unknown error while updating employment');
					}
				};

				if (row.newEmployment.isTouched()) {
					const membership = row.person.id && await MembershipService.get(row.person.id, { includeEmployments: true });
					row.newEmployment.participation.membership = row.newPerson.isTouched() ? new Membership({person: row.newPerson}) : membership;
					row.newEmployment.participation.membership.person = row.person;

					const lastPPno = row.newEmployment.participation.membership?.lastParticipation?.no;
					let result = await EmploymentService.createEmployment(row.newEmployment.clone(), EMPLOYMENT_SOURCE.IMPORT);
					if(result?.errors && Array.isArray(result.errors)){
						console.error(`Error while creating Employment ${row.newEmployment?.employer?.code} of member ${row.person.sin} (PP: ${row.mergedEmployment?.participation?.ppNo})${result.errors[0] ? `: ${result.errors[0]}` : ''}`);
						throw new Error(result.errors[0] || 'Unknown error while creating employment');
					}
					if(result.employment) {
						try {
							var clone = result.employment.clone();
							const empRes = EmploymentBusiness.validate(clone);
							row.empTasks = empRes.tasks;
						} catch(err){
							console.error(err);
						}
					}
					
					//can't rely on newParticipation because createEmployment makes the PP 
					if (result.membership.lastParticipation.no !== lastPPno) { 
						row.newParticipation = result.membership.lastParticipation.clone();
						updateProperties('Participation', row.newParticipation, row, 'new', { employmentInstance: result?.employment });

						//extra rule here since employment automatically marks as not ELIGIBLE, this might be false if an ELIG event was added
						if (row.newParticipation.events.find(e => e.isEligibleEvent())) {
							row.newParticipation.events.pullFilter(e => !e.status.isIneligible());
							row.newParticipation.touch();
						}

						if (row.newParticipation.isTouched()){
							console.log('MembersUpload handleSave Creating/Updating newParticipation', row.newParticipation)
							const updateParticipationResult = await ParticipationService.updateParticipation(row.newParticipation, {fileSource: true});
							if(updateParticipationResult?.errors && Array.isArray(updateParticipationResult.errors)){
								console.error(`Error while creating updating participation ${row.mergedEmployment?.participation?.ppNo} of member ${row.person.sin}${updateParticipationResult.errors[0] ? `: ${updateParticipationResult.errors[0]}` : ''}`);
								throw new Error(updateParticipationResult.errors[0] || 'Unknown error while updating participation');
							}
						}
					}
				} 
				 
				if (row.mergedParticipation.isTouched()) {
					console.log('MembersUpload handleSave Updating participation', row.mergedParticipation)
					const updateParticipationResult = await ParticipationService.updateParticipation(row.mergedParticipation, {fileSource: true});
					if(updateParticipationResult?.errors && Array.isArray(updateParticipationResult.errors)){
						console.error(`Error while creating updating participation ${row.mergedEmployment?.participation?.ppNo} of member ${row.person.sin}${updateParticipationResult.errors[0] ? `: ${updateParticipationResult.errors[0]}` : ''}`);
						throw new Error(updateParticipationResult.errors[0] || 'Unknown error while updating participation');
					}
				};

				
			} catch (e) {
				console.error('MembersUpload handleSave error', e);
				const newErrorMessage = {code: 
					e instanceof Error && e.message === "Invalid name, first and last name are required" ? 'invalidName' :
					'unknownError',
				cmt: e instanceof Error && e.message ? e.message : undefined}
				row.message = new MemberUploadMessage(newErrorMessage);
			}

			processedCount++;
			setProcessed(processedCount)
		}

		if(rows.find(row => row.message)) setActiveTab('errors');
		else setActiveTab('tasksAfterSave');
		setSuccess(true);
		setIsLoading(false);
		clearCache(); //need to do this so that the app pull latest data
	}

	const handleDownloadTemplate = () => {
		const type = 'loaded';
		const excel = new Excel('Member-Upload-template.xlsx')
		const headers = new Excel.Headers(FileRow, getFields().map(field => (type + field.entityName + '.' + field.name)))
		for (const property of headers._propNames){
			headers[property].title = getFields().find(x=> type + x.entityName + '.' + x.name === property)?.title
		}
		excel.addSheet(headers, []);
		excel.download()
	}

	const clearCache = () => {
		//Clear all service caches
		PersonService.invalidateCache();
		EmployerService.invalidateCache();
		EmploymentService.invalidateCache();
		MembershipService.invalidateCache();
		ParticipationService.invalidateCache();
	}

	/**
	 * Formats the row based on the message severity
	 * @param {*} row table row
	 */
	const handleRowFormatting = (row) => { 
		if (row.getData().message.severity === 'w') row.getElement().className += ' warning';
		else if (row.getData().message.severity === 'e') row.getElement().className += ' light-error';
	}
	
	return <>
		<Row >
			<Col right>
				<Row className="button-row">
					<Button type="link" className='btn-secondary' onClick={handleDownloadTemplate}>Export Template</Button>
					{file ? 
						<>
							<Button key="cancel" className='btn-primary' onClick={handleReset}>Reset</Button>
							<Button key="save" className='btn-primary' onClick={handleSave} disabled={rows.find(row => row.message.code && row.message.severity === 'e') || success}>Save</Button>
						</>
						: <UploadButton onUpload={handleUpload} text='Import Data' accept='.xls,.xlsx,.csv' displayfileName={false} /> }
					<MercerKeyUpload />
				</Row>
			</Col>
		</Row>

		<div className="table-container">
			{success && <div>The member information has been updated: {processed ? '✅ ' : ''}{processed} row(s) were saved successfully and {rows.filter(row => row.message?.code).length ? '❌ ' : ''}{rows.filter(row => row.message?.code).length} row(s) had errors preventing changes to be saved.</div> }
			<>
				{ isLoading ? <Loader processed={processed} total={totalItems}/> :
					<>
						<Tabs initial={activeTab} onChange={tab => setActiveTab(tab)} headerCN='mb-2'> 
							{Object.getOwnPropertyNames(tabs).filter(x=> (!success && x !== 'tasksAfterSave') || (success && (x === 'errors' || x === 'tasksAfterSave'))).map(tabName => {
								return <Tabs.Tab key={tabName} name={tabName} title={`${tabs[tabName].title} (${tabs[tabName].filter && rows.filter(tabs[tabName].filter).length})`} />
							})}
						</Tabs>
						<div className="info-panel">The "{tabs[activeTab].title}" table contains a list of {tabs[activeTab].description}</div>
						<Table id='upload-employees-table' data={filterData} columns={columns} rowFormatter={handleRowFormatting} />
					</>
				}
			</>

		</div>
	</> 
}
export default MemberUpload;


/**
 * Possible error/warning messages for the eligibility upload
 */
export class MemberUploadMessage extends RefMessage {
	static messages = [
		['missingSIN', 'No SIN provided', '', 'e'],
		['invalidName', 'Invalid name, first and last name are required', '', 'e'],
		['duplicateSIN', 'This SIN already appears in this file', '', 'e'],
		['invalidSIN', 'Invalid SIN number', '', 'e'],
		['invalidErCode','Invalid Employer Code', '', 'e'],
		['missingErCode', 'Employer Code Missing', '', 'e'],
		['noHireDate', 'A hire date is required to add a new employment', '', 'e'],
		['invalidStatusDate', 'The status date is not valid', '', 'e'],
		['missingStatusDate', 'The status date is missing', '', 'e'],
		['missingOrInvalidStatus', 'The status is not valid or is missing', '', 'e'],
		['invalidField', 'Invalid field', '', 'e'],
		['unknownError', 'An unknown error occured', '', 'e'],
		['dobChanged', 'Date of birth is different. Changes will not be saved through eligibility upload. Update manually.', '', 'w'],
		['hiredDateChanged', 'Hired date is different. Changes will not be saved through eligibility upload. Update manually.', '', 'w'],
		['joinDateChanged', 'Join date is different. Changes will not be saved through eligibility upload. Update manually.', '', 'w'],
	]
}
class FileRow extends Ref {
	constructor(data) {
        super(data);
		this.updatedProperties = {};
    }
	static definitions = {
		//People
		loadedPerson: { ref: Person, text: 'Person'},
		newPerson: { ref: Person, text: 'Person'},
		mergedPerson: { ref: Person, text: 'Person'},
		person: { ref: Person, text: 'Person'},
		//Employments
		loadedEmployment: { ref: Employment, text: 'Employment'},
		newEmployment: { ref: Employment, text: 'Employment'},
		mergedEmployment: { ref: Employment, text: 'Employment'},
		employment: { ref: Employment, text: 'Person'},
		//Participations
		loadedParticipation: { ref: Participation, text: 'Participation'},
		newParticipation: { ref: Participation, text: 'Participation'},
		mergedParticipation: { ref: Participation, text: 'Participation'},
		participation: { ref: Participation, text: 'Person'},
		//Errors or warnings
		message: {ref: MemberUploadMessage, text: 'Message'},
		// tasks after save
		empTasks: {ref: EmploymentTasks, text: 'Employment tasks'}
	}

	getOtherEmploymentEventFromStatus() {
		/** Value is an EmploymentEvent's code if found, or undefined */
		const employmentEventCode = this.loadedEmployment?.['eventStatus'];
		const eventStatusDate = this.loadedEmployment?.['eventStatusDate'];

		if(!eventStatusDate && !employmentEventCode) {
			return null;
		}

		if(eventStatusDate && !isValidDate(eventStatusDate)){
			console.error('invalidStatusDate for employmentEventCode', employmentEventCode, eventStatusDate, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'invalidStatusDate'});
			return null;
		}
		if(employmentEventCode && !eventStatusDate){
			console.error('missingStatusDate for employmentEventCode', employmentEventCode, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'missingStatusDate'});
			return null;
		}
		if(!employmentEventCode && eventStatusDate){
			console.error('missingOrInvalidStatus for eventStatusDate', eventStatusDate, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'missingOrInvalidStatus'});
			return null;
		}

		const event = toEmploymentEvent(eventStatusDate, employmentEventCode);
		return event;
	}

}