import { RefList, Definition } from '../../../framework/infra'
import { sum, round } from '../../../framework/utils/helper'

import Payment from './Payment'

export default class Payments extends RefList {
    get total() { return round(sum(this._list, 'amount')) }
    get nonRejectedPayments() { return new Payments(this.all.filter(pm => !pm.rejected)) }
    get desc() { return this.all.map(payment => payment.reportDesc).join('\n') }
    get cmt() { return this.all.map(payment => payment.cmt).join('\n') }
    set desc(val) {}
    //Model attributes
    static definitions = {
        total: { abstract: true, type: Definition.types.amount, text: 'Total Distribution'},
        cmt: { abstract: true, type: Definition.types.STRING, text: 'Comments'},
        nonRejectedPayments: { abstract: true, ref: Payments }
    }
    static ref = Payment
}

