import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Control } from '.'

export default class Number extends Control {
    render() {
         return this.wrapper(<MaskedInput mask={defaultMaskOptions} {...this.controlProps} />)
    }

    handleChange(event){
        if(this.props.onChange) this.props.onChange(event.currentTarget.value.substring(1).split(',').join(''), event)
    }
}

const defaultMaskOptions = createNumberMask({
  prefix: '',
  suffix: '',
  integerLimit: 2
})
