import { RefMap, Definition } from '../../../framework/infra'
import { round } from '../../../framework/utils/helper'
import Earning from './Earning'
import EarningType from './EarningType'

export default class Earnings extends RefMap {
	
	get mat() { return this.getAmount('m1')}
    set mat(amount)  { this.setAmount('m1', amount)}
      
    get ltd() { return this.getAmount('l1')}
    set ltd(amount) { this.setAmount('l1', amount)}
       
    get slf() { return this.getAmount('s1')}
	set slf(amount) {  this.setAmount('s1', amount)}
	
	get regular() { return this.sumAmount(this.filter(earning => earning.earningType.category.isRegular())) }
	get overtime() { return this.sumAmount(this.filter(earning => earning.earningType.category.isOvertime())) }
	get oneTime() { return this.sumAmount(this.filter(earning => earning.earningType.category.isOneTime())) }
	get nonPensionable() { return this.sumAmount(this.filter(earning => !earning.earningType.category.isPensionable())) }
	get pensionable() { return this.sumAmount(this.filter(earning => earning.earningType.category.isPensionable())) }
	get deductable() { return this.sumAmount(this.filter(earning => earning.earningType.category.isDeductable())) }
	get deemed() { return this.sumAmount(this.filter(earning => earning.earningType.category.isDeemed())) }
	
	get hours() { return this.sumHours(this._list) }
	get regularHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isRegular())) }
	get overtimeHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isOvertime())) }
	get deemedHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isDeemed())) }
	get ltdHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isLtd())) }
	get matHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isMaternity())) }
	get slfHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isSelf())) }
	get deductableHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isDeductable())) }
	get pensionableHours() { return this.sumHours(this.filter(earning => earning.earningType.category.isPensionable()))}
	
	get total() { return this.sumAmount(this._list) }
	get isEmpty() { return this.total === 0 && this.hours === 0 }
	
	//we might have amount that add up to 0, isEmpty won;t work if we want to know that earnings exist
	get hasAtLeastOneAmount() {
		return this.find((earning) => this.isNumberNot0(earning.hours) || this.isNumberNot0(earning.amount))
	}

	getAmount(code) { return this[code] ? this[code].amount : 0 }
	setAmount(code, amount) {
		if (this[code]) this[code].amount = amount
        else if(amount) this.push(new Earning({amount: amount, code: code, earningType: {code: code}}))
	}
	
	sumAmount(earnings) { return round(earnings.reduce((sum, earning) => { sum += earning.amount; return sum }, 0))}
	sumHours(earnings) { return round(earnings.reduce((sum, earning) => { sum += earning.hours; return sum }, 0))}

	add(earnings) {
		earnings.forEach(earning => {
			if (!this[earning.code]) this.push(new Earning({code: earning.code, earningType: {code: earning.code}}))
			this[earning.code] = this[earning.code].add(earning)
		})
		return this
	}

	removeEarning(func) {
		this.forEach(earning => {
			if (func(earning)) {
				if (this[earning.code]) delete this[earning.code];
				this.pullFilter((e) => !func(e));
			}
		})
		return this;
	}

	assignEarningTypes(earningTypes) {
		this.forEach(earning => {
			if (earningTypes[earning.code]) earning.earningType = earningTypes[earning.code] 
			else earning.earningType = new EarningType({code: earning.code})
		})
	}
	
	appendMissingEarningTypes(earningTypes) { //this is used for the tabulator to display all the columns so they can be edited
		earningTypes.forEach(type => {
			if(!this[type.code]) {
				this.push(new Earning({code: type.code, earningType: {code: type.code}}))
			}
		})
	}

	isNumberNot0(value) {
		return typeof value === 'number' && !isNaN(value) && value !== 0;
	}

}

Earnings.ref = Earning
Earnings.key = 'code'
Earnings.definitions = {
	mat: { abstract: true, type: Definition.types.AMOUNT, text: 'Maternity Earnings' },
	ltd: { abstract: true, type: Definition.types.AMOUNT, text: 'LTD Earnings' },
	slf: { abstract: true, type: Definition.types.AMOUNT, text: 'Self Earnings' },
	regular: { abstract: true, type: Definition.types.AMOUNT, text: 'Regular Earnings' },
	overtime: { abstract: true, type: Definition.types.AMOUNT, text: 'OverTime Earnings' },
	oneTime: { abstract: true, type: Definition.types.AMOUNT, text: 'One Time Earnings' },
	nonPensionable: { abstract: true, type: Definition.types.AMOUNT, text: 'Non Pensionable Earnings' },
	pensionable: { abstract: true, type: Definition.types.AMOUNT, text: 'Pensionable Earnings' }, //Includes Mat/ltd/slf
    deductable: { abstract: true, type: Definition.types.AMOUNT, text: 'Deductable' }, //Regular, Overtime, oneTime
	deemed: { abstract: true, type: Definition.types.AMOUNT, text: 'Deemed Earning' }, // Mat/ltd/slf
	total: { abstract: true, type: Definition.types.AMOUNT, text: 'Total Earnings' },
	
	hours: { abstract: true, type: Definition.types.NUMBER, text: 'Total Hours' },
	regularHours: { abstract: true, type: Definition.types.NUMBER, text: 'Regular Hours' },
	overtimeHours: { abstract: true, type: Definition.types.NUMBER, text: 'Overtime Hours' },
	deemedHours: { abstract: true, type: Definition.types.NUMBER, text: 'Deemed Hours' },
	deductableHours: { abstract: true, type: Definition.types.NUMBER, text: 'Total Hours (exclude Deemed)' },
	pensionableHours: { abstract: true, type: Definition.types.NUMBER, text: 'Pensionable Hours' },

	isEmpty: { abstract: true, type: Definition.types.BOOLEAN, text: 'Has Earnings' },
}


Earnings.sum = function(earningsToAdd) {
	return earningsToAdd.reduce((sum, earn) => {
		sum.add(earn)
		return sum
	}, new Earnings())
}
