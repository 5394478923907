import { RefEvent, RefHistorical } from "../../framework/infra";
import { concatStr } from "../../framework/utils/helper";
import { yeMessageEventConfig } from "./YearEndMessageEventConfig";

export default class YearEndMessageEvent extends RefEvent {
    get config() {
        return {
            ...yeMessageEventConfig["default"],
            ...yeMessageEventConfig[this.code],
        };
    }
    static messages = [

        // group: sts
        [ "missingPersonalInformation", "• Enter missing data", "", "e" ],
        [ "stdMatAndNoMatContribs", "• Member was on Maternity and we don't have any maternity contributions", "", "e" ],
        [ "stsActNoEarnings", "• (1) Active all year, but no earnings were reported", "", "e" ],
        [ "stsLeaveAllYearAndEarnings", "• (2) Absent all year, but earnings reported", "", "e" ],
        [ "stdJoinDtAfterHireDt", "• (3) Join Date is before the Hire Date", "", "e" ],
        [ "stdTermitatedPrevYear", "• (4) Terminated from the Plan, but earnings reported", "", "e" ],
        [ "stdTermitatedPrevYearHasPA", "• (5) Member terminated in a prior year; Regular Earnings/Hours reported this year; PA calculated this year", "", "e" ],
        [ "missingBME", "• (6) Base monthly earnings missing", "", "e" ],
        ['janOne', '• (7) Join date not January 1st: Amend/Confirm in the Personal Data Tab', '', 'e'],
        [ "missingEmploymentType", "• (8) Employment type missing", "", "e" ],
        ['casualType', '• (9) Member is Casual. Provide last day of work', '', 'e'],
        [ "guessedDate", "• (10) Guessed date reported on {date}", "", "e" ],
        
        // group: monthlyEarnings
        ["monthlyEarnings",
            [
                "{if:monthsAct}• (11) Active in {monthsAct}, but no earnings reported {fi:monthsAct}",
                "{if:monthsLoa}• (12) Absent in {monthsLoa}, but earnings reported {fi:monthsLoa}",
                "{if:monthsSlf}• (13) Had approved leave with option to self-contribute; missing member's decision {fi:monthsSlf}",
            ], "", "e"
        ],

        // group: creSrv
        [ "creSrv",
            [
                "{if:csNotFullYear}• (14) Did not work the full year, but accrued 12 months' service {fi:csNotFullYear}",
                "{if:csFullYear}• (15) Active all year, but did not accrue 12 months' service {fi:csFullYear}",
                '{if:csVariationGreater}• (16) Service based on pensionable hours is over 6% greater than Service based on "Active Days" {fi:csVariationGreater}',
                '{if:csVariationLess}• (17) Service based on "Active Days" is over 6% greater than Service based on pensionable hours {fi:csVariationLess}',
                "{if:regExceedWS}• (18) Regular hours reported exceed the normal work schedule for this job {fi:regExceedWS}",
                "{if:workSchMissing}• (19) Enter missing work schedule in column O. Minimum work schedule can be 28 hours/week {fi:workSchMissing}",
            ], "", "e"
        ],

        // group: deeContrib
        [ "deemedEarnHoursValidation",
            [
                "{if:expectedEarn}• Expected deemed earnings calculated ({expectedEarn}) does not match the YTD deemed earnings ({ytdEarn}). Adjustment of {earnAdj} required {fi:expectedEarn}",
                "{if:expectedContribs}• Expected deemed contributions calculated ({expectedContribs}) does not match the YTD deemed contributions ({ytdContribs}). Adjustment of {contribsAdj} required {fi:expectedContribs}",
                "{if:expectedHours}• Expected deemed hours calculated ({expectedHours}) does not match the YTD deemed hours ({ytdHours}). Adjustment of {hoursAdj} required{fi:expectedHours}",
            ], "", "e"
        ],

        // group: reqContrib
        [ "reqValidation", "• Contribution Error: {value} <> {to}", "", "e" ],
        [ "deemedPrior", "• Deemed started in prior year. Manual calculation needed.", "", "i" ],
        [ "reqValidationOK", "• Contributions were validated against Reported Pensionable Earnings", "", "i" ],

        // group: maxContrib
        [ "maxCon", "• OKAY: Maximum contributions attained and not exceeded", "", "e" ],
        [ "maxVol", "• OKAY: Maximum Voluntary contributions attained and not exceeded", "", "e" ],
        [ "maxBoth", "• OKAY: Maximum Regular contributions and Voluntary contributions have been attained and not exceeded", "", "e" ],

        // group: annEarn
        [ "highEarn", "• Increased considerably: Explain or amend data", "", "e" ],
        ["lowEarn", "• Decreased: Explain or amend data", "", "e"],

        // group: result
        ["incomplete", "• Incomplete-Warning to address", "", "e"],
        ["ok", "ok", "", "i"],
    ];

    static definitions = {
        config: { abstract: true },
    };
}

export class YearEndMessageEvents extends RefHistorical {
    pushEvent(eventData, params) {
        const event = this.create(eventData);
        this.push(event);
        if (event.config.next) event.config.next(params);
    }

    hasError() { return this.filter(msg => msg.isError()).length > 0 }

    static getTypeOptions() {
        return [
            { text: "Error", value: "e" },
            { text: "Warning", value: "w" },
            { text: "Message", value: "i" },
        ];
    }

    static ref = YearEndMessageEvents;
}
