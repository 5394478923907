import RefList from './RefList'

export default class RefMap extends RefList {
	reset() {
		Object.getOwnPropertyNames(this).forEach(prop => delete this[prop])
        this._list = []
    }
	push(element) {
		if (!element) return
		const key = element.keyValue
		if (this[key]) this._list[this._list.findIndex(item => item.keyValue === key)] = element
		else this._list.push(element)
		this._put(element)
		return element
	}

	pushList(elements = []) {
		elements.forEach(x=> this.push(x));
	}
	
	remove(func) {
		this._list.forEach((element, index) => {
			if(func(element)) {
				const key = element.keyValue
				if(key) delete this[key]
				this._list.splice(index, 1)
			}
		})
	}
	
	resetMapKeys() {
		Object.getOwnPropertyNames(this).forEach(prop => {
			if (prop !== '_list' && prop !== this[prop].keyValue) {
				this[this[prop].keyValue] = this[prop]
				delete this[prop]
			}
		})
    }

	_put(element) { if (element && element.keyValue) this[element.keyValue] = element }

	add(reflist, attribute, debug) {
		if(debug) console.log(attribute, reflist)
		reflist.forEach(item => {
			if (!this[item.keyValue]) this.push(this.create(item.getData()))
			else this[item.keyValue][attribute] = item[attribute]
		})
		return this
	}
	substract(reflist, attribute) {
		reflist.forEach(item => {
			if (!this[item.keyValue]) {
				this.push(this.create(item.getData()))
				this[item.keyValue][attribute] *= -1
			} else this[item.keyValue][attribute] -= item[attribute]
		})
		return this
	}
	static combine(...refmaps) {
		return refmaps.reduce((all, refmap) => {
			refmap.forEach(el => all.push(el))
			return all
		}, new RefMap())
	}
}

RefMap.isRefMap = true
RefMap.key = 'key'

