import StringInput from '../../../components/filters/StringInput';
import Definition from './Definition';
import Ref from './Ref';

export default class RefFilter extends Ref {

    constructor(data, childDefinitions) {
        super(data);   
        this._childDefinitions = childDefinitions;

    }

    async getValue (key) {
        if (this._childDefinitions[key].getValue) {
            return this._childDefinitions[key].getValue(this, key);
        }
        return this[key];
    }

    async getLabel (key) {
        if (this._childDefinitions[key].getLabel) {
            return this._childDefinitions[key].getLabel(this, key);
        }
        return this[key];
    }

    static get filterDefinitions() {
        const filterFields = [];
        for (const key in this.definitions) {
            if (this.definitions[key].isFilter) {
                filterFields.push({
                    key,
                    ...this.definitions[key],
                });
            }
        }
        return filterFields;
    }

    static definitions = {
        pageSize: { type: Definition.types.CHOICE, text: 'Page Size',
        isFilter: true,
        isBaseFilter: true,
        icon: 'list-ol', 
        limit: 1,
        component: <StringInput options={
            [
                { key: '15', value:'15', text: '15', },
                { key: '25', value:'25', text: '25', },
                { key: '50', value:'50', text: '50', },
            ]
        } /> 
    }}
}



