import { RefMap, RefMessage } from '../../framework/infra'

export class EmploymentMessage extends RefMessage {
    static messages = [
        ['enrForm', 'Submit Enrolment Form', 'Submit Enrolment Form', 'w'],
        ['enrFormIncomplete', 'Enrolment form is incomplete. Submit revised enrolment form', 'Submit revised enrolment form', 'w'],
        ['transferLetter', 'Member transferred to a new jurisdiction. Submit the Transfer Letter to Member', 'w'],
        ['activeNoEarnings', 'Member was active in {period} but no earnings were received. Submit Leave of Absence form if member is on leave','Submit Leave of Absence form if member is on leave', 'w'],
        ['memberReturned', 'Member was On leave in {period} but earnings were received. Confirm if the member has returned', 'Confirm if the member has returned', 'w'],
        ['maternityEarnings', 'Member was on maternity in {period} but earnings were received. Confirm if the member has returned', 'Member was on maternity but earnings were received. Confirm if the member has returned', 'w'],
        ['ltdEarnings', 'Member was on LTD in {period} but earnings were received. Confirm if the member has returned', 'Member was on LTD but earnings were received. Confirm if the member has returned', 'w']
    ]
}

export class EmploymentMessages extends RefMap {
    static ref = EmploymentMessage
}
