import React from 'react';
import { useEmployerStore } from '../../hooks/useEmployerStore';
import StringInput from './StringInput';

const EmployerInput = ({onEnter, instance, property, existingFilters}) => {
    const employerMapping = useEmployerStore((state) => state.employers);
    const employerCodeOptions = Object.entries(employerMapping).map(([code, obj]) => ({ key: obj.id, value: obj.id, text: code }));

    return <StringInput onEnter={onEnter} instance={instance} property={property} options={employerCodeOptions} existingFilters={existingFilters} />;
}

export default EmployerInput;