import RefList from './RefList'
import RefMessage from './RefMessage'

export default class RefMessages extends RefList {
    add(code, params) { return this.pushNew({code: code, params: params}) } 


    static definitions = {
        last: {abstract: true, ref: RefMessage, text: 'Message'}
    }
}
