import { RefMap, RefMessage } from '../../framework/infra'

export class MembershipMessage extends RefMessage {
    static messages = [
        ['exceedProceeds', 'The total share of proceeds percentage should equal 100%', 'Exceed total share of proceeds', 'e']
    ]
}

export class MembershipMessages extends RefMap {
    static ref = MembershipMessage
}
