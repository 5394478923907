import React from 'react'
import { Modal } from 'react-bootstrap'

export default class MyModal extends React.Component {
    render() {
        const { show, centered, onHide, children } = this.props
        const backdrop = this.props.backdrop ?? (onHide ? {} : {backdrop: 'static'})

        return <Modal {...this.props} show={show !== false} centered={centered !== false } {...backdrop} >
            <div className='modal-content-body'>
                {children}
            </div>
        </Modal>
    }
}