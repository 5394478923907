import { useEffect } from "react";
import { EInput } from "../../../framework/components";
import { Row, FieldSet } from "../../../framework/containers";
import { Button, Icon } from "../../../framework/controls";
import { Period } from "../../../framework/utils";

const PaymentDistributionForm = ({ distributions = [], disabled, handleAdd, handleChange, handleDelete }) => {

    useEffect(() => {
        if (distributions.length === 0) handleAdd();
    })

    return (
        <FieldSet title="Distribution">
            { distributions.map((distribution, index) => (
                <Row
                    key={`distribution-row-${index}`}
                    className="input-spacing-2 mt-2 d-flex align-content-center"
                >
                    <DeleteButton disabled={index === 0} handleDelete={() => handleDelete(index)}/>
                    <EInput
                        name="acc"
                        noLabel
                        instance={distribution}
                        onChange={handleChange}
                        onDelete={handleChange}
                        ccn="framed"
                        readOnly={disabled}
                    />
                    <EInput
                        name="period"
                        noLabel
                        instance={distribution}
                        options={Period.getPeriods( undefined, undefined, true)}
                        onChange={handleChange}
                        onDelete={handleChange}
                        ccn="framed"
                        readOnly={disabled}
                    />
                    <EInput
                        name="amount"
                        noLabel
                        instance={distribution}
                        onChange={handleChange}
                        onDelete={handleChange}
                        ccn="framed"
                        readOnly={disabled}
                    />
                </Row>
            ))}
            <AddButton handleAdd={handleAdd} disabled={disabled} />
        </FieldSet>
    );
};

export default PaymentDistributionForm;

const DeleteButton = ({ disabled, handleDelete }) => {
    return (
        <Button
            className="btn btn-sm px-2 align-self-end m-0 mr-3 bg-danger text-white"
            type="button"
            onClick={handleDelete}
            disabled={disabled}
        >
            <Icon icon="trash" large tooltip="Remove this bucket" />
        </Button>
    );
};

const AddButton = ({ disabled, handleAdd }) => {
    return (
        <Row className="d-flex justify-content-end">
            <Button
                className="btn btn-sm py-0 px-2 mt-3"
                type="button"
                onClick={handleAdd}
                disabled={disabled}
            >
                <Icon
                    icon="plus"
                    large
                    className="v-bottom"
                    tooltip="Add new bucket"
                    tooltip-left
                />
            </Button>
        </Row>
    );
};