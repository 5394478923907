import { useEffect, useMemo, useState } from 'react';
import { useAPIError } from '../../hooks/useAPIError';
import config from '../../utils/config';

const APIErrorBoundary = ({ hasRenderError, renderErrorMessage, renderErrorStack, children }) => {
    const [hasAPIError, setHasAPIError] = useState(false);
    const [apiError, setAPIError] = useState(false);
    const errorMessage = useMemo(
        () => renderErrorMessage || apiError?.message || apiError,
        [renderErrorMessage, apiError]
    );
    const stack = useMemo(
        () => renderErrorStack || apiError?.stack,
        [renderErrorStack, apiError]
    );

    useEffect(() => {
        useAPIError.subscribe(
            (state) => {
                setHasAPIError(state.error !== {});
                setAPIError(state.error);
            }
        );
    });

    return (
        <>
            {hasRenderError || hasAPIError ? (
                <div className="h-80vh w-100 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="error-title">Oops! Something went wrong.</h1>
                    {(
                        <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                            <p>Error Message: {errorMessage}</p>
                            <code className="error-stack">{stack}</code>
                        </div>
                    )}
                </div>
            ) : (
                children
            )}
        </>
    );
}

export default APIErrorBoundary;
