import Definition from './Definition'
import Ref from './Ref'
import { moment } from '../../utils/helper'
import { Period } from '../../utils'

export default class RefDated extends Ref {
    get day() { return moment.utc(this.ets).date() }
    get effDt() { return moment.utc(this.ets).format('YYYY-MM-DD') }
    set effDt(dt) { this.ets = moment(dt).valueOf() }
    get rptDt() { return moment.utc(this.rts).format('YYYY-MM-DD') }
    set rptDt(dt) { this.rts = moment(dt).valueOf() }
    get modifiedDate() { return moment.utc(this.mts).format('YYYY-MM-DD') }
    set modifiedDate(dt) { 
        this.mts = moment(dt).valueOf() 
    }
    get userName() { return this.rur }
    get historyDesc() { return this._historyDesc || this.desc}
    set historyDesc(desc) { this._historyDesc = desc }
    
    get effMoment() { return moment.utc(this.ets) }
    get effPeriod() { return Period.fromDate(this.effDt)}
    
    getEffectiveDaysInPeriod(period, includeDay = true) {
        const effDt = moment(this.effDt)
        const effPeriod = Period.fromDate(effDt)
        const endDt = this.endTs < this.ets ? moment('9999-12-31') : moment(this.endTs) || moment('9999-12-31');
        const endPeriod = Period.fromDate(endDt)
        if (effPeriod.isAfter(period)) return 0
        const startCondition = includeDay
            ? effPeriod.moment < period.timestampAtPeriodStart
            : effPeriod.isBefore(period);
        const endCondition = includeDay
            ? endPeriod.moment > period.timestampAtPeriodEnd
            : endPeriod.isAfter(period);
        const start = startCondition ? 1 : Number(effDt.format('D'))
        const end = endCondition
            ? period.numberOfDaysInPeriod
            : Number(endDt.format("D"));
        return end - start + 1
    }

    getEffectiveDaysToEvent(period){
        const effDay = this.getEffectiveDaysInPeriod(period);
        return period.numberOfDaysInPeriod - effDay;
    }

    static definitions = {
        ets: { type: Definition.types.TIMESTAMP },
        rts: { type: Definition.types.TIMESTAMP },
        rur: { type: Definition.types.USER },
        
        effPeriod: { abstract: true, type: Definition.types.PERIOD, text: 'Effective Period' },
        endDt: { transient: true, type: Definition.types.DATE, text: "End Date" },
        endTs: {},
        effDt: { abstract: true, type: Definition.types.DATE, text: "Effective Date" },
        day: { abstract: true, type: Definition.types.DATE, text: "Day" },
        rptDt: { abstract: true, type: Definition.types.DATE, text: "Reported Date" },
        mts: {},
        modifiedDate: { abstract: true, type: Definition.types.DATE, text: "Modified Date" },
        userName: { abstract: true, type: Definition.types.USER, text: "Reported User" },
        historyDesc: { abstract: true, type: Definition.types.STRING, text: "Description" },
        guessed: { type: Definition.types.BOOLEAN, text: "Is guessed" },
    }
}

