import React from 'react'
import { Form } from 'react-bootstrap'

export default class SchemaSelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = { currentSchema: getSchema('default') } //TODO get from context
    }
    
    handleSchemaChange(event) {
        const schema = getSchema(event.target.value)
        const schemaElement = document && document.getElementById('schema')
        if (schemaElement && schema) {
            schemaElement.href = 'css/' + schema.value
            this.props.onContextChange && this.props.onContextChange({ schema: schema })
        }
    }
  
    render() {
        const schema = this.props.context && this.props.context.schema
        return(
            <Form.Control width='fit' as="select" value={schema} onChange={this.handleSchemaChange.bind(this)} className={this.props.className}>
                {getSchemas().map(schema => (
                    <option key={schema.key} value={schema.key}>{schema.text}</option>
                ))}  
            </Form.Control>
        )
    }
}

function getSchemas() {
    return [
        { key: 'default', value: 'core.min.css', text: 'Default'},
        { key: 'argon', value: 'argon.min.css', text: 'Argon'},
        { key: 'bootstrap', value: 'bootstrap.min.css', text: 'Bootstrap'},
        { key: 'sketch', value: 'sketch.min.css', text: 'Sketch'},
        { key: 'darkly', value: 'darkly.min.css', text: 'Darkly'},
        { key: 'materia', value: 'materia.min.css', text: 'Materia'},
        { key: 'pulse', value: 'pulse.min.css', text: 'Pulse'},
        { key: 'cerulan', value: 'cerulan.min.css', text: 'Cerulan'},
    ]
}
function getSchema(key) {
    return getSchemas().find(schema => schema.key === key)
}