import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'
import { adjustmentsTypeConfigs } from './AdjustmentConfigs';

export default class AdjustmentType extends RefEnum {
    constructor(key, defaultDesc, template) {
        super(key, defaultDesc);
        this.template = template;
    }

    get config() {
        return { ...adjustmentsTypeConfigs['default'], ...adjustmentsTypeConfigs[this.key] };
    }

    isRemainingCreditType() {
        return this.key === 'MECHR' || this.key === 'RCRR' || this.key === 'SOCR';
    }

    static getCreditTypes() {
        return Object.keys(this.types).filter(type => {
            const adjType = new AdjustmentType(type);
            return adjType.config.isCredit;
        });
    }

    static types = deepFreeze({
        MAN: new AdjustmentType('MAN', 'Manual'),
        INI: new AdjustmentType('INI', 'Initial Consolidation'),
        C19: new AdjustmentType('C19', 'Covid-19 Solvency Relief Measure', 'Covid19Relief'),
        ERC: new AdjustmentType('ERC', 'Employer Contribution Rate Change'),
        ERL: new AdjustmentType('ERL', 'Employer Late Rate Change'),
        RSC: new AdjustmentType('RSC', 'Retro Solvency Amount Change', 'SolvencyAmountChange'),
        INR: new AdjustmentType('INR', 'Interest Reversal'),
        EFE: new AdjustmentType('EFE', 'ER Factor Error'),
        
        EER: new AdjustmentType('EER', 'Employee Retro'),
        ESC: new AdjustmentType('ESC', 'Employee Status Change', 'EmployeeStatusChange'),
        ESP: new AdjustmentType('ESP', 'Employee Status Change Previous Year'),
        RNE: new AdjustmentType('RNE', 'Reimbursement - Not Eligible to Join'),
        EOP: new AdjustmentType('EOP', 'Contributions Overpaid Previous year'),
        CQC: new AdjustmentType('CQC', 'CQPP change'),
        REI: new AdjustmentType('REI', 'Reimbursement'),
        OPM: new AdjustmentType('OPM', 'Overpayment'),

        TER: new AdjustmentType('TER', 'Termination'),
        MID: new AdjustmentType('MID', 'Mid-month'),
        A60: new AdjustmentType('A60', 'Age-60 Retirement'),
        DEEM: new AdjustmentType('DEEM', 'Automatic Deemed Calculation'),
        
        MECH: new AdjustmentType('MECH', 'Mandatory Employer Contribution Holiday'),
        MECHR: new AdjustmentType('MECHR', 'Used Mandatory Employer Contribution Holiday'),
        MECHC: new AdjustmentType('MECHC', 'Cancelled Used Mandatory Employer Contribution Holiday'),

        RCR: new AdjustmentType('RCR', 'Retro Employer Contribution Adjustment due to Rate Change', 'EmployerContributionRateChange'),
        RCRR: new AdjustmentType('RCRR', 'Used Retro Employer Contribution Adjustment due to Rate Change ', 'EmployerContributionRateChange'),
        RCRC: new AdjustmentType('RCRC', 'Cancelled Used Retro Employer Contribution Adjustment due to Rate Change ', 'EmployerContributionRateChange'),

        SOC: new AdjustmentType('SOC', 'Solvency Credit'),
        SOCR: new AdjustmentType('SOCR', 'Used Solvency Credit'),


    })
    
    static key = 'key'
    static definitions = {
        code: { type: Definition.types.STRING, text: 'Code' },
        desc: { type: Definition.types.STRING, text: 'Adjustment Type' },
        template: { type: Definition.types.STRING, text: 'Template' },
        config: { abstract: true } 
    }
}
