import React from 'react'
import { Component, Message } from '../../framework/components'
import { Table } from '../../framework/controls'
import { Modal, FieldSet } from '../../framework/containers'
import { round, sum, group } from '../../framework/utils/helper'
import { RefundDistributions, RefundDistribution } from '../../entities/pension'

import RefundDistributionForm from "./RefundDistributionForm"

export default class RefundDistributionList extends Component {
    view() {
        const { payout, refundAccounts, readOnly } = this.props 
        const { selected, errorMessage } = this.state
        const columns = new Table.Headers(RefundDistribution, 'type.desc, amount, account.searchDesc')

        return <FieldSet title='Refund Distribution Info' className='mt-4' variant='page'>
            {errorMessage && <Message message={{ text: errorMessage, severity: 'danger' }} />}
            <Table
                id="refundDistrib-table"
                data={[...payout.distribution.all]}
                entity={RefundDistribution}
                columns={columns}
                onTableLoad={(table) => this.table = table}
                onSelect={!readOnly && this.handleSelect.bind(this)}
                noPlaceHolder
                selectable
                fitHeightToContent
                sort='type.desc'
            />
            {selected && <Modal className='w-40 h-40 modal-bg-color'>
                <RefundDistributionForm refundAccounts={refundAccounts} payout={payout} distribution={selected} onOK={this.handleDistributionConfirm.bind(this)} onCancel={this.handleCancel.bind(this)}/>
            </Modal>}
        </FieldSet>
    }
    handleCancel() { this.setState({selected: null}) }
    handleSelect(row) { this.setState({selected: row}) }
    
    handleDistributionConfirm() {
        const { payout } = this.props
        payout.distribution = getDistributionList(payout)
        this.setState({ selected: null})
        if (this.props.onChange) this.props.onChange()
    }
}
function getDistributionList(payout) {
    const groupBykey = group(payout.distribution.all, 'type.key')
    return Object.getOwnPropertyNames(groupBykey).reduce((distrbList, key) => {
        if(groupBykey[key].length > 1) {
            const groupByAccount = group(groupBykey[key], 'account.keyValue')
            Object.values(groupByAccount).forEach(distributions=> {
                if (distributions.length > 1) { 
                    if (distributions[0].account.accountType.key === 'RRSP' && sum(distributions, 'amount') > payout.maxLIPortion) {
                        distrbList.pushNew({type: 'UTR', amount: payout.maxLIPortion, account: distributions[0].account})
                        distrbList.pushNew({type: 'OTR', amount: round(sum(distributions, 'amount') - payout.maxLIPortion), account: distributions[0].account})
                     }
                    else distrbList.push(new RefundDistribution({account: distributions[0].account, type: distributions[0].type, amount: sum(distributions, 'amount')}))
                } 
                else { distributions.forEach(distribution => distrbList.push(distribution)) }
            })
        } 
        else { distrbList.push(groupBykey[key][0]) }
        return distrbList
    }, new RefundDistributions())
}