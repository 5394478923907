import EmploymentEventInput from "../../components/filters/EmploymentEventInput";
import ParticipationEventInput from "../../components/filters/ParticipationEventInput";
import ParticipationNonStatusEventInput from "../../components/filters/ParticipationNonStatusEventInput";
import StringInput from "../../components/filters/StringInput";
import { Definition } from "../../framework/infra";
import { removeNonNumeric } from "../../framework/utils/helper";
import { EmploymentEvent } from "../employment";
import { ParticipationEvent } from "../membership";
import moment from 'moment';
import DateOfBirthInput from "../../components/filters/DateOfBirthInput";
import { useEmployerStore } from "../../hooks/useEmployerStore";
import EmployerInput from "../../components/filters/EmployerInput";

/** Description of how the name field works in the search (with and without fuzziness). This description will be shown as hint under the text input. */
const nameWithFuzzinessHint = "The full name search will find partial or exact matches in any order, in the full name: \"LastName (MaidenName), FirstName MiddleName\". \nFor example, searching \"arl\" will find \"Johnson, Arleen\". Searching \"John Doe\" will find \"Doe, John\". \nEnable the fuzzy search option to account for typos. For example, searching \"alreen\" or \"arlene\" will find \"Johnson, Arleen\".";
/** Description of how the fuzziness flag works in the search. This description will be shown in a help icon next to the filter name in the filters list. */
const nameFuzzinessDescription = "Apply fuzzy search on the full name, to account for typos. For example, searching the name \"alreen\" or \"arlene\" will find \"Johnson, Arleen\".";

/**
 * Reusable filters that can be shared across multiple entities
 */
export default {
    sin: { 
        isFilter: true,
        type: Definition.types.SIN, 
        text: 'SIN', 
        icon: 'id-card', 
        limit: 1,
        component: <StringInput />,
        getValue: async (instance) => removeNonNumeric(instance.sin),
        getLabel: async (instance) => removeNonNumeric(instance.sin),
    },
    name: { 
        isFilter: true,
        type: Definition.types.STRING, 
        text: 'Name', 
        icon: 'user',
        limit: 1,
        component: <StringInput />  
    },
    nameWithFuzziness: { 
        isFilter: true,
        type: Definition.types.STRING, 
        text: 'Name', 
        icon: 'user',
        limit: 1,
        component: <StringInput description={nameWithFuzzinessHint} />,
    },
    joinDateString: { 
        isFilter: true,
        type: Definition.types.STRING, 
        text: 'Join Date', 
        icon: 'calendar',
        limit: 1,
        component: <StringInput /> 
    },
    employmentStatusKey: { 
        isFilter: true,
        type: Definition.types.CHOICE, 
        text: 'Employment Status', 
        icon: 'briefcase',
        component: <EmploymentEventInput/>,
        getLabel: (instance, prop) => {
            return new EmploymentEvent({code: instance[prop]}).desc;
        }
    },
    participationStatusKey: { 
        isFilter: true,
        type: Definition.types.CHOICE, 
        text: 'Participation Status', 
        icon: 'user-check',
        component: <ParticipationEventInput />,
        getLabel: (instance, prop) => {
            return new ParticipationEvent({code: instance[prop]}).desc;
        }
    },
    participationNonStatusKey: { 
        isFilter: true,
        type: Definition.types.CHOICE, 
        text: 'Participation Event', 
        icon: 'user-clock',
        component: <ParticipationNonStatusEventInput/>,
        getLabel: (instance, prop) => {
            return new ParticipationEvent({code: instance[prop]}).desc;
        }
    },
    dob: { 
        isFilter: true,
        type: Definition.types.STRING, 
        text: 'Date of Birth', 
        icon: 'birthday-cake',
        limit: 1,
        component: <DateOfBirthInput />,
    },
    dobUnder: { 
        isFilter: true,
        hidden: true,
        type: Definition.types.STRING,
        text: "Under",
        limit: 1,
        icon: 'birthday-cake',
        getLabel: (instance) => `Under ${moment().diff(moment(Number(instance.dobUnder)), 'years')}`,
        getValue: (instance) => moment().subtract(instance.dobUnder, 'years').valueOf(),
    },
    dobOver: { 
        isFilter: true,
        hidden: true,
        type: Definition.types.STRING,
        text: "Over",
        limit: 1,
        icon: 'birthday-cake',
        getLabel: (instance) => `Over ${moment().diff(moment(Number(instance.dobOver)), 'years')}`,
        getValue: (instance) => moment().subtract(instance.dobOver, 'years').valueOf(),
    },
    employerIds: { 
        isFilter: true,
        type: Definition.types.CHOICE, 
        text: 'Employer Code', 
        icon: 'briefcase',
        component: <EmployerInput />,
        getLabel: (instance) => {
            return Object.values(useEmployerStore.getState().employers).find((obj) => obj.id === instance.employerIds)?.code;
        }
    },
    nameFuzziness: {
        type: Definition.types.YESNO, text: 'Fuzzy name search',
        isFilter: true,
        isBaseFilter: true,
        icon: 'spell-check', 
        limit: 1,
        description: nameFuzzinessDescription,
        getValue: (instance) => { 
            const value =  (instance.fuzziness === 'true' || instance.fuzziness === 'y' || instance.fuzziness === 'Yes') ? true :
                (instance.fuzziness === 'false' || instance.fuzziness === 'n' || instance.fuzziness === 'No') ? false : Boolean(instance.fuzziness)
            return value;
        },
    }
}