import React from "react"
import { Component } from '../../../framework/components'
import { Modal } from '../../../framework/containers'
import { Button } from "../../../framework/controls"

import EarningTypesForm from "./EarningTypesForm"

export default class EarningTypes extends Component {
    view() {
        const { edit } = this.state
        const employer = this.props.employer

        return <>
            <div className="modal-component-body"><EarningTypesForm employer={employer} readOnly={true}/></div>
            {!edit && <div className="modal-bottom">
                <Button key='edit' className='btn-secondary' onClick={this.handleEditInfo.bind(this)}>Edit</Button>
            </div>}
            {edit && <Modal className='h-100 w-80 modal-bg-color'>
                <EarningTypesForm employer={employer} onClose={this.handleClose.bind(this)}/>
            </Modal>}
        </>
    }
    
    handleEditInfo() { this.setState({edit: true}) }
    handleClose() { this.setState({edit: false}) }
}
