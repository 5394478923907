import moment from "moment";
import { LONG_FORMAT } from "../../framework/utils/helper";

const FED = 'federally';
const PRO = 'provincially';
const FEDTYPE = 'federal';
const PROTYPE = 'Quebec';

const Email = (props) =>  { 

    let hireDate = props.employment.hiredDate ? moment(props.employment.hiredDate).format(LONG_FORMAT) : null;
    let trmDate = props.lastEmployment.firedQuitDate ? moment(props.lastEmployment.firedQuitDate).format(LONG_FORMAT) : null;
    let joinDate = props.employment.participation.joinDt ? moment(props.employment.participation.joinDt).format(LONG_FORMAT) : '';
    let lastEmpJur = props.lastEmployment.employer.plan.code === 'que'? PRO :FED
    let empJur = props.employment.employer.plan.code === 'que'? PRO :FED
    let lastEmpJurType = props.lastEmployment.employer.plan.code === 'que'? PROTYPE :FEDTYPE
    let empJurType = props.employment.employer.plan.code === 'que'? PROTYPE : FEDTYPE

    return <div>
        <div>
                <b>
                    <i>
                        <u>
                            [NO MEMBER EMAIL: SEND TO ADMINISTRATOR]<br/>
                            Dear {props.admin.firstName ?? '[Administrator-FirstName]'},
                            <br/><br/>
                            Please ensure Member receives this message as we have no email on file.<br/>
                            ~~~<br/>
                            Dear {props.employment.person.firstName},
                            <br/><br/>
                            Dear [MEMBER EMAIL ON FILE: SEND TO MEMBER]<br/>
                            Dear {props.employment.person.firstName},
                            <br/><br/>
                        </u>
                    </i>
                </b>
                You are a member of the James Bay Cree-Naskapi Pension Plan (“JBCNPP”). The 
                JBCNPP is a multi-employer pension plan with 50 employers dotting Eeyou Istchee. 
                One generous feature of the plan enables members to maintain their membership 
                when they transfer employment between sponsoring employers (baring some 
                restrictions).  Generally, if a member’s employment is permanently terminated (i.e.,
                employee was fired or resigned) their membership will be sustained with the new 
                employer if they are hired within 60 days; and if a member’s employment is not 
                permanently terminated but laid-off or end of contract with an intent to rehire, their 
                membership will be transferred to the new employer if they are hired within one 
                year. In some cases, a member might still be employed by the original employer 
                and will be automatically enrolled with the new employer.
            <br/><br/>
            { props.jurChanged ? 
            <>
                It is our understanding that you were: (1) not permanently terminated on {trmDate ?? hireDate} from your 
                employment with {props.lastEmployment.employer.formatedName} who are {lastEmpJur} legislated; (2) hired on {hireDate} by {props.employment.employer.formatedName} who is {empJur} legislated; and (3) met the requirements to sustain your membership.
                </> : 
            <>
                It is our understanding that you were: (1) not permanently terminated on {trmDate ?? hireDate} from your 
                employment with {props.lastEmployment.employer.formatedName}; (2) hired on {hireDate} by {props.employment.employer.formatedName} and (3) met the requirements to sustain your membership.
            </>}
            <br/><br/>
            { props.jurChanged ? 
                <>This confirms that your JBCNPP membership, which commenced on {joinDate}, will continue seamlessly with your new employer. Contributions will be owing from your hire date at {props.employment.employer.formatedName}. Your marital status will be determined by the jurisdiction of your last employment
                { props.nrlForm ?
                <>
                    . Please ensure the marital status you declared on your Beneficiary Designation Form under your original employer in the {lastEmpJurType} jurisdiction is still valid under your new employer in the {empJurType} jurisdiction (see spouse definition below) If it is not, you must revise and submit your new designation. Please contact your employer or A.S. Hayes Consulting to obtain the necessary form.
                </> : <>
                    . We have no Enrolment Form on file for you. Please see your administrator as soon as possible to complete the mandatory Enrolment Form.
                </>}</> 
            : <>
                { props.nrlForm ? <>
                    Because your membership has been maintained, there is no need to submit another Enrolment Form (assuming one was submitted via your original employer). Should you wish to make changes to your marital status or beneficiary designation please contact your employer or A.S. Hayes Consulting for the appropriate form.
                </> :<>
                    We have no Enrolment Form on file for you. Please see your administrator as soon as possible to complete the mandatory Enrolment Form.
                </> }
            </> }
            {/* { props.jurChanged &&
            <><br/><br/>
                <b> Please ensure the marital status you declared under your original 
                employer in the {lastEmpJurType} jurisdiction is still valid under your new 
                employer in the {empJurTYpe} jurisdiction. </b> If it is not, you must revise and
                submit your new designation. Please contact your employer or A.S. Hayes 
                Consulting to obtain the necessary form.
            </> } */}
        </div>
        {props.jurChanged && <>
            <div><br/>
                <b>DEFINITIONS:</b>
                <br/> <br/>
                <b>Federal</b> (Pension Benefits Standards Act): 
                <br/>Your spouse is the person who, at the date a determination of spousal status is required:
                <ul type='a'>
                    <li>a) if there is no person described in (b) below, is married to you or is party to a void marriage with you;<br/>or</li>
                    <li>b) has been cohabiting with you in a conjugal relationship for at least one year.</li>
                </ul>
            </div>
            <div><br/>
                <b>Quebec</b> (Supplemental Pension Plans Act): 
                <br/>Your spouse is the person who, at the date a determination of spousal status is required:
                <ul type="a">
                    <li>a) is married to you; or</li>
                    <li>b) if you are not married to anyone, a person of the opposite sex or same sex who has been living in a conjugal relationship with you for a period of not less than three years, or for a period of not less than one year if:
                    <br/>
                        -- (i)  at least one child is born, or to be born, of your union;<br/>
                        -- (ii) you have jointly adopted at least one child while living together in a conjugal relationship; or<br/>
                        -- (iii) one of you has adopted at least one child of the other while living together in a conjugal relationship.<br/>
                    </li>
                </ul>
            </div>
        </>}
            {/* {!props.nrlForm && 
                <div><br/>
                <p>Because we did not receive an enrolment form via {props.lastEmployment.employer.formatedName}, <b>please complete the forms 
                <a href="https://drive.google.com/file/d/1Uxlg5FKvmYcz650rzRDEXwwH5MY0St1P/view?usp=sharing"> JBCNPP_1-ENROLMENT</a> and 
                <a href="https://drive.google.com/file/d/1RX7Dt4ItJIzTcs54wYDvfO5PL2r7FyMe/view?usp=sharing"> JBCNPP_2-BENEFICIARY DESIGNATION FORM</a> with your employer and submit at your earliest convenience.</b></p>
                </div>
            } */}
        <div>
            <br/>
            Please do not hesitate to contact us if you have questions.
            <br/><br/>
            Warm regards,
        </div>
        </div>
}

export default Email;