import React from 'react'
import ReactDOM from 'react-dom'
import TabulatorTable from 'tabulator-tables'
import _ from 'lodash'
import { renderComponent } from '../utils/renders'

export default class Tabulator extends React.Component {
    componentDidMount() {
        const { id, columns, data, desc, sort, selected, initialSort, onTableLoad, hideEmptyColumns, resizableColumns, className, rowFormatter, dataKey, dataTree, dataTreeStartExpanded, dataTreeBranchElement, groupBy, groupText, groupStartOpen, fitHeightToContent, selectable, noPlaceHolder, initialHeaderFilter, removeDuplicateRows } = this.props

        this.columns = this.convertColumns(hideEmptyCols(columns, data, hideEmptyColumns))
        this.dataCopy = data //HACK use to refresh tabulator if data was modified

        var pageBodyElement = document.getElementsByClassName('page-body')[0] //use to set the temporary size of the tabulator to handle performance issues
        const element = ReactDOM.findDOMNode(this.refs[id])
        if (!element || !data || !this.columns) return
        this.selected = selected
        this.tabulator = new TabulatorTable(element, {
            data: removeDuplicateRows ? removeDuplicates(columns, data) : data,
            columns: this.columns,
            rowClick: this.handleSelect.bind(this),
            cellEditing: (event, cell, row) => {
                this.editing = true
            },
            cellEdited: (event, cell, row) => {
                this.editing = false
            },
            cellEditCancelled: (event, cell, row) => {
                this.editing = false
            },
            index: dataKey || 'id',
            initialSort: initialSort || [{ column: sort, dir: desc ? 'desc' : 'asc' }],
            selectable: selectable === false ? false : '1',
            height: !fitHeightToContent ? pageBodyElement.offsetHeight : null, //HACK if not set then it renders all rows and takes forever, we set to hight of page and we reduce after rendering
            layout: "fitColumns",
            resizableColumns: resizableColumns !== false,
            tooltipsHeader: true,
            placeholder: noPlaceHolder ? '' : "No Results Found",
            keybindings: {
                "navLeft": "37",
                "navRight": "39"
            },
            groupBy: groupBy,
            groupHeader: groupText,
            groupStartOpen: groupStartOpen,
            groupToggleElement: "header",

            rowFormatter: rowFormatter,
            dataTree: dataTree,
            dataTreeStartExpanded: dataTreeStartExpanded ?? false,
            dataTreeCollapseElement: renderComponent(<div className='icon-noun-dropdown tabulator-data-tree-control'/>), //HACK? to overwrite tree icon with text, I put them here to make every tree same but not sure if it's okay here
			dataTreeExpandElement: renderComponent(<div className='icon-noun-dropdown-up tabulator-data-tree-control'/>),
            dataTreeBranchElement: dataTreeBranchElement,
            initialHeaderFilter: initialHeaderFilter
        })
        this.tabulator.element.className = this.tabulator.element.className + ' ' + (className || '')
        this.handleSelect()

        //Handle tabulator height
        if(!fitHeightToContent) {
            var offsetHeight = pageBodyElement.offsetHeight + pageBodyElement.offsetTop
            var offsetTop = this.tabulator.element.offsetTop
            for(var el = this.tabulator.element; el && el !== pageBodyElement; el = el.parentElement) {
                if(el.className) {
                    if (el.className.startsWith('card') || el.className.startsWith('row')) offsetTop += el.offsetTop
                    if (el.className.startsWith('modal-content')) offsetHeight = el.offsetHeight - 4 //Investigate whay we need the -4
               }
            }
            this.tabulator.setHeight(offsetHeight - offsetTop);
        }
        
        onTableLoad && onTableLoad(this.tabulator)
    }

    render() {
        const { id } = this.props
        if (this.tabulator) this.handleTableChange()
        return <div ref={ id } id={ id || 'tabulator' } />
    }

    handleTableChange() {
        const scrollTop = this.tabulator.rowManager.element.scrollTop
        var { data, hideEmptyColumns, columns } = this.props
        const convertedData = this.props.removeDuplicateRows ? removeDuplicates(columns, data) : data
        if (this.tabulator) {
            if (!_.isEqual(this.dataCopy, convertedData)) { //Data have changed 
                this.dataCopy = convertedData
                this.tabulator.setData(convertedData)
            }
            columns = hideEmptyCols(columns, convertedData, hideEmptyColumns)

            const compareA = this.columns.map(x=> ({name: x.name, description: x.description, title: x.title}))
            const compareB = columns.map(x=> ({name: x.name, description: x.description, title: x.title}))
            if (!_.isEqual(compareA, compareB)) { //Columns have changed
                this.columns = _.cloneDeep(columns)
                this.tabulator.setColumns(this.convertColumns(columns));
            }
            this.handleSelect()
        }
        this.tabulator.rowManager.element.scrollTop = scrollTop
    }

    handleSelect(e, row) {
        if (!this.tabulator || !row || !row.select) return
        const key = _.get(this, 'tabulator.options.index')
        const rowId = _.get(row, '_row.data.' + key)
        this.tabulator.deselectRow()
        if (row) {
            row.select()
            this.selected = rowId
            if (this.props.onSelect) this.props.onSelect(row._row.data)
        } else if (this.selected) {
            this.tabulator.getRows().forEach(row => { if (_.get(row, '_row.data.' + key) === this.selected) row.select() })
        }
    }

    convertColumns(cols) {
        return cols.map(col => {
            const convertedCols = {...col}
            convertedCols.headerTooltip = col.description
            convertedCols.field = col.name
            convertedCols.columns = col.columns && this.convertColumns(col.columns)
            convertedCols.cssClass = col.className
            convertedCols.minResizeWidth = col.minWidth
            convertedCols.maxResizeWidth = col.maxWidth
            convertedCols.headerSort = col.headerSort !== false
            
            if (col.format) {
                convertedCols.formatter = typeof col.format === "function" ? cell => col.format(cell.getValue(), cell._cell.row.data, cell) : col.format
            } else {
                convertedCols.formatter = cell => cell.getValue()
            }

            if (col.topCalc) {
                convertedCols.topCalc = col.topCalc
                convertedCols.topCalcFormatter = col.topCalcFormatter ? cell => col.topCalcFormatter(cell.getValue(), cell._cell.row.data) : null
            }

            convertedCols.cellClick = (event, cell) => {
                if (this.editing) event.stopPropagation()
                else if (col.cellClick) col.cellClick(event, cell)
            }
            return convertedCols
        })
    }
}

function hideEmptyCols(cols, data, forceHide) {
    return cols.filter(col => {
        if (col.columns && col.columns.length > 0) {
            return col.columns = hideEmptyCols(col.columns, data, forceHide)
        } else if (forceHide || col.hideIfEmpty) {
            return data.find(item => _.get(item, col.name))
        } else {
            return true
        }
    })
}

function removeDuplicates(columns, data) {
    const dataByKeys = data.reduce((rows, item) => {
        const key = []
        columns.forEach(col => {
            key.push(_.get(item, col.name))
        })
        rows[key] = item 
        return rows
    }, {})
    return Object.values(dataByKeys)
} 