import { Service } from '../../framework'

import { EmployerService, MembershipService, PersonService } from '..'
import { Employment, Employments, Membership, Participation, Person } from "../../entities"
import { EmploymentBusiness, ParticipationBusiness } from "../../business"
import { RefEvent } from '../../framework/infra'
import { setAPIError } from '../../hooks/useAPIError'
import { EmploymentTriggers } from '../../entities/employment/EmploymentTriggers'
import EmploymentListMapper from '../../mappers/EmploymentListMapper'
import moment from 'moment'
import { AdjustmentService } from '../pension'
import { promisify } from "util";
import { ExcelRenderer } from 'react-excel-renderer';
import { EMPLOYEE_ID_VARIATIONS, SIN_VARIATIONS, cleanHeaderRow, errors, findHeaderPosition, findNameHeaderPosition } from '../../framework/utils/excelImportHelper'
import { cleanseSIN, isEmptyObj, isValidSIN } from '../../framework/utils/helper'

class EmploymentService extends Service {
    constructor() { super(Employment, 'Employment', 'employment') }

	getMemberEmployments(memberId, options) {
		return this.getBy('GetEmploymentsForMember', {member: memberId}, options).then(employments => {
            return this.initList(employments)
        })
	}
	
	getParticipationEmployments(ppKey, options) {
		return this.getBy('GetEmploymentsForParticipation', {participation: ppKey}, options).then(employments => {
            return this.initList(employments)
        })
	}

	async getEmployerEmployments(employerId, options = {}) { 
        let action = "GetEmploymentsForEmployer";
        return await this.callApi(action, action+'_' + employerId, { employer: employerId }, EmploymentListMapper, options);
    }

	getAllEmployments(options) {
		return MembershipService.getMemberships().then(() => {
			return this.getAll(options, true);
		}).then(employments => {
            return this.initList(employments)
        })
	}

	deleteEmptyEmployment(employmentId, options = {}) {
        this.invalidateCache();
		return this.persistence
            .callApi(this.moduleName + "_DeleteEmployment", {
                keys: employmentId,
            })
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });;
	}

	async load(employment, options = {}) {
		const keys = Participation.getKeyValues(employment.participation.keyValue)

		let membership = await MembershipService.get(keys.membership)
		employment.participation = membership.participations[employment.participation.keyValue]
		employment.employer = await EmployerService.get(employment.employer.id)

		let ppEmployments = [];
		try {
			if(employment?.participation?.keyValue === undefined || employment?.participation?.keyValue === null) {
				console.error('EmploymentService load: employment.participation.keyValue is undefined/null, data might be corrupted', {employment, membership});
			} else {
				ppEmployments = membership.participations[employment.participation.keyValue].employments;
			}
		}catch (e) {
			console.log(membership);

			console.log(e, employment, options.item );
		}
		if (!ppEmployments?.find(x=>x.keyValue === employment.keyValue))
			ppEmployments?.push(employment);

		return employment
	}
	
	initList(employments, options = {}) {
		employments.sortChronologically()
		const empsByPP = employments.group('participation.keyValue')
		Object.getOwnPropertyNames(empsByPP).forEach(ppKey => {
			const pp = empsByPP[ppKey].first && empsByPP[ppKey].first.participation
			if (!pp) this.logDataIntegrity('Participation ' + ppKey + ' is not existant' )
			pp.employments = empsByPP[ppKey]

			pp.membership.participations[ppKey].employments = empsByPP[ppKey]
			ParticipationBusiness.validate(pp)
		})
		return employments
	}

	init(employment) { 
		employment.events.sortEvents();
		employment.eventStatuses.assignEndTs()
		return EmploymentBusiness.validate(employment) 
	}

	/**
	 * 
	 * @param {Employment} emp 
	 * @param {{key: string}} source 
     * @param {{end?: boolean | undefined; noCommit?: boolean | undefined;} | undefined} options 
     * @returns {Promise<{ warning?: string | undefined; employment: Employment; membership: Membership; newEmploymentCreated?: boolean | undefined;} | void>}
	 */
    createEmployment(emp, source, options = {}) {
		emp.source = source.key;
		return MembershipService.getMembership(emp.person.id).then(mem => {
			return this.getMemberEmployments(emp.person.id, {refresh: true}).then(emps => {
				if (mem.person) {
					emp.participation.membership = mem;
				}
				return EmploymentTriggers.create(emp, options);
			})
		})
	}

	async updateEmployment(employment) {
		await this.checkDeemed(employment);
        return this.update(employment)
	}

	async checkDeemed(employment) {
		const checkEvent = employment.events.find(e => e.config.isDeemedCheck);
		if (!checkEvent) {
			const { prevYear, currentYear } = await AdjustmentService.createDeemedAdjustment(employment);
			const previousYearCheck = (prevYear?.difference?.earnings?.length !== 0 || prevYear?.difference?.earnings?.deemedHours 
				|| prevYear?.difference?.contributions?.length !== 0 )&& !isEmptyObj(prevYear);
			const currentYearCheck = (currentYear?.difference?.earnings?.length !== 0 || currentYear?.difference?.earnings?.deemedHours 
				|| currentYear?.difference?.contributions?.length !== 0) && !isEmptyObj(currentYear);

			if (previousYearCheck || currentYearCheck) employment.addEvent({code: 'cDmd'});
		}
	}

	async handleEmployeeIdImport(employerId, file, employments) {
		const supportedTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ''];
		let error = "";

		if (!employments || employments.length === 0) {
			employments = await this.getEmployerEmployments(employerId);
		}

        if(!file) {
            error = errors.noFileSelected;
        } else if (!supportedTypes.includes(file.type)) {
            console.log(file.type);
            error = errors.fileNotSupported;
        }

        return promisify(ExcelRenderer)(file).then(data => {
            const rows = data.rows;
            const headerPosition = findHeaderPosition(rows, ['sin', 'id']);
            if (headerPosition < 0) error = errors.noHeaderFound;
            const headers = cleanHeaderRow(rows[headerPosition]);
            const sinIndex = headers.findIndex(header => (SIN_VARIATIONS.includes(header)));
            const employeeIdIndex = headers.findIndex(header => (EMPLOYEE_ID_VARIATIONS.includes(header)));
            let count = headerPosition + 1;

            rows.splice(0, headerPosition + 1);
            const summary = rows.reduce((summaryList, row) => {
				let message = "";
				let sameSIN = false;
                count++;
                const sin = cleanseSIN(row[sinIndex]);
                const id = row[employeeIdIndex] ? String(row[employeeIdIndex]).trim() : "";
                if (isValidSIN(sin)) {
					const employment = employments.find(emp => emp.person.sin === sin);
					message = id !== "" 
						? `Employment ID for ${employment?.person.desc} (SIN: ${sin}) changed to ${id}.`
						: `Employment ID for ${employment?.person.desc} cleared.`;

					if (!employment) {
						message = `Employment for SIN ${sin} not found for this employer. Unable to update employee ID.`;
					} else if (employment.noEmp === id) {
						message = `Employment for SIN ${sin} has not changed. No update necessary.`;
						sameSIN = true;
					} else {
						employment.noEmp = id;
					}

					summaryList.push({
						rowId: count,
						sin,
						id,
						employment,
						message,
						severity: !employment ? "e" : "",
						isChanged: !sameSIN,
					});
                } else {
					message = `Unexpected SIN value. Unable to update employee ID.`;
					summaryList.push({
						message,
						rowId: count, 
						sin,
						severity: "e",
					})
				}
                return summaryList;
            }, []);

			this.updateAll(summary
				.filter(item => item.employment && item.isChanged)
				.map(item => item.employment), "noEmp");

			return {summary, error};
        }).catch(err => {
            console.log(err)
			setAPIError(err);
        })
	}

	existCurrentEmployment(memId, erId) {
		return this.getMemberEmployments(memId, {refresh: true}).then(employments => {
			return employments.find(emp => emp.employer.id === erId && emp.status.isNotTerminated());
		})
	}
}

const instance = new EmploymentService()
export default instance
