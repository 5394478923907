
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"

import App from './App'
import NotificationProvider from './providers/NotificationProvider';
import routes from './routes'
import config from './utils/config';

console.warn = () =>{}
console.debug = (message) => { if (config.debug) console.log(message)};
render(<Router>
    <NotificationProvider>
        <App routes={routes}/>
    </NotificationProvider>
</Router>, document.getElementById("root"))