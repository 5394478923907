import React from "react";
import { Modal, Row } from "../containers";
import { Button } from "../controls";

const QuestionLarge = ({ title, onAnswer, content, yesLabel, noLabel }) => {
  const handleAnswer = (answer) => {
    if (onAnswer) onAnswer(answer);
  };

  return (
    <Modal
      size="lg"
      show={true}
      onHide={() => handleAnswer(false)}
      centered
      className="s-question"
    >
      <Row>
        <h3 style={{padding: '1rem 0'}}>
          <span>
            <i className="far fa-bell"></i>
          </span>
          <span className="ml-3">{title}</span>
        </h3>
      </Row>
      {Boolean(content) && <Row>{content}</Row>}
      <Row className="mt-4">
        <div className="col" />
        <Button key="No" onClick={() => handleAnswer(false)} startIcon="ban">
          {noLabel || 'No'}
        </Button>
        <Button key="Yes" onClick={() => handleAnswer(true)} startIcon="check">
          {yesLabel || 'Yes'}
        </Button>
      </Row>
    </Modal>
  );
};

export default QuestionLarge;
