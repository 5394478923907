import moment from "moment";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Row, Title } from "../../containers";

const CalendarValidation = ({
    dateLabel,
    disableComment,
    message,
    instance,
    params,
    taskSave,
    onClose,
    onSave,
    startDate,
    isWarning,
}) => { 
    const [date, setDate] = useState(moment(startDate).format('YYYY-MM-DD') ?? '');
    const [comment, setComment] = useState('');

    const handleConfirmEvent = () => {
        if(taskSave) taskSave(instance, date, comment, params);
        onSave(instance);     
    }
    const handleChange = (ev) => {
        setDate(ev.target.value)
    }
   
    return <><Title title={message} onHide={onClose} isWarning={isWarning}/>
        <div className='mb-2'>
            <label className='mb-0'>{dateLabel}</label>
            <Form.Control type='date' value={date} onChange={handleChange} />
        </div>
        {!disableComment && <Row className='input-spacing-2'>
            <label className='mb-0'>Comment</label>
            <Form.Control value={comment} onChange={(ev) => setComment(ev.target.value)} />
        </Row>}
        <Row className='mt-4'>
            <div className='col'/>
            <Button onClick={handleConfirmEvent} disabled={date === ''}>Save</Button>   
        </Row> 

    </>
 }

 export default CalendarValidation;