import { ParticipationService } from '../../../../services';
import YesNoQuestion from '../YesNoValidation';


const YesNoDetailResolution = (props) => { 

    const handleOnSave = (participation) => {
        const promise = () => ParticipationService.updateParticipation(participation);
        if(props.onSave) props.onSave(props.remittanceDetail, promise);
    }


    return <YesNoQuestion 
        onClose={props.onCancel} 
        onSave={handleOnSave} 
        instance={props.remittanceDetail.employment.participation}
        onYes={props.onYes}
        onNo={props.onNo}
        message={props.message}
    />
 }

 export default YesNoDetailResolution;