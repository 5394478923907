import moment from 'moment'
import { Lng } from '../infra'
import config from '../../utils/config'

export default class Period {
    constructor (period) {
        if (period === null || period === '') {
            this.empty = true
            this.moment = moment()
        } else if (period instanceof Period) {
            this.moment = moment(period.moment)
            this.empty = period.empty
            this.yearEnd = period.yearEnd
        } else if (typeof period === 'string' && period.length === 4) {
            this.yearEnd= true
            this.moment = moment(period + '-12-31')
        } else {
            this.moment = moment(period)
        }
        return this
    }

    get value() { return this.format('YYYYMM') }
    get text() { return this.format('YYYY/MM') }
    get longDesc() { return this.format('MMMM YYYY', true) }
    get shortDesc() { return this.format('MMM-YY', true)}
    get desc() { return this.format('MMMM YYYY') }

    get date() { return this.format('YYYY-MM-DD') }
    get dateEndPeriod() { return  this.moment.format("YYYY-MM-") + this.moment.daysInMonth()  }
    get yearEndPeriod() { return Period.create(this.year) }
    get year () { return this.format('YYYY') }
    get month () { return this.format('MM') }
    get monthLong() { return this.format('MMMM') }
    get intValue () { return Number(this.year + (this.yearEnd ? '13' : this.month )) }
    get numberOfDaysInPeriod() { return this.yearEnd ? this.numberOfDaysInYear : this.moment.daysInMonth() }
    get numberOfDaysInYear() { return 365 + (this.moment.isLeapYear() ? 1 : 0) }
    get timestampAtPeriodStart() { return this.moment.startOf(this.yearEnd? 'year' :'month').valueOf() }
    get timestampAtPeriodEnd() { return this.moment.endOf(this.yearEnd? 'year' :'month').valueOf() }
    
    format(format, pretifyYE) {
        if (this.empty) return ''
        if (this.yearEnd) return pretifyYE ? Period.getText(this.year) : this.moment.format("YYYY")
        return moment(this.moment.valueOf()).format(format) 
    }

    clone() { return new Period(this.value) }
   
    
    isSame(period){ return this.value === period.value }
    isBefore(period){ return this.value + (this.yearEnd ? '13' : '') < period.value + (period.yearEnd ? '13' : '') }
    isAfter(period){ return this.value + (this.yearEnd ? '13' : '') > period.value + (period.yearEnd ? '13' : '') }
    isSameOrBefore(period){ return this.isSame(period) || this.isBefore(period) }
    isSameOrAfter(period){ return this.isSame(period) || this.isAfter(period) }
    isSameYear(period){ return this.year === period.year }

    inc(month = 1) { 
        const period = Period.create(this.moment)
        period.moment.add(month, 'M')
        return period
    }
    dec(month = 1, includeYearEnd = false) { 
        let period = Period.create(this.moment);
        if (includeYearEnd && this.month === '01') {
            period = new Period(this.decYear().year);
        } else if (includeYearEnd && this.yearEnd) {
            period = new Period(`${this.year}12`);
        } else {
            period.moment.subtract(month, 'M');
        }
        return period;
    }
    decYear(year = 1) { 
        const period = Period.create(this.moment)
        period.moment.subtract(year, 'Y')
        return period
    }

    getNumberOfEffectiveDays(fromTs = 0, toTs = 253402300799) { //TODO convert to constant in Timestamp Object (253402300799000 = 9999/21/31) 
        const startTs = this.timestampAtPeriodStart
        const endTs = this.timestampAtPeriodEnd
        if(fromTs > endTs || toTs < startTs) return 0
        return moment(Math.min(endTs, toTs)).diff(moment(Math.max(startTs, fromTs)), 'days') + 1
    }

}

Period.create = (yearMonth) => { return new Period(yearMonth) }
Period.getCurrentPeriod = () => { return config.currentPeriod ? new Period(config.currentPeriod) : new Period() }
Period.getLaunchPeriod = () => { return new Period(config.launchPeriod) }
Period.getEpochPeriod = () => { return new Period(config.epoch) }
Period.getCreditCalcStartPeriod = () => { return new Period(config.creditAdjustmentsCalculationsStartPeriod)}
Period.getTotalOwingStartPeriod = () => { return new Period(config.totalOwingStartPeriod)}
Period.fromDate = (date) => new Period(date)
Period.currentPeriod = Period.getCurrentPeriod()

Period.getYears = (fromPeriod, toPeriod) => {
    const periods = Period.getPeriods(fromPeriod, toPeriod, true)
    const years = periods.filter((period) => period.yearEnd);
    
    if (!periods[periods.length - 1].yearEnd) {
        years.push(new Period(Period.currentPeriod.year));
    }
    return years;
}

Period.getPeriods = (fromPeriod, toPeriod, includeYearEnd) => {
    var from = fromPeriod || Period.getLaunchPeriod()
    const to = toPeriod || new Period()
    const periods = []
    while (from.value <= to.value) {
        periods.push(from)
        if(includeYearEnd && from.month === '12') periods.push(new Period(from.year))
        from = from.inc()
    }
    return periods
}

Period.getText = (year) => { 
    const texts = { 'en' : `Year End ${year}`,
                    'fr' : `Fin d'année ${year}`}
    return texts[Lng.current]
}
