import { AdjustmentService, BeneficiaryService, EmployerService, EmploymentService, MembershipService, PersonService, RemittanceDetailService, RemittanceService, SpouseService } from "../services";

export default class Mapper {
    
    constructor (data) {
        this.data = data;
        
        //register entity services here, or custom ones
        this.entities = {
            beneficiaries:  BeneficiaryService,
            spouses:        SpouseService,
            remittances:    RemittanceService,
            people:         PersonService,
            memberships:    MembershipService,
            employments:    EmploymentService,
            details:        RemittanceDetailService,
            adjustments:    AdjustmentService,
            employers:      EmployerService,
        }
    }

    //uses configured entities above to load data
    async load (key, options) {
        let loadedData = (await this.entities[key]?.loadList(this.data[key], options)) ?? [];
        await this.storeData(key, loadedData.filter(item => item !== undefined));
        return loadedData;
    }

    async storeData (key, data = []) {
        if (this.entities[key].useCache) {
            if (this.entities[key].customCache) await this.entities[key].customCache(data);
            else {
                for (let item of data) {
                    if (item.keyValue) {
                        this.entities[key].useCache(item.keyValue, () => Promise.resolve(item), true);
                    }
                }
            }
        }
    }
}