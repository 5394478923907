import moment from "moment";
import { ETS_FORMAT, getSafe, isValueUndefined } from "../../framework/utils/helper";
import { Ref, RefEnum } from "../../framework/infra";
import { Icon } from "../../framework/controls";
import { formatAmount } from "../../framework/utils/formating";

const DoubleColumnDisplay = ({entity, instance, properties = [], naValue ='None', flexLabel, flexValue, onHistoricalClick}) => {

    /**
     * Get the value of a property from an instance based on the property name and definition
     * @param {*} property 
     * @returns 
     */
    const getValueComponent = (property) => {
        let def = entity.getDefinition(property)
        let val = getSafe(instance, property);
        if (def && def.list) {
            let newVal = [];
            for (let item of val.all) {
                if (item instanceof Ref) newVal.push(item.desc);
                else newVal.push(item);
            }
            val = newVal;
        }

        if (def?.type?.isChoice && val) val = def.getOptionText(val);
        if (def?.type?.isDate && val) val = moment(val).utc().format(ETS_FORMAT);
        if (def?.type?.isCurrency && val) val = formatAmount(val);
        if (val instanceof RefEnum) val = val.desc;

        const isHistorical = def?.historical;
        const empty = isValueUndefined(val) || (Array.isArray(val) && val.length === 0);

        const getValueSpan = (val) => {
            return <span className={empty? 'btn-ghost':''}>{empty ? naValue : val}</span>;
        }

        let value;
        if (Array.isArray(val) && val.length > 0) {
            value = val.map((v, i) => <div key={i}>{getValueSpan(v)}</div>);
        } else {
            value = getValueSpan(val);
        }

        return isHistorical ? <div className='clickable' onClick={()=>onHistoricalClick(property)}>
                <Icon tooltip={'Historical Field'} icon='calendar-alt' className='clickable pr-2 hist-btn' />
            {value}
        </div> : value;
    }


    const style = {flex: 1}
    return properties.map(property =>
            <div className="row g15 w-100">
                <div style={flexLabel || style} className="bold">
                    {entity.getLabel(property)}:
                </div>
                <div style={flexValue || style} >
                    {getValueComponent(property)}
                </div>
            </div>
        )
    
}

export default DoubleColumnDisplay;