import React from 'react'
import { Definition } from '../../../framework/infra'
import { Modal } from '../../../framework/containers'
import { moment } from '../../../framework/utils/helper'
import { EmploymentService } from '../../../services'
import { Employment } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import { withRouter } from 'react-router-dom'

class EmployerCQPPStatusReport extends ReportLayout {
    reportDesc = 'Member CQPP/Native/Tax Status'
    entity = Employment
    headers = ['person.sin', 'person.name', 'person.dob', 'event.desc', 'isN', 'isCQ', 'isTP']
    headerProps = {
        'event.desc': {title: 'Employment Status'},
        'person.sin': {width: 150},
        'person.dob': {width: 150},
    }
    tableSort = 'person.name'
    filters = {}
    params = {
        from: {definition: {type: Definition.types.DATE, text: "From", default: moment().startOf('year').format('YYYY-MM-DD')}},
        to: {definition: { type: Definition.types.DATE, text: "To", default: moment().format('YYYY-MM-DD')}}
    }
   
    execQuery(queryParams) {
        return EmploymentService.getEmployerEmployments(this.props.params.employer.id).then(employments => {
            return employments.filter(employment => {
                return employment.participation.joinDt >= queryParams.from && employment.participation.joinDt <= queryParams.to
            })
        })
    }
    handleRowSelect(row, showDetails) {
        this.props.history.push('/member/' + row.person.id);
    }
}

export default withRouter(EmployerCQPPStatusReport)