import React from 'react'
import { Table, Icon  } from '../../../framework/controls'
import { Excel } from '../../../framework/utils'
import { renderComponent } from '../../../framework/utils/renders'
import { downloadPdf } from '../../../framework/utils/pdf'
import Payment from '../../../entities/financial/trustee/Payment'
import BankInstructionsPdf from '../../financial/reporting/BankInstructionsPdf' 
import { ConfigService }from '../../../services'
import { useEffect } from 'react'
import { useState } from 'react'

const PaymentList = ({
    employer,
    period,
    remittances,
    selected,
    onSelect,
    className,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setData(prepData(remittances));

        return () => { isMounted = false };
    }, [remittances]);

    const prepData = (remittances) => {
        return remittances.reduce((payments, rem) => {
            if (!period || period.isSame(rem.period))
                payments = payments.concat(rem.payments.all);
            return payments;
        }, []);
    };

    const handleSelect = (row) => {
        if (onSelect) onSelect(row);
    };

    const optionsSelected = (e, cell) => {
        e.preventDefault();
        e.stopPropagation();
        const payment = cell.getRow().getData();
        return Promise.all([
            ConfigService.getConfig("bank"),
            ConfigService.getConfig("corporate"),
            ConfigService.getConfig("trustee"),
        ]).then(([bank, corporate, trustee]) => {
            downloadPdf(
                <BankInstructionsPdf
                    payment={payment}
                    config={{ bank, corporate, trustee }}
                    employer={employer}
                ></BankInstructionsPdf>
            );
        });
    };

    const columns = new Table.Headers(
        Payment,
        "no, rcvDate, amount, distribution.longDescByPeriod, cmt, sentDate, accepted"
    );
    columns["no"].width = 400;
    columns["rcvDate"].width = 160;
    columns["sentDate"].width = 160;
    columns["accepted"].width = 100;
    columns["amount"].width = 160;
    columns["cmt"].width = 250;
    columns.actions = {
        width: 50,
        align: "center",
        cellClick: optionsSelected,
        format: actionIcon,
        headerSort: false,
    };

    return (
        <Table
            id="employer-payments-table"
            data={data}
            columns={columns}
            sort="rcvDate"
            desc
            dataKey="no"
            selected={selected && selected.no}
            onSelect={handleSelect}
            className={className || "h-95"}
        />
    );
};
export default PaymentList;

var actionIcon = function(value, instance) {
	return renderComponent(<Icon tooltip={"Download Bank"} icon='file-invoice' tooltip-left large/>);
}