import React from "react";
import { Modal, Title } from "../../../../framework/containers";
import { Accordion, Button, Form as BForm } from "react-bootstrap";
import YECertificationItem from "./YECertificationItem";
import { YECertificationEvent } from "../../../../entities/pension/yeCertification/YECertificationEvent";
import { yeCertificationConfigs } from "../../../../entities/pension/yeCertification/YECertificationConfig";
import { Form, Formik } from "formik";
import moment from "moment";
import { RemittanceService } from "../../../../services";
import useNotification from "../../../../hooks/useNotification";

const YECertification = ({
    onClose,
    remittance,
}) => {
    const { addMessage } = useNotification();
    const initialValues = YECertificationEvent.messagesOption.reduce(
        (prev, option) => {
            const yeEvent = remittance.yeCertificationEvents.all.find(
                (event) => event.key === option.key
            );
            return {
                ...prev,
                [option.key]: {
                    name: yeEvent?.cmt ?? "",
                    date: Boolean(yeEvent?.ets) ? moment(yeEvent?.ets).format("YYYY-MM-DD") : "",
                    validated: yeEvent?.reviewed ?? false,
                },
            };
        },
        {}
    );

    const handleOnSubmit = (values) => {
        remittance.yeCertificationEvents.reset();
        Object.entries(values).forEach(([code, value]) => {
            const primaryConfig = Object.entries(yeCertificationConfigs)
                .map(([key, config]) => ({ key, config }))
                .filter(configItem => configItem.config.secondCheckboxCode)
                .find(configItem => configItem.config.secondCheckboxCode === code);

            const hasDate = primaryConfig
                ? values[primaryConfig.key].date
                : value.date;
            remittance.yeCertificationEvents.pushEvent({
                key: code,
                ets: hasDate ? moment(hasDate).valueOf() : "",
                cmt: primaryConfig
                    ? values[primaryConfig.key].name
                    : value.name,
                mts: moment().valueOf(),
                reviewed: value.validated,
            });
        });
        RemittanceService.updateYECertification(remittance).then(() => {
            addMessage('Year End Certification saved', 'success');
            onClose();
        });
    }

    return (
        <Modal className="w-100 h-100 modal-bg-color">
            <Title title="Administrator Certification" onHide={onClose} />
            <div className="modal-component-body">
                <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
                    <Form
                        as={BForm}
                        className="h-100 d-flex flex-column justify-content-between"
                    >
                        <div className="d-flex flex-column">
                            <p>
                                I have read and understood the instructions for
                                completing and submitting the data collection
                                report of <b>{remittance.period.value}</b>.
                            </p>
                            <p>
                                I hereby certify that to the best of my
                                knowledge the membership data provided is a
                                complete and accurate description of every
                                person who is entitled to benefits up to and
                                including December 31,{" "}
                                <b>{remittance.period.value}</b>, crucially the
                                following data:
                            </p>

                            <Accordion className="mb-4">
                                {YECertificationEvent.messagesOption
                                    .filter((item) => item.key !== "bme")
                                    .map((item, index) => (
                                        <div key={`${item.key}-${index}`}>
                                            <YECertificationItem
                                                itemInfo={{
                                                    ...yeCertificationConfigs[
                                                        item.key
                                                    ],
                                                    code: item.key,
                                                    sectionTitle: `${
                                                        item.value.text
                                                    }${
                                                        item.key ===
                                                        "statusAtDec31"
                                                            ? remittance.period
                                                                  .value
                                                            : ""
                                                    }`,
                                                }}
                                                index={index + 1}
                                                remittance={remittance}
                                            />
                                        </div>
                                    ))}
                            </Accordion>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button
                                type="submit"
                                variant="primary"
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Modal>
    );
}

export default YECertification;

