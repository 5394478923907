///Refactoring the class above to functional component
import React from 'react'
import { EInput, Warning, Message, Button, Question } from '../../framework/components'
import { Card, Row, Title, } from '../../framework/containers'
import AddressDetails from '../address/AddressDetails'
import CommunicationDetails from '../address/CommunicationDetails'
import { PersonService } from '../../services'
import { isValidSIN } from '../../framework/utils/helper'
import { Address, Person } from '../../entities'

const PersonForm = ({person, readOnly, hideTitle, onSave, onCancel, onChange, notify, isReadOnly}) => {
    
    const [errorMessage, setErrorMessage] = React.useState('');
    const [existingPerson, setExistingPerson] = React.useState();
    const [draft, setDraft] = React.useState(new Person(person.getData()));
    const [canceling, setCanceling] = React.useState(false);

    const handleCancel = () => {
        const oldData = person.getData();
        const newData = draft.getData();

        if (JSON.stringify(oldData) === JSON.stringify(newData)) {
            onCancel()
        } else {
            setCanceling(true);
        }
    }

    const handleConfirmCancel = (confirmed) => {
        if (confirmed) {
            if (onCancel) onCancel()
        } else {
            setCanceling(false)
        }
    }

    const isPersonValid = () => {
        const errors = PersonService.validate(draft)
        if (errors.length > 0) {
            setErrorMessage(errors.join(', '));
            return false;
        }
        return true;
    }

    const handleSave = async () => {
        if (!isPersonValid()) return;

        let existingPerson = await PersonService.getPersonBySin(draft.sin);
        if(existingPerson && existingPerson.id !== person.id) {
            setExistingPerson(existingPerson);
            return;
        }

        PersonService.update(draft).then(() => {
            if (notify) {notify('Information was successfully saved', 'success')}
            person.setData(draft.getData(), false);
            if (onSave) onSave(person)
        })
    }

    const onCloseExistingSinWarning = () => {
        draft.sin = person.sin
        setExistingPerson();
    }
    

    return <>{!hideTitle && <Title title={draft.isNew() ? 'New Person' : draft.desc} onHide={handleCancel} />}
        {errorMessage && <Message message={{ text: errorMessage, severity: 'danger' }} />}
        <Card className='pl-3'>
            <Row className="spacing-3">
                <EInput name='firstName' instance={draft} readOnly={readOnly} />
                <EInput name='middleName' instance={draft} readOnly={readOnly} />
                <EInput name='lastName' instance={draft} readOnly={readOnly} />
                <EInput name='sin' instance={draft} readOnly={readOnly} />
            </Row>
            <Row className='spacing-3'>
                <EInput name='gender' instance={draft} readOnly={readOnly} />
                <EInput name='lng' instance={draft} readOnly={readOnly} />
                <EInput name='dob' instance={draft} readOnly={readOnly} min={'1900-01-01'}/>
                <EInput name='dod' instance={draft} readOnly={readOnly}/>
            </Row>
            <Row className='spacing-3'>
                <EInput name='maidenName' instance={draft} readOnly={readOnly} cn='col-3'/>
            </Row>
        </Card>
        <AddressDetails label='Address' className='mt-2' address={draft.address} readOnly={readOnly}/>
        <CommunicationDetails className='mt-2'instance={draft} readOnly={readOnly}/>
        <Row className='mt-3'>
            <EInput name='cmt' label={'Comment – Cite changes made to the above data'} instance={draft} variant='textarea' cn='mt-1 col-7' readOnly={readOnly}/>
        </Row>
        <div className='d-flex justify-content-end'> 
            {(onCancel && !isReadOnly) && <Button key="cancel" className='btn-secondary' onClick={handleCancel}>Cancel</Button>}
            {(onSave && !isReadOnly)   && <Button key="save" className='btn-secondary' onClick={handleSave}>Save</Button> }
        </div>
        {existingPerson && <Warning title={'A person with the same same SIN already exists'} cn='w-40'
        message={<>
            <ul>
            <li>SIN: {existingPerson.formatedSin}</li>
            <li>Name: {existingPerson.desc}</li>
            <li>Gender: {existingPerson.gender}</li>
            <li>Date of birth: {existingPerson.dob}</li>
            {existingPerson.dod && <li>Date of death: {existingPerson.dod}</li>}
            {existingPerson.address?.desc && <li>Address: {existingPerson.address.desc}</li>}
            </ul>
        </>} onConfirm={onCloseExistingSinWarning} onClose={onCloseExistingSinWarning} />}
        {canceling && <Question title='You have unsaved changes, are you sure you want to cancel?' onAnswer={handleConfirmCancel} />}
    </>

}

export default PersonForm;