import { Service } from '../../framework'
import { Solvencies } from '../../entities'
class SolvencyService extends Service {
    getSolvencies() {
        return Promise.resolve(employerSolvencies)
    }
    getSolvency(solvencies, employer, period) {
        return solvencies[employer.id] && solvencies[employer.id].getSolvencyAtPeriod[period]
    }
    getEmployerSolvencies(employerId) {
      return this.getSolvencies().then(allSolvencies => allSolvencies[employerId] || new Solvencies())
  }
}

const instance = new SolvencyService()
export default instance

const employerSolvencies = {
    '9217dce8-0b1a-40d8-a0e7-67120f330576': new Solvencies([ // AIRC
        { employerId: '9217dce8-0b1a-40d8-a0e7-67120f330576', effPeriod: '201901', amount: 18100 },
        { employerId: '9217dce8-0b1a-40d8-a0e7-67120f330576', effPeriod: '202001', amount: 3800 },
        { employerId: '9217dce8-0b1a-40d8-a0e7-67120f330576', effPeriod: '202106', amount: 2800 },
        { employerId: '9217dce8-0b1a-40d8-a0e7-67120f330576', effPeriod: '202208', amount: 0 },
    ]),
    '38f828f7-2c31-4a80-99a4-d7c1f889a84d': new Solvencies([ // GINW
        { employerId: '38f828f7-2c31-4a80-99a4-d7c1f889a84d', effPeriod: '201901', amount: 1700 },
        { employerId: '38f828f7-2c31-4a80-99a4-d7c1f889a84d', effPeriod: '202001', amount: 300 },
        { employerId: '38f828f7-2c31-4a80-99a4-d7c1f889a84d', effPeriod: '202106', amount: 200 },
        { employerId: '38f828f7-2c31-4a80-99a4-d7c1f889a84d', effPeriod: '202208', amount: 0 },
    ]),
    '61b89463-1361-43cc-8a52-a8bed24ff523': new Solvencies([ // WASW
        { employerId: '61b89463-1361-43cc-8a52-a8bed24ff523', effPeriod: '201901', amount: 18000 },
        { employerId: '61b89463-1361-43cc-8a52-a8bed24ff523', effPeriod: '202001', amount: 3400 },
        { employerId: '61b89463-1361-43cc-8a52-a8bed24ff523', effPeriod: '202106', amount: 2200 },
        { employerId: '61b89463-1361-43cc-8a52-a8bed24ff523', effPeriod: '202208', amount: 0 },
    ]),
    '218fab62-a749-47f9-a0a4-a60cae664d9f': new Solvencies([ // CREG
        { employerId: '218fab62-a749-47f9-a0a4-a60cae664d9f', effPeriod: '201901', amount: 31100 },
        { employerId: '218fab62-a749-47f9-a0a4-a60cae664d9f', effPeriod: '202001', amount: 6000 },
        { employerId: '218fab62-a749-47f9-a0a4-a60cae664d9f', effPeriod: '202106', amount: 4500 },
        { employerId: '218fab62-a749-47f9-a0a4-a60cae664d9f', effPeriod: '202208', amount: 0 },
    ]),
    '560a24e4-292a-4fbc-8f52-99640cd44a9b': new Solvencies([ // BOFC
        { employerId: '560a24e4-292a-4fbc-8f52-99640cd44a9b', effPeriod: '201901', amount: 2300 },
        { employerId: '560a24e4-292a-4fbc-8f52-99640cd44a9b', effPeriod: '202001', amount: 400 },
        { employerId: '560a24e4-292a-4fbc-8f52-99640cd44a9b', effPeriod: '202106', amount: 300 },
        { employerId: '560a24e4-292a-4fbc-8f52-99640cd44a9b', effPeriod: '202208', amount: 0 },
    ]),
    '2376f788-20b7-4e66-9e80-0e10bc09b57c': new Solvencies([ // CHIS
        { employerId: '2376f788-20b7-4e66-9e80-0e10bc09b57c', effPeriod: '201901', amount: 40600 },
        { employerId: '2376f788-20b7-4e66-9e80-0e10bc09b57c', effPeriod: '202001', amount: 7800 },
        { employerId: '2376f788-20b7-4e66-9e80-0e10bc09b57c', effPeriod: '202106', amount: 5300 },
        { employerId: '2376f788-20b7-4e66-9e80-0e10bc09b57c', effPeriod: '202208', amount: 0 },
    ]),
    '06c886e9-d179-4b5d-96f0-c68bcc7689c3': new Solvencies([ // EAST
        { employerId: '06c886e9-d179-4b5d-96f0-c68bcc7689c3', effPeriod: '201901', amount: 18400 },
        { employerId: '06c886e9-d179-4b5d-96f0-c68bcc7689c3', effPeriod: '202001', amount: 3500 },
        { employerId: '06c886e9-d179-4b5d-96f0-c68bcc7689c3', effPeriod: '202106', amount: 2400 },
        { employerId: '06c886e9-d179-4b5d-96f0-c68bcc7689c3', effPeriod: '202208', amount: 0 },
    ]),
    '4f49bbe2-cce8-4e3a-9fe3-dd312ab94e4b': new Solvencies([ // MIST
        { employerId: '4f49bbe2-cce8-4e3a-9fe3-dd312ab94e4b', effPeriod: '201901', amount: 27400 },
        { employerId: '4f49bbe2-cce8-4e3a-9fe3-dd312ab94e4b', effPeriod: '202001', amount: 5100 },
        { employerId: '4f49bbe2-cce8-4e3a-9fe3-dd312ab94e4b', effPeriod: '202106', amount: 3500 },
        { employerId: '4f49bbe2-cce8-4e3a-9fe3-dd312ab94e4b', effPeriod: '202208', amount: 0 },
    ]),
    '9898dfdb-9ab8-4db5-824b-94ad4728408d': new Solvencies([ // NEMA
        { employerId: '9898dfdb-9ab8-4db5-824b-94ad4728408d', effPeriod: '201901', amount: 11200 },
        { employerId: '9898dfdb-9ab8-4db5-824b-94ad4728408d', effPeriod: '202001', amount: 2100 },
        { employerId: '9898dfdb-9ab8-4db5-824b-94ad4728408d', effPeriod: '202106', amount: 1400 },
        { employerId: '9898dfdb-9ab8-4db5-824b-94ad4728408d', effPeriod: '202208', amount: 0 },
    ]),
    'bad2bbed-681a-4175-ab19-c2e69adb4081': new Solvencies([ // WASK
        { employerId: 'bad2bbed-681a-4175-ab19-c2e69adb4081', effPeriod: '201901', amount: 21100 },
        { employerId: 'bad2bbed-681a-4175-ab19-c2e69adb4081', effPeriod: '202001', amount: 4000 },
        { employerId: 'bad2bbed-681a-4175-ab19-c2e69adb4081', effPeriod: '202106', amount: 2700 },
        { employerId: 'bad2bbed-681a-4175-ab19-c2e69adb4081', effPeriod: '202208', amount: 0 },
    ]),
    'b7fdd2e3-75af-4a50-bb2f-cd9a8d3a9605': new Solvencies([ // WEMI
        { employerId: 'b7fdd2e3-75af-4a50-bb2f-cd9a8d3a9605', effPeriod: '201901', amount: 17600 },
        { employerId: 'b7fdd2e3-75af-4a50-bb2f-cd9a8d3a9605', effPeriod: '202001', amount: 3400 },
        { employerId: 'b7fdd2e3-75af-4a50-bb2f-cd9a8d3a9605', effPeriod: '202106', amount: 2400 },
        { employerId: 'b7fdd2e3-75af-4a50-bb2f-cd9a8d3a9605', effPeriod: '202208', amount: 0 },
    ]),
    '0828eed4-1387-407a-878e-1d2fa619aa46': new Solvencies([ // TRAP
        { employerId: '0828eed4-1387-407a-878e-1d2fa619aa46', effPeriod: '201901', amount: 5600 },
        { employerId: '0828eed4-1387-407a-878e-1d2fa619aa46', effPeriod: '202001', amount: 1000 },
        { employerId: '0828eed4-1387-407a-878e-1d2fa619aa46', effPeriod: '202106', amount: 700 },
        { employerId: '0828eed4-1387-407a-878e-1d2fa619aa46', effPeriod: '202208', amount: 0 },
    ]),
    '5576e28f-3a57-43d3-a9c0-95560bd344c5': new Solvencies([ // EEPF
        { employerId: '5576e28f-3a57-43d3-a9c0-95560bd344c5', effPeriod: '201901', amount: 11900 },
        { employerId: '5576e28f-3a57-43d3-a9c0-95560bd344c5', effPeriod: '202001', amount: 2400 },
        { employerId: '5576e28f-3a57-43d3-a9c0-95560bd344c5', effPeriod: '202106', amount: 1600 },
        { employerId: '5576e28f-3a57-43d3-a9c0-95560bd344c5', effPeriod: '202208', amount: 0 },
    ]),
    '02c81273-30d9-49a1-a4e2-554326f0f564': new Solvencies([ // COMU
        { employerId: '02c81273-30d9-49a1-a4e2-554326f0f564', effPeriod: '201901', amount: 500 },
        { employerId: '02c81273-30d9-49a1-a4e2-554326f0f564', effPeriod: '202001', amount: 100 },
        { employerId: '02c81273-30d9-49a1-a4e2-554326f0f564', effPeriod: '202106', amount: 100 },
        { employerId: '02c81273-30d9-49a1-a4e2-554326f0f564', effPeriod: '202208', amount: 0 },
    ]),
    '43a1e72f-f8a3-4d38-a09c-97066630df47': new Solvencies([ // EYOU
        { employerId: '43a1e72f-f8a3-4d38-a09c-97066630df47', effPeriod: '201901', amount: 2100 },
        { employerId: '43a1e72f-f8a3-4d38-a09c-97066630df47', effPeriod: '202001', amount: 400 },
        { employerId: '43a1e72f-f8a3-4d38-a09c-97066630df47', effPeriod: '202106', amount: 200 },
        { employerId: '43a1e72f-f8a3-4d38-a09c-97066630df47', effPeriod: '202208', amount: 0 },
    ]),
    '4711f4b6-74c4-47f9-9299-fbee8fde4e8f': new Solvencies([ // NAPI
        { employerId: '4711f4b6-74c4-47f9-9299-fbee8fde4e8f', effPeriod: '201901', amount: 10400 },
        { employerId: '4711f4b6-74c4-47f9-9299-fbee8fde4e8f', effPeriod: '202001', amount: 2100 },
        { employerId: '4711f4b6-74c4-47f9-9299-fbee8fde4e8f', effPeriod: '202106', amount: 1400 },
        { employerId: '4711f4b6-74c4-47f9-9299-fbee8fde4e8f', effPeriod: '202208', amount: 0 },
    ]),
    '8c8f9d61-2a2a-41c3-9f45-f59bea5784ca': new Solvencies([ // OUJE
        { employerId: '8c8f9d61-2a2a-41c3-9f45-f59bea5784ca', effPeriod: '201901', amount: 10900 },
        { employerId: '8c8f9d61-2a2a-41c3-9f45-f59bea5784ca', effPeriod: '202001', amount: 2100 },
        { employerId: '8c8f9d61-2a2a-41c3-9f45-f59bea5784ca', effPeriod: '202106', amount: 1500 },
        { employerId: '8c8f9d61-2a2a-41c3-9f45-f59bea5784ca', effPeriod: '202208', amount: 0 },
    ]),
    '04336347-1c89-443b-969c-3dbc50c2a041': new Solvencies([ // GRWH
        { employerId: '04336347-1c89-443b-969c-3dbc50c2a041', effPeriod: '201901', amount: 13100 },
        { employerId: '04336347-1c89-443b-969c-3dbc50c2a041', effPeriod: '202001', amount: 2500 },
        { employerId: '04336347-1c89-443b-969c-3dbc50c2a041', effPeriod: '202106', amount: 1800 },
        { employerId: '04336347-1c89-443b-969c-3dbc50c2a041', effPeriod: '202208', amount: 0 },
    ]),
}
