import { useHistory } from "react-router-dom";
import { Icon } from "../framework/controls";

const Title = ({
    titleText,
    showBackButton,
    endButtons = [],
}) => {
    let history = useHistory();

    const handleOnClick = () => {
        history.goBack();
    }

    return (
        <div className='d-flex justify-content-between'>
            <div className="d-flex align-items-center mb-2">
                {showBackButton && (
                    <Icon
                        icon="arrow-circle-left"
                        large
                        onClick={handleOnClick}
                        className="mr-2 clickable text-primary"
                    />
                )}
                <span className="navigation-title">{titleText}</span>
            </div>
            <div>
                {endButtons}
            </div>
        </div>
    );
};

export default Title;
