import { Employments } from "../entities";
import Mapper from "./Mapper";

export default class EmploymentListMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.employments = new Employments();
    }

    async map () {
        await this.load('spouses');
        await this.load('people');
        await this.load('beneficiaries');
        await this.load('memberships');
        this.employments.pushList(await this.load('employments'));
        return this.employments;
    }
}