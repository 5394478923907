import { Service } from '../../framework'
import MembershipSummaryResults from '../../entities/search/MembershipSummarySearch/MembershipSummaryResults';
import { EmployerService } from '..';

class MembershipSummarySearch extends Service {
    constructor() {
        super(MembershipSummaryResults, 'MembershipSummary', 'search')
    }
    
    static DEFAUT_PAGE_SIZE = 15;
    
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }

    async load(membershipSummaryResults, options) {
        const documents = membershipSummaryResults.documents.all || [];
        for (const document of documents) {
            for (const employerId of document.employerIds) {
                const employer = await EmployerService.get(employerId);
                document.employers.push(employer);
            }
        }
        return membershipSummaryResults
    }
}

const instance = new MembershipSummarySearch()
export default instance

