import  RefDated from './RefDated'
import  Ref from './Ref'
import  RefEnum from './RefEnum'
import  Definition from './Definition'
import RefMap from './RefMap'
import { arrayFilter, deepFreeze } from '../../utils/helper'

export default class RefDescription extends Ref {
    static perCounter = 0
    static perTimer = 0

    getMessageParams() {
        const messParams = String(this.text).match(/[^{\}]+(?=})/g) 
        return messParams ? messParams.reduce((ret, param) => {
            param = param.replace('fi:', '').replace('if:', '')
            ret[param] = param
            return ret
          }, {}) : {}
    }
    getDesc(params) {
        //TODO PERFORMANCE issues, this is surely THE performace issue
        this.constructor.perCounter ++
        if (!this.text) return ''
        const messageParams = this.getMessageParams()
        if (!messageParams) return this.text
        return arrayFilter([].concat(this.text).reduce((res, val) => {
            Object.getOwnPropertyNames(messageParams).forEach(paramName => {
                const value = ( params[paramName] &&  params[paramName].value) || ''
                val = val.replace(new RegExp(`{if:${paramName}}(.*){fi:${paramName}}`, 'g'), value ? '$1' : '')
                val = val.replace(new RegExp(`{${paramName}}`, 'g'), value) 
            })
            if(val) res.push(val)
            return res
        }, []), v=>!!v).join('\n')
    }

    static definitions = {
        key: { type: Definition.types.STRING, text: 'Message Key' },
        text: { type: Definition.types.STRING, text: 'Text' },
        severity: { type: Definition.types.STRING, text: 'Severity' },
        name: { type: Definition.types.STRING, text: 'Message Name' },
        title: { type: Definition.types.STRING, text: 'Message Title' },
        options: { ref: Options }
    }
    static key = 'key'
}
class Option extends Ref {
    static definitions = {
        key: { type: Definition.types.STRING },
        value: { type: Definition.types.STRING }
    }
    static key = 'key'
}
class Options extends RefMap { static ref = Option }

