import React from 'react'
import { Modal, Row } from '../containers'
import { Button } from '../controls'
import { Title } from '../containers'

const Warning = ({onConfirm, onClose, message, confirmation = 'Ok', title, cn=''}) => {

    const confirmationHandler = () => {
        if (onConfirm) onConfirm();
        if (onClose) onClose();
    }

    return <Modal className={'modal-bg-color ' + cn}>
        <Title onHide={onClose} title={title}/>
        <div className='modal-content-body'>{message}</div>
        <Row className="justify-content-center">
            <Button onClick={confirmationHandler}>{confirmation}</Button>
        </Row>
    </Modal>
}
export default Warning;