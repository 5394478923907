import { useState, useEffect } from 'react'
import config from '../../utils/config';

const Footer = () => {

    const [version, setVersion] = useState();
    useEffect( ()=> {
        let isMounted = true;

        fetch('/version.txt').then((r) => r.text().then((text)=> {if (isMounted && config.env !== 'local') setVersion(text)}));

        return () => { isMounted = false };
    }, [])

    return (
        <div className="text-center mt-1">
                &copy;  {new Date().getFullYear()}{" "} A.S. Hayes Consulting Inc.  <span className="version">{version}</span>
        </div>
    )
}

export default Footer;