import React from 'react';
import StringInput from './StringInput';
import moment from 'moment';

const DateRange = ({onEnter, instance, property, existingFilters}) => {
    const dateOptions = ["dateFrom", "dateTo"];

    for (const option of dateOptions) {
        if (existingFilters[option]) {
            instance[option] = moment(Number(existingFilters[option].value));
        }
    }

    const handleEnter = async () => {
        const inputResult = {}; 
        let label = '';
        for (const option of dateOptions) {
            if (instance[option]) { 
                const value = await instance.getValue(option);
                instance[option] = value;
                label = await instance.getLabel(option);
                inputResult[option] = {value: instance[option], label};
            }
        }
        onEnter(inputResult);
    }

    return <div>
        {instance[property].text}
        <div className="d-flex">
            <StringInput onEnter={handleEnter} instance={instance} property="dateFrom" className="mr-2" placeholder="From..." hideApplyButton noWidth />
            <StringInput onEnter={handleEnter} instance={instance} property="dateTo" className="mr-2" placeholder="To..." noWidth focusOnMount={false}/>
        </div>
    </div>;
}

export default DateRange;