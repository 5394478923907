import React from 'react'

export default class Tooltip extends React.Component {

    render() {
        return  <div></div>
        // <MDBTooltip domElement tag="span" placement={this.props.placement || "top"}>
        //     <span className="blue-text"><MDBIcon icon={this.props.icon || "info-circle"} /></span>
        //     <span>{ this.props.text }</span>
        // </MDBTooltip>
    }
}
