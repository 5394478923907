import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"

import { Component } from "../../components"
import './login.css'


export default class LoginWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = { validated: false }
        this.title = 'Sign in'
    }

    wrapper(view) {
        return (
            <React.Fragment>
                <Container className="login" style={{background: 'url(/img/bg-image.png) no-repeat', backgroundSize: 'cover', height:'100vh'}}>
                    <Row>
                        <Col className="mx-auto d-table">
                            <div className="">
                                <div id="logo" className="login-col">
                                    <img src="/img/logo.png" alt="A.S.Hayes Consulting inc." height="41" width="250" />
                                </div>
                                <Card id="login-card" className="login-col">
                                    <Card.Header id="login-header" >
                                        <img src="/img/jbccnpp-logo.svg" alt="A.S.Hayes Consulting inc." height="100" width="100" />
                                    </Card.Header>
                                    <div id="login-welcome">
                                        <span className="login-welcome-text">Wachiya</span>
                                        <span className="login-welcome-ndash">&ndash;</span>
                                        <span className="login-welcome-text">Welcome</span>
                                        <span className="login-welcome-ndash">&ndash;</span>
                                        <span className="login-welcome-text">Bienvenue</span>
                                    </div>
                                    <div className="login-welcome-name">James Bay Cree-Naskapi Pension Plans</div>
                                    <Card.Body>
                                        <Error message={this.state.message} />
                                        <div>
                                            {view}
                                        </div>
                                        
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div id="copyright-images">Background image © Cree Nation Government</div>
                </Container>
            </React.Fragment>
        )
    }
}

class Error extends React.Component {
    render() {
        if (this.props.message) {
            const title = this.props.message.text || this.props.message.message
            return (
                <div className={'row alert alert-' + (this.props.message.severity || 'danger alert-danger-box')} role="alert">
                    {title && <h4 className='w-100 alert-heading text-center alert-danger'>{title}</h4>}
                    {this.props.message.content && <div className='w-100  text-center alert-danger'>{this.props.message.content}</div>}
                </div>
            )
        } else {
            return <div />
        }
    }
}