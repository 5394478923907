import { Ref, Definition }  from '../../framework/infra'

export default class Solvency extends Ref {
    
    static key = 'effPeriod.value'
    static definitions = {
        employerId: {type: Definition.types.STRING, text: 'Employer Id', text_fr: ''},
        effPeriod: { type: Definition.types.PERIOD, text: 'Period', text_fr: 'Period'},
        amount: {type: Definition.types.AMOUNT, text: 'Plan Name', text_fr: ''},
    }
}
