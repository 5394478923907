import React, { useState } from "react";
import Card from "../../components/containers/Card";
import DoubleColumnDisplay from "../../components/containers/DoubleColumnDisplay";
import Loading from "../../components/containers/Loading";
import { Employment, Participation } from "../../entities";
import { Modal, Row, Title } from "../../framework/containers";
import { Icon } from "../../framework/controls";
import useUser from "../../hooks/useUser";
import HistoricalList from "./HistoricalList";
import { EmploymentService } from "../../services";
import SuperAdminGuard from "../../guards/SuperAdminGuard";
import RemittanceDetailsAdmin from "../financial/remittance/RemittanceDetailsAdmin";

const EmploymentPanel = ({employment, participation, onSave, loadingContext}) => {
    const title = <>{`${employment.statusDesc}`}</>
    const user = useUser();
    const [selectedHistItem, setSelectedHistItem] = useState(null);
    const [adminRem, setAdminRem] = useState(false);

    if (!employment || !participation || !user) return <Loading />;

    const actions = [
        <SuperAdminGuard> 
            <Icon key='manage-remittances' icon='wrench' className='text-primary clickable' tooltip-left={true} tooltip='Manage Remittances' onClick={() => setAdminRem(true)} /> 
        </SuperAdminGuard> ,
    ]

    const handleSave = async () => {
        await EmploymentService.updateEmployment(employment)
    }


    const emplyomentStyle = { flex: 3 }

    return <Card title={title} actions={actions} style={emplyomentStyle}>
        {adminRem && <Modal className='modal-bg-color w-80'>
            <Title title={'Manage Remittance Details'} onHide={() => setAdminRem(false)}/>
            <RemittanceDetailsAdmin 
                employment={employment} 
                handleClose={() =>{ setAdminRem(false) }}
                loadingContext={loadingContext}
                onSave={onSave}
            />
        </Modal>}
        <Row>
            <div className="row g15" >
                <div>
                    <div className="mb10 bold underline">Employment Details {employment.noEmp && ('#' + employment.noEmp)}</div>
                    <DoubleColumnDisplay 
                        flexLabel={{flex: 1.5, width: '300px' }} 
                        flexValue={{flex: 1}} entity={Employment} 
                        instance={employment} 
                        onHistoricalClick={(value) => setSelectedHistItem(value)}
                        properties={[
                           'hiredDate', 'isN', 'isTP', 'isCQ', 'employmentType', 'baseEarnings', 'workSch'
                    ]} />
                    {selectedHistItem && <Modal id='historicalList' className='w-60 h-60 modal-bg-color'>
                        <HistoricalList 
                            instance={employment} 
                            name={selectedHistItem} 
                            onClose={()=>setSelectedHistItem(null)} 
                            onCancel={()=>setSelectedHistItem(null)} 
                            onUpdate={handleSave} 
                        />
                    </Modal>}
                </div>
            </div>
            <div className="row g15" >
                <div>
                    <div className="mb10 bold underline">Participation Details (PP#{participation.ppNo})</div>
                    <DoubleColumnDisplay flexLabel={{flex: 1.5, width: '300px' }} flexValue={{flex: 1}} entity={Participation} instance={participation} properties={[
                        'firstEligibilityDesc', 'joinDt', 'closeDate', 
                    ]} />
                </div>
            </div>
        </Row>
    </Card>
}

export default EmploymentPanel;