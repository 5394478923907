import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class RefundDistributionType extends RefEnum {
    constructor(key, defaultDesc, lockedIn) {
        super(key, defaultDesc)
        this.lockedIn = lockedIn
    }
    static types = deepFreeze({
        MITA: new RefundDistributionType('MITA', 'Max ITA', true),
        OITA: new RefundDistributionType('OITA', 'Over ITA', false),
        AB: new RefundDistributionType('AB', 'Additional Benefit', false),
        EC: new RefundDistributionType('EC', 'Excess Contributions', false),
        VC: new RefundDistributionType('VC', 'Voluntary Contributions', false),
        LI: new RefundDistributionType('LI', 'Locked-In', true),
        CR: new RefundDistributionType('CR', 'Cash Refund', false),
        UTR: new RefundDistributionType('UTR', 'Transfer to RRSP (Under ITA)', false),
        OTR: new RefundDistributionType('OTR', 'Transfer to RRSP (Over ITA)', false)
    })
    
    static definitions = {
        desc: { type: Definition.types.STRING, text: 'Distribution Type' },
        lockedIn: { type: Definition.types.BOOLEAN, text: 'Locked-In', text_fr: '' }
    }
}