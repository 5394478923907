
import React from 'react'
import { Control, Choice } from '.'

export default class YesNo extends Control {
    render() {
        const { name, label, value, readOnly } = this.props
        const options = [
            { key: 'y', text: 'Yes'},
            { key: 'n', text: 'No'}
        ]
        return this.wrapper(<Choice readOnly={readOnly} name={name} value={value ? 'y' : 'n'} nullable={false} label={label} options={options} searcheable={false} onChange={this.handleChange.bind(this)}></Choice>)
    }

    handleChange(value) {
        console.log(value)
        if(this.props.onChange) this.props.onChange(value === 'y')
    }
}
