import Lng from '../Lng'
import Definition from './Definition'
import Ref from './Ref'
import { deepFreeze } from '../../../framework/utils/helper'

export default class RefEnum extends Ref {
    constructor(key, desc, data) {
        super(data)
        this.key = key
        this['_desc'] = typeof desc === 'object' ? desc.en : desc
    }

    get desc() { return this['_desc_' + Lng.current] || this['_desc'] || this.key }
    set desc(defaultDescription) {}

    apply() {}
    getData() { return this.key }

    static get values() { return Object.values(this.types) }
    static get types() { return this._types }
    static set types(types) { this._types = deepFreeze(types)}

    static definitions = {
        key: { type: Definition.types.STRING, text: 'Key'},
        desc: { type: Definition.types.STRING, text: 'Status'}
    }
    static key = 'key'
    static isRefEnum = true
}



