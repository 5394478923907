import { RefList } from '../../framework/infra'
import { Period } from '../../framework/utils'

import Rates from './Rates'


export default class HistoricRates extends RefList {

    get sortedRates() { return this.getSorted((a,b) => a.effPeriod.isAfter(b.effPeriod) ? 1 : -1) }

    get currentRates() {
        return this.sortedRates.findLast(rate => rate.effPeriod.isSameOrBefore(Period.currentPeriod))
    }
    
    getRatesAtPeriod(period) {
        return this.sortedRates.findLast(rate => rate.effPeriod.isSameOrBefore(period))
    }
    
    static ref = Rates
}