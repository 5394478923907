import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../framework/components';
import { Icon } from '../../framework/controls';
import { useLocation } from 'react-router-dom';
import { Excel } from '../../framework/utils';
import { isArray } from '../../framework/utils/helper';

const FilterContainer = (
    /** @type {{view: EventView; onUpdate: () => Promise<void> | undefined; persistent: boolean | undefined; exportFn: () => Promise<EventView[]> | undefined; filterExport: {fn: () => Promise<EventView[]>; label: string;} | undefined; defaults: object | undefined; postExportFn: () => void | undefined; exportHeaderFn: () => void | undefined; filterBoxWidth: number | undefined;}} */
    {
    view, // View representing on row of document collection
    onUpdate, // Callback function to update the filters
    persistent=true, // Boolean to determine if the filters should be saved in the URL
    exportFn, // Function to call to get search results for exporting
    filterExport, // Function to call to get filter the search results for exporting
    defaults = {}, // Default filters to apply
    postExportFn, // Function to call after exporting
    exportHeaderFn, // Columns to export if specified
    filterBoxWidth, // Width (in pixels) of the div of the filters. Default: 200. Other supported width: 300 (see .filter-fixed-* in /public/css/sysem/main.css)
}) => {

    const filterFields = view.filters.filterDefinitions;
    const filterInstance = new view.filters();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const urlFilters = {...defaults};

    const [filters, setFilters] = useState();
    const history = useHistory();
    const dropdownRef = useRef(null);
    const [isAdding, setIsAdding] = useState(false);
    const [filterSelected, setIsSelected] = useState(false);

    const toggleDropdown = () => {
        setIsSelected(false);
        setIsAdding(true);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            handleExit();
        }
    };
        
    const handleMouseLeave = () => {
        if (isAdding && !filterSelected) {
            handleExit();
        }
    };
    
    const handleExit = () => {
        setIsAdding(false);
        setIsSelected(false);
    };

    const FilterBadge = ({filter, onRemove}) => {
        const filterIcon = filterFields.find(field => field.key === filter.key).icon;
        const iconValue = filterIcon + ' mr-1';

        return (
            <Button className={'btn btn-min no-mar'} >
                <Icon icon={iconValue} onClick={() => onRemove(filter.key)} />
                {filter.label || filter.value}
                <Icon icon="times-circle ml-1" onClick={() => onRemove(filter.key, filter.value)} />
            </Button>
        );
    };

    useEffect(() => {
        const fetchURLFilters = async () => {
            //loop through filterFiles to get keys and see if they are set in the URL
            for (const filter of filterFields) {
                if (searchParams.has(filter.key)) {
                    const filterValueString = decodeURIComponent(searchParams.get(filter.key));
	                let filterValue = filterValueString;
                    if(filter?.type?.isDate && typeof filter.getValue === 'function') {
                        // field is a date
                        // filterValue is a string like "1577854800000"
	                    // must call filter.getValue(newValue = "1577854800000") to get the date as number
                        filterValue = filter.getValue({[filter.key]: filterValueString});
                    }
                    else if(filter?.type?.type === 'yesno' && filterValueString && typeof filterValueString === 'string') {
                        // field is a boolean
                        // newValue is a string like "true"
                        filterValue = filter.getValue?.({[filter.key]: filterValueString}) ?? filterValueString;
                    }
                    const hasLimit = filter.limit;
                    const isList = !hasLimit || (hasLimit > 1);

                    if (isList && typeof filterValue === 'string') {
                        for (const fValue of filterValue.split(',')) {
                            filterInstance[filter.key] = fValue;
                            const fLabel = await filterInstance.getLabel(filter.key);
                            urlFilters[filter.key] = urlFilters[filter.key] ? [...urlFilters[filter.key], {value: fValue, label: fLabel}] : [{value: fValue, label: fLabel}];
                        }
                    } else {
                        filterInstance[filter.key] = filterValue
                        const filterLabel = await filterInstance.getLabel(filter.key);
                        urlFilters[filter.key] = {value: filterValue, label: filterLabel}
                    }
                }
            }
            setFilters({...urlFilters});
        }
        
        if (persistent) fetchURLFilters().catch(console.error);
        else setFilters({...defaults});

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!filters) return;

        if (persistent) {
            const queryString = Object.keys(filters).map(key => {
                let queryForFilterKey = `${encodeURIComponent(key)}=${encodeURIComponent(filters[key].value)}`;
                if (isArray(filters[key])) {
                    queryForFilterKey = filters[key].reduce((endString, obj, index) => {
                        const firstElementString = `${encodeURIComponent(key)}=${encodeURIComponent(obj.value)}`;
                        return endString + (index === 0 ? firstElementString : `,${encodeURIComponent(obj.value)}`);
                    }, '');
                }
                return queryForFilterKey;
            }).join('&');
            
            history.replace({
                search: `?${queryString}`
            });
        }

        const filtersToSend = {};
        Object.entries(filters ?? {}).forEach(([key, value]) => { 
            const newValue = isArray(value) ? value.map(x => x.value) : value.value;
            filtersToSend[key] = newValue;
        });
        if (onUpdate) onUpdate(filtersToSend);
    }, [filters]);

    useEffect(() => {
        if (filterSelected) {
            //if has no component, apply filter without input
            const selectedField = filterFields.find(filter => filter.key === filterSelected);
            if (!selectedField.component) {
                filterInstance[selectedField.key] = true;
                handleApply();
            }
        }
    }, [filterSelected]);

    const handleApply = async (inputObj) => {
        setIsAdding(false);
        const value = await filterInstance.getValue(filterSelected);
        const label = await filterInstance.getLabel(filterSelected) || value;
        const selectedField = filterFields.find(filter => filter.key === filterSelected);
        const hasLimit = selectedField?.limit;
        const isList = !hasLimit || (hasLimit > 1);

        setFilters((prev) => {
            if (inputObj) {
                prev = {...prev, ...inputObj}
            } else if (isList) {
                prev[filterSelected] = prev[filterSelected] ? [...prev[filterSelected], {value, label}] : [{value, label}];
            } else {
                prev[filterSelected] = {value, label};
            }
            return {...prev};
        });
        setIsSelected(false);
    };

    /**
     * 
     * @param {{withFilter: boolean | undefined;} | undefined} options 
     * @returns 
     */
    const handleExport = (options) => {

        if (!exportFn) throw new Error('Search function is required for exporting');
        const doFilter = Boolean(filterExport) && Boolean(options?.withFilter);
        const exportConfigs = view.exportConfigs;
        const fileName = `${exportConfigs.fileName}${doFilter ? '-filtered' : ''}`;
        const excel = new Excel(fileName);

        let headers;
        if (exportHeaderFn) {
            headers = exportHeaderFn(Excel);
        } else {
            const columns = exportConfigs.columns;
            headers = new Excel.Headers(view, columns);
        }
        
        const sheet = excel.addSheet(headers, [], exportConfigs.sheetName);
        const allResults = [];

        // Scrollsearch returns a scrollId that can be used to get more results,
        // Recursively search until all results are returned
        const search = async (scrollId) => {
            const params = { scrollId: scrollId, scrollDuration: '5s'}
            const results = await exportFn(params);
            allResults.push(...results.documents.all)

            if (results.scrollId && results.documents.all.length > 0) {
                await search(results.scrollId);
            }
        };


        return search().then(() => { 
            const filteredResults = doFilter ? filterExport.fn(allResults) : allResults;
            excel.addDataRows(sheet, headers, filteredResults);
            let doc = excel;
            if (postExportFn) doc = postExportFn({ excel, sheet, headers });
            doc.download() 
        });
    };

    const handleDelete = (key, value) => {
        const hasLimit = filterFields.find(filter => filter.key === key)?.limit;
        const isList = !hasLimit || (hasLimit > 1);
        let currentFilters = {...filters};
        if (isList) {
            currentFilters[key] = currentFilters[key].filter(obj => obj.value !== value);
            if (!currentFilters[key].length) delete currentFilters[key];
        } else delete currentFilters[key];
        setFilters(currentFilters);
    };

    const handleClear = () => {
        setFilters({});
    };

    //Some filters you can only apply once based on the limit
    const filterOptions = []
    const baseFilterOptions = []
    for (const filter of filterFields) {
        if (filter.hidden) continue;
        if (filter.limit) {
            const count = Object.keys(filters ?? {}).filter(f => f === filter.key).length;
            if (count >= filter.limit) continue;
        }
        if (filter.isBaseFilter) {
            baseFilterOptions.push(filter);
        } else {
            filterOptions.push(filter);
        }
    }

    const filterField = filterFields.find(field => field.key === filterSelected);

    return (
        <div style={{display: 'flex', justifyContent: 'start', gap: 5, flexWrap: 'wrap'}}>
            {Object.keys(filters ?? {}).map((key) => {
                if (isArray(filters[key])) {
                    return filters[key].map(obj => (<FilterBadge key={`${key}-${obj.value}`} filter={{key, value: obj.value, label: obj.label}} onRemove={handleDelete} />))
                }
                return (<FilterBadge key={key} filter={{key, value: filters[key].value, label: filters[key].label}} onRemove={handleDelete} />)
            })}
            <div className="dropdown-container filter-container" ref={dropdownRef} onMouseLeave={handleMouseLeave}>
                <Button className={'btn btn-secondary btn-min no-mar'} onClick={toggleDropdown}>
                    + Add filter 
                </Button>
                {isAdding && !filterSelected &&
                    <div className={`dropdown-menu filter-menu filter-fixed-${filterBoxWidth ?? '200'}`}>
                    <ul> 
                        {filterOptions.map((field) => 
                            <li key={field.key} onClick={() => setIsSelected(field.key)}>
                                <Icon icon={field.icon} className='mr-2'/>
                                {field._text}
                                {field.description && <Icon 
                                    icon='question-circle'
                                    tooltip={field.description}
                                    className='ml-2'
                                    tooltip-right
                                />}
                            </li>
                        )}
                        {/* divider for base options */}
                        {[...baseFilterOptions, ...filterOptions].length > 0 && <hr style={{margin: 0}} />}
                        {baseFilterOptions.map((field) => 
                            <li key={field.key} onClick={() => setIsSelected(field.key)}>
                                <Icon icon={field.icon} className='mr-2'/>
                                {field._text}
                                {field.description && <Icon 
                                    icon='question-circle'
                                    tooltip={field.description}
                                    className='ml-2'
                                    tooltip-right
                                />}
                            </li>
                        )}
                    </ul>
                    </div>
                }
                {isAdding && filterSelected &&
                    <div className="dropdown-menu filter-menu card-panel-body" style={{minWidth: 400}}>
                        <div style={{display: 'flex', justifyContent: 'start', gap: 10}}> 
                            { filterField?.component &&
                                React.cloneElement(filterField.component, {
                                    instance: filterInstance,
                                    property: filterSelected,
                                    existingFilters: filters,
                                    onEnter: handleApply,
                                })
                            }
                        </div>
                    </div>
                }
            </div>
            <Button className={'btn btn-secondary btn-min no-mar'} onClick={handleClear}>
                Clear
            </Button>
            { exportFn &&
                <Button className={'btn btn-secondary btn-min no-mar'} onClick={() => handleExport()}>
                    Export
                </Button>
            }
            {filterExport && 
                <Button className={'btn btn-secondary btn-min no-mar'} onClick={() => handleExport({withFilter: true})}>
                    {filterExport.label}
                </Button>
            }
        </div>
    );
};

export default FilterContainer;
