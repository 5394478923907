import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row, Col, Title } from '../../framework/containers'
import { Form, Select, Currency } from '../../framework/controls'
import { round } from '../../framework/utils/helper'
import { RefundAccounts, RefundDistribution, RefundDistributionType } from '../../entities/pension/refund'

export default class RefundDistributionForm extends Component {
    load() {
        const totalAmount = this.props.distribution.amount
        const bankAccountKey = this.props.distribution.account.keyValue
        return {totalAmount: totalAmount, bankAccountKey: bankAccountKey}
    }
    
	view() {
		const { distribution, refundAccounts } = this.props
        const { newLine, switched } = this.state
        const accountOptions = distribution ? getFilteredAccount(refundAccounts.all, distribution) : []

		return <><Title title='Refund Distribution Details' onHide={this.handleCancel.bind(this)}/>
            <Form className='h-100' id="RefundDistributionForm" data={distribution} onSave={this.handleConfirm.bind(this)} onCancel={this.handleCancel.bind(this)} onChange={this.handleChange.bind(this)}  labelBtnSave='OK' ref={(ref) => this._form = ref}>
                <Row className='input-spacing-2'>
                    <Col span='9'><EInput name='type' instance={distribution} disabled/></Col>
                </Row>
                <Row className='input-spacing-2 mt-2'>
                    <EInput name='amount' instance={distribution} cn='col-3' onChange={this.handleAmountChange.bind(this)} disabled={switched}/>
                    <EInput name='account' instance={distribution} cn='col-6'><Select name="name" className="col" nullable={false} options={accountOptions} disabled={!!newLine} value={distribution.account.keyValue} onChange={this.handleAccountSelect.bind(this,distribution, false)} /></EInput>{/* If remove Select since options is RefList, Einput send it EList */}
                </Row>
                { newLine && <Row>
                    <EInput name='amount' instance={newLine} disabled/>
                    <EInput name='account' instance={newLine} cn='col-6'><Select name="name" className="col" options={accountOptions} nullable={false} value={newLine.account.keyValue} onChange={this.handleAccountSelect.bind(this, newLine, true)} /></EInput> {/* If remove Select since options is RefList, Einput send it EList */}
                </Row>} 
            </Form>
        </>
	}
    
    handleConfirm() { 
        const { newLine } = this.state
        const { payout } = this.props
      
        if (newLine) { payout.distribution.push(newLine) }

        const RRSPAccount = payout.distribution.all.find(distrb => distrb.account.accountType.key === 'RRSP' && (distrb.type.key === 'UTR' || distrb.type.key === 'OTR' || distrb.type.key === 'CR' )) //to keep other types (additional, excess, vol) as same as it's
        const notRRSPAccount = payout.distribution.all.find(distrb => distrb.account.accountType.key !== 'RRSP' && (distrb.type.key === 'UTR' || distrb.type.key === 'OTR')) 

        if (RRSPAccount) { 
            if (RRSPAccount.amount > payout.maxLIPortion) {
                payout.distribution.pushNew({type: 'OTR', amount: round(RRSPAccount.amount - payout.maxLIPortion), account: RRSPAccount.account})
                RRSPAccount.amount =  payout.maxLIPortion
            }
            RRSPAccount.type = new RefundDistributionType('UTR', 'Transfer to RRSP (Under ITA)', false)
        }
        if (notRRSPAccount) notRRSPAccount.type = new RefundDistributionType('CR', 'Cash Refund', false)
        if (this.props.onOK) this.props.onOK() 
    }
    handleCancel() { if (this.props.onCancel) this.props.onCancel() }
    handleAccountSelect(distribution, splitted, key, data) {
        distribution.account = data
        this.setState({switched: distribution.account.keyValue !== this.state.bankAccountKey && !splitted})
        this._form.forceDirty()
    }
    handleChange() { this.setState({ isDirty: true }) } 

    handleAmountChange(amount) { 
        var newLine = this.state.newLine || new RefundDistribution({type: this.props.distribution.type})
        newLine.amount = round(this.state.totalAmount - Currency.parseCurrency(amount))
        this.setState({ newLine: newLine.amount ? newLine : null}) 
    }
}

function getFilteredAccount(accounts, selected) {
    return accounts.reduce((filteredList, acc) => {
        if (acc.accountType.lockedIn === selected.type.lockedIn) {
            filteredList.push({key: acc.keyValue, text: acc.searchDesc, value: acc})
        }
        return filteredList
    }, new RefundAccounts())
}