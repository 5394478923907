import React from 'react'
import Notification from  '../framework/containers/Notification';
import Header from '../views/application/Header'
import Footer from '../views/application/Footer'
import NotificationMessage from '../components/NotificationMessage';
import useNotification from '../hooks/useNotification';

const DashboardLayout = ({children, title, notifyRef, ...rest}) => {
    const {message} = useNotification();

    return (<div className='page bg-color'>
        <div className='page-header'>
            <Header {...rest} />
        </div>
        <div className='page-body'>
            {message && <NotificationMessage notificationMessage={message} />}
            <Notification ref={notifyRef}/>
            {children}
        </div>
        <div className='page-footer'>
            <Footer {...rest} />
        </div>
    </div>)
    
}
export default DashboardLayout;