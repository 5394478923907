import { Service } from '../../framework'

import LegacyMember from '../../entities/membership/LegacyMember'

class LegacyService extends Service {
	constructor() {
        super(LegacyMember, 'LegacyMember', 'membership')
    }
}

const singelton = new LegacyService()
export default singelton
