import { Definition, Entity } from '../../framework/infra'

export default class FinancialSummary extends Entity {
    get ppJnDt() { return this.participation.joinDt }
    get isCQ() { return this.employment.isCQ }


    static definitions = {
        participation: {ref: require('../membership/participation/Participation'), text: 'Participation'},
        employment: {ref: require('../employment/Employment'), text: 'Employment'},
        sin: {  type: Definition.types.SIN, text: 'SIN', text_fr: 'NAS' },
        name:  { type: Definition.types.STRING, text: 'Name' },
        isCQ: { abstract: true, type: Definition.types.YESNO, text: 'CPP/QPP' },
        ppJnDt: { abstract: true, type: Definition.types.DATE, text: 'Join Date' },
        sumContributions: { transient: true, ref: require('./contributions/Contributions'), text: 'Sum Contributions'},
        earnings: { transient: true, ref: require('../financial/earning/Earnings'), text: 'Sum Earnings'},
        priorYearAdjustment: {type: Definition.types.AMOUNT, text: 'Prior Year Adjustment'},
    }
}