import { Definition, RefEnum } from '../../framework/infra'
import { deepFreeze } from '../../framework/utils/helper'

export default class WorkSchedule extends RefEnum {
    get weeklySchedule() { return Number(this.key || 35)}
    get yearlySchedule() { return this.weeklySchedule * 52 }
    get monthlySchedule() { return this.yearlySchedule / 12 }

    static types = deepFreeze({
        '28': new WorkSchedule('28', '28 hours/week'),
        '30': new WorkSchedule('30', '30 hours/week'),
        '32': new WorkSchedule('32', '32 hours/week'),
        '32.5': new WorkSchedule('32.5', '32.5 hours/week'),
        '35': new WorkSchedule('35', '35 hours/week'),
        '36': new WorkSchedule('36', '36 hours/week'),
        '36.75': new WorkSchedule('36.75', '36.75 hours/week'),
        '37.5': new WorkSchedule('37.5', '37.5 hours/week'),
        '38.75': new WorkSchedule('38.75', '38.75 hours/week'),
        '39': new WorkSchedule('39', '39 hours/week'),
        '40': new WorkSchedule('40', '40 hours/week'),
        '42': new WorkSchedule('42', '42 hours/week'),
        '45': new WorkSchedule('45', '45 hours/week'),
        '50': new WorkSchedule('50', '50 hours/week'),
        '55': new WorkSchedule('55', '55 hours/week'),
        '60': new WorkSchedule('60', '60 hours/week'),
    })

    static default = WorkSchedule.types['35']
    static definitions = {
        monthlySchedule: { abstract: true, type: Definition.types.NUMBER, text: 'Monthly Work Schedule' },
        yearlySchedule: { abstract: true, type: Definition.types.NUMBER, text: 'Yearly Work Schedule' },
        weeklySchedule: { abstract: true, type: Definition.types.NUMBER, text: 'Weekly Work Schedule' }
    }
}
