import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row, Title } from '../../framework/containers'
import { Form } from '../../framework/controls'

import AddressDetails from '../address/AddressDetails'

export default class ParticipationRefundAccountForm extends Component {
	
	view() {
		const { refundAccount } = this.props
        
		return <><Title title='Account Details' onHide={this.handleCancel.bind(this)}/>
            <Form className='h-100' id="ParticipationRefundAccountForm" data={refundAccount} onSave={this.handleConfirm.bind(this)} onCancel={this.handleCancel.bind(this)} labelBtnSave='OK' ref={(ref) => this._form = ref}>
                <Row>
                    <EInput name='name' instance={refundAccount} cn='col-3'/>
                    <EInput name='transit' instance={refundAccount} cn='col-3'/>
                    <EInput name='eft' instance={refundAccount} cn='col-3'/>
                    <EInput name='number' instance={refundAccount} cn='col-3'/>
                    <EInput name='accountType' instance={refundAccount} cn='col-3' onChange={() => this._form.forceDirty()}/>
                </Row>
                <AddressDetails label='Bank Address' className='mt-4' address={refundAccount.address} onChange={this.props.onChange}/>
            </Form>
        </>
	}
    handleConfirm() { if (this.props.onOK) this.props.onOK() }
    handleCancel() { if (this.props.onCancel) this.props.onCancel() }
}