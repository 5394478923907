import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankWireBeneficiary extends  Ref {
    
}

const { REF } = Definition.types


// "wire": {

//     "beneficiary": {
//       "accounts": {
//         "fed": "James Bay Cree-Naskapi Pension Plan 07522010",
//         "que": "James Bay Cree-Naskapi Quebec Pension Plan 07522011"
//       },
//       "address": {
//         "city": "Westmount",
//         "civic": "14150 St. Catherine Street W., Suite 205",
//         "country": "Canada",
//         "pc": "H3Z 2Y5",
//         "poBox": "",
//         "prov": "Quebec"
//       },
//       "name": "Beneficiary (Field 58/59)"
//     },

ConfigBankWireBeneficiary.definitions = {
    account_fed: { ref: require('./ConfigBankRegistrationAccount'), text: 'Federal Account', text_fr: 'Compte Quebec' },
    account_que: { ref: require('./ConfigBankRegistrationAccount'), text: 'Quebec Account', text_fr: 'Numero Compte Quebec' },
    address: { ref: require('../../address/Address'), text: 'Address', text_fr: 'Adresse' }
}


