import RefList from './RefList'
import Ref from './Ref'
import moment from 'moment'


export default class RefHistorical extends RefList {
    
    //GETTERS
    get current() { return this.getAt() }
    get latest() { return this.last || this.pushNew() }
    get history() { return this.all }

    //METHODS
    getAt(ts) { return [...this._list].reverse().find(element => element.ets <= ts) || this.create() }
    getAtEffectiveDate(effectiveDate) {
        return this.all.find(element => element.effDt === effectiveDate)
    }
    
    getInterval(fromTs, toTs) {
        if (fromTs === null || fromTs === undefined) fromTs = -2208988800000 //1900-01-01
        if (toTs === null || toTs === undefined) toTs = 253402300799000 //9999-12-13
        var begin = this._list.findIndex((e) => e.ets >= fromTs);
        if (begin === -1 || (begin > 0  && this._list[begin].ets !== fromTs)) {
          const prevEts = this._list[begin === -1 ? this._list.length-1 : begin-1].ets
          const foundFirst = this._list.findIndex((e) => e.ets === prevEts)
          begin = foundFirst
        }
        var end = this._list.findIndex((e) => e.ets >= toTs);
        if (end === -1) end = this._list.length;
      
        return this._list.slice(begin, end)
    }

    //Get all items during range, if none, get the most recent item 
    getDuring(fromTs, toTs, log) {
        var foundStart = false
        return [...this._list].reverse().filter(e => {
            const eventEts = moment(e.effDt).valueOf();
            if(log) {
                console.log(this._list, e, foundStart, e.ets > toTs, e.ets, fromTs, toTs)
            }
            if (foundStart || eventEts > toTs) {
                return false
            } else {
                if (eventEts <= fromTs) foundStart = true
                return true
            }
        }).reverse()
    }
    //Get all items during range, makes sure endTs is in range
    getAllDuringWithEndTs(fromTs, toTs) {
        return [...this._list].reverse().filter((e, index, array) => {
            const nextEvent = array[index - 1];
            const endBeforeMonth = (e.endTs || e.endTs !== "" ? e.endTs : (nextEvent?.config?.isProgressiveReturn ? nextEvent?.endTs : nextEvent?.ets)) < fromTs;
            const beginningAfterMonth = e.ets > toTs;
            return !(endBeforeMonth || beginningAfterMonth);
        }).reverse();
    }
    //Get all items strictly between range
    getAllDuring(fromTs, toTs) {
        return [...this._list].reverse().filter(e => {
            if (e.ets >= fromTs && e.ets <= toTs) {
                return true;
            } else {
                return false;
            }
        }).reverse()
    }
    
    
    reorder() {
        const array = [...this._list] //HACK we copy the array so the component refreshes
		array.sort((a, b) => a.ets < b.ets ? -1 : ( a.ets > b.ets ? 1 : a.rts < b.rts))
        this._list = array
        return this._list
    }

    pullByEts(ets) {
        this._list = this.filter((elem) => elem.ets !== ets);
    }
    
    setHistoryDesc(definition) {
        this._list.forEach(item => { 
            var val = item.value
            if (definition && definition.type && definition.type.format) val = definition.type.format(val)
            else if (item.value instanceof Ref) val = item.value.historyDesc || item.value.desc
            item.historyDesc = definition.text + ' changed to ' + val
        })
    }
    addNewHistoricalItem (newItem) {
		let historyItemSameReportedDate = this.getAtEffectiveDate(newItem.effDt);
		if (historyItemSameReportedDate && newItem && newItem.ets && !isNaN(newItem.ets)) { 
            this.remove((historicalItem) => historicalItem.effDt === newItem.effDt);
            this.push(newItem);
		} else if (newItem && newItem.ets && !isNaN(newItem.ets)) { 
			this.push(newItem);
		}
		this.reorder();
    }

    static combine(historicals) {
        const ret =  historicals.reduce((all, hist) => {
            all.pushList(hist)
            return all
        }, new RefHistorical())
        ret.reorder()
        return ret
    }

    //won't push to list
    findOrCreate(property, instance) {
        return this.all.find(element => element[property] === instance[property]) || this.create(instance);
    } 

    static isRefHistorical = true
}
