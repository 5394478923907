import { Entity } from "draft-js";

export const findLinkEntites = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(
		(character) => {
		  const entityKey = character.getEntity();
		  return (
			entityKey !== null &&
			Entity.get(entityKey).getType() === 'LINK'
		  )
		},
		callback
	  )
}


export const Link = (props) => {
    const {url, linkText} = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}>{linkText || props.children}</a>
    )
}