import React from "react"

import LoginWrapper from './LoginWrapper'
import Auth from '../Auth'


export default class RequestSecurityCode extends LoginWrapper {
    
    handleRequestPassword(event) {
        event.preventDefault()
        if (event.currentTarget.checkValidity() === false) {
            this.setState({validated: true})
        } else {
            const userName = event.target.userName.value.toLowerCase()
            Auth.requestCode(userName).then(data => {
                this.props.onStatusChanged('changePassword', userName)
            }).catch(err => {
                console.log(err)
                this.setState({ message: err })
            })
        }
    }
    
    handleCancel(event) {
        event.preventDefault()
        window.location.reload()
    }

    view() {
        return (
            <form noValidate validated={this.state.validated} onSubmit={this.handleRequestPassword.bind(this)} className="login-form">
                <div className="md-form">
                    <input className="form-control" id="userName" name="userName" label="Username" type="text" required/>
                    <label className="active" for="userName" data-error="" data-success="" id="" aria-labelledby="">Username</label>
                </div>

                <div className="text-center sign-in-no-opacity">
                    <button className="btn sign-in" type="submit">Request Code</button>
                </div>
                <p className="font-small blue-text d-flex justify-content-end">
                    <a href="#!" className="ml-1 forgot-password" onClick={this.handleCancel.bind(this) }>Cancel</a>
                </p>
            </form>
        )
    }
}
