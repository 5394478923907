import React from 'react'
import InputControl from './InputControl'
import { Form } from 'react-bootstrap'

export default class Period extends InputControl {
    handleChange(event){
        this.props.instance[this.props.realName] = event.target.value
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { instance, realName, name, required, className, options, readOnly } = this.props
        return (
            <Form.Control 
                className={className}    
                as="select" 
                name = {name}
                key = {name}
                value = {instance[realName]}
                onChange={this.onChange}
                required={required}
                readOnly={readOnly}
            >
                <option disabled selected value=""></option>
                {options.map(option => (
                    <option key = {option.key} value = {option.key}>{option.text}</option>
                ))}  
            </Form.Control>
        )
    }
}

Period.format = function(def, value) {
    return value ? value.text : ''
}

Period.sort = function(def, a, b) {
    if(!a || !b) return !a || !b ? 0 : (a ? 1 : -1)
    if (a.isSame(b)) return 0
    return a.isAfter(b) ? 1 : -1
}

Period.filter = (def, filterValue, rowValue) => {
    if (!rowValue || !rowValue.text) return false
    return rowValue.text.includes(filterValue) || rowValue.value.includes(filterValue) || rowValue.longDesc.includes(filterValue)
}