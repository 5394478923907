import React from 'react'
import { Component, EInput } from '../../framework/components'
import { FieldSet, Row } from '../../framework/containers'

export default class UnknownPersonForm extends Component {
    
	view() {
		const { person, readOnly, errors, title } = this.props

		return <FieldSet title={title}>
				<Row className='input-spacing-2'>
					<EInput name='firstName' instance={person} />
					<EInput name='lastName' instance={person} readOnly={readOnly}/>
					<EInput name='dob' instance={person} readOnly={readOnly}/>  
				</Row>
			</FieldSet>
		
	}
}