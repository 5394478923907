import { createContext, useCallback, useState } from "react";

export const NotificationContext = createContext({
    message: null,
    addMessage: () => {},
});

const NotificationProvider = ({children}) => {
    const [message, setMessage] = useState(null);

    const addMessage = (newMessage, severity) => setMessage({ message: newMessage, severity, ts: Date.now() });

    const contextValue = {
        message,
        addMessage: useCallback((message, severity) => addMessage(message, severity), []),
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationProvider;