import { RefEvent, RefHistorical } from '../../../framework/infra'
import ParticipationEvent from './ParticipationEvent'
import { Definition } from '../../../framework/infra';
import moment from 'moment';
import { RefEvents } from '../../../framework/infra/model';
import ParticipationConstraints from './ParticipationConstraints';

export default class ParticipationEvents extends RefEvents {

    constructor(data) {
        super(data, ParticipationConstraints);
    }

    get statusEvents() { return this.getFiltered(eve => eve.config?.status) }
    get statusDesc() { 
        var descriptions = this.getFiltered(eve => eve.config.status || eve.config.includeInDesc);
        descriptions.sortEvents();
        let statusEventIndex = descriptions.all.length-1;
        if(typeof descriptions.all.length === 'number' && descriptions.all.length >= 2) {
            const indexIsActive = descriptions.all.findIndex(eve => eve.status?.isActive?.());
            const indexNewPot = descriptions.all.findIndex(eve => eve.code === "newPot");
            // if there are both an active (NROL) event and a newPot event,
            // and the newPot is last, and the newPot event is after the active (NROL) event
            // use the active (NROL) event, not the newPot event
            const NewPotIsLastAndIsAfterActive = (indexIsActive >= 0 && indexNewPot >= 0 && indexNewPot > indexIsActive && statusEventIndex === indexNewPot);
            if(NewPotIsLastAndIsAfterActive){
                statusEventIndex = indexIsActive;
            }
        }
        return descriptions.all[statusEventIndex]?.statusDesc ?? ''; 
    }

    static ref = ParticipationEvent
    static definitions = {
        statusDesc: { abstract: true, ref: Definition.types.STRING, text: 'Status'},
    }
}

