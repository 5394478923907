/**
 * Check if the event's status is in a date range.
 * 
 * This function is for Employment|Participation events that have a status.
 * It's expected that the `to` and `from` dates have been set on the event before calling this function (by applying `setEventsToAndFromDates`).
 * @param {{code: string; effectiveDate: number; to: number | undefined; from: number}} event 
 * @param {{to: number | undefined; from: number | undefined}} dateRange 
 * @returns {boolean} True if the event's status is in the range (the `from` date is before the end of the range, and the `to` date is after the start of the range)
 */
const eventRangeOverlapsDateRange = (event, dateRange) => {
    return (!event.to || !dateRange.from || event.to >= dateRange.from) && (!event.from || !dateRange.to || event.from <= dateRange.to)
}

/**
 * Set the `from` and `to` dates on Employment|Participation events that have a status.
 * 
 * The `from` date will be the `effectiveDate`. The `to` date will be the `effectiveDate` of the next event.
 * The events are ordered by `effectiveDate` asc.
 * @param {{code: string; effectiveDate: number;}[]} events 
 * @returns {{code: string; effectiveDate: number; to: number | undefined; from: number}[]} The events with to and from dates
 */
const setEventsToAndFromDates =  (events) => {
    const orderedEvents = [...events].sort((a, b) => a.effectiveDate - b.effectiveDate)
    return orderedEvents.map((ev, i, arr) => ({code: ev.code, effectiveDate: ev.effectiveDate, from: ev.effectiveDate, to: arr[i+1]?.effectiveDate}));
}

/**
 * Regex: only digits
 */
const dateNumberRegex = new RegExp(/^\d+$/);

/**
 * Check if the value is a number to be used for a date.
 * @param {string} value 
 * @returns True if the value is a number, for example "12345"
 */
const isDateNumber = (value) => {
    return value && typeof value === 'string' && dateNumberRegex.test(value) && !Number.isNaN(Number.parseInt(value))
}

const SearchUtilModule = {eventRangeOverlapsDateRange, setEventsToAndFromDates, isDateNumber};

export default SearchUtilModule;