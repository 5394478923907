import { useState } from "react";
import Loading from "../../../components/containers/Loading";
import { EInput } from "../../../framework/components";
import { FieldSet, Title } from "../../../framework/containers";
import { RemittanceService } from "../../../services";
import { Button, Icon } from "../../../framework/controls";
import { Ref } from "../../../framework/infra";
import { Remittance } from "../../../entities";

const BalanceForm = ({balances, period, employer, onClose, onSave}) => {
    
    const [draft, _setDraft] = useState(balances.clone());
    const [warning, setWarning] = useState();

    const isLocked = balances.isLocked;
    const icon = isLocked ? 'lock' : 'unlock-alt';
    const title = <><Icon icon={icon} tooltip-left large /> {isLocked ? ' Locked balances' : ' Unlocked balances'} {period.text}</>

    const setDraft = () => {
        const clone = draft.clone();
        _setDraft(clone);
    }
    const handleLock = async () => {
        draft.isLocked = true;
        handleSave(true);
    }
    const handleUnlock = async () => {
        draft.isLocked = false;
        handleSave(true);
    }
    const handleSave = async (answer) => {
        if (!answer) return;
        await RemittanceService.update(new Remittance({
            period: period,
            employer: employer,
            lockedBalance: draft
        }), 'lockedBalance');
        if(onSave) onSave();
        close(answer);
    }
    const handleClose = () => {
        close(true);
    }
    const close = (answerIsYes) => {
        setWarning();
        if (answerIsYes) onClose();
    }

    const handleChange = (instance) => {
        setDraft(instance);
    }

    return !draft ? <Loading /> : (<>
        <Title title={title} onHide={handleClose}/>

        <FieldSet title="Balances" variant="field">
            <div className="grid-ellipsis">
                <EInput name="eeBal" onChange={handleChange} instance={draft} />
                <EInput name="erBal" onChange={handleChange} instance={draft}/>
                <EInput name="volBal" onChange={handleChange} instance={draft}/>
            </div>
            <div className="grid-ellipsis mt-2">
                <EInput name="solBal" onChange={handleChange} instance={draft}/>
                <EInput name="intBal" onChange={handleChange} instance={draft}/>
                <EInput name="balance" readOnly={true} instance={draft}
                />
            </div>
            <div className="line g10">
                <EInput variant="textarea" name='cmt' instance={draft}/>
            </div>
        </FieldSet>
        <div className='footer-buttons mt15 button-row g15'>
            <Button key='close' className='btn-secondary' onClick={handleClose}>Close</Button>
            {isLocked && <Button key='save' className='btn-secondary' onClick={handleSave}>Save Balances</Button>}
            {!isLocked && <Button key='save' className='btn-secondary' onClick={handleLock}>Lock Balances</Button>}
            {isLocked && <Button key='save' className='btn-secondary' onClick={handleUnlock}>Unlock Balances</Button>}
            {warning}
        </div>
    </>)
}   

export default BalanceForm;