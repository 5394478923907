import React from 'react'
import NotificationAlert from 'react-notification-alert'

export default class Notification extends React.Component {
    
    notify = (message, severity) => {
        const type = severity || 'info'
        let options = {
          place: 'tc',
          message: (
            <div className="alert-text">
              <span className="alert-title" data-notify="title">
              {message}
              </span>
              
            </div>
          ),
          type: type,
          icon: "fas fa-bell",
          autoDismiss: 7
          
        };
        this.refs.notification.notificationAlert(options);
    }

	render() {
		  return <NotificationAlert ref="notification" />
	}
}
// primary
// secondary
// success
// danger
// warning
// info
// light
// dark
