import { StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import { Component } from '../../../framework/components'
import { Container, Image, Row, Text } from '../../../framework/utils/pdf'


export default class PDFHeader extends Component {
	view() {
		const styles =StyleSheet.create({
			planText: { fontSize: 12, color: '#5a3c1e', fontFamily: 'Arial-Narrow-Bold' },
			planLogo: {	width: 52, height: 52 },
			corporateLogo: { width: 170, height: 100 }
		})
		
		return (
			<Row className='space-between'>
				<Container>
					<Image style={styles['corporateLogo']} src="/img/logoWithAddress.jpg" />	
					{/* <Text className='mt-3'>{config.corporate.address.civic} </Text>
					<Text>{config.corporate.address.city}  {config.corporate.address.prov}  {config.corporate.address.pc}</Text>
					<Text>t. {config.corporate.getNumberByUsage('work')} / f. {config.corporate.getNumberByUsage('fax')} </Text>
					<Text>{config.corporate.getEmailByUsage('work')} </Text>       */}
				</Container>
				<Container className='w-50 right'>
					<Image style={styles['planLogo']} src="/img/jbccnpp-logo.png" />
					<Text className='mt-2' style={styles['planText']}>James Bay Cree-Naskapi Pension Plan</Text>
					<Text style={styles['planText']}>James Bay Cree-Naskapi Quebec Pension Plan</Text>        
					<Text text={texts['planNo']} className='mt-2'/>       
				</Container>
			</Row>
		)
	}
}
const texts = {
	"planNo" : { //TODO get data for that
		"en" : "OSFI Registration No.: 56179 / RRQ Registration No.: 31575",
		"fr" : "No d'enregistrement OSFI: 56179 / No d'enregistrement RQ.: 31575" 
	} 
}