import React from 'react'
import { Form } from 'react-bootstrap'

export default class NumberCtrl extends React.Component {
    handleChange(event){
        if (!this.props.isCustomOnChange) {
            this.props.instance[this.props.realName] = event.target.value
        }
        if(this.props.onChange) this.props.onChange(event)
        this.setState({touch:true })
    }

    render() {
        const { name, required, className, placeholder, instance, realName, readOnly, disabled, max, min, value} = this.props
       
        return (
            <Form.Control 
                className={className}
                type='number'
                required = {required}
                name= {name}
                key= {name}
                value = {value ?? (instance[realName] || '')}
                onChange={this.handleChange.bind(this)}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                min={min}
                max={max}
            />
        )
    }
}
