import { sort, } from '../../../framework/utils/helper'
import { EmployerService } from '../../../services'
import { CompanyContact } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import { sortBy } from 'lodash'

export default class EmployerContactsReport extends ReportLayout {
    reportDesc = 'Employers Contacts'
    entity = CompanyContact
    headers = [
        'employer', 'person.firstName', 'person.lastName', 'pos', 
        'adm', 'cme', 'trr', 'person.lng', 'person.alternatePhone', 'person.alternateEmail', 'cmt'
    ]
    headerProps = {
        'employer': {overrideText: 'Employer'},
    }

    sheetConfig = {
        sheetName: "Contacts",
        headers: [...this.headers],
        headerProps: {...this.headerProps}
    };

    filters = {}
    params = {}

    async execQuery(queryParams) {
        const allEmployers = await EmployerService.getAll();
        const employers = sort((allEmployers?.all ?? []), 'code');
        const employersContacts = sortBy((employers.map(employer => 
            (employer.contacts?.all?.map(employerContact => ({
                employer: employer.code,
                pos: employerContact.pos,
                adm: employerContact.adm,
                cme: employerContact.cme,
                cmt: employerContact.cmt,
                trr: employerContact.trr,
                person: {
                    firstName: employerContact.person?.firstName ?? '',
                    lastName: employerContact.person?.lastName ?? '',
                    lng: employerContact.person?.lng ?? '',
                    alternatePhone: employerContact.person?.alternatePhone ?? '',
                    alternateEmail: employerContact.person?.alternateEmail ?? '',
                }
            })))) ?? []).flat(), 
            ['employer', 'person.lastName', 'person.firstName']);
        return employersContacts;
    }
}