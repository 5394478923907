import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class ParticipationPensionType extends RefEnum {
    constructor(key, defaultDesc) {
        super(key, defaultDesc)
    }

    static types = deepFreeze({
        S10: new ParticipationPensionType('S10', 'Single Life G10'),
        S15: new ParticipationPensionType('S15', 'Single Life G15'),
        JS50: new ParticipationPensionType('JS50', 'J&S 50% G10'),
        JS60: new ParticipationPensionType('JS60', 'J&S 60% G10'),
        JS75: new ParticipationPensionType('JS75', 'J&S 75% G10'),
        JS100: new ParticipationPensionType('JS100', 'J&S 100% G10')
    })
    
    static definitions = {
        desc: { type: Definition.types.STRING, text: 'Pension Type' },
    }
}