import React from 'react'
import { LanguageSelector } from '../../framework/components'
import Menu from '../../components/HeaderMenu'
import UserMenu from '../../components/UserMenu'


const Header = ({context, onContextChanged}) => {
    return (<div className='line'>
            <div className='line-item'> 
                <img src="/img/logo.png" alt="A.S.Hayes Consulting inc." height="35" width="" className="pl-4" />
            </div>
            <div className='line-stretch ml-5'>
                <Menu/>
            </div>
            <div className='line-item'>
                <LanguageSelector context={context} onContextChanged={onContextChanged} ></LanguageSelector>
            </div>
            <div className='line-item'>
               <UserMenu/>
            </div>
        </div>)
}
export default Header;