import React from 'react'
import Input from './Input'

export default class Check extends Input {
   
    view() {
        const props = this.inputProps
        return <label className={this.props.lcn}>
            <input {...props} type='checkbox' disabled={props.readOnly} checked={props.value}/>
            <span>{this.props.label}</span>
        </label>
    }
    
    handleChange(event, onChange) {
        onChange(event.target.checked);    
    }
}