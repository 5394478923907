import React, { useEffect } from 'react'
import { Form } from '../../../framework/controls'
import BankChequeForm from './BankChequeForm';
import BankEFTForm from "./BankEFTForm";
import BankWireForm from './BankWireForm';

const ConfigBankForm = ({ config, onCancel, onSave }) => {
    const { cheque, eft, wire } = config;

    const handleSave = (config, original) => {
        if (onSave) onSave('bank', config, original);
    }

    const handleCancel = (original) => {
        if (onCancel) onCancel('bank', original);
    }

    useEffect(() => {
        eft.address.removeSuggestions('city');
        eft.address.removeSuggestions('pc');
		eft.address_account.removeSuggestions('city');
        eft.address_account.removeSuggestions('pc');
		wire.account.address.removeSuggestions('city');
        wire.account.address.removeSuggestions('pc');
		wire.account_intermediary.address.removeSuggestions('city');
        wire.account_intermediary.address.removeSuggestions('pc');
		wire.beneficiary.address.removeSuggestions('city');
        wire.beneficiary.address.removeSuggestions('pc');
    }, []);

    return (
        <Form
            id="ConfigBank"
            className="w-100 bg-color"
            data={config}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <BankChequeForm cheque={cheque} />
            <BankEFTForm eft={eft} />
            <BankWireForm wire={wire} />
        </Form>
    );
};

export default ConfigBankForm;