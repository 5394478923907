import _ from 'lodash'
import Definition from './Definition'
import { isObject, isArray, isFalsy, getSafe } from '../../utils/helper'
import Lng from '../Lng'

export default class Ref {
    constructor(data, reset = true) {
        this._sts = 'N'
        if (reset) this.reset()
        if (data) this.setData(data)
    }

    reset() {
        Object.getOwnPropertyNames(this).forEach(prop => delete this[prop])
        Object.getOwnPropertyNames(this.constructor.definitions).forEach(defName => {
            const def = this.constructor.definitions[defName]

            if (!def.abstract) {
                if (def.historical) this['__' + defName] = new def.refHist()
                else if (def.type.reset) this[defName] = def.type.reset(this, defName)
                else if (def.default) this[defName] = def.default
                else if (def.isList()) this[defName] = new (def.refList)()
                else if (def.isMap()) this[defName] = new (def.refMap)()
                else if (def.isEntity()) {
                    this[defName] = new (def.getRef())(null, false) // the false is not to loop if reference itself
                }
                else if (def.isRef()) this[defName] = new (def.getRef())(null, true)
                else if (def.isFactor()) this[defName] = '';
                else if (def.isNumeric()) this[defName] = 0
                else if (def.isBool()) this[defName] = !!this[defName] //trap so is not set to blank
                else this[defName] = ''
            }
    
		})
        this._sts = 'N'
        return this
    }

    setData(data, reset) {
        if (reset) this.reset()
        if (!data) return
        this._sts = 'U'
        Object.getOwnPropertyNames(data).forEach(propName => {
            const value = data[propName]
            const def = this.constructor.definitions[propName]

            if (def && !def.abstract && value !== null && value !== undefined) {
                if (def.historical) {
                    this['__' + propName].setData(value)
                } else if (def.isRef()) {
                    const refClass = def.getRef()
                    if (def.isList()) {
                        this[propName] = this.newRef(def.refList, value)
                    } else if (def.isMap() && typeof value === 'object') {
                        this[propName] = this.newRef(def.refMap, value)
                    } else if (refClass.isRefEnum) {
                        this[propName] = (refClass.types && refClass.types[value]) || refClass.default || this.newRef(refClass, value)
                    } else if (def.isEntity()) {
                        this[propName] = this.newRef(refClass, {id: value.id ? value.id : value })
                    } else if (def.key) { 
                        this[propName] = this.newRef(refClass, refClass.getKeyValues(value))
                    } else {
                        this[propName] = this.newRef(refClass, value)
                    }
                } else {
                    if (def.isNumeric()) this[propName] = Number(value)
                    else if (def.isBool()) { this[propName] = !isFalsy(value) }
                    else if (def.type.setData) this[propName] = def.type.setData(value)
                    else this[propName] = value
                }
            }
        })

        return this
    }
    
    getData(options ={}) {
        return Object.getOwnPropertyNames(this).reduce((data, propName) => {
            const value = this[propName]
            if (propName.startsWith('__')) propName = propName.slice(2)
            const def = this.constructor.definitions[propName]
            if (def && !def.abstract && !def.transient) {
                if (value && value.getData) {
                    //TODO check if empty
                    if (def.isEntity()) data[propName] = value.id
                    else if(def.key) data[propName] = value.keysValues
                    else if(def.isListRef) data[propName] = value.all.map(item => item.getData(options))
                    else data[propName] = value.getData(options)
                } else if(def.isRef()) {
                    if (def.isList()) {
                        data[propName] = isArray(value) ? value.map(item => item.getData(options)) : []
                    } else if (def.isMap()) {
                        data[propName] = isObject(value) ? Object.getOwnPropertyNames(value).reduce((map, key) => {
                            map[key] = value[key].getData(options)
                            return map
                        }, {}) : {}
                    }
                } else {
                    if (def.isBool()) { 
                        data[propName] = (value && value !== 'false' && value !== 'n')
                        if (propName === '_del' && !value) delete data._del
                    } else if(def.type.getData) {
                        data[propName] = def.type.getData(value)
                    } else if(def.type.isUser && options.username) {
                        data[propName] = options.username
                    } else if(value !== null || value !== undefined ) {
                        if (value || !options.removeBlanks) data[propName] = value
                    }
                }
            }
            return data
        }, {})
    }

    newRef(clazz, data, reset) { 
        if (!data) return new clazz(data, reset);
        try {
            return (data instanceof clazz) ? data : new clazz(data, reset) 
        } catch (e) {
            console.log(e)
        }
    }
        
    clone() { return _.cloneDeep(this) } //TODO!! BUG! this might be causing the refresh problem
    copy(reset) { 
        const inst = this.newRef(this.constructor, this.getData(), reset)
        inst._sts = 'N'
        return inst
    }
    isEmpty() { return false } //overwrite if different test is needed to determine if it is to ignore by getData 
    isNew() { return this._sts === 'N' } //overwrite if different test is needed to determine if it is to ignore by getData
    isExisting() { return !this.isNew() }
    touch() { this._sts = 'T' }
    isTouched() { return this._sts === 'T'}
    apply() { 
        this._sts = 'U'
        Object.values(this.constructor.definitions).filter(def => def.isRef() && !def.isEntity() && !def.abstract && !def.transient ).forEach(def => { 
            const propName = (def.historical ? '__' : '') + def.name
            if( this[propName] && this[propName] instanceof Ref) this[propName].apply()
            //TODO create method in def to return the real propname
        })
    }
    
    //Descriptors
    get desc() { return this.constructor.descriptor ? this.getDesc(this.constructor.descriptor.name) : '' } 
    get shortDesc() { return this.getDesc('shortDesc', 'desc') }
    get longDesc() { return this.getDesc('longDesc', 'desc') }
    get tip() { return this.getDesc('tip', 'desc') }
    get help() { return this.getDesc('help', 'desc') }
    getDesc(propName, alternatePropName) {
        propName = '__' + propName
        alternatePropName = '__' + alternatePropName
        var ret = this[propName] && (this[propName][Lng.current] || this[propName][Definition.default])
        if (ret === undefined || ret === null) ret = this[alternatePropName] && (this[alternatePropName][Lng.current] || this[alternatePropName][Definition.default])
        return ret || ''
    }
    
    //Soft delete
    isDeleted() { return this._del }
    deleted() { return this._sts = 'D' }
    delete() { 
        this._del = true
        this._sts = 'D'
    }
    unDelete() {
        if (this._del) this._del = false
        this._sts = 'U'
    }

    get keysValues() {
        if (!this.constructor.hasCompositeKey() && this.constructor.keys[0] === 'id') return {id: this['id'] || ''}

        return this.constructor.keys.reduce((params, key) => {
            var val = getSafe(this, key)
            const def = this.constructor.getDefinition(key)
            if (val && def) {
                if (val instanceof Ref) val = val.keyValue
                else if (def.type.getData) val = def.type.getData(val)
            }
            params[key] = val
            return params
        }, {})
    }
    get keyValue() {
        if (!Object.values(this.keysValues).join('')) return ''
        return Object.values(this.keysValues).join('_')
    }
   
    static create(data) { return new this.constructor(data) }
    static set definitions(defs) {
        const parentsDefs = this['__proto__']._definitions || {}
        const thisDefs = Object.getOwnPropertyNames(defs).reduce((map, defName) => {
            const def = defs[defName]
            map[defName] = new Definition(defName, def)
            //HACK needed for Tabulator - authomaticaly creates missing setter for abstract definitions
            if (def.abstract) {
                const fieldDescriptor = Object.getOwnPropertyDescriptor(this.prototype, defName)
                if (fieldDescriptor && fieldDescriptor.get && !fieldDescriptor.set) {
                    Object.defineProperty(this.prototype, defName, { set: function (v) {} })
                }
            }
            if (def.historical) {
                //TODO move to Definition just like RefList
                class GenericRefHist extends require('./RefHistorical').default {}
                class GenericRefHistItem extends require('./RefDated').default {
                    static definitions = {
                        value: { ref: def.ref, text: def.text, type: def.type, pipi:{}},
                    }
                }
                GenericRefHist.ref = GenericRefHistItem
                map[defName].refHist = GenericRefHist
                
                const prop = '__' + defName
                Object.defineProperty(this.prototype, defName, { 
                    get: function() { return this[prop].isEmpty() ? this[prop].create().value : this[prop].latest.value },
                    set: function(v) { (this[prop].latest.isNew() ? this[prop].latest : this[prop].pushNew()).value = v }
                })
                Object.defineProperty(this.prototype, defName + 'History', { 
                    get: function() { return this[prop] },
                    set: function() { }
                })
            }
            if (map[defName].descriptor) this.descriptor = map[defName]
            return map
        }, {})
        this.ownDefinitions = thisDefs
        this._definitions = Object.assign({}, parentsDefs, thisDefs)
    }
    static get definitions() { return this._definitions }
    static get ownDefinitionNames() { return Object.getOwnPropertyNames(this.ownDefinitions) }

    static getDefinition(name) {
        const props = name.split('.')
        var def = this._definitions[props.splice(0,1)[0]]
        if (props.length > 0) {
            if (def) {
                if(this.ref) def = this.ref.getDefinition(props.join('.'))
                else if (def.isRef()) def = def.getRef().getDefinition(props.join('.'))
            } else { 
                def = this.getDefinition(props.join('.'))
            }
        }
        return def    
    }

    static getLabel(property) {
        let def = this.getDefinition(property)
        return def?.shortText ?? def?.text ?? '';
    }
    
    static getOptionLabel(code, value) { //DEPRECATED use definition getOptionText
        const definition = this.getDefinition(code)
        if (definition && definition.options && value) {
            const option = definition.options.find(defOption => defOption.key === value)
                return option['text_' + Lng.current] || option.text || ''
        } else {
            return ''
        } 
    }
    
    static hasCompositeKey() {
        return this.keys.length > 1
    }
    static get keys() {
        return [].concat(this.key || 'id')
    }
    static makeKey(...keys) {
        return keys.join('_')
    }
    static getKeyValues(keyVal = '') {
        const keyValue = keyVal.keyValue ?? keyVal;
        const keyParts = keyValue.split('_')
        return this.keys.reduce((keys, keyName, index) => {
            keys[keyName] = keyParts[index] || ''
            return keys
        }, {})
    }

    getOptionText(attr) {
        return this.constructor.getDefinition(attr).getOptionText(this[attr])
    }
}

Ref.definitions = {
    cmt: { type: Definition.types.STRING, text: 'Comment' },
    '_del': { type: Definition.types.BOOLEAN, text: 'Deleted' },
    '_sts': { transient: true, type: Definition.types.STRING, text: 'Status' },
    desc: { abstract: true, type: Definition.types.STRING, text: 'Description'},
    shortDesc: { abstract: true, type: Definition.types.STRING, text: 'Description'},
    longDesc: { abstract: true, type: Definition.types.STRING, text: 'Description'},
    tip: { abstract: true, type: Definition.types.STRING, text: 'Tip'},
    help: { abstract: true, type: Definition.types.STRING, text: 'Help'},
}

