import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageTitle } from '../../framework/components'
import { Table } from '../../framework/controls'
import { Row, Col } from '../../framework/containers'
import Loading from "../../components/containers/Loading"
import MembershipSummarySearch from '../../services/search/MembershipSummarySearch'
import MembershipSummaryView from '../../entities/search/MembershipSummarySearch/MembershipSummaryView'
import FilterContainer from '../../components/containers/FilterContainer'

export default function MembersPage(props) {
    const currentPageNumber = 1; // TODO: get from URL params when filters are implemented
    let pageSize = MembershipSummarySearch.DEFAUT_PAGE_SIZE;
    const history = useHistory();
    const [summaries, setSummaries] = useState();
    const [currentFilters, setCurrentFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState({});
    const columns = new Table.Headers(MembershipSummaryView, 'sin, nameOriginal, dateOfBirth, joinDateString, employerCodes, participationEventDescription') 
   
    useEffect(() => {
        loadMembers(currentPageNumber);
    }, [currentFilters]);

    const handleSelectMember = (row) => { 
        history.push(`/member/${row.id}`);
    }

    const loadMembers = (pageNumber = 1, scrollDuration, scrollId) => {
        search({pageNumber, scrollDuration, scrollId}).then((results) => {
            setSummaries(results.documents.all);
            setPaginationInfo({total: results.total, totalPages: results.totalPages, currentPage: pageNumber, pageSize })
        });
    }

    const search = async ({pageNumber = 1, scrollDuration, scrollId}) => {
        if (currentFilters.pageSize) pageSize = parseInt(currentFilters.pageSize);

        return MembershipSummarySearch.search(currentFilters, pageNumber, pageSize, scrollDuration, scrollId);
    }

    const onPageChange = (selectedPageNumber) => loadMembers(selectedPageNumber);

	return <> 
            <Row className='align-items-end' >
                <Col><PageTitle className='mt-1'>Members</PageTitle></Col>
            </Row>
            {!summaries ? <Loading /> :
                <div className="h-90 content-container members-container">
                    <FilterContainer view={MembershipSummaryView} onUpdate={setCurrentFilters} exportFn={search} filterBoxWidth={300}/>
                    <Table id='members-table' 
                        dataKey='membership.keyValue' 
                        data={summaries} 
                        columns={columns} 
                        className="mt-2"
                        editable
                        sort='name'
                        onSelect={handleSelectMember}
                        fitHeightToContent
                        isPaginated
                        paginationInfo={{onPageChange, ...paginationInfo }}
                    />
                </div>
            }
    </>;
}