import React, { useState } from "react";
import { FastField, Form, Formik } from "formik";
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Form as BForm } from "react-bootstrap";
import { NewEmployerChecklistEvent } from '../../../entities/company/newEmployer/NewEmployerChecklistEvent';
import EmployerChecklistItem from './newEmployer/EmployerChecklistItem';
import moment from 'moment';
import { EmployerService } from '../../../services';
import useNotification from '../../../hooks/useNotification';
import Loading from '../../../components/containers/Loading';
import { newEmployerChecklistConfig } from "../../../entities/company/newEmployer/NewEmployerConfig";


const EmployerChecklist = ({ employer }) => {
    const { addMessage } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = {
        ...NewEmployerChecklistEvent.messagesOption().reduce((prev, option) => {
            const checklistEvent = employer.checklist.all.find(
                (event) => event.key === option.key
            );
            return {
                ...prev,
                [option.key]: {
                    date: Boolean(checklistEvent?.ets)
                        ? moment(checklistEvent?.ets).format("YYYY-MM-DD")
                        : "",
                    completed: checklistEvent?.reviewed ?? false,
                },
            };
        }, {}),
        comment: employer.checklistComment ?? "",
    };

    const handleOnSubmit = (values) => {
        setIsLoading(true);
        employer.checklist.reset();
        Object.entries(values)
            .filter(([code, value]) => !newEmployerChecklistConfig[code]?.hideDate)
            .forEach(([code, value]) => {
            const hasSecondaryCheckboxes = newEmployerChecklistConfig[code]?.secondCheckboxes;
            
            employer.checklist.pushEvent({
                key: code,
                ets: value.date ? moment(value.date).valueOf() : "",
                mts: moment().valueOf(),
                reviewed: value.completed,
            });

            if (hasSecondaryCheckboxes) {
                hasSecondaryCheckboxes.map(key => {
                    employer.checklist.pushEvent({
                        key,
                        ets: value.date ? moment(value.date).valueOf() : "",
                        mts: moment().valueOf(),
                        reviewed: values[key].completed,
                    });
                });
            }
        });
        employer.checklistComment = values.comment;
        EmployerService.update(employer).then(() => {
            addMessage("New employer checklist saved", "success");
            setIsLoading(false);
        });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
            {({ values, setFieldValue }) => <Form as={BForm} className="d-flex flex-column align-items-center">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <h4 className="mt-2 d-flex justify-content-center">
                            <b>New Employer Checklist</b>
                        </h4>
                        <div className="d-flex flex-column align-items-center">
                            {Object.keys(NewEmployerChecklistEvent.groups).map(
                                (groupKey, index) => (
                                    <EmployerChecklistItem
                                        groupKey={groupKey}
                                        index={index}
                                        key={`${groupKey}-${index}`}
                                    />
                                )
                            )}
                        </div>
                        <BForm.Group
                            controlId="comment"
                            className="new-employer comment mt-4"
                        >
                            <BForm.Label>Notes</BForm.Label>
                            <FastField
                                component={TextareaAutosize}
                                name="comment"
                                placeholder="Enter a comment..."
                                onChange={(event) => 
                                    setFieldValue("comment", event.target.value)
                                }
                                value={values.comment}
                                minRows={4}
                            />
                        </BForm.Group>
                    </>
                )}
                <div className="d-flex justify-content-end w-100">
                    <Button type="submit" variant="primary">
                        Save
                    </Button>
                </div>
            </Form>}
        </Formik>
    );
};

export default EmployerChecklist;
