import React from 'react'
import { EmploymentService } from '../../../services'
import ReportLayout from '../../../framework/components/page/ReportLayout'
import RefDescription from '../../../framework/infra/model/RefDescription'
import { withRouter } from 'react-router-dom'
import Report from '../../../entities/pension/member/Report'
import { ParticipationTask } from '../../../entities'
import { EmploymentBusiness, ParticipationBusiness } from '../../../business'
import { EmploymentTask } from '../../../entities/employment'
import pLimit from 'p-limit'

class EmployerMessageWarningReport extends ReportLayout {
    reportDesc = `Member Message/Warnings`
    entity = Report
    headers = ['employment.person.sin', 'employment.person.name', 'employment.person.dob', 'message.desc', 'message.formalDescription']
    headerProps = {
        'employment.person.name': {width: 400, headerFilter: true},
        'employment.person.sin': {width: 150, variant: 'link', cellClick: this.handleCellSelect.bind(this)},
        'employment.person.dob': {width: 150},
        'message.formalDescription': {title: "Employer Description"}
    }
    removeDuplicateRows = true
    tableSort = 'employment.person.name'
    filters = {
        'message._msgDesc': {definition: {ref: RefDescription, text: 'Message Type' }, display: 'name', filterBy: 'key', nullable: true}
    }
    params = {}
    fieldsetClass='no-frame'

    getWarningList(emps) {
        const warningList = []
        emps.forEach(emp => {
            EmploymentBusiness.validate(emp);
            ParticipationBusiness.validate(emp.participation);
            const lastStatus = emp.participation.lastStatusEvent.status;
            const empWarningList = emp.combinedAllWarnings;
            ParticipationTask.alwaysShownTask().forEach(validTask => {
                const task = empWarningList._list.find(warn => warn.code === validTask.key);
                if(task) warningList.push({employment: emp, message: task});
            });

            if (emp.combinedAllWarnings.length > 0) {
                emp.combinedAllWarnings.forEach(warning => warningList.push({employment: emp, message: warning}))
            }
            
            warningList.forEach(task => {
                const message = task.message;
				if (message.config?.isMultipleResolution) {
                    const newText = emp.participation.getFlowStatusDesc(emp.participation.events.findOrCreate('code', { code: message.code }));
                    const newTextDesc = new RefDescription({key: message.code, text: newText, name: message.name, options: message.options});
                    const newFormalText = emp.participation.getFlowStatusDesc(emp.participation.events.findOrCreate('code', { code: message.code }), true);
                    
                    message._msgDesc = newTextDesc; 
                    message.flowFormalDescription = newFormalText; 
                }
            });

            const guessedEmpEvents = emp.events.filter(event => event.guessed);
            const guessedPPEvents = emp.participation.events.filter(event => event.guessed);

            if (guessedEmpEvents.length > 0 || guessedPPEvents.length > 0) {
                warningList.push({employment: emp, message: new EmploymentTask({ code: 'guessedDt' }) });
            }
        })
        return warningList;
    }

    handleCellClick(cell) {
        const employment = cell.getData().employment
        this.props.history.push(`/employer/${employment.employer.code}/employment/${employment.keyValue}`);
    }
    execQuery() {
        return EmploymentService.getEmployerEmployments(this.props.params.employer.id).then(employments => {
            const limit = pLimit(100);
            return Promise.all(employments.map(employment => limit(() => EmploymentService.getMemberEmployments(employment.participation.membership.keyValue))))
            .then(() => this.getWarningList(employments))
        })
    } 
    
}

export default withRouter(EmployerMessageWarningReport)