import { RefList } from "../framework/infra";
import Mapper from "./Mapper";
import Employers from './../entities/employment/Employers';

export default class EmployerListMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.employers = new Employers();
    }

    async map () {
        await this.load('spouses');
        await this.load('people');
        this.employers.pushList((await this.load('employers')));
        return this.employers;
    }
}