import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import usePagination, { skipPages } from '../../hooks/usePagination';
import Icon from './Icon';
import { Button } from '../components';

const TablePagination = ({
    onPageChange,
    total,
    currentPage,
    pageSize,
    totalPages,
}) => {
    const pagesToShow = usePagination({currentPage, totalPages, pageSize, total});
    const firstPage = 1;
    const isNextArrowsDisabled = currentPage === totalPages || totalPages === 1;
    const isPrevArrowsDisabled = currentPage === firstPage;
    if (currentPage === 0 || totalPages === 0) return null;

    const onNext = () => onPageChange(currentPage + 1);
    const onPrevious = () => onPageChange(currentPage - 1);
    const onFirst = () => onPageChange(firstPage);
    const onLast = () => onPageChange(totalPages);

    return <Pagination className='pagination-container' >
        <li><Button className="btn-secondary pagination-item chevron" onClick={onFirst} disabled={isPrevArrowsDisabled}><Icon icon='angle-double-left' /></Button></li>
        <li><Button className="btn-secondary pagination-item chevron" onClick={onPrevious} disabled={isPrevArrowsDisabled}><Icon icon='angle-left' /></Button></li>
        {pagesToShow.map((pageNo, index) => {
            if (pageNo === skipPages) return <Pagination.Ellipsis key={`pagination-ellipsis-${index}`} className="pagination-item ellipsis" disabled />;
            
            return (<li key={`pagination-item-${pageNo}`}><Button className={`pagination-item ${currentPage === pageNo ? "btn-primary" : "btn-secondary"}`} onClick={() => onPageChange(pageNo)} active={currentPage === pageNo}>{pageNo}</Button></li>);
        })}
        <li><Button className="btn-secondary pagination-item chevron" onClick={onNext} disabled={isNextArrowsDisabled}><Icon icon='angle-right' /></Button></li>
        <li><Button className="btn-secondary pagination-item chevron" onClick={onLast} disabled={isNextArrowsDisabled}><Icon icon='angle-double-right' /></Button></li>
    </Pagination>
};

export default TablePagination;