import React from 'react'

export default class Title extends React.Component {
    //TODO combine all the titles and send variant
    static cn='modal-content-header'
    render() {
        const { title, midtitle, subtitle, isWarning } = this.props
        return  <div className='modal-content-header'>
            <div className={`modal-title ${isWarning ? 'text-warning':''}`}>
                <div className='modal-title-left'>{title}</div>
                {midtitle && <div className='modal-title-center'>{midtitle}</div>}
                {(midtitle || subtitle) && <div className='modal-title-right'>{subtitle || ''}</div>}
            </div>
            <div><button onClick={this.props.onHide} className='modal-close'><i className="fa fa-times-circle" aria-hidden="true"></i></button></div>
        </div> 
    }
}
