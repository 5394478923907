import { RemittanceDetailService } from "../../../services";

// Helper functions for participations
const getJoinDateChangeWarning = async (params) => {
    var oldEts = params.oldEvent?.ets;
    var newEts = params.newEvent?.ets;

    if (oldEts === newEts) return;
    //if same year and data changes, simple message
    if (oldEts !== newEts && params.oldEvent.effMoment.year() === params.newEvent.effMoment.year()) return 'Confirm if you want to change join date.';

    var details = await RemittanceDetailService.getEmploymentRemittancesDetails(params.instance.employments._list.find((employment) => employment.keysValues.participation === params.instance.keyValue));
    var targetYear = (oldEts > newEts) ? params.newEvent.effMoment.year() : params.oldEvent.effMoment.year();

    details = details.getFiltered((detail) =>{ return detail.period.year === targetYear.toString()});
    var hasContribsOrHours = details.contributionsTotals.length > 0

    if (!hasContribsOrHours) return `Confirm if you want to change join date since there are no contributions calculated in ${targetYear}` 
    return `Confirm if you want to change join date since there are contributions calculated in ${targetYear}`  
}

export { getJoinDateChangeWarning }