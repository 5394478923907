import React from "react"

import LoginWrapper from './LoginWrapper'
import Auth from '../Auth'

export default class NewPasswordRequired extends LoginWrapper {
    constructor(props) {
        super(props)
        this.state.message = {
            severity: 'warning', 
            content: (<div>A new password is required</div>)
        }
        this.title = 'Change Password'
    }

    handleMustChangePassword(event) {
        event.preventDefault()
        this.setState({message: null})
        
        const { newPassword, confirmPassword } = event.target
        if (newPassword.value !== confirmPassword.value) {
            this.setState({message: {errorCode: 'noMatchingPasswords', text:"Password didn't match, please try again"}})
        } else {
            Auth.setPassword(newPassword.value).then(data => {
                window.location.reload()
            }).catch(err => {
                this.setState({message: err})
            })
        }
    }

    view() {
        return (
            <form onSubmit={this.handleMustChangePassword.bind(this)}>
                <div className="md-form">
                    <input className="form-control" id="newPassword" name="newPassword" label="New Password" type="password" required/>
                    <label className="active" for="newPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="md-form">
                    <input className="form-control" id="confirmPassword" name="confirmPassword" label="Confirm New Password" type="password" required/>
                    <label className="active" for="confirmPassword" data-error="" data-success="" id="" aria-labelledby="">Password</label>
                </div>
                <div className="text-center mt-3">
                    <button className="btn sign-in" type="submit">Change Password</button>
                </div>
            </form>
        )
    }
}

