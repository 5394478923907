import React, { useMemo } from "react"
import { Accordion, Card, Form } from "react-bootstrap";
import Checkbox from "../../../../components/form/Checkbox";
import { Icon } from "../../../../framework/controls";
import { YECertificationEvent } from "../../../../entities/pension/yeCertification/YECertificationEvent";
import { FastField, useField } from "formik";
import { yeCertificationConfigs } from "../../../../entities/pension/yeCertification/YECertificationConfig";

const YECertificationItem = ({
    itemInfo,
    index,
}) => {
    const {code, sectionTitle, secondCheckboxCode} = itemInfo;
    const [field, meta, helpers] = useField(code);
    const [secondaryField, secondaryMeta, secondaryHelpers] = useField(secondCheckboxCode);

    // TODO: include any specific validation for each section (APP-384/APP-385)
    const isCheckable = useMemo(
      () => meta.value.name !== '' && meta.value.date,
      [meta.value.name, meta.value.date]
    );

    const isValidated = useMemo(
      () =>
        (!secondCheckboxCode && meta.value.validated) ||
        (secondCheckboxCode && secondaryMeta.value.validated),
      [meta.value.validated, secondaryMeta.value.validated]
    );

    return (
        <Card>
            <Accordion.Toggle
                as={Card.Header}
                className="fw-bold clickable"
                eventKey={index}
            >
                <b>{sectionTitle}</b>
                {isValidated && (
                    <Icon icon="check" className="text-success ml-1" />
                )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
                <Card.Body className="d-flex">
                    <div className="d-flex flex-column justify-content-lg-between w-75 input-spacing-2">
                        <Form.Group
                            className="w-25"
                            controlId={`${code}-name-group`}
                        >
                            <Form.Label>Name</Form.Label>
                            <FastField
                                as={Form.Control}
                                name={`${code}.name`}
                                placeholder="Your full name"
                            />
                        </Form.Group>
                        <Form.Group
                            className="w-25"
                            controlId={`${code}-date-group`}
                        >
                            <Form.Label>Date</Form.Label>
                            <FastField
                                as={Form.Control}
                                aria-label="Date"
                                name={`${code}.date`}
                                type="date"
                            />
                        </Form.Group>
                        <span>
                            <Checkbox
                                disabled={!isCheckable}
                                name={`${code}.validated`}
                                label={`${sectionTitle} validated?`}
                                className="mr-2"
                            />
                            {yeCertificationConfigs[code]?.tooltip && (
                                <Icon
                                    icon="exclamation-circle"
                                    tooltip={
                                        yeCertificationConfigs[code].tooltip
                                    }
                                    className="text-muted"
                                />
                            )}
                        </span>
                        {secondCheckboxCode && (
                            <span>
                                <Checkbox
                                    disabled={!isCheckable}
                                    name={`${secondCheckboxCode}.validated`}
                                    label={`${YECertificationEvent.messages[secondCheckboxCode].text} validated?`}
                                    className="mr-2"
                                />
                                {yeCertificationConfigs[secondCheckboxCode]
                                    ?.tooltip && (
                                    <Icon
                                        icon="exclamation-circle"
                                        tooltip={
                                            yeCertificationConfigs[
                                                secondCheckboxCode
                                            ].tooltip
                                        }
                                        className="text-muted"
                                    />
                                )}
                            </span>
                        )}
                        {!isValidated && (
                            <small
                                className={`text-${
                                    isCheckable ? "muted" : "danger"
                                } ml-3`}
                            >
                                {isCheckable
                                    ? "All information has been provided."
                                    : "Missing information: please enter name and validation date above."}
                                <Icon
                                    icon={
                                        isCheckable
                                            ? "check"
                                            : "exclamation-triangle"
                                    }
                                    className="ml-1"
                                />
                            </small>
                        )}
                    </div>
                    {/* TODO: show warnings (APP-384/APP-385) */}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default YECertificationItem;

