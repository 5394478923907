
import React from "react"
import { Person } from '../../entities'
import { Component } from '../../framework/components'
import { Col, Row } from '../../framework/containers'
import { Button, Table } from '../../framework/controls'
import { Excel } from '../../framework/utils'
import { group } from '../../framework/utils/helper'

import { PersonService } from '../../services'


export default class DuplicatedSinReport extends Component {
    load() {
        return PersonService.getAll().then(people => {
            const duplicates = Object.values(group(people, 'sin')).filter(dups => dups.length > 1)
            return {report: {
                duplicatesList: duplicates.flat(),
                duplicatesTree: duplicates.map(dups => ({ sin: dups[0].sin, _children: dups }))
            }}
        })
    }

    view() {
        const { report } = this.state
        const headers = new Table.Headers(Person, ['sin', 'firstName', 'middleName' ,'lastName', 'dob'])
        const groupText = (value, count, data, groupComponent) => { return data[0].formatedSin + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>" }
        return <> 
            <Row><Col right><Button key="export" type="link" onClick={this.handleExport.bind(this)} >Export</Button></Col></Row>
            <Table id='duplicates' data={report.duplicatesList} columns={headers} groupBy="sin" groupText={groupText} className="mb-3" />
        </>
    }

    handleExport() {

        this.busy(() => {
            const headers = new Excel.Headers(Person, ['sin', 'firstName', 'middleName' ,'lastName', 'dob'])
            const excel = new Excel('Duplicated SIN Report')
            excel.addSheet(headers, this.state.report.duplicatesList, 'Duplicates')
            return excel.download()
        })
    }
}
