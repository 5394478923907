import { Employment, RemittanceDetail } from "..";
import { Ref } from "../../framework/infra";
import YearEndEmploymentSummary from "./YearEndEmploymentSummary";
import Validations from "./Validation";

export class Report extends Ref {}
Report.definitions = {
    employer:  { ref: YearEndEmploymentSummary, text: 'Employer' },
    employmentFull: { ref: YearEndEmploymentSummary, text: 'Personal Information' },
    employment: { ref: YearEndEmploymentSummary, text: 'Personal Information' },
    details: { ref: YearEndEmploymentSummary, text: 'Status' },
    detailsLastMonth: { ref: YearEndEmploymentSummary, text: 'Status' },
    pensionableEarnings: { ref: YearEndEmploymentSummary, text: 'Total Pensionable Earnings' },
    contributions: { ref: YearEndEmploymentSummary, text: 'Total Contributions' },
    creditedService: { ref: YearEndEmploymentSummary, text: 'Total Credited Service' },
    pensionAdjustment: { ref: YearEndEmploymentSummary, text: 'Pension Adjustment' },
    lastYearDetails: { ref: YearEndEmploymentSummary, text: 'AnualizedEarnings' },
    adjustments: { ref: YearEndEmploymentSummary, text: 'Adjustments' },
    validation: { ref: YearEndEmploymentSummary, text: 'Validations' },
}