
import React, {useEffect} from "react"
import { Button } from '../../../framework/controls'
import { Row, Col, Modal, Card } from '../../../framework/containers'

import { AdjustmentService } from '../../../services'
import { Adjustment } from '../../../entities'
import AdjustmentList from './AdjustmentList'
import AdjustmentForm from './AdjustmentForm'
import { useState } from "react"
import useNotification from "../../../hooks/useNotification"
import { Tabs } from '../../../framework/containers'
import Loading from "../../../components/containers/Loading"

const Adjustments = ({ employer, onChange }) => {
    const [adjustments, setAdjustments] = useState([]);
    const [adjustmentsExport, setAdjustmentsExport] = useState();
    const [selected, setSelected] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('contribution');
    const { addMessage } = useNotification();

    useEffect(() => {
        let isMounted = true;

        AdjustmentService.getAdjustmentsForEmployer(employer.keyValue).then(
            (adjustments) => {
                if (isMounted) {
                    setAdjustments(adjustments.all);
                    setIsLoading(false);
                }
            }
        );

        return () => { isMounted = false };
    }, []);

    const handleNew = () => setSelected(new Adjustment());

    const handleSelect = (adjustment) => setSelected(adjustment);

    const handleCancel = () => setSelected(null);
    
    const handleChangeAdjustmentForm = (adjustment, isDelete = false) => {
        setSelected(null);
        setIsLoading(true);
        refresh().then(() => {
            addMessage(`Adjustment successfully ${isDelete ? 'deleted' : 'saved'}`, 'success');
            if (onChange) {
                onChange(adjustment);
            }
            setIsLoading(false);
        });
    }

    const refresh = () => {
        return AdjustmentService.getAdjustmentsForEmployer(
            employer.keyValue
        ).then((adjustments) => {
            setAdjustments(adjustments);
        });
    }

    return (
        <>  
            {!isLoading ? <div className="modal-component-body">
                <Card>
                    <Row>
                        <Col left>
                            <Tabs initial={activeTab} onChange={(tab) => setActiveTab(tab)} className="h-100">
                                <Tabs.Tab name='contribution' title='Contribution'/>
                                <Tabs.Tab name='earning' title="Earnings/Hours"/>
                            </Tabs>
                        </Col>
                        <Col right>
                            <Button className="btn btn-secondary" onClick={() => handleNew()}>New Adjustment</Button>
                            <Button key="export" className="btn btn-secondary" onClick={() => adjustmentsExport()}>Export</Button>
                        </Col>
                    </Row>
                </Card>
                <AdjustmentList 
                    activeTab={activeTab} 
                    adjustments={adjustments} 
                    variant='monoEmployer' 
                    selected={selected && selected.id} 
                    onSelect={handleSelect} 
                    setExportFunction={setAdjustmentsExport} 
                />
             </div> : <Loading />}
             {selected && <Modal className='h-100 w-80 modal-bg-color'>
                 <AdjustmentForm 
                     adjustment={selected} 
                     employer={employer} 
                     onSave={handleChangeAdjustmentForm} 
                     onDelete={handleChangeAdjustmentForm} 
                     onCancel={handleCancel} 
                     variant='monoEmployer' 
                 />
             </Modal>}
        </>
    );
}

export default Adjustments;

