import React from "react"
import { Component, EList, Form } from '../../../framework/components'
import { Col, FieldSet, Row, Title } from '../../../framework/containers'

import EmployerService from '../../../services/employer/EmployerService'
import { EarningCategory, EarningType } from '../../../entities'
import { Form as BsForm } from 'react-bootstrap';


export default class EarningTypesForm extends Component {
   view() {
        const { employer, readOnly } = this.props
        const { showInactive } = this.state;
        const earningTypes = showInactive ? employer.earningTypes : employer.getActiveEarningTypes();

        const colsETHourly = [
            {name: 'alias', className:'col'}, 
            {name: 'label', className:'col-5'}, 
            {name:'hoursOrRate', className:'ml-2 col'}]
        const colsET = [{name: 'alias', className:'col-2 mb-1'}, {name: 'label'}]
        
        const categories = EarningType.splitEarningsTypesByCategory(earningTypes)
        return <>{!readOnly && <Title title='Earning Types'/>}
            {!readOnly && <BsForm.Check
                        className="mb-2 large-checkbox"
                        type= 'checkbox'
                        name= 'show-inactive'
                        label = 'Show inactive'
                        onChange={this.handleCheck.bind(this)}
                    />}
            <Form className="h-100" data={employer} onSave={!readOnly && this.handleSave.bind(this)} onCancel={!readOnly && this.handleCancel.bind(this)} isDirty={true} isValid={true} ref={(ref) => this._form = ref}>
                <Row>
                    {['REG', 'OTE'].map(type => {
                        return (<Col>
                            <FieldSet title={categories[type].text} variant='field' className='mr-3'>
                                <EList 
                                    name={type + ".earningTypes"} 
                                    entity={EarningType} instance={categories} 
                                    columns={type === 'REG' ? colsETHourly : colsET} 
                                    readOnly={readOnly} 
                                    onNew={this.handleNew.bind(this, EarningCategory.types[type])} 
                                    onTouch={this.handleTouch.bind(this)}
                                    softDelete
                                    retrievable
                                />
                            </FieldSet>
                        </Col>)
                    })}
                </Row>
                <Row className='mt-4'>
                    {['OVR', 'NPE'].map(type => {
                        return (<Col>
                            <FieldSet title={categories[type].text} variant='field' className='mr-3'>
                                <EList 
                                    name={type + ".earningTypes"} 
                                    entity={EarningType} 
                                    instance={categories} 
                                    columns={type === 'OVR' ? colsETHourly : colsET} 
                                    readOnly={readOnly} 
                                    onNew={this.handleNew.bind(this, EarningCategory.types[type])} 
                                    onTouch={this.handleTouch.bind(this)}
                                    softDelete 
                                    retrievable/>
                            </FieldSet>
                        </Col>)
                    })}
                </Row>
            </Form>
        </>
    }
    handleCheck(e) { 
        this.setState({showInactive: e.target.checked}) 
    }

    handleTouch() {
        this._form.forceDirty();
    }


    handleSave() {
        this.busy(() => EmployerService.update(this.props.employer).then(updatedEmployer => {
            if (this.props.notify) this.props.notify('Earning types were successfully saved', 'success')
            if (this.props.onClose) this.props.onClose()
        }))
    }

    handleCancel() {
        if (this.props.onClose) this.props.onClose()
        //this.setState({employer: this.state.original.clone()})
    }

    handleNew(category, instance) {
        instance.code = this.props.employer.earningTypes.getNextCode(category)
        this.props.employer.earningTypes.push(instance)
    }

}

