import React from 'react'
import { Button } from 'react-bootstrap'
import Icon from './Icon';


export default class UploadButton extends React.Component {
    render() {
        const { id, text, fileName, accept, className, buttonClassName } =
            this.props;
        const uplodedFile = fileName || this.setState.fileName

        return (
            <div className={!buttonClassName ? className + " file-upload line" : ""}>
                <Button
                    className={buttonClassName || "line-item"}
                    onClick={this.handleBrowseClick.bind(this)}
                >
                    {text || "Import File"}
                    <Icon
                        icon="file-import"
                        className="ml-2"
                        tooltip-left
                        large
                    />
                </Button>
                {uplodedFile && (
                    <div className="line-item ml-3">{uplodedFile}</div>
                )}
                <input
                    type="file"
                    id={id + "_fileupload"}
                    style={{ display: "none" }}
                    accept={accept}
                    onChange={this.handleUpload.bind(this)}
                />
            </div>
        ); 
    }

    handleBrowseClick(e) {
        document.getElementById(this.props.id + "_fileupload").click()
    }

    handleUpload(e) {
        e.preventDefault()
        if(e.target.files[0]) this.setState({fileName: e.target.files[0].name})
        
        this.props.onUpload && this.props.onUpload(e.target.files[0])
        e.target.value = '' //HACK to allow to reload the same file twice
    }
}
