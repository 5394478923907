import { useState } from "react";
import Card from "../../components/containers/Card";
import DoubleColumnDisplay from "../../components/containers/DoubleColumnDisplay";
import { Person } from "../../entities";
import { Modal, Row, Title } from "../../framework/containers";
import { Button, Icon } from "../../framework/controls";
import PersonForm from "./PersonForm";
import ConditionalWrapper from "../../components/containers/ConditionalWrapper";
import { useHistory } from "react-router-dom";
import { PersonService } from "../../services";
import { Question } from "../../framework/components";
import { isEmptyObj } from "../../framework/utils/helper";

const FlexRow = ({children}) => <div className='row g15 mt-3' style={{alignItems: 'stretch'}}>{children}</div>

const PersonPanel = ({person, detailed, basic, cn = ''}) => {
    const title = <>{`Personal Details`}</>

    const [personEdit, setPersonEdit] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [warningDelete, setWarningDelete] = useState(false)
    const history = useHistory()
    
    const handleSave = () => {
        setPersonEdit(false)
    }

    const onDelete = () => {
        setIsDeleting(true)
    }

    const onDeleteConfirm = (answer) => {
        setIsDeleting(false)
        if(answer){
			return PersonService.deletePerson(person).then(resp => {
				if(!isEmptyObj(resp)) setWarningDelete(true)
                else history.push('/members')
			}).catch(err => console.log(err));
		}
    }

    const baseFields = [ 'formatedSin', 'firstName', 'middleName', 'lastName' ]
    const extendedFields = [ 'lng', 'gender', 'dob', 'dod' ]

    const personStyle = { flex: 1 }
    const contactStyle = { flex: 2 }

    const editActions = [    
        <Icon key='edit' icon='edit' className='text-primary clickable' tooltip='Edit' onClick={() => setPersonEdit(true)} />,
    ]

    return <>
        <ConditionalWrapper condition={detailed} wrapper={FlexRow} >
            {personEdit && <Modal id='personEdit' className='h-100 w-60 modal-bg-color'>
                <PersonForm 
                    person={person} 
                    onCancel={() => setPersonEdit(false)} 
                    onSave={() => handleSave()} 
                />
            </Modal>}
            <Card title={title} style={personStyle} actions={editActions} cn = {cn}>
                    <Row>
                        <div style={{flex: 4}} >
                            <DoubleColumnDisplay entity={Person} instance={person} properties={
                                basic ? baseFields : baseFields.concat(extendedFields)
                            } />
                        </div>
                    </Row>
            </Card>
            {detailed && 
            <Card title={'Contact Details'} style={contactStyle} actions={editActions}>
                    <Row>
                        <div style={{flex: 4}} >
                            <DoubleColumnDisplay 
                                flexLabel={{flex: 1, width: '300px' }} 
                                flexValue={{flex: 5}}
                                entity={Person} 
                                instance={person} 
                                properties={[
                                'address.desc', 'emails', 'phones'
                            ]} />
                        </div>
                    </Row>
            </Card>}
        </ConditionalWrapper>
        {detailed && <Card title={'Comments'} actions={editActions} cn='mt15'>
                {person.cmt || <span className='btn-ghost'>No comments</span>}
        </Card> }
        { detailed && <Row className='justify-content-end button-row mt20'>
            <Button key='delete' text='Delete Person' className='btn-secondary' onClick={onDelete} />
        </Row> }
		{ isDeleting && <Question title='Warning. Deleting a person can impact existing financial data and plan participation. Are you sure you want to delete?' onAnswer={onDeleteConfirm} />}
		{ warningDelete && <Modal centered ><Title className="ml-3" onHide={() => setWarningDelete(false)} title="The member still has financial data, we cannot delete this member. He must be reimbursed"/></Modal>}
    
    </> 

}

export default PersonPanel;