import { Service } from '../../framework'
import EmploymentResults from '../../entities/search/EmploymentSearch/EmploymentResults';

class EmploymentSearch extends Service {
    constructor() {
        super(EmploymentResults, 'Employments', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }
}

const instance = new EmploymentSearch()
export default instance

