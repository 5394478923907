import { Definition, Ref}  from '../../../framework/infra'
import RefFilter from '../../../framework/infra/model/RefFilter';
import { ParticipationEvent } from '../../membership'
import CommonFilters from '../CommonFilters';

export default class MembershipSummaryView extends Ref {
    
    get participationEventDescription() {
        let event = new ParticipationEvent({code: this.participationStatusKey});
        return event.statusDesc;
    }

    get employerCodes() {
        return this.employers.all.map(employer => employer.code).join(' ');
    }

    static get filters() {
        return MemberSummaryViewFilters;
    }

    static get exportConfigs() {
        return {
            fileName: 'MembershipExport-' + new Date().toISOString().slice(0, 10),
            sheetName: 'Memberships',
            columns: ['sin', 'nameOriginal', 'dateOfBirth', 'joinDateString', 'employerCodes', 'participationEventDescription']
        };
    }

    static definitions = {
        id: { type: Definition.types.STRING, text: 'ID' },
        nameOriginal: { type: Definition.types.STRING, text: 'Name' },
        sin: { type: Definition.types.SIN, text: 'SIN' },
        dateOfBirth: { type: Definition.types.DATE, text: 'Date of Birth' },
        joinDate: { type: Definition.types.DATE, text: 'Join Date' },
        joinDateString: { type: Definition.types.STRING, text: 'Join Date' },
        employerIds: { type: Definition.types.UNDEFINED, text: 'Employer IDs' },
        participationStatusKey: { type: Definition.types.STRING, text: 'Participation Status Key' },

        //transients
        employers: { transient: true, ref: require('../../employment/Employers'), text: 'Employers' },
 
        //abstracts
        employerCodes: { abstract: true, type: Definition.types.STRING, text: 'Code' },
        participationEventDescription: { abstract: true, type: Definition.types.STRING, text: 'Status' },
        filters: { abstract: true, type: MemberSummaryViewFilters, text: 'Filters' }
    }
}

class MemberSummaryViewFilters extends RefFilter {

    constructor(data) {
        super(data, MemberSummaryViewFilters.definitions);
    }

    static definitions = {
        sin: CommonFilters.sin,
        name: CommonFilters.nameWithFuzziness,
        joinDateString: CommonFilters.joinDateString,
        dob: CommonFilters.dob,
        dobUnder: CommonFilters.dobUnder,
        dobOver: CommonFilters.dobOver,
        employerIds: CommonFilters.employerIds,
        participationStatusKey: CommonFilters.participationStatusKey,
        fuzziness: CommonFilters.nameFuzziness
    }
}