import React from 'react'
import Container from './Container'
import ContainerChild from './ContainerChild'

export default class Collapse extends Container {
    static cn = 'collapsable'
    constructor(props) {
        super(props)
        this.state = { open: this.props.open !== false   }
    }

    render() {
        return this.wrapper(<>
            <div className='row' onClick={() => this.setState({open: !this.state.open})} >
                {Collapse.Title.filter(this.props.children)}
                <div className='stretch collapsable-line'/>
                <div className={(this.state.open ? 'icon-noun-dropdown-up' : 'icon-noun-dropdown') + ' collapse-icon v-center'} />
            </div>
            
            {this.state.open && Collapse.Title.filterOut(this.props.children)}
        </>)
    }       

}

Collapse.Title = class Title extends ContainerChild {
    static cn = 'collapsable-title'
    render() { return <div className='collapsable-title'>{this.props.children || ''}</div> }
}