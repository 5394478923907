import { RefEnum } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class EarningCategory extends RefEnum {
    constructor(key, defaultDesc, codePrefix) {
        super(key, defaultDesc)
        this.codePrefix = codePrefix
    }

    isRegular() { return this.key === 'REG' || this.key === 'Y19' }
    isOvertime() {return this.key === 'OVR'}
    isOneTime() {return this.key === 'OTE'}
    isMaternity() { return this.key === 'MAT' }
    isLtd() { return this.key === 'LTD' }
    isSelf() { return this.key === 'SLF' }

    isPensionable() { return this.key !== 'NPE' }
    isDeductable() { return ['REG', 'OVR', 'OTE', 'Y19'].includes(this.key) }
    isDeemed() { return ['MAT', 'LTD', 'SLF'].includes(this.key) }
    isHourly() { return ['REG', 'OVR', 'Y19', 'MAT', 'LTD', 'SLF'].includes(this.key) }


    static types = deepFreeze({
        REG: new EarningCategory('REG', 'REGULAR EARNINGS', 'r'),
        OVR: new EarningCategory('OVR', 'OVERTIME EARNINGS', 'o'),
        OTE: new EarningCategory('OTE', 'ONE-TIME EARNINGS', 't'),
        NPE: new EarningCategory('NPE', 'NON-PENSIONABLE EARNINGS', 'n'),
        MAT: new EarningCategory('MAT', 'MATERNITY EARNINGS', 'm'),
        LTD: new EarningCategory('LTD', 'LTD EARNINGS', 'l'),
        SLF: new EarningCategory('SLF', 'SELF CONTRIBUTION', 's'),
        Y19: new EarningCategory('Y19', 'YEAR END 2019', 'y'),
    })

    static default = new EarningCategory('', 'Inexistant')
    static definitions = {}
}