import React from 'react'
import Control from './Control'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Form } from 'react-bootstrap'

export default class Choice extends Control {
    render() {
        var { options, name, value, displayKey, searcheable, nullable, placeholder, readOnly } = this.props

        if (nullable !== false) options = [{key: '', text: placeholder ? '[' + placeholder + ']' : ''}].concat(options)
        var selected = options.find(opt => opt.key === value)
        if(!selected) {
            if (this._typeahead) this._typeahead.clear()
        } else {
            selected = [selected] 
        }
        var select = searcheable === false ? 
            <Form.Control name={name} as="select" value={value} disabled={readOnly} onChange={this.handleChangeClasic.bind(this)}>
                {options.map(option => (<option key = {option.key} value = {option.key}>{option.text}</option>))}  
            </Form.Control>
        : 
            <Typeahead {...this.controlProps}
                ref={(ref) => this._typeahead = ref}
                labelKey= {displayKey || "text"}
                options={options}
                selected={selected}
                highlightOnlyResult={true}
                selectHintOnEnter={true}
                onFocus={e => { this._typeahead.clear(); this._typeahead._showMenu() }}
                onBlur={e => { this.setState({touch:true}) }}
                disabled={readOnly}
            />
        
        return this.wrapper(select)
    }

    handleChange(option){
        const id = option && option[0] && option[0].key
        if(id && this.props.value !== id && this.props.onChange) this.props.onChange(id)
    }
    
    handleChangeClasic(event){
        const id = event.target.value
        if(this.props.value !== id && this.props.onChange) this.props.onChange(id)
    }
}
