import { useState, useEffect } from 'react';
import Auth from '../framework/infra/Auth';
import { getSafe, concatStr } from '../framework/utils/helper';

const UserMenu = () => {

    const [show, setShow] = useState(false);
    const [name, setName] = useState();

    const logout = () => {
        Auth.signOut().then(() => window.location.href = window.location.origin)
    }

    useEffect(()=> {
        let isMounted = true;

        Auth.getCurrentUser().then(user => {
            const name = concatStr(getSafe(user, 'attributes.given_name')) + concatStr(getSafe(user, 'attributes.family_name'), '')[0] + '.'
            if (isMounted) {
                setName(name);
            }
        })

        return () => { isMounted = false };
    }, []);

    return <div>
        <button className='btn dropdown-toggle dropdown' onClick={() => setShow(!show)}>
            <span className="user-menu">{name}</span>
        </button> 
        <div className={'dropdown-menu' + (show ? ' show' : '')} onBlur={() => setShow(false)}>
            <div className="dropdown-item" onClick={logout}>Logout</div>
        </div>
    </div>
    
}
export default UserMenu;