import React from 'react'
import Container from './Container'

export default class Col extends Container {
    static cn = 'col'
    render() {
        var { right, span, className, children} = this.props
        span = span ? ('col-' + span) : ''
        if (right) children = <div className='float-right'>{this.props.children} </div>
        return <div className={[this.constructor.cn, span, className].join(' ')}>{children}</div> 
    }
}
