import { round } from './helper'


export default class Timer {
	constructor(name, method, text = '', logOn = true) {
		this.className = name
		this.methodName = method
		this.text = text
		this.logOn = logOn
		this.start = new Date()
		if (this.logOn) console.log("START   = " + this.start.toISOString().substring(14,22) + '   [' + this.className + ' - ' + this.methodName + ']   ' + this.text)
	}
	
	stop() {
		this.end = new Date()
		if (this.logOn) {
			console.log("END      = " + (Number(this.end || Date.now()) - Number(this.start)) + '   [' + this.className + ' - ' + this.methodName + ']   ' + this.text)
		}
	}
	
	logElapsed(message) {
		console.log("ELAPSED = " + (Number(this.end || Date.now()) - Number(this.start)) + '   [' + this.className + ' - ' + this.methodName + ']   ' + this.text + (message ? ('  -  ' + message) : ''))
	}

	static start(name, method, text, logOn) {
		return new Timer(name, method, text, logOn)
	}
}
