import { useState } from "react";
import { Button } from "react-bootstrap";
import { Row, Title } from "../../containers";
import EInput from "../eComp/EInput";

const MultipleChoiceValidation = ({
    choice,
    instance,
    header,
    paramName,
    task,
    onClose,
    onSave,
    onNo,
    onYes,
}) => {
    const [selectEvent, setSelectEvent] = useState();
    const handleConfirmEvent = (confirmed) => {
        if(confirmed){
            if (onYes) {
                if (paramName) {
                    onYes(
                        instance,
                        {...selectEvent, cmt: task.cmt},
                        task.params
                    );
                } else {
                    onYes(
                        instance,
                        selectEvent
                    );
                }
            }
            onSave(instance);
        } else {
            if (onNo) onNo();
            onClose();
        }
    }

    const handleRadioButtonChange = (event) => {
        setSelectEvent(event)
    }

   
    return (
        <>
            <Title title={paramName ? header + task.params[paramName]?.value : header} onHide={onClose} />
            <Row className="input-spacing-2">
                <EInput name="cmt" instance={task} />
            </Row>
            <form>
                {choice.map((button) => {
                    return (
                        <div className="radio mt-3">
                            <label>
                                <input
                                    type="radio"
                                    class="mr-2"
                                    value={button.event}
                                    checked={
                                        selectEvent?.code === button.event.code
                                    }
                                    onChange={handleRadioButtonChange.bind(
                                        this,
                                        button.event
                                    )}
                                />
                                {button.desc}
                            </label>
                        </div>
                    );
                })}
            </form>
            <Row className="mt-4">
                <div className="col" />
                <Button key="Yes" onClick={handleConfirmEvent.bind(this, true)}>
                    Save
                </Button>
                <Button key="No" onClick={handleConfirmEvent.bind(this, false)}>
                    Cancel
                </Button>
            </Row>
        </>
    );
 }

 export default MultipleChoiceValidation;