import StringInput from '../../../components/filters/StringInput';
import { Definition, Ref}  from '../../../framework/infra'
import RefFilter from '../../../framework/infra/model/RefFilter';
import { removeNonNumeric } from '../../../framework/utils/helper';
import CommonFilters from '../CommonFilters';

export default class PersonView extends Ref {

    static get filters() {
        return PersonViewFilters;
    }

    static get exportConfigs() {
        return {
            fileName: 'PeopleExport-' + new Date().toISOString().slice(0, 10),
            sheetName: 'People',
            columns: ['sin', 'nameOriginal', 'dateOfBirth']
        };
    }

    static definitions = {
        id: { type: Definition.types.STRING, text: 'ID' },
        nameOriginal: { type: Definition.types.STRING, text: 'Name' },
        sin: { type: Definition.types.SIN, text: 'SIN' },
        dateOfBirth: { type: Definition.types.TIMESTAMP, text: 'Date of Birth' },
    }
}

class PersonViewFilters extends RefFilter {

    constructor(data) {
        super(data, PersonViewFilters.definitions);
    }

    static definitions = {
        sin: CommonFilters.sin,
        first: { 
            isFilter: true,
            type: Definition.types.STRING, 
            text: 'First Name', 
            icon: 'user',
            limit: 1,
            component: <StringInput />  
        },
        middleName: { 
            isFilter: true,
            type: Definition.types.STRING, 
            text: 'Middle Name', 
            icon: 'user',
            limit: 1,
            component: <StringInput />  
        },
        last: { 
            isFilter: true,
            type: Definition.types.STRING, 
            text: 'Last Name', 
            icon: 'user',
            limit: 1,
            component: <StringInput />  
        },
        maidenName: { 
            isFilter: true,
            type: Definition.types.STRING, 
            text: 'Maiden Name', 
            icon: 'user',
            limit: 1,
            component: <StringInput />  
        },
        name: {...CommonFilters.nameWithFuzziness, text: 'Full Name', description: `The full name: LastName (MaidenName), FirstName MiddleName. The full name search will find partial or exact matches in any order, in the name.`},
        fuzziness: CommonFilters.nameFuzziness
    }
}
