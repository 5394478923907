import deepFreeze from 'deep-freeze'
import Period from '../../utils/Period'
import Lng from '../Lng'
import Auth from '../Auth'
import { moment } from '../../utils/helper'
import { formatStringByPattern, formatAmount, formatNumber, formatDate, formatPercentage } from '../../utils/formating'

export default class Definition {
    constructor(name, def) {
        this.name = name
        Object.assign(this, def)
        if (!def.type) this.type = Definition.types.UNDEFINED
        if (this.type.options) this.options = this.options || this.type.options
        this.refList = getRefList(this.ref)
        this.refMap = getRefMap(this.ref)
    }

    get text() { 
        return this['text_' + Lng.current] || this['_text'] 
    }
    set text(str) { this['_text'] = str } 

	get desc() { return this['desc_' + Lng.current] || this['_desc'] || this.text}
    set desc(str) { this['_desc'] = str } 
	
	get message() { return this['message_' + Lng.current] || this['_message'] }
    set message(str) { this['_message'] = str }

    get help() { return this['help_' + Lng.current] || this['_help'] }
    set help(str) { this['_help'] = str }

    getOption(key) { return this.options && this.options.find(opt => opt.key === key) }
    getOptionText(key) { const opt = this.getOption(key); return opt ? (opt['text_' + Lng.current] || opt['text']) : '' }
    getOptionHelp(key) { const opt = this.getOption(key); return opt ? (opt['help_' + Lng.current] || opt['help']) : '' }
    
    isBool() { return this.type.isBoolean }
    isNumeric() { return this.type.isNumeric }
    isFactor() { return this.type.isFactor }
    isString() { return this.type.isString }
    isCurrency() { return this.type.isCurrency }
    isDate() { return this.type.isDate }
    isTimestamp() { return this.type.isTimestamp }
    isList() { return this.list }
    isMap() { return this.map }
    isChoice() { return this.type.isChoice }
    isQuestion() { return this.type.isQuestion }
    isMessage() { return this.type.isMessage }
    hasMask() { return !!this.type.mask }
    hasSuggestions() { return !!this.suggestions }
    isPeriod() { return this.type.isPeriod }

    isRef() { return this.ref }
    isEntity() { return this.getRef() && this.getRef().isEntity }
    isRefList() { return this.getRef() && this.getRef().isRefList }
    isRefEnum() { return this.getRef() && this.getRef().isRefEnum }
    isRefMessage() { return this.getRef() && this.getRef().isMessage }
    isRefTask() { return this.getRef() && this.getRef().isRefTask }
    getRef() { return getRef(this.ref) }
    
    set ref(ref) { this._ref = getRef(ref) }
    get ref() { return this._ref }
}

function getRef(ref) { 
    return ref instanceof module.constructor && ref.default && typeof ref === 'object' ? ref.default : ref 
}
function getRefList(ref) {
    if (!ref) return
    const refListClass = ref.refList || (class GenericRefList extends require(ref.isMessage ? './RefMessages' : './RefList').default {})
    refListClass.ref = ref
    return refListClass
}
function getRefMap(ref) {
    if (!ref) return
    const refListClass = ref.refMap || (class GenericRefMap extends require('./RefMap').default {})
    refListClass.ref = ref
    return refListClass
}

//TODO create objects for each type

//Birthday

Definition.types = deepFreeze({
    STRING: {type: 'string', isString: true},
    BOOLEAN: {type: 'boolean', isBoolean: true,
        getData: (val) => val && val !== 'false' && val !== 'n' ? true : false
    },
    
    YESNO: {type: 'yesno', isChoice: true,
        setData: (val) => val === false || val === 'false' ? 'n' : (!val ? '' : (val === true ? 'y' : val)),   //TODO Backward compatible until data conversion
        options: [
            { key: '', text: '', text_fr: ''},
            { key: 'y', text: 'Yes', text_fr: 'Oui'},
            { key: 'n', text: 'No', text_fr: 'Non'},
        ],
    },
    DATE: {type: 'date', isDate: true, 
        // getData: (val) => val ? val.format('YYYY-MM-DD') : '', 
        // setData: (val) => moment(val), 
        // reset: () => moment()
        isInRange: (dt, from = '1900-01-01', to = '9999-12-31') => dt >= from && dt <= to
    },
    TIMESTAMP: {type: 'timeStamp', isTimestamp: true,
        reset: () => moment().valueOf()
    },
    NUMBER: {type: 'number', isNumeric: true},
    PONDERATION: {type: 'ponderation', isNumeric: true}, //TO COME
    AMOUNT: {type: 'amount', isNumeric: true, isCurrency: true, 
        format: formatAmount
    },
    PERCENTAGE: {type: 'percentage', isNumeric: true},
    FACTOR: {type: 'factor', isNumeric: true, isFactor: true},
    CHOICE: {type: 'choice', isChoice: true},
    PERIOD: {type: 'period', isPeriod: true, mask: '9999/99',
        getData: (val) => val ? val.value : '', 
        setData: (val) => Period.create(val), 
        reset: () => Period.create()
    },
    MESSAGE: {type: 'message', isMessage: true},
    REF: {type: 'ref'},
    CHILDREF: {type: 'childRef'},
    ENTITY: {type: 'entity', isEntity: true},
    SIN: {type: 'sin', text: 'SIN', mask: '111-111-111', getData: (val) => String(val).replace(/[^\d]/g, "")}, 
    USER: {type: 'user', text: 'User', isUser: true }, 
    UNDEFINED: {type: 'undefined'},    
})

Lng.default = 'en'
Lng.current = Lng.default
