import Company from '../company/Company'
import { Definition, RefMap }  from '../../framework/infra'
import { NewEmployerChecklistEvents } from '../company/newEmployer/NewEmployerChecklistEvent'
import { EarningType } from '../financial'
import Employers from './Employers'


const { STRING, CHOICE, DATE, YESNO, BOOLEAN } = Definition.types

export default class Employer extends Company{

    get desc() { return this.name }
    get longDesc() { return this.code + ' - ' + this.name}
    get shortDesc() { return this.code}
    get jurisdictionCode() { return this.rst === 'pol' ? 'fed' : this.rst }
    
    getActiveEarningTypes() {
        return this.earningTypes.filter(earningType => !earningType.isDeleted())
    }

    assignDefaultEarningTypes() {
        const defaults = ['r1', 'o1', 't1', 'n1'];
        defaults.forEach((code) => {
            const earningType = new EarningType({code: code});
            earningType.alias = earningType.getHayesCode();
            earningType.label = earningType.getHayesLabel();
            this.earningTypes.push(earningType);
        })
    }

    static definitions = {
        act: { type: BOOLEAN, text: 'Active', text_fr: 'Active' },
        cmt: { type: STRING, text: 'Comment' },
        mercerDiv: { type: STRING, text: 'DIV' },
        mercerKey: { type: STRING, text: 'Mercer Key' },
        joinDt: { type: DATE, text: 'Join Date' },
        withDrawDt: { type: DATE, text: 'Withdraw Date' },
        eligibilityRestriction: { type: YESNO, text: 'Eligibility Restriction' },
        participatingEeGroup: { type: STRING, text: 'Participating Employee Group', default: 'All Eligible Employees' },
        earningTypes: { ref: require('../financial/earning/EarningTypes'), text: 'Earning Types', text_fr: 'Earning Types'  },
        rst: { type: CHOICE, text: 'Jurisdiction', options: [
            {key: 'que', text: 'Quebec', text_fr: 'Quebec'},
            {key: 'fed', text: 'Federal', text_fr: 'Federal'},
            {key: 'pol', text: 'Fed(POLICE)', text_fr: 'Fed(POLICE)'},
        ]},
        businessNumber: { type: STRING, text: 'Employer Business Number'  },
        craBusinessNumber: { type: STRING, text: 'Employer CRA Business Number',  },
        plan: { transient: true, ref: require('../pension/Plan'), text: 'Plan', text_fr: 'Plan'  }, 
        solvencies: { transient: true, ref: require('../pension/Solvencies'), text: 'Solvency' },
        adjustments: { transient: true, ref: require('../pension/adjustment/Adjustments'), text: 'Adjustments' },
        checklistComment: { type: STRING, text: 'Comment' },
        checklist: { ref: NewEmployerChecklistEvents },
    }

    static refMap = Employers;

}