import React from 'react'
import { EInput } from '../../components'
import { Row, Title } from '../../containers'

const TaskPage = (props) => {

    const handleCancel = () => { if (props.onClose) props.onClose() }

    return <><Title title={props.task.desc} onHide={handleCancel.bind(this)}/>
        <Row className='input-spacing-2'>
            <EInput name='cmt' instance={props.task} />
        </Row>
    </>
    
}

export default TaskPage;