import { RefEnum, Definition } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class PayeeType extends RefEnum {
    constructor(key, defaultDesc) {
        super(key, defaultDesc)
    }

    static types = deepFreeze({
        R: new PayeeType('R', 'Regular'),
        B: new PayeeType('B', 'Beneficiary'),
        S: new PayeeType('S', 'Spouse'),
        ER: new PayeeType('ER', 'Early Retirement')
    })
    
    static definitions = {
        desc: { type: Definition.types.STRING, text: 'Payee Type' },
    }
}
