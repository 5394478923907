import { Definition, Ref } from '../../../framework/infra'
import { sum } from '../../../framework/utils/helper'

import EarningType from './EarningType'


export default class Earning extends Ref {
    isEmpty() { return !this.code || (!this.amount && !this.hours) }

    get rate() { return (this.hours && this.amount) ? this.amount / this.hours : 0 }
    set rate(rate) { this.hours = rate * this.amount }

    add(earning) {
        this.amount = sum([this, earning], 'amount')
        this.hours = sum([this, earning], 'hours')
        return this
    }
}

const { AMOUNT, NUMBER, STRING } = Definition.types
Earning.definitions = {
    code: { type: STRING, key: true, text: 'Earning Type' },
    amount: { type: AMOUNT, text: 'Earning', default: 0 },
    hours: { type: NUMBER, text: 'Name', default: 0 },
    rate: { abstract: true, type: NUMBER, text: 'Hourly Rate' },
    earningType: { transient: true, ref: EarningType, text: 'Earning Type' },
}
Earning.key = 'code'