import React from 'react'
import Container from './Container'

export default class BulletList extends Container {
    static cn = 'bulletList'
    
    render() {
		const { entity, data, headerList } = this.props
		return this.wrapper(
            <div> 
				{headerList.map(headerProp => {
					return <ul className='no-bullet-point'>
						{data.map((prop) => {
							return (prop[headerProp] === '' ? <li className='bulletList-row'>&nbsp;</li> : <li className='bulletList-row'>{prop[headerProp]}</li>)
						})}
					</ul>
					
				})} 
			</div>
        )
    }
}