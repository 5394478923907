import { RefEnum } from '../../../framework/infra'
import { deepFreeze } from '../../../framework/utils/helper'

export default class ContributionType extends RefEnum {

    isRegular() { return this.key === 'REG' }
    isMaternity() { return this.key === 'MAT' }
    isLtd() { return this.key === 'LTD' }
    isSelf() { return this.key === 'SLF' }
    isVoluntary() { return this.key === 'VOL' }
    isDeemed() { return ['MAT', 'LTD', 'SLF'].includes(this.key) }

    static types = deepFreeze({
        REG: new ContributionType('REG', 'Regular'),
        MAT: new ContributionType('MAT', 'Maternity'),
        LTD: new ContributionType('LTD', 'Long Term'),
        SLF: new ContributionType('SLF', 'Self'),
        VOL: new ContributionType('VOL', 'Voluntary'),
    })

    static default = new ContributionType('', 'Inexistant')

    static definitions = {}
}
