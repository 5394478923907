import { RemittanceDetails } from "../entities";
import Mapper from "./Mapper";

export default class DetailListMapper extends Mapper {
    
    constructor (data, options = {}) {
        super (data, options);
        this.details = new RemittanceDetails();
    }

    async map () {
        await this.load('spouses');
        await this.load('people');
        await this.load('remittances');
        await this.load('beneficiaries');
        await this.load('memberships');
        await this.load('employments');
        this.details.pushList(await this.load('details'));
        return this.details;
    }
}