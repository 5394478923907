import Ref from './Ref'
import { arrayFilter, insertAt, sort, group } from '../../utils/helper'

export default class RefList extends Ref {

    reset() { this._list = [] }
    setData(data, reset) { // creates new array
        if(reset) this.reset()
        if (Array.isArray(data)) {
            data.forEach(val => this.push(this.create(val)))
        }
    }
    getData(options) { return this._list.filter(item => {
        if (typeof item === "object") {
            return item !== {};
        } else {
            return !item.isEmpty();
        }
    }).map(item => {
        if (typeof item !== "object") {
            return item.getData(options)
        } else {
            return item;
        }
    })}
    isEmpty() { return this.length === 0 }
    create(data) { return this.newRef(this.constructor.ref || Ref, data) }
    apply() { this._list.forEach(elem => {
        if (typeof elem !== "object") {
            return elem.apply();
        }
    }) }

    //alters object
    push(element) { if(element) this._list.push(element); return element }
    pushNew(data) { return this.push(this.create(data)) }
    pushList(list) { list.forEach(elem => this._list.push(elem)); return this}
    pull(element) { this._list = this.filter((elem) => elem !== element); return element } // creates new array
    pullFilter(func) { this._list = this.filter(func) } 
    insertAt(element, index = 0) { this._list = insertAt(this._list, element, index) } // creates new array
    remove(func) { return arrayFilter(this._list, func) }
    sortBy(prop) { this._list = sort(this._list, prop); return this} // creates new array
    sort(func) { return this._list.sort(func)} //TODO refactor - this returns Array, should return RefList
    reverse() { return this._list.reverse() } //TODO refactor -  this returns Array, should return RefList
    splice(startIndex, deleteCount) { return this._list.splice(startIndex, deleteCount); }

    //array functions
    forEach(func) { return this._list.forEach(func)}
    reduce(func, initialValue) { return this._list.reduce(func, initialValue) }
    map(func) { return this._list.map(func)}
    filter(func) { return this._list.filter(func)}
    find(func) { return this._list.find(func)}
    findLast(func) { return [...this._list].reverse().find(func)}
    findIndex(func) { return this._list.findIndex(func)}
    concat(array) { return this._list.concat(array)}
    slice(func) { return this._list.slice(func)}
    
    group(prop) {
        const elemsByProp = group(this._list, prop)
        Object.getOwnPropertyNames(elemsByProp).forEach(grp => {
            const newList = new this.constructor()
            newList.pushList(elemsByProp[grp])
            elemsByProp[grp] = newList
        })
        return elemsByProp
    }

    /** Same functionality as array.map() but returns an instance of this
     * 
     * @param {*} func : the function that array map takes as input 
     * @returns a mapped instance of this
     */
    mapList(func) {
        const returnedMap = new this.constructor();
        returnedMap.pushList(this.map(func));
        return returnedMap;
    }

    getFiltered(func) {
        const ret = new this.constructor()
        ret.pushList(this.filter(func))
        return ret
    }

    getSorted(func) {
        const ret = new this.constructor();
        ret.pushList(this.sort(func));
        return ret;
    }
    
    getPrev(item) { 
        const index = this._list.findIndex((i) => i === item);
        return this._list[index - 1]; 
    }
    getNext(item) { return this._list[this._list.findIndex(i => i === item) + 1] }
    at(index) { return this._list[index] }

    get length() { return this._list.length }
    get all() { return this._list }
    get first() { return this._list[0] }
    get last() { return this._list[this._list.length - 1] }
    get desc() { return this.reduce((endMessage, currentMessage) => endMessage + `${endMessage === "" ? "" : "\n"}${currentMessage.desc}`, "") }

    set last(v) {}
    static ref = Ref
    static isRefList = true
}
