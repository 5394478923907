import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Remittance, RemittanceDetail } from "../../../entities";
import { Table } from "../../../framework/components";
import { Title } from "../../../framework/containers";
import { Definition } from "../../../framework/infra";
import { Excel } from "../../../framework/utils";
import { array2Object, isString } from "../../../framework/utils/helper";
import { RemittanceDetailService } from "../../../services";
import YearEndEmploymentSummary from "../../../entities/yearEnd/YearEndEmploymentSummary";

const YEUploadComments = ({file, report, onCancel, onSave}) => {
    const [details, setDetails] = useState();
    const headers = new Table.Headers(YearEndEmploymentSummary, 'sin, employerCmt, reviewedCmt, financialCmt, reasonDaysDiff, confStatus, finalPayOut, yeValidated')

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setDetails(initializeDetails());

        return () => { isMounted = false };
    }, []);

    const initializeDetails = () => {
        const details = [...report.details];
		const existingDetailsBySin = array2Object(details, 'sin');

        const StatusVALIDATE_sheet = file.findSheet('Status-VALIDATE');
        const StatusVALIDATE_sheet_data =  Excel.getSheetData(StatusVALIDATE_sheet, 3);
        const AnnualizedEarningsVALIDATE_sheet = file.findSheet('Annualized Earnings-VALIDATE');
        const AnnualizedEarningsVALIDATE_sheet_data =  Excel.getSheetData(AnnualizedEarningsVALIDATE_sheet, 3);
        const TerminationsCONFIRM_sheet = file.findSheet('Terminations-CONFIRM');
        const TerminationsCONFIRM_sheet_data =  Excel.getSheetData(TerminationsCONFIRM_sheet, 5);

        StatusVALIDATE_sheet_data.forEach(data => {
            procComment(data[1], { cmt: data[15], employerCmt: data[15], reviewedCmt: data[16] }, existingDetailsBySin);
        });

        AnnualizedEarningsVALIDATE_sheet_data.forEach(data => {
            procComment(data[1], { financialCmt: data[5] }, existingDetailsBySin);
        });

        TerminationsCONFIRM_sheet_data.forEach(data => {
            procComment(data[1], { reasonDaysDiff: data[11], confStatus: data[12], finalPayOut: data[13]}, existingDetailsBySin);
        });
            
        return Object.values(existingDetailsBySin);
    }

    const procComment = (dataSin, comments, existingDetailsBySin) => {
        const sin = Definition.types.SIN.getData(dataSin);
        if (existingDetailsBySin[sin]) {
            Object.getOwnPropertyNames(comments).forEach(prop => {
                if (comments[prop] === '' || (isString(comments[prop]) && comments[prop]?.toUpperCase() === 'BLANK') || !comments[prop]) comments[prop] = '';
                existingDetailsBySin[sin][prop] = comments[prop];
            })
            existingDetailsBySin[sin].yeValidated = String(existingDetailsBySin[sin].reviewedCmt).toLowerCase().includes('validated');
        }
    }

    const handleSave = async () => {
        const changes = {};
        for (const det of details) {
            changes[det.detailKey] = {
                cmt: det.cmt,
                employerCmt: det.employerCmt,
                financialCmt: det.financialCmt,
                reviewedCmt: det.reviewedCmt,
                reasonDaysDiff: det.reasonDaysDiff,
                confStatus: det.confStatus,
                finalPayOut: det.finalPayOut,
            };
        }
        await RemittanceDetailService.updateYEComments(changes);
        if(onSave) onSave(details);
    }

    return (<>
        {!details ? <p>Loading...</p>: 
        <><Title title='Year End Validation' onHide={onCancel}/>
        <Table id='messages-table' data={details} columns={headers}/>
        <Button key='save' onClick={handleSave}>Save</Button>
        <Button key='cancel' onClick={onCancel}>Cancel</Button></>}
    </>);

}

export default YEUploadComments;