import React from 'react'
import { Component } from '../../components'
import { Card } from '../../containers'

export default class ContentPage extends Component {
    wrapper(view) {
        return ( <>
            <h3 className='text-center mb-3'>{this.title}</h3>
            <Card>
                <Card.Body>
                    view
                </Card.Body>
            </Card>
        </> )
    }
}