import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmployerStore } from "./useEmployerStore";

const useEmployer = () => {
    const { employerCode } = useParams();
    const employerMapping = useEmployerStore((state) => state.employers);
    const [employerInfo, setEmployerInfo] = useState();

    useEffect(() => {
        let isMounted = true;

        if (employerMapping[employerCode] && isMounted) {
            setEmployerInfo(employerMapping[employerCode]);
        }

        return () => { isMounted = false };
    }, [employerMapping[employerCode], employerCode]);

    return { employerId: employerInfo?.id, employerName: employerInfo?.name, employer: employerInfo };
}

export default useEmployer;
