import React from "react"
import { Alert } from "../../controls"

export default class Message extends React.Component {
    render() {
        const { message, children } = this.props
        if (!message || (!message.text && !message.message && !message.desc && !children)) return ''

        return <Alert variant={message.severity || 'danger'}>
            {(message.text || message.message || message.desc) && <div>
                <span className="alert-icon"><i className="far fa-bell"></i> </span>
                <span className="alert-message ml-3"> {message.text || message.message || message.desc}</span>
            </div>}
            {message.body || children}
        </Alert>
    }
}
