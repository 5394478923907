import TaskPage from '../../framework/components/page/TaskPage'
import { RefTask } from '../../framework/infra'

export default class MembershipTask extends RefTask {
    get form() {return <TaskPage/>}

    static messages = [
        ['submitBdf','Submit Beneficiary Designation Form', 'Submit Beneficiary Designation Form']   
    ]

    static definitions = {
        form: {abstract: true}
    }
}
