import React, { useEffect, useState } from "react"
import RemittanceBalance from "./RemittanceBalance"
import { AdjustmentService, RemittanceService } from '../../../services'
import CustomDropdown from "../../../components/form/Dropdown"
import { Period } from "../../../framework/utils"
import { useHistory, useLocation } from "react-router-dom"
import Loading from "../../../components/containers/Loading"
import useQuery from "../../../hooks/useQuery"
import { RemittanceBusiness } from "../../../business"

const Remittances = ({ employer }) => {
    const yearOptions = [
        { text: "All", value: "all" },
        { text: "Default", value: "default" },
        ...Period.getYears(undefined, undefined).map((option) => ({
            text: option.year,
            value: option.year,
        })),
    ];
    let history = useHistory();
    const { pathname } = useLocation();
    let query = useQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [remittances, setRemittances] = useState();
    const [year, setYear] = useState(
        yearOptions.find((opt) => opt.value === query.get("year"))
            ? query.get("year")
            : "default"
    );
    
    useEffect(() => {

        let isMounted = true;
        const fetch = async () => {
            history.replace(`${pathname}?year=${year}`);
                await refreshRemittances();
		}
		fetch().then(() => { if(isMounted) setIsLoading(false) });
		return () => { isMounted = false };
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            history.replace(`${pathname}?year=${year}`);
        }
        return () => {
            isMounted = false;
        };
    }, [year]);

    const refreshRemittances = async () => {
        setIsLoading(true);
        const adjustments = await AdjustmentService.getAdjustmentsForEmployer(employer.id);
        const rems = await RemittanceService.getRemittancesForPeriods("all", employer, {load: true});
        rems.setAdjustmentsOnRemittanceList(adjustments);
        RemittanceBusiness.refreshBalances(rems);
        setRemittances(rems);
    }

    const onSelect = (row) => {
        history.push(`/employer/${employer.code}/remittance/${row.period.value}`);
     }

    const getFilteredRemittances = () => {
        let rems = remittances;
        if (year === "default") {
            rems = remittances.getFiltered((rem) => {
                const currentPeriod = new Period();
                return rem.period.isSameYear(currentPeriod) || rem.period.isSameYear(currentPeriod.decYear());
            })
        } else if (year !== "all") {
            rems = remittances.getFiltered((rem) => {
                const yearPeriod = new Period(year);
                return rem.period.isSameYear(yearPeriod);
            })
        }
        return rems;
    }

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && remittances && (
                <div className="d-flex flex-column g15 dropdown-no-margin">
                    <CustomDropdown
                        buttonText={`Year: ${year}`}
                        options={yearOptions}
                        onSelect={(value) => setYear(value)}
                    />
                    <RemittanceBalance
                        remittances={getFilteredRemittances()}
                        employer={employer}
                        onSelect={onSelect}
                    />
                </div>
            )}
        </>
    );
}

export default Remittances;

