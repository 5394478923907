import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankWire extends  Ref {
    
}

const { BOOLEAN, REF, STRING } = Definition.types


//  "wire": {
//       "accounts": [
//         {
//           "address": {
//             "city": "Toronto",
//             "civic": "180 Wellington St",
//             "country": "Canada",
//             "pc": "M5J 1J1",
//             "poBox": "",
//             "prov": "Ontario"
//           },
//           "bank": "Royal Bank of Canada",
//           "name": "Intermediary Bank (Field 56)",
//           "swift": "ROYCCAT2"
//         },
//         {
//           "address": {
//             "city": "Toronto",
//             "civic": "155 Wellington St. West, 4th Floor",
//             "country": "Canada",
//             "pc": "M5V 3L3",
//             "poBox": "",
//             "prov": "Ontario"
//           },
//           "bank": "RBC I&TS",
//           "name": "Account with Bank (Field 57)",
//           "swift": "ROYCCAT"
//         }
//       ],
//       "beneficiary": {
//         "accounts": {
//           "fed": "James Bay Cree-Naskapi Pension Plan 07522010",
//           "que": "James Bay Cree-Naskapi Quebec Pension Plan 07522011"
//         },
//         "address": {
//           "city": "Westmount",
//           "civic": "14150 St. Catherine Street W., Suite 205",
//           "country": "Canada",
//           "pc": "H3Z 2Y5",
//           "poBox": "",
//           "prov": "Quebec"
//         },
//         "name": "Beneficiary (Field 58/59)"
//       },
//       "notice": "Advise A.S. Hayes Consulting of the date and amount of your transfer or the funds will not be deposited",
//       "show": true
//     }
//   }

ConfigBankWire.definitions = {
    account: { ref: require('./ConfigBankWireAccount'), text: 'Same Bank', text_fr: 'Même banque' },
    account_intermediary: { ref: require('./ConfigBankWireAccountIntermediary'), text: 'Intermediary', text_fr: 'Intermédiaire' },
    beneficiary: { ref: require('./ConfigBankWireBeneficiary'), text: 'Beneficiary', text_fr: 'Bénéficiaire' },
    disabled: { type: BOOLEAN, text: 'Disabled', text_fr: 'Désactivé' },
    notice: { type: STRING, text: 'Notice', text_fr: 'Avis' },
}


