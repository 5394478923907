import { Definition,  Ref } from '../../../framework/infra'

export default class ConfigBankWireAccountIntermediary extends  Ref {
    
}

const { STRING, REF } = Definition.types

// "wire": {
//     "accounts": [
//       {
//         "address": {
//           "city": "Toronto",
//           "civic": "180 Wellington St",
//           "country": "Canada",
//           "pc": "M5J 1J1",
//           "poBox": "",
//           "prov": "Ontario"
//         },
//         "bank": "Royal Bank of Canada",
//         "name": "Intermediary Bank (Field 56)",
//         "swift": "ROYCCAT2"
//       },



ConfigBankWireAccountIntermediary.definitions = {
    address: { ref: require('../../address/Address'), text: 'Address', text_fr: 'Adresse' },
    bank: { type: STRING, text: 'Bank', text_fr: 'Banque' },
    name: { type: STRING, text: 'Name', text_fr: 'Nom' },
    swift: { type: STRING, text: 'SWIFT', text_fr: 'SWIFT' },
}


