import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Input } from "reactstrap";

const CustomDropdown = ({ buttonText, buttonVariant, options, onSelect }) => {
    const [active, setActive] = useState();

    const handleSelect = (value) => {
        setActive(value);
        onSelect(value);
    }

    return (
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
                variant={buttonVariant ?? "primary"}
                id="dropdown-basic"
            >
                {buttonText}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options.map((option) => (
                    <Dropdown.Item eventKey={option.value} active={option.value === active} key={option.value} >
                        {option.text}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CustomDropdown;
