import React from "react";
import { EInput } from "../../../framework/components";
import { Row, Card } from "../../../framework/containers";

const BankWireForm = ({ wire }) => {
    const { account, account_intermediary, beneficiary } = wire;

    return (
        <Card className="px-3 py-3 mb-3">
            <h3>Wire</h3>
            <h5 className="mt-2">Account</h5>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="civic"
                    instance={account.address}
                    cn="col-9"
                />
                <EInput
                    name="poBox"
                    instance={account.address}
                    cn="col-2"
                />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="city"
                    instance={account.address}
                    cn="col-3"
                />
                <EInput
                    name="prov"
                    instance={account.address}
                    cn="col-3"
                />
                <EInput
                    name="pc"
                    instance={account.address}
                    cn="col-3"
                />
                <EInput
                    name="country"
                    instance={account.address}
                    cn="col-2"
                />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <EInput name="bank" instance={account} cn="col-6" />
                <EInput name="swift" instance={account} cn="col-5" />
            </div>
            <hr />
            <h5>Account Intermediary</h5>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="civic"
                    instance={account_intermediary.address}
                    cn="col-9"
                />
                <EInput
                    name="poBox"
                    instance={account_intermediary.address}
                    cn="col-2"
                />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="city"
                    instance={account_intermediary.address}
                    cn="col-3"
                />
                <EInput
                    name="prov"
                    instance={account_intermediary.address}
                    cn="col-3"
                />
                <EInput
                    name="country"
                    instance={account_intermediary.address}
                    cn="col-3"
                />
                <EInput
                    name="pc"
                    instance={account_intermediary.address}
                    cn="col-2"
                />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="bank"
                    instance={account_intermediary}
                    cn="col-6"
                />
                <EInput
                    name="swift"
                    instance={account_intermediary}
                    cn="col-5"
                />
            </div>
            <hr />
            <h5>Beneficiary</h5>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="civic"
                    instance={beneficiary.address}
                    cn="col-9"
                />
                <EInput
                    name="poBox"
                    instance={beneficiary.address}
                    cn="col-2"
                />
            </div>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="city"
                    instance={beneficiary.address}
                    cn="col-3"
                />
                <EInput
                    name="prov"
                    instance={beneficiary.address}
                    cn="col-3"
                />
                <EInput
                    name="country"
                    instance={beneficiary.address}
                    cn="col-3"
                />
                <EInput
                    name="pc"
                    instance={beneficiary.address}
                    cn="col-2"
                />
            </div>
            <hr />
            <h5>Federal Account Information</h5>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="name"
                    instance={beneficiary.account_fed}
                    cn="col-6"
                />
                <EInput
                    name="number"
                    instance={beneficiary.account_fed}
                    cn="col-5"
                />
            </div>
            <hr />
            <h5>Quebec Account Information</h5>
            <div className="d-flex justify-content-between mt-2">
                <EInput
                    name="name"
                    instance={beneficiary.account_que}
                    cn="col-6"
                />
                <EInput
                    name="number"
                    instance={beneficiary.account_que}
                    cn="col-5"
                />
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-2">
                <EInput name="notice" instance={wire} cn="col-9" />
                <EInput name="disabled" instance={wire} cn="col-2" />
            </div>
        </Card>
    );
};

export default BankWireForm;
