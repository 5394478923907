import { Ref, RefMap, Definition, RefList } from '../../../framework/infra'
import { sum, round, group, moment } from '../../../framework/utils/helper'
import { formatAmount } from '../../../framework/utils/formating'

import Account from './Account'

export default class AccountDistribution extends RefList {
    get eeAmount() { return this.sumByAccount('EE') }
    get erAmount() { return this.sumByAccount('ER') }
    get volAmount() { return this.sumByAccount('VOL') }
    get solAmount() { return this.sumByAccount('SOL') }
    get total() { return sum(this._list, 'amount') }
    get eeBalance() { return this.balanceByAccount('EE') }
    get erBalance() { return this.balanceByAccount('ER') }
    get volBalance() { return this.balanceByAccount('VOL') }
    get solBalance() { return this.balanceByAccount('SOL') }
    get totBalance() { return sum(this._list, 'balance') }
    
    get longDesc() { return this._list.reduce((text, ad) => text += `${ad.acc.key} ${ad.period.longDesc} [${formatAmount(ad.amount)}] + `, '').slice(0,-2) }
    
    get longDescByPeriod() { 
        return this.periods.reduce((text, period) => {
            const periodDistribution = this.getDistributionForPeriod(period)
            text += `${period.longDesc} (`
            periodDistribution.forEach( ad => text += `${ad.acc.key} [${formatAmount(ad.amount)}] + `)
            return text.slice(0, -2) + ') + '
        }, '').slice(0, -2)
    }

    get periods() {
        return Object.values(this._list.reduce((periods, dist) => {
            periods[dist.period.value] = dist.period
            return periods
        }, {}))
    }
    get sumByPeriodValue() {
        return this._list.reduce((sums, dist) => {
            if (!sums[dist.period.value]) sums[dist.period.value] = 0
            sums[dist.period.value] += dist.amount
            return round(sums)
        }, {})
    }
    get previousYearDistribution() {
        const previousYear = moment().subtract(1, 'year').year()
        return this.getdistributionsByYear(previousYear)
    }
    get currentYearDistribution() {
        const currentYear = moment().year() 
        return this.getdistributionsByYear(currentYear)
    }

    longDescByAccount(code) {
        return this._list.filter(el => el.acc.key === code ).reduce((text, add) =>  text += `${add.period.longDesc} & `, '').slice(0, -3)
    }

    sumByAccount(code) {
        return sum(this._list.filter(d => d.acc.key === code), 'amount')
    }
    balanceByAccount(code) {
        return sum(this._list.filter(d => d.acc.key === code), 'balance')
    }

    getDistribution(period) {
        const accDistr = new AccountDistribution(this._list)
        Account.values.filter(acc => !accDistr.find(dis => dis.acc.key === acc.key)).forEach(acc => accDistr.pushNew({acc: acc.key, period: period.value}))
        return accDistr
    }

    getDistributionForPeriod(period) {
        const accDistr = new AccountDistribution(this._list.filter(d => period.isSame(d.period)))
        Account.values.filter(acc => !accDistr.find(dis => dis.acc.key === acc.key)).forEach(acc => accDistr.pushNew({acc: acc.key, period: period.value}))
        return accDistr
    }
    getdistributionsByYear(year) { return new AccountDistribution(group(this._list, 'period.year')[year]) }

    setRemaining(amount) {
        if(this.length === 0) this.pushNew({amount: amount})
        else if (this.total !== amount) this.last.amount = round(amount - this.total + this.last.amount)
    }
}

const { AMOUNT, STRING, PERIOD } = Definition.types
AccountDistribution.definitions = {
    referenceText: { transient: true, type: STRING, text: 'Reference'},
    type: { transient: true, type: STRING, text: 'Type'},
    longDesc: { abstract: true, type: STRING, text: 'Distribution'},
    longDescByPeriod: { abstract: true, type: STRING, text: 'Distribution'},
    longDescContribAndSolvency: { abstract: true, type: STRING, text: 'Distribution'},
    eeAmount: { abstract: true, type: AMOUNT, text: 'Employee'},
    erAmount: { abstract: true, type: AMOUNT, text: 'Employer'},
    volAmount: { abstract: true, type: AMOUNT, text: 'Voluntary'},
    solAmount: { abstract: true, type: AMOUNT, text: 'Solvency'},
    total: { abstract: true, type: AMOUNT, text: 'Total'},
    eeBalance: { abstract: true, type: AMOUNT, text: 'Bal Employee'},
    erBalance: { abstract: true, type: AMOUNT, text: 'Bal Employer'},
    volBalance: { abstract: true, type: AMOUNT, text: 'Bal Voluntary'},
    solBalance: { abstract: true, type: AMOUNT, text: 'Bal Solvency'},
    totBalance: { abstract: true, type: AMOUNT, text: 'Balance'},

}

AccountDistribution.ref = class Item extends Ref {
    static definitions = {
        acc: { ref: Account, text: 'Account', default: Account.types.ER },
        period: { type: PERIOD, text: 'Period' },
        amount: { type: AMOUNT, text: 'Amount'},
        
        balance: { transient: true, type: AMOUNT, text: 'Balance'}
    }
    static key = ['acc.key', 'period']
}

AccountDistribution.text = {
    credit: {en: 'Credit', fr: 'Credit'},
    debit: {en: 'Debit', fr: 'Debit'},
    solvency: {en: 'Solvency', fr: 'Solvency'},
    contrib: {en: 'Contribution', fr: 'Contribution'},
}