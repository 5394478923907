/* eslint-disable import/no-anonymous-default-export */
import React, {useEffect} from 'react';
import { Button, EInput } from '../../framework/components';
import { Form } from 'react-bootstrap';

export default ({onEnter, instance, property, description, options = [], existingFilters, className='', label, hideApplyButton, noWidth, focusOnMount=true, ...rest }) => {

    const sortedOptions = options.sort((a, b) => a.text.localeCompare(b.text))
        .filter(option => !existingFilters[property]?.find(x => x.value === option.value))

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            onEnter();
        }
    };

    const handleApply = () => {
        onEnter();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEnter);
        return () => {
            document.removeEventListener('keydown', handleEnter);
        };
    }, []);

    return (
        <div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' , gap: 10, width: noWidth ? "auto" : "100%"}}>
            <EInput
                focusOnMount={focusOnMount}
                name={property}
                instance={instance}
                options={sortedOptions}
                className={className}
                label={label}
                {...rest}
            />
            {!hideApplyButton && <Button className={'btn btn-secondary btn-min no-mar'} onClick={handleApply}>Apply</Button>}
        </div>
        {description && <div style={{whiteSpace: "pre-line", marginTop: "12px"}}><Form.Text>{description}</Form.Text></div>}
        </div>
    );
}