import React from 'react'
import { Component, EInput, Form} from '../../../../framework/components'
import { FieldSet, Row } from '../../../../framework/containers'
import { sum } from '../../../../framework/utils/helper'
import { Container, Form as TextForm } from 'react-bootstrap'
import { Adjustment, AdjustmentType, Earning, EarningType } from '../../../../entities'
import { AdjustmentService, ParticipationService, RemittanceDetailService } from '../../../../services'
import { Text } from '../../../../framework/components/eComp/inputs'
import moment from 'moment/moment'

export default class MidMonthAdjustment extends Component {
	load(){ 
		const { remittanceDetail } = this.props
		const codes = [];
		['REG', 'OTE', 'OVR', 'NPE'].forEach(type => {
			var earningType = Object.assign(new EarningType(), remittanceDetail.employment.employer.earningTypes.find(earning => earning.category.key === type && earning.alias === earning.getHayesCode()));
			const earning = new Earning({earningType: earningType, code: earningType.code})
			if(earningType){
				codes.push({
					earning,
					totalEarning : sum(remittanceDetail.earnings.filter(earning => earning.earningType.category.key === type),'amount'),
					originalTotalEarning : sum(remittanceDetail.earnings.filter(earning => earning.earningType.category.key === type),'amount'),
					totalHours : sum(remittanceDetail.earnings.filter(earning => earning.earningType.category.key === type),'hours'),
					originalHours : sum(remittanceDetail.earnings.filter(earning => earning.earningType.category.key === type),'hours')  
				})
			}
		})
		return {remittanceDetail: remittanceDetail, earnings: codes}
	}

	view() {
		const { remittanceDetail } = this.props
		const { earnings } = this.state

		return <div className="modal-component-body">
			<Form className='h-100 w-80' bypassDirty={true} data={remittanceDetail} onSave={this.handleSave.bind(this)} onCancel={this.handleCancel.bind(this)} onChange={this.handleChange.bind(this)} validate={this.validate.bind(this)} ref={(ref) => this._form = ref}>
				<Row className='input-spacing-3 mb-3'>
					<EInput name='name' instance={remittanceDetail.person} readOnly />
					<EInput name='sin' instance={remittanceDetail.person} readOnly />
					<EInput name='periodText' instance={remittanceDetail} readOnly />
				</Row>
				<Container>{earnings.map(code => {
					return <FieldSet title={code.earning.earningType.category.desc} variant='field' className='mr-3'>	
						<Row>
							<div>
								<TextForm.Label>Total Earnings</TextForm.Label>
								<TextForm.Control value={code.totalEarning} readOnly />
							</div>
							{ code.earning.earningType.category.isHourly() &&
							<div class="ml-3">
								<TextForm.Label>Total Hours</TextForm.Label>
								<TextForm.Control value={code.totalHours} readOnly />
							</div> }
						</Row>

						<Row className='input-spacing-3 mb-3'>
							<EInput name='label' instance={code.earning.earningType} readOnly/>
							<div>
								<TextForm.Label>Earning</TextForm.Label>
								<Text realName={"amount"} instance={code.earning} readOnly={!code.earning.earningType.category.isPensionable()} onChange={this.handleEarningChange.bind(this, code)}/>
							</div>
							{code.earning.earningType.category.isHourly() && <EInput name={"hours"} label="Hours" instance={code.earning} onChange={this.handleEarningChange.bind(this, code)}/>}
						</Row>
					</FieldSet>
				})}</Container>
			</Form>
		</div>
	}


	handleEarningChange(selectedEarning){
		const selectEarnAmount = Number(selectedEarning.earning.amount)
		const selectEarnHour = Number(selectedEarning.earning.hours)
		selectedEarning.totalEarning = selectedEarning.originalTotalEarning < selectEarnAmount ?  selectEarnAmount :  selectedEarning.originalTotalEarning - selectEarnAmount
		selectedEarning.totalHours =  selectedEarning.originalHours < selectEarnHour ? selectEarnHour : selectedEarning.originalHours - selectEarnHour;
		
		//update Hayes non-pensionable bucket to update NPE amount
		const nonPensionableBucket = this.state.earnings.find(typeEarning => !typeEarning.earning.earningType.category.isPensionable())
		nonPensionableBucket.earning.amount = sum(this.state.earnings.filter(typeEarning => typeEarning.earning.earningType.category.isPensionable() && Number(typeEarning.earning.amount) < typeEarning.originalTotalEarning).map(earn => ({amount : earn.originalTotalEarning - earn.earning.amount})), 'amount')	
		nonPensionableBucket.totalEarning = nonPensionableBucket.earning.amount;
		
		this.setState({touched: true})
	}

	handleChange() {
		this._form.forceDirty()
	}
	handleCancel() {
		if (this.props.onCancel) this.props.onCancel()
	}
	handleSave() {
		//save the AS-Hayes earnings in the open remittance detail
		const newEarnings = this.state.earnings.filter(typeEarning => typeEarning.earning.amount).map(typeEarning => {
			return typeEarning.earning
		})
		const remDet = this.props.remittanceDetail

		const effDt = this.props.type === 'MID' ? remDet.employment.participation.joinDt : remDet.employment.participation.events.find(ev => ev.config.isRetirementEvent)?.effDt;
		const endEffDt = this.props.type === 'MID' ?   remDet.remittance.period.dateEndPeriod: remDet.remittance.period.date;
		const adjustment = new Adjustment({
			employer: remDet.employment.employer,
			remittance: remDet.remittance.keyValue,
			participation: remDet.employment.participation.keyValue,
			type: AdjustmentType.types[this.props.type],
			category: 'EARN',
			distributionEarning: newEarnings,
			effDate: effDt,
			endEffDate: endEffDt,
		})
		this.props.remittanceDetail.adjustments.push(adjustment)
		this.props.remittanceDetail.adjustmentEarnings.add(newEarnings)
		this.props.remittanceDetail.employment.participation.addEvent({code: 'midMonthAdj', ets: moment(adjustment.effDate).valueOf()})

		AdjustmentService.update(adjustment).then(() => {
			ParticipationService.updateParticipation(this.props.remittanceDetail.employment.participation).then(() => {
				const promise = () => RemittanceDetailService.update(this.props.remittanceDetail);
				if (this.props.onSave) this.props.onSave(this.props.remittanceDetail, promise);
			})
		})
		
	}

	validate() {
		var errorMsg = ""
		//Commenting out but may be temporary
		// const earnings = this.state.earnings.filter(earns => Number(earns.earning.amount) !== 0 && !(earns.totalEarning === 0 && earns.originalTotalEarning === 0));
		// if(earnings.length === 0){
		// 	errorMsg = 'Please enter information for the earnings'
		// }
        if (errorMsg !== this.state.errorMessage) {
            this.setState({ errorMessage: errorMsg })
        }
		return errorMsg
    }
}
