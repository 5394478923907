import React from "react"
import ConditionalWrapper from "../../components/containers/ConditionalWrapper"
import SuperAdminGuard from "../../guards/SuperAdminGuard"
import OpenClose from "../controls/OpenClose"

export default class Menu extends React.Component {
    static cn = 'menu'
    constructor(props) {
        super(props)
        this.state = {active: this.props.active, menu: this.props.menu}
    }
    render() {
        const { variant, className } = this.props 
        
        return <div className={['menu', 'menu-' + variant, className].join(' ')}>
            {this.state.menu.map((menuItem ,index)=> {
                if(!menuItem) return;
                return <ConditionalWrapper key={'cwrapper' + index} condition={menuItem.superOnly} wrapper={SuperAdminGuard}>
                    <div key={index}>
                        <div className="text-menu">
                            {variant === 'sidebar' && <OpenClose key={menuItem.key} initial={menuItem.open} text={menuItem.text.toUpperCase()} onClick={this.toggleMenu.bind(this, menuItem)} variant='arrow'/>}
                            {variant === 'line' && <div className='line-item' key={menuItem.key} onClick={this.handleClickMenu.bind(this, menuItem)}>{menuItem.text.toUpperCase()}</div>}
                        </div>
                        {menuItem.subMenu && menuItem.open && <div className="menu-subMenu">
                            {menuItem.subMenu.map((childItem, index) => {
                                return <div key={childItem.key + index} className={[this.isActiveChild(childItem.key) && 'active', 'menu-subMenu-title'].join(' ')} onClick={this.handleClickMenu.bind(this, childItem)}>{childItem.text}</div>
                            })}
                        </div>}
                    </div> 
                </ConditionalWrapper> 
            })}
        </div>
    }

    toggleMenu(menuItem) {
        const menu = this.state.menu
        const toggled = menu.find(line => line.key === menuItem.key)
        toggled.open = !menuItem.open
        this.setState({menu: menu, active: menuItem})
    }
    isActiveChild(key) { 
        return this.state.active && this.state.active.key === key 
    }
    handleClickMenu(item) {
        this.setState({active: item})
        if (this.props.onSelect) this.props.onSelect(item)
    }
}