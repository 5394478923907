import React from "react"
import Button from './Button'

export default class MenuDropdown extends React.Component {
    constructor(props) {
		super(props)
		this.state = {showSubMenu: ''}
	}
	
	render() {
		const { name, text, options } = this.props
        return <div className='v-center'>
            <Button name={name + '-btn'} type="link" className='text-primary' onClick={this.swap.bind(this)} onMouseLeave={this.handleMenuMouseOut.bind(this)} >{text}</Button>
            <div className={this.state.showSubMenu + 'dropdown-menu'} onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseOut.bind(this)}>
                {options.map(opt => <a name={opt.key} ref="#" className="dropdown-item" onClick={this.handleSelect.bind(this)}>{opt.text}</a>)}
            </div>
        </div>
	}
	
	swap() {this.setState({showSubMenu: this.state.showSubMenu ? '' : 'show '})}
    handleMouseOut(e) {this.setState({showSubMenu: '', mouseIn: false})}
	handleMouseEnter(e) { this.setState({mouseIn: true}) }
	handleMenuMouseOut(e) {
		const that = this
		setTimeout(function(){
			if (that.state.showSubMenu && !that.state.mouseIn) that.setState({showSubMenu: '', mouseIn: false})
		}, 200);
	}
	
	handleSelect(e) {
		this.setState({showSubMenu: ''})
		if(this.props.onSelected) this.props.onSelected(e.currentTarget.name, this.props.name)
	}
}