import { Service } from '../../framework'
import { Beneficiaries, Beneficiary, Person } from "../../entities"
import BeneficiaryMapper from '../../mappers/BeneficiaryMapper';
import { isString } from 'lodash';
import { PersonService } from '..';
import UnknownPerson from '../../entities/person/UnknownPerson';

class BeneficiaryService extends Service {
    constructor() {
        super(Beneficiary, 'Beneficiary', 'beneficiary')
    }

    GET_ACTION = 'GetBeneficiaries';

    async get(personId, options) {
        if (!personId) return;
        return await this.callApi(this.GET_ACTION, this.getActionKey(personId), { key: personId }, BeneficiaryMapper, options);
    }

    getActionKey(personId) { return this.GET_ACTION + '_' + personId }

    async delete(beneficiary, options) {
        return await this.deleteBy('Delete' + this.serviceName, {key: beneficiary.key}, options);
    }

    async load(beneficiary, options) {
        if (!beneficiary.person || !isString(beneficiary.person.id) || beneficiary.person.id === '') return beneficiary;
        const person = await PersonService.get(beneficiary.person.id, {...options, load: false});
        if (person) beneficiary.person = person;
        return beneficiary;
    } 
    
    preSave(beneficiary) {
        beneficiary.touch();
        if (beneficiary.noSin) beneficiary.person = new Person();
        else beneficiary.unknownPerson = new UnknownPerson();
        
        return beneficiary;
    }

    validate(beneficiary) {
        var errorMessages = {};
        if (!beneficiary.isLegacy) {
            if (!beneficiary.noSin && !beneficiary.person.id) errorMessages['sin']= 'A person is required.';
            if (beneficiary.shareOfProceeds === '') errorMessages['shareOfProceeds']= 'A share is required.';
            else if (Number(beneficiary.shareOfProceeds) > 1 || Number(beneficiary.shareOfProceeds) <= 0) {
                errorMessages['shareOfProceeds']= 'Share % must be between 0 and 1';
            }    
        }
        if (beneficiary.designationReceivedDate === '') errorMessages['designationReceivedDate']= 'A designation date is required.';

        return errorMessages;
    }

    //bulk load requires caching spouseHistories per person
    async customCache(data) {
        let people = {}
        for (let item of data) {
            let beneficiaries = people[item.parent] ?? [];
            beneficiaries.push(item);
            people[item.parent] = beneficiaries;
        }
        Promise.all(Object.keys(people).map(async (personId) => {
            return this.useCache(this.getActionKey(personId), () => Promise.resolve(new Beneficiaries(people[personId])), true);
        }))
    }
}

const instance = new BeneficiaryService()
export default instance