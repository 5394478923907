import React from 'react'
import Control from './Control'

export default class OpenClose extends Control {
    constructor(props) {
		super(props)
		this.state = {open: this.props.initial}
	}
    render () {
        const { text, key, variant, className } = this.props

        const variantClassName = variant === 'arrow' && (this.state.open ? 'icon-noun-dropdown-up' : 'icon-noun-dropdown') + ' collapse-icon v-center' //TODO include button classname
        
        return  <div className={['row', className].join(' ')}>
            <div onClick={this.handleClick.bind(this)} className={variantClassName}/>
            <div key={key} className='line-item pl-2'>{text}</div>
        </div> 
       
    }
    handleClick(val) {
        this.setState({open: !this.state.open})
        if(this.props.onClick) this.props.onClick()
    }
}