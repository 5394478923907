import { Field } from 'formik';
import { Form } from 'react-bootstrap'

const Checkbox = ({
    ...rest
}) => {
    
    return <Field as={Form.Check}
                type="checkbox" 
                {...rest}
            />
}

export default Checkbox;
