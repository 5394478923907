import { Ref, Definition }  from '../../framework/infra'
import { concatStr } from '../../framework/utils/helper'

export default class Address extends Ref{
    get desc() {
        return !this.isEmpty() ? concatStr(this.civic, ', ') + concatStr(this.poBox ? 'P.O Box '+ this.poBox : '', ', ') + concatStr(this.city, ', ') + concatStr(this.prov, ', ') + concatStr(this.pc,', ') + concatStr(this.country) : '';
    }

    isEmpty() {
        return !(this.civic || this.poBox || this.city || this.prov);
    }

    removeSuggestions(v) {
        delete Address.definitions[v].suggestions
    }
}
// see schema in src/model/membership/Participation.js in Services
Address.definitions = {
    desc: {  //TODO handle in parent
        abstract: true,
        type: Definition.types.STRING,
        text: 'Address'
    },
    usage: { type: Definition.types.CHOICE, options: [
            { key: 'home', text: 'Home', text_fr: 'Maison'},
            { key: 'work', text: 'Work', text_fr: 'Travail'},
            { key: 'other', text: 'Other', text_fr: 'Autre'}
        ]
    },
    civic: { type: Definition.types.STRING, text: 'Civic',  text_fr: 'Civic' },
    poBox: { type: Definition.types.STRING, text: 'P.O. Box', text_fr: 'Casier Postal' },
    city: {
        type: Definition.types.STRING,
        suggestions: [
            { key: 'Amos', text: 'Amos', text_fr: 'Amos'},
            { key: 'Chapais', text: 'Chapais', text_fr: 'Chapais'},
            { key: 'Chibougamau', text: 'Chibougamau', text_fr: 'Chibougamau'},
            { key: 'Chisasibi', text: 'Chisasibi', text_fr: 'Chisasibi'},
            { key: 'Eastmain', text: 'Eastmain', text_fr: 'Eastmain'},
            { key: 'Gatineau', text: 'Gatineau', text_fr: 'Gatineau'},
            { key: 'Kawawachikamach', text: 'Kawawachikamach', text_fr: 'Kawawachikamach'},
            { key: 'Laval', text: 'Laval', text_fr: 'Laval'},
            { key: 'Mistissini', text: 'Mistissini', text_fr: 'Mistissini'},
            { key: 'Montreal', text: 'Montreal', text_fr: 'Montreal'},
            { key: 'Nemaska', text: 'Nemaska', text_fr: 'Nemaska'},
            { key: 'Ouje-Bougoumou', text: 'Ouje-Bougoumou', text_fr: 'Ouje-Bougoumou'},
            { key: 'Quebec', text: 'Quebec', text_fr: 'Quebec'},
            { key: 'Rouyn-Noranda', text: 'Rouyn-Noranda', text_fr: 'Rouyn-Noranda'},
            { key: 'Schefferville', text: 'Schefferville', text_fr: 'Schefferville'},
            { key: 'Timmins', text: 'Timmins', text_fr: 'Timmins'},
            { key: 'Val D\'Or', text: 'Val D\'Or', text_fr: 'Val D\'Or'},
            { key: 'Waskaganish', text: 'Waskaganish', text_fr: 'Waskaganish'},
            { key: 'Waswanipi', text: 'Waswanipi', text_fr: 'Waswanipi'},
            { key: 'Wemindji', text: 'Wemindji', text_fr: 'Wemindji'},
            { key: 'Whapmagoostui', text: 'Whapmagoostui', text_fr: 'Whapmagoostui'},
        ],
        text: 'City', 
        text_fr: 'Ville'
    },
    prov: { type: Definition.types.STRING, text: 'Province', text_fr: 'Province',
        suggestions: [
            { key: 'Alberta', text: 'Alberta', text_fr: 'Alberta'},
            { key: 'British Columbia', text: 'British Columbia', text_fr: 'British Columbia'},
            { key: 'Manitoba', text: 'Manitoba', text_fr: 'Manitoba'},
            { key: 'New Brunswick', text: 'New Brunswick', text_fr: 'New Brunswick'},
            { key: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador', text_fr: 'Newfoundland and Labrador'},
            { key: 'Nova Scotia', text: 'Nova Scotia', text_fr: 'Nova Scotia'},
            { key: 'Ontario', text: 'Ontario', text_fr: 'Ontario'},
            { key: 'Prince Edward Island', text: 'Prince Edward Island', text_fr: 'Prince Edward Island'},
            { key: 'Quebec', text: 'Quebec', text_fr: 'Quebec'},
            { key: 'Saskatchewan', text: 'Saskatchewan', text_fr: 'Saskatchewan'},
            { key: 'Northwest Territories', text: 'Northwest Territories', text_fr: 'Northwest Territories'},
            { key: 'Nunavut', text: 'Nunavut', text_fr: 'Nunavut'},
            { key: 'Yukon', text: 'Yukon', text_fr: 'Yukon'},
        ]
    },
    country: { type: Definition.types.STRING, text: 'Country', text_fr: 'Pays', default: 'Canada' },
    pc: { type: Definition.types.STRING, text: 'Postal Code', text_fr: 'Code Postal',
        suggestions: [
            { key: 'J0M 1E0', text: 'J0M 1E0', text_fr: 'J0M 1E0'},
            { key: 'J0M 1W0', text: 'J0M 1W0', text_fr: 'J0M 1W0'},
            { key: 'G0G 2Z0', text: 'G0G 2Z0', text_fr: 'G0G 2Z0'},
            { key: 'G0W 1C0', text: 'G0W 1C0', text_fr: 'G0W 1C0'},
            { key: 'J0Y 3B0', text: 'J0Y 3B0', text_fr: 'J0Y 3B0'},
            { key: 'G0W 3C0', text: 'G0W 3C0', text_fr: 'G0W 3C0'},
            { key: 'J0M 1R0', text: 'J0M 1R0', text_fr: 'J0M 1R0'},
            { key: 'J0Y 3C0', text: 'J0Y 3C0', text_fr: 'J0Y 3C0'},
            { key: 'J0M 1L0', text: 'J0M 1L0', text_fr: 'J0M 1L0'},
            { key: 'J0M 1G0', text: 'J0M 1G0', text_fr: 'J0M 1G0'},
            { key: 'G8P 2K7', text: 'G8P 2K7', text_fr: 'G8P 2K7'},
        ]
    }
}
            
//    Address.triggers = {
//     valueChange: {
//         city: {
//             'Chisasibi': {
//                 province: 'Quebec',
//                 postalCode: 'J0M 1E0'
//             },
//             'Eastmain': {
//                 province: 'Quebec',
//                 postalCode: 'J0M 1W0'
//             },
//             'Kawawachikamach': {
//                 province: 'Quebec',
//                 postalCode: 'G0G 2Z0'
//             },
//             'Mistissini': {
//                 province: 'Quebec',
//                 postalCode: 'G0W 1C0'
//             },
//             'Nemaska': {
//                 province: 'Quebec',
//                 postalCode: 'J0Y 3B0'
//             },
//             'Ouje-Bougoumou': {
//                 province: 'Quebec',
//                 postalCode: 'G0W 3C0'
//             },
//             'Waskaganish': {
//                 province: 'Quebec',
//                 postalCode: 'J0M 1R0'
//             },
//             'Waswanipi': {
//                 province: 'Quebec',
//                 postalCode: 'J0Y 3C0'
//             },
//             'Wemindji': {
//                 province: 'Quebec',
//                 postalCode: 'J0M 1L0'
//             },
//             'Whapmagoostui': {
//                 province: 'Quebec',
//                 postalCode: 'J0M 1G0'
//             }
//         }
//     }
// }
