import { Definition } from '../../../framework/infra'
import { moment } from '../../../framework/utils/helper'
import { RemittanceService } from '../../../services'
import { AccountDistribution, Payment } from '../../../entities'
import ReportLayout from '../../../framework/components/page/ReportLayout'

const DATE = Definition.types.DATE

export default class RBCPaymentsContribsReport extends ReportLayout {
    reportDesc = 'RBC Payments and Contributions'
    entity= Payment
    subEntity = AccountDistribution
    headers = ['rcvDate', 'distribution.previousYearDistribution', 'distribution.currentYearDistribution', 'distribution', 'no', 'sentDate']
    groupHeaders = ['distribution.previousYearDistribution', 'distribution.currentYearDistribution', 'distribution']
    subHeaders = ['eeAmount', 'volAmount', 'erAmount', 'solAmount', 'total']
    headerProps = {  
        'distribution.previousYearDistribution': {title: `Previous Year - ${moment().subtract(1, 'year').year()}`},
        'distribution.currentYearDistribution': {title: `Current Year - ${moment().year()}`},
        'distribution': {title: 'Total Payment'}
    }
    tableSort = 'rcvDate'
    filters = {}
    params = {
        from: {definition: {type: Definition.types.DATE, text: "From", default: moment().subtract(3, 'months').format('YYYY-MM-DD')}},
        to: {definition: { type: Definition.types.DATE, text: "To", default: moment().format('YYYY-MM-DD')}}
    }
    
    execQuery(queryParams) {
        return RemittanceService.getEmployerRemittances(this.props.params.employer.id).then(remittances => {
            const remittancePayments = remittances.map(rem => rem.payments)
            //ask --> should we get nonRejectedPayments??
            const payments = remittancePayments.reduce((paymentList, payments) => {
                payments.forEach(payment => paymentList.push(payment))
                return paymentList
            }, [])
            return payments.filter(payment => DATE.isInRange(payment.rcvDate, queryParams.from, queryParams.to))
        })
    }
}
