// Definitions:
//  - group: Some keys are for earnings only or contribution only or even for both. They are cont by default
//  - isHidden: Will be hidden from adjustment form type dropdown

export const group = {
    EARN: 'EARN',
    CONT: 'CONT'
};

export const adjustmentsTypeConfigs = {
    default: {
        group: [group.CONT]
    },
    MAN: {
        group: [group.CONT, group.EARN]
    },
    DEEM: {
        group: [group.CONT, group.EARN],
        isHidden: true,
    },
    MID: {
        group: [group.EARN],
        isHidden: true,
    },
    TER: {
        group: [group.EARN]
    },
    OPM: { 
        group: [group.CONT], 
        invoiceDescription: "Other",
        isOverpayment: true,
        warningMessage: "This type of adjustment will not have any credits applied to it!",
    },
    MECH: {
        group: [group.CONT], 
        targetAccounts: ['r', 'i'],
        isCredit: true,
        isStartCredit: true,
        isMandatoryCredit: true,
        priority: 1,
        warningMessage: "This adjustment type will create a new adjustment in the next month if there is a remaining credit amount!",
    }, 
    MECHR: {
        targetAccounts: ['r', 'i'],
        isMandatoryCredit: true,
        isCredit: true,
        isHidden: true,
        priority: 1,
        invoiceDescription: "Mandatory Employer Contribution Holiday",
    },
    MECHC: {
        group: [group.CONT], 
        targetAccounts: ['r', 'i'],
        isCredit: true,
        isCancelUsedCredit: true,
        isMandatoryCredit: true,
        priority: 1,
        warningMessage: "This adjustment type will cancel the Used Mandatory Employer Contribution Holiday!",
    },
    RCR: {
        group: [group.CONT], 
        targetAccounts: ['r', 'i'],
        isStartCredit: true,
        isCredit: true, 
        priority: 2,        
        warningMessage: "This adjustment type will create a new adjustment in the next month if there is a remaining credit amount!",
    },
    RCRR: {
        targetAccounts: ['r', 'i'],
        isCredit: true, 
        isHidden: true, 
        priority: 2,        
        invoiceDescription: "Retro Employer Contribution Adjustment due to Rate Change",
    },
    RCRC: {
        group: [group.CONT], 
        targetAccounts: ['r', 'i'],
        isCancelUsedCredit: true,
        isCredit: true, 
        priority: 2,        
        warningMessage: "This adjustment type will cancel the Used Retro Employer Contribution Adjustment due to Rate Change!",
    },
    SOC: {
        group: [group.CONT], 
        targetAccounts: ['s', 'r', 'i'],
        isStartCredit: true,
        isCredit: true,
        priority: 3,
        warningMessage: "This adjustment type will create a new adjustment in the next month if there is a remaining credit amount!",
    },
    SOCR: {
        targetAccounts: ['s', 'r', 'i'],
        isCredit: true,
        isHidden: true,
        priority: 3,
        invoiceDescription: "Solvency Credit",
    },
    INI: { isHidden: true },
    C19: { isHidden: true },
    ERC: { isHidden: true },
    ERL: { isHidden: true },
    RSC: { isHidden: true },
    EFE: { isHidden: true },
    EER: { isHidden: true },
    ESC: { isHidden: true },
    ESP: { isHidden: true },
    RNE: { isHidden: true },
    EOP: { isHidden: true },
    CQC: { isHidden: true },
    REI: { isHidden: true },
    TER: { isHidden: true },
    A60: { isHidden: true },
}