import { Ref, Definition } from '../../../framework/infra'
import { round } from '../../../framework/utils/helper'

import RefundBreakdownType from './RefundBreakdownType'

export default class RefundBreakdown extends Ref {
    get total() { return round(this.principleAmount * (1 + (Number(this.interestRate) * this.elapsedDays) / 365)) }

    static definitions = {
        principleAmount: { type: Definition.types.AMOUNT, text: 'Principle Amount', text_fr: '' },
        interestRate: { type: Definition.types.PERCENTAGE, text: 'Interest Rate', text_fr: '' },
        type: { ref: RefundBreakdownType, text: 'Refund Breakdown Type' },

        total: { abstract: true, type: Definition.types.AMOUNT, text: 'Total', text_fr: '' },
        elapsedDays: { transient: true, text: 'Elapsed Days', type: Definition.types.NUMBER, inherited: true }
    }
}
