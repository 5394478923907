import React, { useEffect } from 'react'
import { Table  } from '../../../framework/controls'
import { Excel } from '../../../framework/utils'
import { Adjustment, Distribution } from '../../../entities'

const AdjustmentList = (props) => {

    var { adjustments, onSelect, activeTab = 'contribution' } = props;
    const select = (row) =>  { if (onSelect) onSelect(row) }
    var filteredAdjustments = adjustments.filter(adjustment => {
        return adjustment.category === (activeTab === 'contribution' ? 'CONT':'EARN')
    } );

    return (activeTab === 'contribution') ?
            <ContributionAdjustmentTable 
                select={select}
                filteredAdjustments={filteredAdjustments}
                {...props}
            />
            : <EarningAdjustmentTable
                select={select} 
                filteredAdjustments={filteredAdjustments}
                {...props}
            />
}

const ContributionAdjustmentTable = ({setExportFunction, filteredAdjustments, variant, select, selected, className}) => {

    const download = () => {
        const excelHeaders = new Excel.Headers(Adjustment, 'type.desc, period, targetPeriod, remittance.employer.code, employment.person.name, distributionContribution.e.am, distributionContribution.em.am, distributionContribution.es.am, distributionContribution.el.am, distributionContribution.ev.am, distributionContribution.r.am, distributionContribution.s.am, distributionContribution.i.am, total, effDate, endEffDate, cmt')
        excelHeaders.list.forEach(header => {
            if (['distributionContribution.e.am', 'distributionContribution.ei.am', 'distributionContribution.em.am', 'distributionContribution.es.am', 'distributionContribution.el.am', 'distributionContribution.ev.am', 'distributionContribution.r.am', 'distributionContribution.s.am', 'distributionContribution.i.am'].includes(header.name)) header.title = Distribution.getOptionLabel('ta', header.name.split('.')[1])
        })
        const excel = new Excel('Remittance-adjustments')
        excel.addSheet(excelHeaders.list, filteredAdjustments)
        excel.download()
    }

    useEffect(() => {
        let isMounted = true;

        if(setExportFunction && isMounted)
            setExportFunction(() => download)

        return () => { isMounted = false };
    },[]);

    var columns = new Table.Headers(Adjustment, 'type.desc, period, targetPeriod, remittance.employer.code, employment.person.name, distributionContribution.e.am, distributionContribution.ei.am, distributionContribution.em.am, distributionContribution.es.am, distributionContribution.el.am, distributionContribution.ev.am, distributionContribution.r.am, distributionContribution.s.am, distributionContribution.i.am, total, effDate, endEffDate, cmt')
    const colsToHideIfEmpty = ['distributionContribution.e.am', 'distributionContribution.ei.am', 'distributionContribution.em.am', 'distributionContribution.es.am', 'distributionContribution.el.am', 'distributionContribution.ev.am', 'distributionContribution.r.am', 'distributionContribution.s.am', 'distributionContribution.i.am']
    colsToHideIfEmpty.forEach(col => {
        columns[col].hideIfEmpty = true
        columns[col].title = Distribution.getOptionLabel('ta', col.split('.')[1])
    })

    columns = alterColumns(columns, variant);

    return <Table 
        id='employer-adjustments-table' 
        data={filteredAdjustments} 
        entity={Adjustment} 
        columns={columns} 
        sort='rcvDate' 
        desc 
        dataKey='no' 
        selected={selected && selected.no} 
        className={className || 'header-no-gap h-90'}
        onSelect={select} 
    />

}
const EarningAdjustmentTable = ({setExportFunction, filteredAdjustments, variant, select, selected, className}) => {

    const download = () => {
        const excelHeaders = new Excel.Headers(Adjustment, 'type.desc, period, targetPeriod, remittance.employer.code, employment.person.name, distributionEarning.regular, distributionEarning.regularHours, distributionEarning.overtime, distributionEarning.overtimeHours,distributionEarning.oneTime,distributionEarning.nonPensionable, distributionEarning.total, distributionEarning.hours, effDate, endEffDate, cmt')
        const excel = new Excel('Remittance-adjustments')
        excel.addSheet(excelHeaders.list, filteredAdjustments)
        excel.download()
    }

    useEffect(() => {
        let isMounted = true;

        if(setExportFunction && isMounted)
            setExportFunction(() => download)
        
        return () => { isMounted = false };
    },[]);

    var columns = new Table.Headers(Adjustment, 'type.desc, period, targetPeriod, remittance.employer.code, employment.person.name, distributionEarning.regular, distributionEarning.regularHours, distributionEarning.overtime, distributionEarning.overtimeHours,distributionEarning.oneTime,distributionEarning.nonPensionable, distributionEarning.total, distributionEarning.hours, effDate, endEffDate, cmt')
    const colsToHideIfEmpty = ['distributionEarning.regular', 'distributionEarning.regularHours', 'distributionEarning.overtime', 'distributionEarning.overtimeHours', 'distributionEarning.oneTime','distributionEarning.nonPensionable']

    colsToHideIfEmpty.forEach(col => columns[col].hideIfEmpty = true)

    columns = alterColumns(columns, variant);

    return (
        <Table
            id="employer-adjustments-table"
            data={filteredAdjustments}
            entity={Adjustment}
            columns={columns}
            sort="rcvDate"
            desc
            dataKey="no"
            selected={selected && selected.no}
            className={className || "header-no-gap h-90"}
            onSelect={select}
        />
    );
}
const alterColumns = (columns, variant) => {

    columns['type.desc'].minWidth = 146
    columns['period'].minWidth = 90
    columns['targetPeriod'].minWidth = 90
    columns['employment.person.name'].minWidth = 150
    columns['effDate'].minWidth = 118;
    columns['endEffDate'].minWidth = 118;
    columns['cmt'].minWidth = 190;
    columns['period'].headerFilter = true
    columns['remittance.employer.code'].headerFilter = true
    columns['type.desc'].headerFilter = true

    if (variant === 'monoEmployer') {
        delete columns['remittance.employer.code']
    }

    return columns;
}

export default AdjustmentList;