import React from 'react'
import { Form } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/fr'
import { Lng } from '../../infra'
import config from '../../../utils/config'

moment.locale(Lng.current)
export default class LanguageSelector extends React.Component {

    handleLanguageChange(event) {
        const lngValue = event.target.value
        Lng.current = lngValue
        moment.locale(Lng.current)
        this.props.onContextChanged && this.props.onContextChanged({ lng: lngValue })
    }

    render() {
        if(config.modules[LanguageSelector.module]?.unreleased) return ''
        const lng = this.props.context && this.props.context.lng
        return(
            <Form.Control as="select" value={lng} onChange={this.handleLanguageChange.bind(this)}>
                {getLanguages().map(language => (
                    <option key={language.key} value={language.key}>{language.text}</option>
                ))}  
            </Form.Control>
        )
    }
}

function getLanguages() { //TODO use Definition
    return [
        { key: 'en', value: 'en', text: 'English', text_fr:'Anglais'},
        { key: 'fr', value: 'fr', text: 'French', text_fr:'Français'},
    ]
}

LanguageSelector.module = 'I18N'