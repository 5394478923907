import React from "react"
import { saveAs } from 'file-saver'
import { pdf, PDFViewer, Document as PDFDocument, Page as PDFPage, Text as PDFText, View, Image as PDFImage, Font, StyleSheet } from '@react-pdf/renderer'
import { formatAmount } from './formating'
import Lng from '../infra/Lng'


class Viewer extends React.Component {
    render() {
        return <PDFViewer className='pdf-viewer' name={this.props.fileName}>{this.props.children}</PDFViewer>
    }
}
class Document extends React.Component {
    render() {
        return <PDFDocument>{this.props.children}</PDFDocument>
    }
}
class Page extends React.Component {
    render() {
        return <PDFPage size={this.props.size || "A4"} style={toStyle('page', this.props.className, this.props.style)}>{this.props.children}</PDFPage>
    }
}
class Container extends React.Component {
    render() {
        return <View style={toStyle('container', this.props.className, this.props.style)}>{this.props.children}</View>
    }
}
class Row extends React.Component {
    render() {
        return <View style={toStyle('row', this.props.className, this.props.style)}>{this.props.children}</View>
    }
}
class Col extends React.Component {
    render() {
        return <View style={toStyle('col', this.props.className, this.props.style)}>{this.props.children}</View>
    }
}
class Separator extends React.Component {
    render() {
        return <View style={toStyle('separator', this.props.className, this.props.style)}>{this.props.children}</View>
    }
}
class Image extends React.Component {
    //HACK wrap in <Text> to be able to align with 'textAlign'
    render() {
        return <PDFText><PDFImage style={toStyle('image', this.props.className, this.props.style)} src={this.props.src}/></PDFText> 
    }
}
class Text extends React.Component {
    render() {
        return <PDFText style={toStyle('text', this.props.className, this.props.style)}>{getText(this.props.text, this.props.children, this.props.params)}</PDFText>
    }
}
class Table extends React.Component {
    render() {
        return <View style={toStyle('table', this.props.className, this.props.style)}>{this.props.children}</View>
    }
}
class Cell extends React.Component {
    render() {
        return <PDFText style={toStyle('cell', this.props.className, this.props.style)}>{getText(this.props.text, this.props.children, this.props.params)}</PDFText>
    }
}
class Label extends React.Component {
    render() {
        return <PDFText style={toStyle('label', this.props.className, this.props.style)}>{getText(this.props.text, this.props.children, this.props.params)}</PDFText>
    }
}
class Currency extends React.Component {
    render() {
        const amount = formatAmount(this.props.children, this.props.blankForZero ? '' : undefined)
        const style = Object.assign(toStyle('currency', this.props.className, this.props.style), amount[0] === '-' ?  {color:'red'} : {})
        return <PDFText style={style}>{amount[0] === '-' ? ('(' + amount.slice(1) + ')') : amount}</PDFText>
    }
}
class AmountDetail extends React.Component {
    render() {
        var { className, style, label, amount, labelWidth, blankForZero, amountStyle, text, params } = this.props
        return <View style={toStyle('row', 'space-between ' + (className || ''), style)}>
            <Text text={text} params={params} style={labelWidth ? {width: labelWidth} : {}}>{label}</Text>
            <Currency blankForZero={blankForZero} style={amountStyle}>{amount}</Currency>
        </View>
    }
}

function downloadPdf(component, fileName) {
    return pdf(component).toBlob().then(blob => {
        return saveAs(blob, fileName)
    })
}

function getText(text, defaulText = '', params = {}) {
    var res = text && text[Lng.current] ? text[Lng.current] : defaulText                                                       
    if (text) Object.getOwnPropertyNames(params).forEach(paramName => res = res.replace('{' + paramName + '}', params[paramName]))
    return res
}

export {
    Viewer,
    Document, 
    Page,
    Container,
    Row,
    Col,
    Image,
    Text,
    Table,
    Cell,
    Label,
    Currency,
    Separator,
    AmountDetail,

    View, 
    downloadPdf,
    StyleSheet,
    getText
}


function toStyle(comp, className, style) {
    return Object.assign({}, 
        styles[comp] || {},
        className ? className.split(' ').reduce((ret, cn) => Object.assign(ret, styles[cn] || {}), {}) : {},
        style || {},
    )
}


const css = {
    row: {
        display: 'flex', 
        flexDirection: 'row',

    },
    col: {
        display: 'flex', 
        flexDirection: 'column'
    },
}

const styles = StyleSheet.create({
    //Containers
    page: {
        paddingHorizontal: 20,
        paddingTop: 20,
        fontFamily: 'Roboto',
        fontSize: 9,
    },
    container: {},
    row: css.row,
    col: css.col,
    //Components
    image: {},
    text: {},
    label: {},
    separator: {
        borderTop: '1 solid #dfdfdf',
        width: '100%'
    },
    table: {
        borderTop: '1 solid black',
        borderLeft: '1 solid black',
        width: '80%'
    },
    cell: {
        borderBottom: '1 solid black',
        borderRight: '1 solid black',
        padding: '10',
      },
    //ClassNames, positioning
    "w-10": { width: '10%'},
    "w-20": { width: '20%' },
    "w-30": { width: '30%' },
    "w-40": { width: '40%' },
    "w-50": { width: '50%' },
    "w-60": { width: '60%' },
    "w-70": { width: '70%' },
    "w-80": { width: '80%' },
    "w-90": { width: '90%' },
    "w-100": { width: '100%' },
    "mt-0": { marginTop: '0' },
    "mt-1": { marginTop: '2' },
    "mt-2": { marginTop: '6' },
    "mt-3": { marginTop: '13' },
    "mt-4": { marginTop: '23' },
    "mt-5": { marginTop: '36' },
    "mt-6": { marginTop: '52' },
    "mt-7": { marginTop: '71' },
    "ml-1": { marginLeft: '2' },
    "ml-2": { marginLeft: '6' },
    "ml-3": { marginLeft: '13' },
    "ml-4": { marginLeft: '23' },
    "ml-5": { marginLeft: '36' },
    "mr-1": { marginRight: '2' },
    "mr-2": { marginRight: '6' },
    "mr-3": { marginRight: '13' },
    "mr-4": { marginRight: '23' },
    "mr-5": { marginRight: '36' },
    "mb-1": { marginBottom: '2' },
    "mb-2": { marginBottom: '6' },
    "mb-3": { marginBottom: '13' },
    "pl-2": { paddingLeft: '6' },
    "pl-3": { paddingLeft: '10' }, //check if it's okay with 13
    "pl-4": { paddingLeft: '23' },
    "pl-5": { paddingLeft: '36' },
    "pr-1" : {paddingRight: '2'},
    "pr-2" : {paddingRight: '6'},
    "pr-3" : {paddingRight: '13'},
    "pr-4" : {paddingRight: '23'},
    "pr-5" : {paddingRight: '36'},
    "pt-1" : {paddingTop: '2'},
    "pt-2" : {paddingTop: '6'},
    "left": { justifyContent: 'start' },
    "start": {alignItems: 'flex-start'},
    "end": { justifyContent: 'flex-end' },
    "right": { justifyContent: 'flex-end', textAlign: 'right' },
    "space-between": { justifyContent: 'space-between' }
})

Font.register({
    family: 'Oswald',
    src: '/fonts/oswald-regular.ttf'
})
Font.register({
    family: 'Roboto',
    src: '/fonts/roboto-regular.ttf'
})
Font.register({
    family: 'Roboto-Italic',
    src: '/fonts/roboto-italic.ttf'
})
Font.register({
    family: 'Roboto-Bold',
    src: '/fonts/roboto-bold.ttf'
})
Font.register({
    family: 'Roboto-Bold-Italic',
    src: '/fonts/roboto-bolditalic.ttf'
})
Font.register({
    family: 'Arial-Narrow-Bold',
    src: '/fonts/arial-narrowbold.ttf'
})