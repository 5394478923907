import React from 'react'
import { Component, EList } from '../../framework/components'
import { Row, Col, FieldSet } from '../../framework/containers'
import { Email, Phone } from '../../entities'

export default class CommunicationDetails extends Component {  //TODO play with classnames(Ozge)
    view() {
        const { instance, className, readOnly, label } = this.props

        return <FieldSet className={className} title={label ? label : 'Contact Details'} variant='field'>
            <Row>
                <Col className="col-5"> 
                    <EList name="emails" entity={Email} instance={instance} columns={[{name: 'email', className: 'col pl-2'}, {name: 'usage', className: 'col ml-4'}]} readOnly={readOnly} />
                </Col>
                <Col/>
                <Col className="col-5">
                    <EList name="phones" entity={Phone} instance={instance} columns={[{name: 'number', className: 'col pl-2'}, {name: 'usage', className: 'col ml-4'}]} readOnly={readOnly}/>
                </Col>
            </Row>
        </FieldSet>
    }
}