import React from 'react'
import { Component, EInput } from '../../framework/components'
import { Row, Card, Title } from '../../framework/containers'
import { Form } from '../../framework/controls'

import { RefundAccount } from '../../entities/pension/refund'

export default class ParticipationRefundForm extends Component {
	
	view() {
		const { refund } = this.props

		return<><Title  title='Refund Details' onHide={this.handleCancel.bind(this)}/>
            <Form className='h-100' id="ParticipationRefundForm" data={refund} onSave={this.handleConfirm.bind(this)} onCancel={this.handleCancel.bind(this)} onChange={this.handleChange.bind(this)} labelBtnSave='OK' ref={(ref) => this._form = ref}>
                <Card className='spacing-2'>
                    <Row><EInput name='type' instance={refund} onChange={() => this._form.forceDirty()}/></Row>
                    <Row>
                        <EInput name='principleAmount' instance={refund} />
                        <EInput name='interestRate' instance={refund} />
                    </Row>
                    <Row><EInput name='total' instance={refund} cn='col-5'/></Row>
                </Card>
            </Form>
        </> 
	}
    handleConfirm() { if(this.props.onOK) this.props.onOK() }
    handleCancel() { if(this.props.onCancel) this.props.onCancel() }
    handleAccountSelect(key, data) {
        this.props.refund.depositTo = data ? data : new RefundAccount()
        this.setState({switched: true})
        this._form.forceDirty()
    }
    handleChange() { this.setState({isDirty: true}) }   
}