import React from 'react'
import { Component, Message, Question } from '../../framework/components'
import { Table, Icon } from '../../framework/controls'
import { Modal, FieldSet } from '../../framework/containers'
import { renderComponent } from '../../framework/utils/renders'

import { RefundAccount } from '../../entities/pension/refund'
import ParticipationRefundAccountForm from './ParticipationRefundAccountForm'

export default class RefundAccountList extends Component {
    view() {
        const { membership, readOnly } = this.props 
        const { selected, addAccount, editAccount, errorMessage, deleting} = this.state
        const refundAccounts = membership.refundAccounts
        const columns = new Table.Headers(RefundAccount, 'name, address.desc, transit, eft, number, accountType.desc')
        columns['delete'] = { visible: !readOnly, width: 50, align:"center", cellClick: this.handleDelete.bind(this), headerClick: this.handleAddAccount.bind(this), format: () => renderComponent(<Icon icon='minus-circle' large/>),  headerSort: false , titleFormatter: () => renderComponent(<Icon icon='plus-circle' large className="v-bottom"/>)}
        columns['address.desc'].title = 'Bank Address'

        return <FieldSet title='Bank Accounts Info' className='mt-4' variant='page'>
            {errorMessage && <Message message={{ text: errorMessage, severity: 'danger' }} />}
            <Table
                id="refunds-accounts-table"
                data={[...refundAccounts.all]}
                entity={RefundAccount}
                columns={columns}
                onTableLoad={(table) => this.table = table}
                onSelect={!readOnly && this.handleSelect.bind(this)}
                selectable
                fitHeightToContent
                noPlaceHolder
            />
            {selected && (addAccount || editAccount) && <Modal className='w-60 h-80 modal-bg-color'>
                <ParticipationRefundAccountForm refundAccounts={refundAccounts} refundAccount={selected} onOK={this.handleAccountConfirm.bind(this)} onCancel={this.handleCancel.bind(this)} onChange={this.props.onChange}/>
            </Modal>}
            {deleting && <Question title='Are you sure you want to delete this account?' onAnswer={this.handleConfirmDelete.bind(this)} />}
        </FieldSet>
    }
    handleAddAccount() { this.setState({selected: new RefundAccount(), addAccount: true}) }
    handleCancel() { this.setState({selected: null, addAccount: false}) }
    handleSelect(row) { this.setState({selected: row, editAccount: true}) }
    handleAccountConfirm() {
        const { membership } = this.props
        if (this.state.addAccount) { membership.refundAccounts.push(this.state.selected) }
        this.setState({addAccount: false, editAccount: false})
        if (this.props.onChange) this.props.onChange()
    }
    handleDelete(e, cell) {
		e.preventDefault()
		e.stopPropagation()
        const { membership } = this.props
        const errors = membership.participations.all.reduce((error, participation) => {
            if(participation.payout.distribution.find(distrib => distrib.account.keyValue === cell.getRow().getData().keyValue)){
                error.push(participation.no)
            }
            return error
        }, [])
        const errorMessageNumberList = errors.join(', ')
        if(errors.length > 0) {
            this.setState({errorMessage: `Please remove this account from participation #${errorMessageNumberList} deposit account`})
        } else {
            this.setState({accountToDelete: cell.getRow().getData(), deleting: true, errorMessage: null})
        }
		
	}
    handleConfirmDelete(confirmed) {
        const { membership } = this.props
        if (confirmed) {
            membership.refundAccounts.pull(this.state.accountToDelete)
            this.setState({accountToDelete: null, deleting: false, errorMessage: null})
        } 
        if (this.props.onChange) this.props.onChange()
        this.setState({deleting: null})
	}
}