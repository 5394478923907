import { isObject } from "lodash";
import { Button, Dropdown } from "react-bootstrap"
import TaskBase from "./TaskBase";


const ButtonWithReasons = ({options = [], label, children, onClick, parentValue, cName}) => {
    if (options.length === 0) return <Button onClick={()=>onClick({...parentValue})} className={cName}>{children}</Button>

    return  <div>
            <Dropdown style={{position:'inherit'}}>
                <Dropdown.Toggle className={cName + ' no-mar'}>
                    {children}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {options.map(x=> 
                        <Dropdown.Item onClick={()=>onClick({...parentValue, reason: x.value })}>
                            {x.label}
                        </Dropdown.Item> 
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>

}

const TaskYesNoResolution = (props) => {
    
    const {event, onSave, yesLabel = 'Yes', noLabel = 'No', getYesChoices = ()=>{}, getNoChoices = ()=>{}, instance} = props;

    const handleSave = (result) => {
        event.metadata = result;
        if (onSave) onSave(event);
    }

    let yesOptions = getYesChoices({instance});
    let noOptions = getNoChoices({instance});
    let hasAnswer = isObject(event.metadata) && event.metadata.hasOwnProperty('answer');

    return <TaskBase {...props}>
            <ButtonWithReasons options={yesOptions} parentValue={{answer: true}}  onClick={handleSave} cName={(hasAnswer && event.metadata.answer ? 'btn-primary' : 'btn-secondary')}>{yesLabel}</ButtonWithReasons>
            <ButtonWithReasons options={noOptions}  parentValue={{answer: false}} onClick={handleSave} cName={(hasAnswer && !event.metadata.answer ? 'btn-primary' : 'btn-secondary')}>{noLabel}</ButtonWithReasons>
        </TaskBase>
            
}

export default TaskYesNoResolution;