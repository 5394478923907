import { Entity, Definition } from '../../framework/infra'
const { STRING, AMOUNT, DATE, NUMBER, PERIOD, PERCENTAGE, FACTOR } = Definition.types

export default class Rates extends Entity {

    static definitions = {
        jurisdiction: { type: STRING, text: 'Jurisdiction'},
        effPeriod: { type: PERIOD, text: 'Effective Period' },
        rptDate: { type: DATE, text: 'Reported Date' },
        deductionRate: { type: PERCENTAGE, text: 'Deduction Rate' },
        increassedRate: { type: PERCENTAGE, text: 'Deduction Rate (No CPP/QPP contributing or over YMPE)' },
        employerContribution: { type: FACTOR, text: 'Employer Contribution Factor' },
        ympe: { type: AMOUNT, text: 'Yearly Maximum Pensionable Earnings' },
        maxContributions: { type: AMOUNT, text: 'Maximum Contributions' },
        maxVoluntaryCQPP: { type: AMOUNT, text: 'Maximum Voluntary Contribution' },
        maxVoluntaryNonCQPP: { type: AMOUNT, text: 'Maximum Voluntary Contribution (No CPP/QPP contributing)' },
        moneyPurchaseLimit: { type: AMOUNT, text: 'Money Purchase Limit' },
        definedBenefitLimit: { type: AMOUNT, text: 'Defined Benefit Limit' },
        fundRateOfReturn: { type: PERCENTAGE, text: 'Fund Rate Of Return' },
        reduceAccrual: { type: PERCENTAGE, text: 'Reduce Accrual Rate' },
        reduceAccrualIncreased: { type: PERCENTAGE, text: 'Reduce Accrual Rate(No CPP/QPP contributing or over YMPE)' },
        monthlyInterestRate: { type: PERCENTAGE, text: 'Monthly Interest Rate' },
        maternityDuration: { type: NUMBER, text: 'Materninty Duration' },
        valuationRate: { type: PERCENTAGE, text: 'Valuation Rate' },
        erContribRetroChangeInt: { type: PERCENTAGE, text: 'Employer Contribution Retro rate Change Interest' },
        solRetroChangeInt: { type: PERCENTAGE, text: 'Solvency Retro Amount Change Interest' },
        aiwRate: { type: PERCENTAGE, text: 'AIW Rate' }
    }
}
