import { Service } from '../../framework'
import PersonResults from '../../entities/search/PersonSearch/PersonResults';

class PersonSearch extends Service {
    constructor() {
        super(PersonResults, 'Person', 'search')
    }

    static DEFAUT_PAGE_SIZE = 15;
   
    search(parameters, page, pageSize, scrollDuration, scrollId) {
        return this._search(parameters, page, pageSize, scrollDuration, scrollId);
    }
}

const instance = new PersonSearch()
export default instance

