import React from 'react'
import { Button, Form as RBForm, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import { Message, Question } from '../components'
import Icon from './Icon'

export default class Form extends React.Component {
    constructor (props){
        super(props)
        this.state = {}
    }

    render() {
        this.backUpOriginal()

        const { id, children, bypassDirty, disableSave, canDelete = true, deleteTooltip, isNew, isReadOnly, onCancel, onDelete, onSave, className, labelBtnSave, warningMessage, errorMessage, onClose, deleteMessage = 'Are you sure you want to delete?' } = this.props
        const { isDirty, isValid, canceling, deleting} = this.state
        //const validated = this.state.validated //Don't use form validation for the moment
    
        const validated = false
        return (<>
            <Message message={{text: this.state.errorMessage, severity:'danger'}}/>
            <RBForm ref={id + '_form'} id={id + '_form'} className={['e-form', className].join(' ')} noValidate validated={validated} onChange={this.handleChange.bind(this)}>
                <div className='e-form-body'>
                    {children}
                </div>  
                <div>
                    {warningMessage && <p className='bg-warning p-2 mt-3 rounded'>{warningMessage}</p>}
                    {errorMessage && <p className='text-danger mt-3'>{errorMessage}</p>}
                </div>    
                <div className='d-flex justify-content-end'> 
                    {(onCancel || onClose) && <Button key="cancel" variant="link" className='btn-secondary' onClick={this.handleCancel.bind(this)}>{onCancel ? "Cancel": "Close"}</Button>}
                    {onDelete && !isNew && (isReadOnly || !canDelete) &&<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{deleteTooltip}</Tooltip>}>
                        <span className="d-inline-block">
                            <Button 
                                key="delete" 
                                className='ml-2 mr-2 form-delete' 
                                type="submit" 
                                style={{pointerEvents: 'none'}}
                                disabled
                            >
                                <Icon icon='trash' /> Delete
                            </Button>
                        </span>
                    </OverlayTrigger>}
                    {onDelete && !isNew && (!isReadOnly && canDelete)  && <Button 
                        key="delete" 
                        className='ml-2 mr-2 form-delete' 
                        type="submit" 
                        onClick={this.handleDelete.bind(this)}
                    >
                        <Icon icon='trash' /> Delete
                    </Button>}
                    {onSave && !isReadOnly && <Button key="save" disabled={(!bypassDirty && (!isDirty || !isValid)) || disableSave} className='mr-2' type="submit" onClick={this.handleSave.bind(this)}>{labelBtnSave || 'Save'}</Button> }
                </div>
            </RBForm>
            {canceling && <Question title='You have unsaved changes, are you sure you want to cancel?' onAnswer={this.handleConfirmCancel.bind(this)} />}
            {deleting && <Question title={deleteMessage} onAnswer={this.handleConfirmDelete.bind(this)} />}
        </>)
    }

    forceDirty() {
        this.setState({isDirty: true})
    }
    
    validate() {
        const errorMsg = this.props.validate && this.props.validate(this.props.data, this.original)
        this.setState({validated: true, errorMessage: errorMsg, isValid: errorMsg === "" ? true : false})
        return errorMsg
    }
    displayMessage(msg) {
        this.setState({errorMessage: msg})
        return msg
    }

    isValidated() { return this.state.validated }
    
    backUpOriginal() {
        if (this.props.data && this.props.data !== this.current) {
            this.current = this.props.data
            this.original = this.props.data.clone()
            this.originalData = this.props.data.getData()
            this.setState({isDirty: false, validated: false, isValid: true})
        }
    }

    //TODO handle Cancel and Delete confirmations in the FORM
    handleCancel(event) {
        event.preventDefault();
        if (this.state.isDirty) {
            this.setState({canceling:true})
        } else if(this.props.onCancel) this.props.onCancel()
          else if(this.props.onClose) this.props.onClose()
    }
    handleConfirmCancel(confirmed) {
        if (confirmed) {
            if (this.state.isDirty) {
                if (this.original) this.props.data.setData(this.original, false) //This cause a problem list 
                this.setState({canceling: false, isDirty: false, errorMessage:'', validated: false, isValid: true})
            }
            if(this.props.onCancel) this.props.onCancel()
        } else {
            this.setState({canceling:false})
        }
        
    }
    handleDelete(event) {
        event.preventDefault();
        this.setState({deleting:true})
    }
    handleConfirmDelete(confirmed) {
        if (confirmed) {
            if(this.props.onDelete) this.props.onDelete(this.props.data)
        }
        this.setState({deleting:false})
    }


    handleSave(event) {
        event.preventDefault();
        const errorMsg = this.props.validate && this.props.validate(this.props.data, this.original)
        const form = ReactDOM.findDOMNode(this.refs[this.props.id + '_form'])
        if (form.checkValidity() === false || errorMsg) {
            this.setState({validated: true, errorMessage: errorMsg, isValid: false})
        } else {
            this.setState({isDirty: false, isValid: true, validated:false })
            if(this.props.onSave) this.props.onSave(this.props.data, this.original)    
        }
    }
    
    handleChange(event) {
        if (this.state.validated) this.validate()
        this.setState({isDirty: true})
        if(this.props.onChange) this.props.onChange(this.props.data, event.target.name, event.target.value, event.target.defaultValue)
    }
    
}
