import { Ref, Definition } from '../../framework/infra'
import { round, sum, map, moment, add } from '../../framework/utils/helper'
import { Period } from '../../framework/utils'
import Distribution from '../../entities/pension/Distribution'
import RemittanceDistribution from './RemittanceDistribution'
import RemittanceDetail from './RemittanceDetail'
import Remittances from './Remittances'
import RemittanceStatus from './RemittanceStatus'
import { YECertificationEvents } from './yeCertification/YECertificationEvent'

const { PERIOD, AMOUNT, DATE, BOOLEAN, STRING } = Definition.types

export default class Remittance extends Ref {
    get regular() { return this.contributions.reg }
    get maternity() { return this.contributions.mat }
    get longTerm() { return this.contributions.ltd }
    get self() { return this.contributions.slf }
    get voluntary() { return this.contributions.vol }
    get deductions() { return this.contributions.deductions }
    get totalEeContribsCurYr() { 
        return round(((this.regular ?? 0) + (this.maternity ?? 0) +  (this.longTerm ?? 0) + (this.self ?? 0) + (this.eeCurYearAdjustments ?? 0)));
    }
    get totalEeContribs() {
        return round(((this.regular ?? 0) + (this.maternity ?? 0) +  (this.longTerm ?? 0) + (this.self ?? 0) + (this.eeAdjustments ?? 0)));
    }

    /** totalErContribs: erContribs + erCurYearAdjustments */
    get totalErContribs() {
        // Total Employer Contributions (Current year)
        // = "Employer Contributions" (erContribs) + "Employer Adjustments (Current Year)" (erCurYearAdjustments)
        return round((this.erContribs ?? 0) + (this.erCurYearAdjustments ?? 0));
    }

    get eeAdjustments() { 
        return sum(this.adjustments, 'employeeReg') 
            + sum(this.adjustments, 'employeeLtd') 
            + sum(this.adjustments, 'employeeMat') 
            + sum(this.adjustments, 'employeeSlf') 
            + sum(this.adjustments, 'employeeVol'); 
    }
    get eeCurYearAdjustments() { 
        return sum(this.curYearAdjustments, 'employeeReg') 
            + sum(this.curYearAdjustments, 'employeeLtd') 
            + sum(this.curYearAdjustments, 'employeeMat') 
            + sum(this.curYearAdjustments, 'employeeSlf') 
            + sum(this.curYearAdjustments, 'employeeVol'); 
    }
    /** erAdjustments: sum of employerContribution in all adjustments */
    get erAdjustments() { return sum(this.adjustments, 'employerContribution') }
    get erAdjustmentCmt() {
        // exclude used mandatory and used retro employer, as it's in column X (Credit to offset Employer Contributions & Interest during a holiday)
        const adjsWithoutUsedMandatoryAndUsedRetro = this.curYearAdjustments?.filter(x => x.cmt && x.type.key !== 'MECHR' && x.type.key !== 'RCRR'
        && (x.distributionContribution?.all?.find(y => y.ta === "r") || x.employerContribution));
        return adjsWithoutUsedMandatoryAndUsedRetro.map(x => {
            const distributionsInfo = x.distributionContribution?.all?.map(y => `${Distribution.definitions.ta.options.find(z => z.value === y.ta)?.text || y.ta} ${round(y.am)}`)?.join(', ');
            return distributionsInfo ? `${x.cmt} {${distributionsInfo}}` : x.cmt;
        }).join(' ; ');
    } 
    get curYearAdjCmt() {
        return this.curYearAdjustments?.filter(x => x.cmt).map(x => {
            const distributionsInfo = x.distributionContribution?.all?.map(y => `${Distribution.definitions.ta.options.find(z => z.value === y.ta)?.text || y.ta} ${round(y.am)}`)?.join(', ');
            return distributionsInfo ? `${x.cmt} {${distributionsInfo}}` : x.cmt;
        }).join(' ; ');
    }
    get erAdjustmentCmtER() {
        return this.curYearAdjustments?.filter(x => x.cmt).map(x => {
            const distributionsInfo = x.distributionContribution?.all?.filter(y => y.ta === "r")?.map(y => `${Distribution.definitions.ta.options.find(z => z.value === y.ta)?.text || y.ta} ${round(y.am)}`)?.join(', ');
            return distributionsInfo ? `${x.cmt} {${distributionsInfo}}` : x.cmt;
        }).join(' ; ');
    }
    /** erCurYearAdjustments: sum of employerContribution in all adjustments except MECHR and RCRR adjustments */
    get erCurYearAdjustments() {
        // exclude used mandatory and used retro employer, as it's in column X (Credit to offset Employer Contributions & Interest during a holiday)
        const adjsWithoutUsedMandatoryAndUsedRetro = this.curYearAdjustments.filter(x =>  x.type.key !== 'MECHR' && x.type.key !== 'RCRR' && (x.distributionContribution?.all?.find(y => y.ta === "r") || x.employerContribution) );
        return round(sum(adjsWithoutUsedMandatoryAndUsedRetro, 'employerContribution')) ; 
    }
    /** erCurYearAdjustmentsNoCredit: sum of employerContribution in all adjustments except isCredit adjustments */
    get erCurYearAdjustmentsNoCredit() {
        return round(sum(this.curYearAdjustments.filter(adj => !adj.type?.config?.isCredit), 'employerContribution')) ; 
    }
    get volAdjustments() { return sum(this.adjustments, 'voluntary') }
    get intAdjustments() { return sum(this.adjustments, 'interest') }
    get priorIntAdjustments() { return sum(this.priorYearsAdjustments, 'interest') }
    get totalErAdjustmentsNoCredit() {
        return round((this.erCurYearAdjustmentsNoCredit ?? 0) + (this.totalPriorErAdjustmentsManual ?? 0) + (this.priorIntAdjustments ?? 0))
    }
    get solAdjustments() { return sum(this.adjustments, 'solvency') }
    get solAdjustmentsCmt() { return this.adjustments?.filter(x => x.voluntary && x.cmt)?.map(x => x.cmt)?.join(' ; ')} 
    get intAdjustmentsCmt() { 
        return this.adjustments?.filter(x => x.interest && x.cmt)?.map(x => {
            const distributionsInfo = x.distributionContribution?.all?.map(y => `${Distribution.definitions.ta.options.find(z => z.value === y.ta)?.text || y.ta} ${round(y.am)}`)?.join(', ');
            return distributionsInfo ? `${x.cmt} {${distributionsInfo}}` : x.cmt;
        }).join(' ; ');
    } 

    get eeAdjustedContribs() { return round(this.deductions + this.eeAdjustments) }
    /** erAdjustedContribs: erContribs + erAdjustments (sum of employerContribution in all adjustments) */
    get erAdjustedContribs() { 
        return round(this.erContribs + this.erAdjustments);
    }
    get erAdjustedContribsNoCredit() { 
        
        return round(this.erContribs + this.totalErAdjustmentsNoCredit);
    }
    get volAdjustedContribs() { return round(this.voluntary + this.volAdjustments) }
    get solAdjusted() { return round(this.solvency + this.solAdjustments) }
    get intAdjusted() { return round(this.interest + this.intAdjustments) }

    get solvencyDeficit() {
        const totalSolvencyCredit = this.adjustments
        .filter(adjustment => 
            adjustment.type.config?.isCredit 
            && adjustment.total < 0 
            && adjustment.type.config?.targetAccounts?.[0] === 's'
        )
        .reduce((prev, adjustment) => prev + adjustment.total, 0);
        return round(this.solAdjusted - totalSolvencyCredit);
    }

    get creditUsedNegative() {
        if(this.creditUsed) return round(-this.creditUsed);
        return round(0);
    }

    get intLatePaymentAdjustments() {
        return sum(this.curYearAdjustments, 'interestOnLatePayment');
    }

    /** totalRemainingBalance: principalMandatoryEmployerHoliday - accumulatedMandatoryOffsetCreditUsed + currentServiceAdjustment - accumulatedCurrentServiceCreditUsed */
    get totalRemainingBalance() {
        return round(this.principalMandatoryEmployerHoliday - this.accumulatedMandatoryOffsetCreditUsed + this.currentServiceAdjustment - this.accumulatedCurrentServiceCreditUsed);
    }

    /** totalOwingSum: totalOwingPrePaymentSum + totalPaymentsNegative */
    get totalOwingSum() {
        return round(this.totalOwingPrePaymentSum + (this.totalPaymentsNegative ?? 0));
    }

    /** totalOwingPrePaymentSum: totalCurrentServiceContribs + solvencyDeficit + interest + creditUsedNegative + prevTotalOwing */
    get totalOwingPrePaymentSum() {
        return round((this.totalCurrentServiceContribs ?? 0) + (this.solvencyDeficit ?? 0) + (this.interest ?? 0)
        + (this.creditUsedNegative ?? 0)  + (this.prevTotalOwing ?? 0));
    }

    /** interestSum: interest + intLatePaymentAdjustments */
    get interestSum() { // totalIntLatePayment
        return round((this.interest ?? 0) + (this.intLatePaymentAdjustments ?? 0) );
    }

    get totalPriorEeAdjustments() { 
        return sum(this.priorYearsAdjustments, 'employeeReg') 
            + sum(this.priorYearsAdjustments, 'employeeLtd') 
            + sum(this.priorYearsAdjustments, 'employeeMat') 
            + sum(this.priorYearsAdjustments, 'employeeSlf') 
            + sum(this.priorYearsAdjustments, 'employeeVol'); 
    }

    get totalPriorErAdjustments() {
        return sum(this.priorYearsAdjustments, 'employerContribution') ;
    }
    get totalPriorErAdjustmentsManual() {
        // only manual ER adjustments
        const adjsWithoutUsedMandatoryAndUsedRetro = this.priorYearsAdjustments.filter(x => x.type.key === 'MAN');
        return round(sum(adjsWithoutUsedMandatoryAndUsedRetro, 'employerContribution')) ; 
    }

    get priorErAdjustmentCmt() {
        return this.priorYearsAdjustments.filter(x => (x.distributionContribution?.all?.find(y => y.ta === "r") || x.employerContribution) && x.cmt)
        .map(x => {
            const distributionsInfo = x.distributionContribution?.all?.map(y => `${Distribution.definitions.ta.options.find(z => z.value === y.ta)?.text || y.ta} ${round(y.am)}`)?.join(', ');
            return distributionsInfo ? `${x.cmt} {${distributionsInfo}}` : x.cmt;
        }).join(' ; ');
    }

    /** totalPayments: sum of all amounts of payments.nonRejectedPayments */
    get totalPayments() { return sum(this.payments.nonRejectedPayments.all, 'amount') }
    /** totalPaymentsNegative: -totalPayments */
    get totalPaymentsNegative() { 
        if(this.totalPayments) return round(-this.totalPayments);
        return round(0);
     }
     /** balance: prevBalance + total - totalPayments + (appliedCredit + creditUsed) */
    get balance() {
        const calculatedSum = round(
            this.prevBalance
            + this.total 
            - this.totalPayments
            + (this.appliedCredit + this.creditUsed) // cumulative remaining credit
        );
        return calculatedSum;
    }

    /** appliedCredit: sum of total of (isStartCredit and not isCancelCredit) adjustments
     * 
     * Get all start credit adjustments (MECH, RCR, SOC). Does not include start credit adjustments that cancel credit (MECH, RCR or SOC adjs with positive amount). */
    get appliedCredit() {
        if (this.isBeforeTotalOwingStartPeriod()) return 0;
        return this.adjustments.filter(adj => adj.type.config.isStartCredit && !adj.isCancelCredit).reduce((total, adj) => add(adj.total, total), 0);
    }

    /** appliedCreditAndCancel: sum of total of isStartCredit adjustments
     * 
     * Get all start credit adjustments (MECH, RCR, SOC). Includes start credit adjustments that cancel credit (MECH, RCR or SOC adjs with positive amount). */
    get appliedCreditAndCancel() {
        if (this.isBeforeTotalOwingStartPeriod()) return 0;
        return this.adjustments.filter(adj => adj.type.config.isStartCredit || adj.isCancelCredit).reduce((total, adj) => add(adj.total, total), 0);
    }

    /** total: deductions + voluntary + erContribs + [totalAdjustments | adjustmentsUsedTotal (totalAdjustments - creditUsed - appliedCredit + -creditUsed)] + solvency + interest */
    get total() {
        const adjustmentsUsedTotal = this.validated && this.isBeforeCreditCalcsStartPeriod()
            ? this.totalAdjustments
            : this.getAdjustmentsUsed();
            const calculatedSum = sum([
                this.deductions,
                this.voluntary,
                this.erContribs,
                adjustmentsUsedTotal,
                (this.solvency || 0),
                this.interest,
            ].map(x=>({amount: x})), 'amount'); 
        return calculatedSum; 
    }

    /** totalOwing: balance | (finalDistrBalance - totalNegativeCredit + creditUsed) */
    get totalOwing() { 
        const cancelledUsedCredits = this.adjustments.filter(x => x.type.config.isCancelUsedCredit);
        const cancelledUsedCreditsTotal = sum(cancelledUsedCredits, 'total');
        return this.isBeforeTotalOwingStartPeriod() ? this.balance : round(this.finalDistrBalance.balance - round(this.totalNegativeCredit + this.creditUsed));
    }

    get validated() { return this.status.isValidated()}
    get historicRates() { return this.employer.plan.historicRates }
    get rates() { return this.historicRates.getRatesAtPeriod(this.period)}

    hasOverPayment() {
        return this.finalDistrBalance.eeBal < 0 || this.finalDistrBalance.erBal < 0 || this.finalDistrBalance.volBal < 0
            || this.finalDistrBalance.solBal < 0 || this.finalDistrBalance.intBal < 0;
    }

    hasZeroNonEmployeeBalance() {
        return this.finalDistrBalance.erBal <= 0 && this.finalDistrBalance.solBal <= 0 && this.finalDistrBalance.intBal <= 0;
    }

    /**
     * Check if the event is a event with a pointer to this remittance
     * @param {*} ev Employment event or participation event
     * @returns true if the event has a pointer to this remittance
     */
    isRemittanceEvent(ev){
        return ev.pointers?.all?.some(p => (p.registryKey === 'remittances' && p.name === 'remittance'  && p.instanceKey === `${this?.employer?.code}_${this?.period?.value}`));
    }

    /** 
     * Returns the employment events and participation events with a pointer to this remittance
     * @returns {{personName: string | undefined; eventStatus: string | undefined; eventDesc: string | undefined; eventEffDt: string | undefined;}[]}
     * */
    getLinkedEvents(){
      return this?.details?.all?.filter(detail => detail.employment?.events?.all?.some(ev => this.isRemittanceEvent(ev)) || detail.employment?.participation?.events?.all?.some(ev => this.isRemittanceEvent(ev)))
        .map(detail => {
           return [...detail.employment?.events?.all?.filter(ev => this.isRemittanceEvent(ev))]
            .concat([...detail.employment?.participation?.events?.all?.filter(ev => this.isRemittanceEvent(ev))])
            .map(event => ({
                personName: detail.person?.name,
                eventStatus: event.stsCode,
                eventDesc: event.desc,
                eventEffDt: event.effDt
            }))
        })
        .flat();
    }

    /** Returns true if this remittance's details have employment events with a pointer to this remittance, or participation events with a pointer to this remittance */
    hasLinkedEvents(){
        return this?.details?.all?.some(detail => 
            // has employment events with a pointer to this remittance
            detail.employment?.events?.all?.some(ev => this.isRemittanceEvent(ev))
            // has participation events with a pointer to this remittance
            || detail?.employment?.participation?.events?.all?.some(ev => this.isRemittanceEvent(ev)));
    }

    // Because we don't want to load the full list of adjustments, we need to get the total adjustments used, 
    // while only getting total of non-credit adjustments + actual credit used
    // only used to calculate total
    /** Get adjustments used: (totalAdjustments - creditUsed) - appliedCreditAndCancel + -creditUsed */
    getAdjustmentsUsed() {
        //make credit used value a negative to offset contributions
        return round((this.totalAdjustments - (this.creditUsed)) - this.appliedCredit + (-this.creditUsed));
    }

    isOpen() { 
        const today = moment();
        const lastDayOfNextPeriod = moment(this.period.inc().dateEndPeriod).endOf('day');
        return !this.isLegacy() && (!this.validated || !today.isAfter(lastDayOfNextPeriod)) 
    } 
    isClose() { return !this.isOpen() }
    isCurrent() { return Period.getCurrentPeriod().isSame(this.period)}
    isDue() { return Period.getCurrentPeriod().dec().isSame(this.period) }
    isOverdue() { return Period.getCurrentPeriod().dec(2).isSame(this.period) }
    isSuccessfull() { return this.getDetailsInError().length === 0 }
    isEmpty() {return this.deductions === 0}
    isLegacy() { return this.period.isBefore(Period.getLaunchPeriod()) }
    isBeforeCreditCalcsStartPeriod() { return this.period.isBefore(Period.getCreditCalcStartPeriod()) }
    isBeforeTotalOwingStartPeriod() { return this.period.isBefore(Period.getTotalOwingStartPeriod()) }
    getDetailsInError() { return this.details && this.details.filter(detail => detail.message && detail.message.isError()) }
    getDetailsInWarning() { return this.details && this.details.filter(detail => detail.message && detail.message.isWarning()) }
    
    assignEarningTypes(earningTypes) { this.details.forEach(detail => detail.earnings.assignEarningTypes(earningTypes)) }
    hasPaymentBeforeCreditAdjustment(adjustment) {
        return this.payments.all.find(payment => {
            const paymentReceived = new Period(payment.rcvDate);
            const adjustmentReportedDate = new Period(adjustment.rts);
            return paymentReceived.isBefore(adjustmentReportedDate);
        })
    }

    static definitions = {
        employer: { ref: require('../employment/Employer'), text: 'Employer' },
        period: { type: PERIOD, text: 'Period' },
        yeCertificationEvents: { ref: YECertificationEvents },
        contributions: {ref: require('./contributions/Contributions'), text: 'Contributions', text_fr: 'Contributions' },
        payments: { ref: require('../financial/trustee/Payments'), text: 'Payments', text_fr: 'Payments' },
        
        importedDate: { type: DATE, text: 'Imported Date', },
        prevBalance: { type: AMOUNT, text: 'Previous Balance' },
        prevTotalOwing: { type: AMOUNT, text: 'Previous Total Owing' },
        solvency: { type: AMOUNT, text: 'Solvency', text_fr: 'Solvency' },
        interest: { type: AMOUNT, text: 'Interest', text_fr: 'Intérêt' },
        erContribs: { type: AMOUNT, text: 'Employer Contributions'},
        totalAdjustments: { type: AMOUNT, text: 'Adj. Contributions',text_fr: 'Adjustments' },
        totalNegativeCredit: { type: AMOUNT, text: "Available credit" },
        appliedCredit: { abstract: true, type: AMOUNT, text: "Original credits in period" },
        creditUsed: { type: AMOUNT, text: 'Credit Used' },
        creditUsedNegative: { abstract: true, type: AMOUNT, text: 'Credit Used Negative' },
        shouldRecalculate: { type: BOOLEAN, text: 'Remittance needs recalculation' },

        status: { ref: require('./RemittanceStatus'), text: 'Status', default: RemittanceStatus.types.n },
        cmt: { type: STRING, text: 'Comment' },

        details: { transient: true, list: true, ref: RemittanceDetail, text: 'Details', text_fr: 'Details' },
        adjustments: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Adjustments', text_fr: 'Adjustments' },
        
        curYearAdjustments: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Current Year Adjustments'},
        priorYearsAdjustments: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Prior Years Adjustments'},
        
        startDistrBalance: {  transient: true, ref: RemittanceDistribution },
        currentDistrBalance: {  transient: true, ref: RemittanceDistribution },
        endDistrBalance: {  transient: true, ref: RemittanceDistribution },
        finalDistrBalance: {  transient: true, ref: RemittanceDistribution },
        lockedBalance: { ref: RemittanceDistribution },
        paidDateContribs: { transient: true, type: DATE, text: 'Paid Date'},
        paidDateSolvency: { transient: true, type: DATE, text: 'Solvency Paid Date'},


        
        adjusted: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Adjustments', text_fr: 'Adjusted' },
        rates: {abstract: true, ref: require('../pension/Rates'), text: 'Rates', text_fr: 'Rates'  }, //TODO support 'inherited'
        historicRates: {abstract: true, inherited: true, ref: require('../pension/HistoricRates') },

        regular: { abstract: true, type: AMOUNT, text: 'Regular'},
        maternity: { abstract: true, type: AMOUNT, text: 'Maternity'},
        longTerm: { abstract: true, type: AMOUNT, text: 'Long Term'},
        self: { abstract: true, type: AMOUNT, text: 'Self'},
        voluntary: { abstract: true, type: AMOUNT, text: 'Voluntary'},
        deductions: { abstract: true, type: AMOUNT, text: 'Employee Contribs'},

        totalEeContribsCurYr:{ abstract: true, type: AMOUNT, text: 'Total Employee Contributions (Current year)'},
        totalEeContribs:{ abstract: true, type: AMOUNT, text: 'Total Employee Contributions'},
        totalErContribs:{ abstract: true, type: AMOUNT, text: 'Total Employer Contributions (Current year)'},
        erAdjustmentCmt: { abstract: true, type: STRING, text: 'Current year adjustments',text_fr: 'Current year adjustments' },
        curYearAdjCmt: { abstract: true, type: STRING, text: 'Current year Adjustment Comments'},
        erAdjustmentCmtER: { abstract: true, type: STRING, text: 'ER Adjustment Comment' },

        priorYearAdj:{ abstract: true, type: AMOUNT, text: 'Prior Year Adjustments'},
        
        eeAdjustments: { abstract: true, type: AMOUNT, text: 'Employee Adjustments',},
        erAdjustments: { abstract: true, type: AMOUNT, text: 'Employer Adjustments'},

        eeCurYearAdjustments: {abstract: true, type: AMOUNT, text: 'Employee Adjustments (Current Year)'},
        erCurYearAdjustments: {abstract: true, type: AMOUNT, text: 'Employer Adjustments (Current Year)'},

        eeAdjustmentsList: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Employee Adjustments',text_fr: 'Employee Contributions Adjustments' },
        erAdjustmentsList: { transient: true, list: true, ref: require('./adjustment/Adjustment'), text: 'Employer Adjustments',text_fr: 'Employer Adjustments' },

        erAdjustmentsCmt: { type: STRING, text: 'Employer Adjustments Comments',text_fr: 'Employer Adjustments Comments' },
        volAdjustments: { abstract: true, type: AMOUNT, text: 'Voluntary Adjustments',text_fr: 'Employee Voluntary Contributions Adjustments' },
        solAdjustments: { abstract: true, type: AMOUNT, text: 'Solvency Adjustments',text_fr: 'Solvency Adjustments' },
        solAdjustmentsCmt: { abstract: true, type: STRING, text: 'Solvency Adjustments Comments',text_fr: 'Solvency Adjustments Comments' },
        intAdjustments: { abstract: true, type: AMOUNT, text: 'Interest Adjustments',text_fr: 'Interest Adjustments' },
        priorIntAdjustments: { abstract: true, type: AMOUNT, text: 'Interest Adjustments (Prior Years)' },
        intAdjustmentsCmt: { abstract: true, type: STRING, text: 'Interest Adjustments Comments',text_fr: 'Interest Adjustments Comments' },

        totalPriorEeAdjustments: { abstract: true, type: AMOUNT, text: 'Total Employee Adjustments (Prior Years)',text_fr: 'Total Employee Adjustments (Prior Years)' },
        totalPriorErAdjustments: { abstract: true, type: AMOUNT, text: 'Total Employer Adjustments (Prior Years)',text_fr: 'Total Employer Adjustments (Prior Years)' },
        totalPriorErAdjustmentsManual: { abstract: true, type: AMOUNT, text: 'Employer [ER] Adjustments (Prior Years)'},
        priorErAdjustmentCmt: { abstract: true, type: STRING, text: 'ER Adjustment Comment',text_fr: 'ER Adjustment Comment' },

        totalCurrentServiceContribs: { type: AMOUNT, text: 'Total Current Service Contributions'},
        solvencyDeficit: {abstract: true, type: AMOUNT, text: 'Solvency Deficit'},
        totalErAdjustmentsNoCredit: {abstract: true, type: AMOUNT, text: 'Total ER Adjustments'},
        intLatePaymentAdjustments:{abstract: true, type: AMOUNT, text: 'Interest on Late Payment Adjustment'},

        // set in RemittancesLogReport because it needs other remittances to be calculated
        principalMandatoryEmployerHoliday: {type: AMOUNT, text: 'Principal Mandatory Employer Holiday'},
        // set in RemittancesLogReport because it needs other remittances to be calculated
        accumulatedMandatoryOffsetCreditUsed: { type: AMOUNT, text: 'Accumulated Mandatory Offset Credit Used'},
        // set in RemittancesLogReport because it needs other remittances to be calculated
        currentServiceAdjustment: { type: AMOUNT, text: 'Current Service Adjustment'},
        // set in RemittancesLogReport because it needs other remittances to be calculated
        accumulatedCurrentServiceCreditUsed: { type: AMOUNT, text: 'Accumulated Current Service Credit Used'},

        // set in RemittancesLogReport because it needs other remittances and adjustments to be calculated
        totalEmployerErContributions: { type: AMOUNT, text: 'Total Employer [ER] Contributions'},

        totalRemainingBalance: {abstract: true, type: AMOUNT, text: 'Total Remaining Balance'},
        totalOwingPrePaymentSum: {abstract: true, type: AMOUNT, text: 'Total Owing (pre-payment)'},
        totalOwingSum: {abstract: true, type: AMOUNT, text: 'Total Owing'},
        interestSum: {abstract: true, type: AMOUNT, text: 'Total Interest on Late Payment'},

        // Contributions after adjustments are applied
        eeAdjustedContribs: { abstract: true, type: AMOUNT, text: 'Employee',text_fr: 'Employé' },
        erAdjustedContribs: { abstract: true, type: AMOUNT, text: 'Employer',text_fr: 'Employeur' },
        erAdjustedContribsNoCredit: { abstract: true, type: AMOUNT, text: 'Total Employer [ER] Contributions' },
        volAdjustedContribs: { abstract: true, type: AMOUNT, text: 'Voluntary',text_fr: 'Volontaire' },
        solAdjusted: { abstract: true, type: AMOUNT, text: 'Solvency',text_fr: 'Solvency' },
        intAdjusted: { abstract: true, type: AMOUNT, text: 'Interest',text_fr: 'Interest' },    
        
        totalOwing: { abstract: true, type: AMOUNT, text: 'Total Owing', text_fr: 'Total dû' },
        total: { abstract: true, type: AMOUNT, text: 'Total',text_fr: 'Total' },
        totalPayments: { abstract: true, type: AMOUNT, text: 'Total Payments'},
        totalPaymentsNegative: { abstract: true, type: AMOUNT, text: 'Total Payments Negative'},
        balance: { abstract: true, type: AMOUNT, text: 'Balance', text_fr: 'Balance' },
        validated: {abstract: true, type: BOOLEAN, text: 'Validated'},

    }

    static key = ['employer', 'period']
    static refList = Remittances

}
