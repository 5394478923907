import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../framework/components'
import { Tabs } from '../../framework/containers'
import useNotification from '../../hooks/useNotification'

import ConfigService from '../../services/config/ConfigService'
import ConfigBankForm from './bank/ConfigBankForm'
import ConfigCorporateForm from './corporate/ConfigCorporateForm'
import _ from 'lodash'
import Loading from '../../components/containers/Loading'

const SetUpPage = () => {
    const { addMessage } = useNotification();
    const [bank, setBank] = useState();
    const [originalBank, setOriginalBank] = useState();
    const [corporate, setCorporate] = useState();
    const [originalCorporate, setOriginalCorporate] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        Promise.all([
            ConfigService.getConfig('corporate'),
            ConfigService.getConfig('bank'),
        ]).then(([config_corporate, config_bank]) => {
            if (isMounted) {
                setBank(config_bank);
                setOriginalBank(config_bank.clone());
                setCorporate(config_corporate);
                setOriginalCorporate(config_corporate.clone());
                setIsLoading(false);
            }
        })

        return () => { isMounted = false };
    }, []);

    const handleCancel = (id) => {
        if (id === "bank") {
            setBank(originalBank);
        } else if (id === "corporate") {
            setCorporate(originalCorporate);
        }
        const verb = _.startCase(_.toLower(id));
        addMessage(`${verb} modifications have been cancelled`, "success");
    }

    const handleSave = (id, config, original) => {
        config.id = id;
        setIsLoading(true);
        const verb = _.startCase(_.toLower(id));
        ConfigService.updateConfig(id, config).then(() => {
            addMessage(`${verb} config saved successfully`, "success");
            setIsLoading(false);
        });   
    }

    return (
        <div className="px-4 h-100">
            <PageTitle>Configuration</PageTitle>
            {!isLoading ? (
                <div className="row">
                    <Tabs className="mt-1 w-100" bodyClassName="card bg-color">
                        <Tabs.Tab name="bank" title="Bank">
                            <ConfigBankForm
                                config={bank}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        </Tabs.Tab>
                        <Tabs.Tab name="corporate" title="Corporate">
                            <ConfigCorporateForm
                                config={corporate}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        </Tabs.Tab>
                    </Tabs>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default SetUpPage;
