import { useEffect, useState } from "react";
import { Auth } from "../framework";

const useUser = () => {
    const [user, setUser] = useState();
    useEffect(() => { 
        let isMounted = true;

        Auth.getCurrentUser().then(user => { 
            if (isMounted) {
                setUser(user) 
            }
        });

        return () => { isMounted = false };
    }, [])

    return user ?? {};
}

export default useUser;